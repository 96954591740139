import {Renters} from '@/Apps/Renters/Interfaces/RentersInterface';
import {reactive, Ref, ref, UnwrapNestedRefs} from 'vue';
import SpaFormStorageService from '@/services/SpaFormStorageService';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { StoreDefinition } from 'pinia';
import { useStore } from '@/Composables/Store/Store';
import { RentersStore } from '@/Composables/Store/Types/Renters';

interface RenterFormFields {
    programIc: string;
    products: Renters[];
    marketingConsents: DynamicDictionary;
    insuredAddress: DynamicDictionary;
    insuredFirstName: string;
    insuredLastName: string;
    insuredIdentityNumber: string;
    insuredCountry: DynamicDictionary;
    insuredBirthDate: string;
    agreeTerms: boolean;
    holderEmail: string;
    coverage: string;
    additionalOptions: DynamicDictionary;
    insuredIsResident: boolean;
    insuredIsAuthenticated: boolean;
    policyStartDate: Date;
    policyHolderSameAsInsured: boolean;
    policyHolderIsResident: boolean;
    policyHolderCountry: DynamicDictionary;
    policyHolderFirstName: string;
    policyHolderLastName: string;
    policyHolderEmail: string;
    policyHolderPhone: string;
    policyHolderIdentityNumber: string;
    policyHolderBirthDate: string;
}

export default class RentersService {
    public renters: Ref<Renters[]> = ref([] as Renters[]);
    public fields!: UnwrapNestedRefs<RenterFormFields>;
    public store: StoreDefinition<string, RentersStore> = useStore().renters();
    private static instance: RentersService;
    private spaFormStorageService: SpaFormStorageService = SpaFormStorageService.getInstance();

    public static getInstance(): RentersService {
        if (!RentersService.instance) {
            RentersService.instance = new RentersService();
        }
        RentersService.instance.init();

        return RentersService.instance;
    }

    public init(): void {
        if (!this.fields) {
            this.fields = reactive(this.spaFormStorageService.formStorageFields());
        }
        this.spaFormStorageService.applyDataProviderCallback((): RenterFormFields => this.fields);
    }

    public updateRoute(): void {
        this.store().updateRoute();
    }

    public destroyRoute(): void {
        this.store().destroyRoute();
    }
}
