import Form from '@/assets/libraries/form/form';
import FormField from '@/assets/libraries/form/form-field';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {SpaValidator} from '@/Types/SpaValidatorType';
import {markRaw, reactive, ref, Ref, UnwrapNestedRefs} from 'vue';
import IdleTimer from '@/services/idle.timer.service';
import AppEnvironment from '@/assets/libraries/app/app-environment';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import {useFormatter} from '@/Composables/Formatter';
import Url from '@/Enums/UrlEnum';
import FormStorageElement from '@/interfaces/form.storage.element.interface';
import FormStorage from '@/interfaces/form.storage.interface';
import {useDefine} from '@/Composables/Define';

export default class SpaUserStorage {
    public form: Form = markRaw(new Form());
    public fields: UnwrapNestedRefs<DynamicDictionary> = {};

    private static instance: SpaUserStorage;
    private type: DynamicDictionary = {};
    private storageFields: UnwrapNestedRefs<DynamicDictionary> = reactive({});
    private ActiveIntervalMs: number = 300000;
    private IdleTimerDelay: number = 3;
    private idleTimer!: IdleTimer;
    private initialized: boolean = false;
    private saveFormOnUnload: boolean = false;

    private cachedStorageData: Ref<string> = ref('');
    private storingLocked: Ref<boolean> = ref(false);

    private constructor() {
        if (!(new AppEnvironment()).isAcceptanceTest()) {
            this.idleTimer = new IdleTimer((): void => {
                this.saveStorages();
            }, this.IdleTimerDelay);
            this.idleTimer.start();
            $((): void => {
                document.addEventListener('visibilitychange', (): void => {
                    if (document.visibilityState === 'hidden') {
                        this.saveStorages();
                        if (this.saveFormOnUnload) {
                            this.saveStoragesToDb();
                        }
                    }
                });
                setInterval((): void => {
                    this.saveStorages();
                }, this.ActiveIntervalMs);
            });
        }
    }

    public static getInstance(): SpaUserStorage {
        if (!SpaUserStorage.instance) {
            SpaUserStorage.instance = new SpaUserStorage();
        }

        return SpaUserStorage.instance;
    }

    public newForm(formName: string): Form {
        this.form.create(formName);
        this.restoreStorages();

        return this.form;
    }

    public init(type: DynamicDictionary, storageFields: UnwrapNestedRefs<DynamicDictionary> = {}, saveFormOnUnload: boolean = false): void {
        this.type = type;
        this.storageFields = storageFields;
        this.initialized = true;
        this.saveFormOnUnload = saveFormOnUnload;
    }

    public setupForm(validators: SpaValidator[] = [], initialValues: LimitedVariant[] = []): void {
        if (this.initialized) {
            Object.keys(this.type).forEach((field: string, index: number): void => {
                const formValidators: string | Record<string, any> = useDefine().isSet(validators[index]) ?
                    validators[index] : '';
                const formInitialValue: LimitedVariant = useDefine().isSet(initialValues[index]) ?
                    initialValues[index] : '';
                this.form.addField(new FormField(
                    this.type[field],
                    formInitialValue,
                    formValidators));
            });
            this.form.setReady();
        } else {
            Error.log(ErrorType.Error, 'SpaUserStorage', '.setupForm() is called before .init(...)');
        }
    }

    public storeForm(): void {
        if (!this.initialized || this.form.name !== '') {
            Object.keys(this.type).forEach((field: string): void => {
                const key: string = this.type[field];
                this.fields[key] = this.form.field(key).value;
            });
        }
    }

    public saveUpdatedStorage(): void {
        this.storeForm();
        this.saveStorages();
    }

    public resetStorages(): void {
        if (this.form.name !== '') {
            Object.keys(this.storageFields).forEach((field: string): void => {
                this.storageFields[field] = '';
            });
            Object.keys(this.type).forEach((field: string): void => {
                const key: string = this.type[field];
                this.form.field(key).clear().then((): void => {
                    this.fields[key] = this.form.field(key).value;
                });
            });
            sessionStorage.removeItem(this.form.name);
        }
    }

    public saveStoragesToDb(): void {
        const cachedData: string = this.cachedStorageData.value;
        const token: string = $("meta[name=csrf-token]").attr("content") ?? '';
        const oneBaseService: OneBase = OneBaseService.getInstance();
        const currentUid: string = oneBaseService.userStorage.userStoreUid;
        const currentFacility: string = oneBaseService.facility();
        const currentStep: string = oneBaseService.currentStep().toString();
        const headers: Object = {
            type: 'application/json',
        };
        const storageData: FormStorage = this.transformedFormStorage();
        const body: string = JSON.stringify({
            _token: token,
            uid: currentUid,
            facility: currentFacility,
            step: currentStep,
            forms: [storageData],
        });
        if (cachedData !== body && !this.storingLocked.value) {
            const blob: Blob = new Blob([body], headers);
            if (!navigator.sendBeacon(useFormatter().formattedUrl(Url.Ajax.formStore), blob)) {
                Error.log(ErrorType.Error, 'SpaUserStorage::saveStoragesToDb', 'sendBeacon() failed');
                Error.log(ErrorType.Error, 'SpaUserStorage::saveStoragesToDb -> beacon data', body);
            }
            this.cachedStorageData.value = body;
        }
    }

    public lockStorage(): void {
        this.storingLocked.value = true;
    }

    public unlockStorage(): void {
        this.storingLocked.value = false;
    }

    public isReady(): boolean {
        return this.form.isReady();
    }

    private saveStorages(): void {
        try {
            const jsonString: string = JSON.stringify(this.transformedStorageData());
            const compressed: string = LZString.compress(jsonString);
            sessionStorage.setItem(this.form.name, compressed);
        } catch (err: unknown) {
            if (this.isQuotaExceededError(err)) {
                Error.log(ErrorType.Error, 'SpaUserStorage', 'sessionStorage size quota exceeded!');
            } else {
                Error.log(ErrorType.Error, 'SpaUserStorage', err as LimitedVariant);
            }
        }
    }

    private restoreStorages(): void {
        if (this.form.name !== '') {
            const storage: string | null = sessionStorage.getItem(this.form.name);
            if (storage) {
                try {
                    const decompressed: string = LZString.decompress(storage);
                    const decodedStorage = JSON.parse(decompressed);
                    Object.keys(this.storageFields).forEach((field: string): void => {
                        this.storageFields[field] = decodedStorage[field] || '';
                    });
                    Object.keys(this.type).forEach((field: string): void => {
                        const key: string = this.type[field];
                        this.fields[key] = decodedStorage[key] || '';
                    });
                    this.restoreFormValues();
                } catch (reason) {
                    Error.log(ErrorType.Error, 'SpaUserStorage::restoreStorages', reason as LimitedVariant);
                }
            }
        } else {
            Error.log(ErrorType.Error, 'SpaUserStorage::restoreStorages', '.newForm(...) ins not used in <app-custom-form...');
        }
    }

    private restoreFormValues(): void {
        const storedValues: DynamicDictionary = this.fields;
        Object.keys(this.type).forEach((field: string): void => {
            const keyName: string = this.type[field];
            this.form.field(keyName).setValue(storedValues[keyName]);
        });
    }

    private transformedStorageData(): DynamicDictionary {
        let result: DynamicDictionary = {};
        if (!this.initialized || this.form.name !== '') {
            const domForm: JQuery = $('[data-form-name="' + this.form.name + '"]');
            if (domForm.length > 0) {
                result = {
                    ...this.storageFields,
                    ...this.fields
                };
            }
        }

        return result;
    }

    private transformedFormData(): DynamicDictionary {
        let result: DynamicDictionary = {};
        if (!this.initialized || this.form.name !== '') {
            const domForm: JQuery = $('[data-form-name="' + this.form.name + '"]');
            if (domForm.length > 0) {
                result = this.fields;
            }
        }

        return result;
    }

    private transformedFormStorage(): FormStorage {
        let formFields: FormStorageElement[] = [];
        const fields: DynamicDictionary = this.transformedFormData();
        Object.keys(this.type).forEach((field: string): void => {
            const formFieldName: string = this.type[field];
            formFields.push({
                fieldName: formFieldName,
                fieldValue: fields[formFieldName],
            })
        });

        return {
            formName: this.form.name,
            formElements: formFields,
        }
    }

    private isQuotaExceededError(err: unknown): boolean {
        return (err instanceof DOMException &&
            (
                err.name === "QuotaExceededError" ||
                err.name === "NS_ERROR_DOM_QUOTA_REACHED"
            ));
    }
}