import FormField from '@/assets/libraries/form/form-field';
import Validation from '@/services/validation.service';
import Form from '@/assets/libraries/form/form';

export default class Validators {
    private static instance: Validators;
    private form: Form = new Form();

    public static getInstance(): Validators {
        if (!Validators.instance) {
            Validators.instance = new Validators();
        }

        return Validators.instance;
    }

    public init(form: Form): void {
        this.form = form;
    }

    public captionValidator(assignedToField: string): object {
        const field: FormField = this.form.field(assignedToField);
        return {
            validCaption: (): boolean => field.isTouched ?
                Validation.isValidCaption(field.value) : true
        }
    }

    public identityNumberValidator(isResident: string, country: string, identityNumber: string): object {
        return {
            validIdentityNumber: (): boolean => {
                return this.form.field(identityNumber).isTouched
                    ? this.isValidIdentityNumber(isResident, country, identityNumber)
                    : true;
            }
        }
    }

    public isValidIdentityNumber(isResident: string, country: string, identityNumber: string): boolean {
        let result: boolean = true;
        if (this.fromBaltics(isResident, country)) {
            const personCodeField: FormField = this.form.field(identityNumber);
            const countryField: FormField = this.form.field(country);
            const personIsResident: boolean = this.form.field(isResident).value;
            const validationResult: boolean = personIsResident ?
                Validation.isValidNaturalPersonCode(personCodeField.value) :
                Validation.isValidNaturalPersonCode(personCodeField.value, countryField.value.iso);
            result = validationResult && personCodeField.isNotEmpty();
        }

        return result;
    }

    public fromBaltics(isResident: string, country: string): boolean {
        return this.form.field(isResident).value
            || this.form.field(country).value.iso === 'LT'
            || this.form.field(country).value.iso === 'EE';
    }

    public hasValidForm(): boolean {
        return this.form.isValid();
    }
}
