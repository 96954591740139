<script lang="ts">
import {computed, defineComponent, ref, Ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import {TranslateParams, useTranslate} from '@/Composables/Translate';
import {useDefine} from '@/Composables/Define';
import ClaimOption from '@/interfaces/claim.option.interface';
import CascoVehicleStepValidators from '@/pages/ClaimsCasco/Vehicle/CascoVehicleStepValidators';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {Subscription} from 'rxjs';
import FormField from '@/assets/libraries/form/form-field';
import AppCountry from '@/assets/libraries/app/app-country';
import PersonCodeValidator from '@/Validators/PersonCodeValidator';
import Value from '@/assets/libraries/form/value';
import {InputOption} from '@/interfaces/InputOptionInterface';
import VueEvent from '@/Classes/VueEventClass';
import Sanitizer from '@/services/sanitizer.service';
import InsuredPerson from '@/interfaces/insured.person.interface';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import OneDate from '@/assets/libraries/Date/OneDate';
import moment from 'moment/moment';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const stepsSubmitter = useStepsSubmitter();
        const {translate, translateForType}: TranslateParams = useTranslate();
        const {isSet} = useDefine();

        const CurrentStep: number = 3;
        const Facility: string = 'one-claims-casco';
        const FormFields: string[] = [
            'is-your-vehicle-in-drivable-condition',
            'is-vehicle-driver-guilty',
            'second-vehicle-number',
            'is-driver-the-same-as-portal-user',
            'driverType',
            'identityNumberResident',
            'driverFirstName',
            'driverLastName',
            'driverBirthDate',
            'driversLicenseNumber',
        ];
        const PanelNames: string[] = [
            'is-your-vehicle-in-drivable-condition',
            'is-vehicle-driver-guilty',
            'second-vehicle-number',
            'is-driver-the-same-as-portal-user',
            'driverType',
        ];
        const RadioFields: string[] = [
            'is-your-vehicle-in-drivable-condition',
            'is-vehicle-driver-guilty',
            'is-driver-the-same-as-portal-user',
        ];
        const OptionYes: string = 'yes';
        const OptionNo: string = 'no';
        const CustomScrollOffset: number = 265;
        const OptionsListType: string = 'radio';
        const DriverTypeResident: string = 'resident';
        const DriverTypeNonResident: string = 'nonResident';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);

        const makeAndModel: Ref<string> = ref('');
        const panels: Ref<ClaimOption[]> = ref([]);
        const validators: CascoVehicleStepValidators = new CascoVehicleStepValidators();
        const dbPanels: Ref<DynamicDictionary> = ref({});

        const driverIsResident: Ref<boolean> = computed(() => {
            return form.field('driverType').value === DriverTypeResident;
        });

        const driverIsNonResident: Ref<boolean> = computed(() => {
            return form.field('driverType').value === DriverTypeNonResident;
        });

        const driverIsPortalUser: Ref<boolean> = computed(() => {
            return optionNotSelected('is-driver-the-same-as-portal-user') ?
                true :
                selectedOptionValue('is-driver-the-same-as-portal-user') === OptionYes
        });

        const plateAndModel: Ref<string> = computed(() => {
            return makeAndModel.value;
        });

        const optionsListType: Ref<string> = computed(() => {
            return OptionsListType;
        });

        const hasDriverDetails: Ref<boolean> = computed(() => {
            const driverFields: FormField[] = [
                form.field('driverFirstName'),
                form.field('driverLastName'),
            ];
            if (driverIsResident.value) {
                driverFields.push(
                    form.field('identityNumberResident')
                );
                if (showResidentDriverBirthDate.value) {
                    driverFields.push(
                        form.field('driverBirthDate')
                    );
                }
            } else {
                driverFields.push(
                    form.field('driverBirthDate'),
                    form.field('driversLicenseNumber')
                );
            }

            return driverFields.filter((field: FormField) => field.isEmpty()).length === 0 &&
                driverFields.filter((field: FormField) => !field.isValid).length === 0;
        });

        const showResidentDriverBirthDate: Ref<boolean> = computed(() => {
            return (new AppCountry).isLV() ?
                (new PersonCodeValidator()).isValidPersonCodeWithoutDate(
                    form.field('identityNumberResident').value,
                    (new AppCountry).iso()) :
                false;
        });

        const hasDriverTypeSelected: Ref<boolean> = computed(() => {
            return driverIsResident.value || driverIsNonResident.value;
        });

        const hasUserTypeSelected: Ref<boolean> = computed(() => {
            return selectedOptionValue('is-driver-the-same-as-portal-user') !== '';
        });

        const nextEnabledPanelExists: Ref<boolean> = computed(() => {
            return isSet(nextEnabledPanel.value) ?
                Object.keys(dbPanels.value).filter((key: string) => key === nextEnabledPanel.value.name).length > 0 :
                false;
        });

        const hasEnabledPanels: Ref<boolean> = computed(() => {
            return enabledPanels().length > 0;
        });

        const nextEnabledPanel: Ref<ClaimOption> = computed(() => {
            return panels.value
                .filter((panel: ClaimOption) => panel.enabled)
                .filter((panel: ClaimOption) => !panel.passed)
                .slice(0)[0];
        });

        function applyDbPanels(value: string): void {
            dbPanels.value = JSON.parse(value);
        }

        function applyMakeAndModel(value: string): void {
            makeAndModel.value = value;
        }

        function optionNotSelected(fieldName: string): boolean {
            return isSet(selectedOptionValue(fieldName)) ?
                (new Value(form.field(fieldName).value.selected)).isEmpty() :
                true;
        }

        function isPanelVisible(panelName: string): boolean {
            return panelByName(panelName).visible;
        }

        function options(panelName: string): InputOption[] {
            return panelByName(panelName).options;
        }

        function onDriverPortalUserChange(): void {
            panelByName('driverType').passed = false;
            if (form.field('is-driver-the-same-as-portal-user').isTouched) {
                panelByName('is-driver-the-same-as-portal-user').passed = false;
                panelByName('driverType').visible = false;
            } else {
                if (selectedOptionValue('is-driver-the-same-as-portal-user') !== OptionYes) {
                    panelByName('driverType').visible = !optionNotSelected('is-driver-the-same-as-portal-user');
                }
            }
        }

        function onDriverTypeChange(): void {
            panelByName('driverType').passed = false;
        }

        function onOptionChange(panelName: string): void {
            if (form.field(panelName).isTouched && form.field(panelName).value.selected === '') {
                panelByName(panelName).passed = false;
            }
        }

        function onPanelProceed(event: VueEvent): Promise<void> {
            return new Promise(resolve => {
                passPanel(event.params.panelName).then((): void => {
                    if (nextEnabledPanelExists.value) {
                        showNextEnabledPanel().then((): void => {
                            scrollToLastVisiblePanel();
                        });
                    } else {
                        onSubmitStep();
                    }
                });
                resolve();
            });
        }

        function onDriverPortalUserProceed(): Promise<void> {
            return new Promise(resolve => {
                if (driverIsPortalUser.value) {
                    onSubmitStep();
                } else {
                    passPanel('is-driver-the-same-as-portal-user')
                        .then((): void => {
                            patchDefaultDriverType();
                            showPanel('driverType').then(() => {
                                scrollToLastVisiblePanel();
                            });
                        });
                }
                resolve();
            });
        }

        function onDriverDetailsProceed(): Promise<void> {
            return new Promise(resolve => {
                onSubmitStep();
                resolve();
            });
        }

        function allowProceedToNextPanel(panelName: string): boolean {
            return !panelByName(panelName).passed;
        }

        function panelByName(panelName: string): ClaimOption {
            return panels.value.find((panel: ClaimOption) => panel.name === panelName)!;
        }

        function applyFieldValidatorsAndSanitizers(): void {
            applyRadioFieldValidators();
            applyDriverFieldValidators();
            applyDriverFieldSanitizers();
        }

        function applyDriverFieldSanitizers(): void {
            form.field('identityNumberResident')
                .addSanitizer(Sanitizer.cleanPersonCode);
            form.field('driverFirstName')
                .addSanitizer(Sanitizer.cleanName);
            form.field('driverLastName')
                .addSanitizer(Sanitizer.cleanName);
            form.field('driversLicenseNumber')
                .addSanitizer(Sanitizer.cleanDriversLicenseNumber);
        }

        function applyDriverFieldValidators(): void {
            form.field('identityNumberResident')
                .addValidators(validators.residentIdentityNumberValidators());
            form.field('driverFirstName')
                .addValidators(validators.driverNameValidators('driverFirstName'));
            form.field('driverLastName')
                .addValidators(validators.driverNameValidators('driverLastName'));
        }

        function applyRadioFieldValidators(): void {
            RadioFields.forEach((fieldName: string) => {
                form.field(fieldName)
                    .addValidators(validators.radioFieldValidators(fieldName));
            });
        }

        function setupForm(): void {
            FormFields.forEach((field: string) => {
                form.addField(new FormField(field));
            });
            form.setReady();
            formIsReady.value = true;
        }

        function panelExists(panelName: string): boolean {
            return panels.value.filter((panel: ClaimOption) => panel.name === panelName).length > 0;
        }

        function showLastPanelAndScroll(): void {
            if (nextEnabledPanelExists.value) {
                showPanel(nextEnabledPanel.value.id!).then((): void => {
                    scrollToLastVisiblePanel();
                });
            } else {
                scrollToLastVisiblePanel();
            }
            if (hasDriverTypeSelected.value) {
                onDriverTypeChange();
            } else {
                patchDefaultDriverType();
            }
            if (hasUserTypeSelected.value) {
                onDriverTypeChange();
                onDriverPortalUserChange();
            }
        }

        function nextPanelIsLastPanel(nextPanelId: number): boolean {
            return panels.value.slice(-1)[0].id === nextPanelId;
        }

        function loadFilledPanels(): Promise<void> {
            return new Promise(resolve => {
                form.fields().forEach((field: FormField): void => {
                    if (field.value !== '') {
                        if (panelExists(field.name)) {
                            showPanel(field.name).then((): void => {
                                passPanel(field.name).then();
                            });
                        }
                    }
                    resolve();
                });
            });
        }

        function hasNonEmptyFields(): boolean {
            return form.fields().filter((field: FormField) => field.value !== '').length > 0;
        }

        function onSubmitStep(): void {
            form.touch().then(() => {
                form.validate().then(() => {
                    if (form.isValid()) {
                        prepareSubmit();
                        stepsSubmitter.proceedStep('', 0);
                    }
                });
            });
        }

        function submit(): void {
            prepareSubmit();
            stepsSubmitter.proceedStep('', 0);
        }

        function applyStepUrls(next: string, previous: string): void {
            stepsSubmitter.applyStepUrls(next, previous);
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', btaBase.facility());
            stepsSubmitter.addSubmitCustomParam('driverIsPortalUser', driverIsPortalUser.value);
            stepsSubmitter.addSubmitCustomParam('driverIsResident', driverIsResident.value);
            stepsSubmitter.addSubmitCustomParam('driverIsGuilty', driverIsGuilty());
            stepsSubmitter.addSubmitCustomParam('driver', driver());
            enabledPanels().forEach((panel: ClaimOption) => {
                stepsSubmitter.addSubmitCustomParam(panel.name!, form.field(panel.name!).value);
            });
        }

        function driver(): InsuredPerson {
            const driverDate: string = OneDate.iris(moment(form.field('driverBirthDate').value).toDate());
            const driver: InsuredPerson = {
                isLegal: false,
                isResident: true,
            }
            if (driverIsPortalUser.value) {
                driver.identityNumber = btaBase.user.current.personCode;
                driver.firstName = btaBase.user.current.firstname;
                driver.lastName = btaBase.user.current.lastname;
            } else {
                driver.firstName = form.field('driverFirstName').value;
                driver.lastName = form.field('driverLastName').value;
                if (driverIsResident.value) {
                    driver.identityNumber = form.field('identityNumberResident').value;
                    if (showResidentDriverBirthDate.value) {
                        driver.birthDate = driverDate;
                    }
                } else {
                    driver.birthDate = driverDate;
                    driver.identityNumber = driverDate;
                    driver.driversLicense = form.field('driversLicenseNumber').value;
                    driver.isResident = false;
                }
            }

            return driver;
        }

        function driverIsGuilty(): boolean {
            return panelByName('is-vehicle-driver-guilty').enabled ?
                form.field('is-vehicle-driver-guilty').value.selected === OptionYes :
                false;
        }

        function enabledPanels(): ClaimOption[] {
            return panels.value.filter((panel: ClaimOption) => panel.enabled);
        }

        function buildDriverTypeOptions(): void {
            const driverTypePanel: ClaimOption = panelByName('driverType');
            driverTypePanel.options.push(
                (new InputOptionBuilder())
                    .setName(translateForType('claims_casco_driver_type_resident', 'claims'))
                    .setValue(DriverTypeResident)
                    .build(),
                (new InputOptionBuilder())
                    .setName(translateForType('claims_casco_driver_type_non_resident', 'claims'))
                    .setValue(DriverTypeNonResident)
                    .build()
            );
        }

        function buildYesNoOptions(): void {
            const options: string[] = [
                OptionYes,
                OptionNo,
            ];
            RadioFields.forEach((field: string) => {
                const panel: ClaimOption = panelByName(field)!;
                options.forEach((option: string) => {
                    panel.options.push(
                        (new InputOptionBuilder())
                            .setName(translate('btar_' + option))
                            .setValue(option)
                            .build()
                    );
                });
            });
        }

        function patchDefaultDriverType(): void {
            form.field('driverType').patch(DriverTypeResident);
        }

        function assemblePanels(): void {
            PanelNames.forEach((key: string, index: number) => {
                panels.value.push(
                    {
                        id: index,
                        name: key,
                        enabled: dbPanelExists(key)
                            ? dbPanels.value[key] === 'Y'
                            : false,
                        passed: false,
                        visible: false,
                        value: '',
                        options: []
                    } as ClaimOption
                );
            });
        }

        function dbPanelExists(panelName: string): boolean {
            return isSet(dbPanels.value[panelName]);
        }

        function selectedOptionValue(fieldName: string): string {
            return form.field(fieldName).value.selected;
        }

        function panelById(panelId: number): ClaimOption {
            return panels.value.find((panel: ClaimOption) => panel.id === panelId)!;
        }

        function lastVisiblePanelName(): ClaimOption {
            return panels.value.filter((panel: ClaimOption) => panel.visible).slice(-1)[0];
        }

        function firstEnabledPanel(): ClaimOption {
            const enabledPanels: ClaimOption[] = panels.value
                .filter((panel: ClaimOption) => panel.enabled);
            const lowestPanelId: number = Math.min(...enabledPanels.map(panel => panel.id!));

            return panelById(lowestPanelId);
        }

        function showPanel(panelNameOrId: string | number): Promise<void> {
            return new Promise(resolve => {
                const panel: ClaimOption = typeof panelNameOrId === 'string' ?
                    panelByName(panelNameOrId) :
                    panelById(panelNameOrId);
                panel.visible = true;
                resolve();
            });
        }

        function showFirstEnabledPanel(): void {
            showPanel(firstEnabledPanel().name!).then();
        }

        function showNextEnabledPanel(): Promise<void> {
            return new Promise(resolve => {
                showPanel(nextEnabledPanel.value.name!).then();
                resolve();
            });
        }

        function passPanel(panelName: string): Promise<void> {
            return new Promise(resolve => {
                const panel: ClaimOption = panelByName(panelName)!;
                panel.passed = form.field(panelName).value.selected !== '';
                resolve();
            });
        }

        function scrollToLastVisiblePanel(headerAreaOffset: number = 85): void {
            const lastVisiblePanel: ClaimOption = lastVisiblePanelName();
            const panelElement: JQuery = $(`[data-scroll="${lastVisiblePanel.name}"]`);
            const scrollSpeed: number = 200;
            const offset: JQuery.Coordinates | undefined = panelElement.offset();
            const verticalOffset: number = offset ? offset.top - headerAreaOffset : 0;
            if (offset && verticalOffset > 0) {
                $('html,body').animate({scrollTop: verticalOffset}, scrollSpeed);
            }
        }

        function removeVehicleStepInStepper(): void {
            const accidentDataStepNumber: number = 2;
            const extraDetailsStepNumber: number = 4;
            const uploadFilesStepNumber: number = 5;
            const recipientDataStepNumber: number = 6;
            const yourContactDetailsStepNumber: number = 7;
            btaBase.dynamicStepper.applyEnabled([
                1,
                accidentDataStepNumber,
                extraDetailsStepNumber,
                uploadFilesStepNumber,
                recipientDataStepNumber,
                yourContactDetailsStepNumber,
            ]);
        }

        function init(): void {
            validators.init(form, driverIsPortalUser, driverIsResident, panelByName);
            setupForm();
            assemblePanels();
            buildYesNoOptions();
            buildDriverTypeOptions();
            applyFieldValidatorsAndSanitizers();
        }

        function onAppReady(): void {
            if (hasEnabledPanels.value) {
                showFirstEnabledPanel();
                btaBase.dynamicStepper.applyEnabled(btaBase.userStorage.stepStorageData.dynamicStepper);
                if (hasNonEmptyFields()) {
                    loadFilledPanels().then(() => {
                        showLastPanelAndScroll();
                        lastVisiblePanelName().passed = false;
                    });
                }
            } else {
                removeVehicleStepInStepper();
                btaBase.userStorage.stepStorageData.dynamicStepper = btaBase.dynamicStepper.enableStates;
                stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
                stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
                stepsSubmitter.addSubmitCustomParam('facility', btaBase.facility());
                stepsSubmitter.proceedStep('', 0);
            }
            form.fields().forEach((field: FormField): void => {
                field.markAsUntouched();
            });
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                setupForm,
                applyStepUrls,
                onSubmitStep,
                submit,
                prepareSubmit,
                init,
                onAppReady,

                makeAndModel,
                panels,
                validators,
                dbPanels,
                driverIsResident,
                driverIsNonResident,
                driverIsPortalUser,
                plateAndModel,
                optionsListType,
                hasDriverDetails,
                showResidentDriverBirthDate,
                applyDbPanels,
                applyMakeAndModel,
                optionNotSelected,
                isPanelVisible,
                options,
                onDriverPortalUserChange,
                onDriverTypeChange,
                onOptionChange,
                onPanelProceed,
                onDriverPortalUserProceed,
                onDriverDetailsProceed,
                allowProceedToNextPanel,
                panelByName
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.init();
        const onAppIsPreparedAndReady: Subscription =
            this.onAppIsPreparedAndReady.subscribe((): void => {
                this.onAppReady();
                onAppIsPreparedAndReady.unsubscribe();
            });
    }
});
</script>
