import OneModule from '@/interfaces/OneModuleInterface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import ResponseCode from '@/Enums/ResponseCodeEnum';
import {Observable, of, ReplaySubject} from 'rxjs';
import {reactive, ref, Ref, UnwrapNestedRefs, nextTick} from 'vue';
import {ComponentPublicInstance} from '@vue/runtime-core';
import Translations from '@/services/translations.service';
import Captcha from '@/services/captcha.service';
import SettingsService from '@/services/settings.service';
import User from '@/services/user.service';
import Countries from '@/services/countries.service';
import OneBaseService from '@/services/OneBaseService';
import VueEvent from '@/Classes/VueEventClass';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import {useDefine} from '@/Composables/Define';
import OneBase from '@/interfaces/OneBaseInterface';
import DataLayerType from '@/Enums/DataLayerTypeEnum';

const {isSet} = useDefine();

export const useModule = (): OneModule => {
    const onModuleReady: ReplaySubject<void> = new ReplaySubject();
    let user: User | null = null;
    let countries: Countries | null = null;
    let translations: Translations | null = null;
    let settingsService: SettingsService | null = null;
    let scrollMode: boolean = false;

    const ready: Ref<boolean> = ref(false);
    const prepared: Ref<boolean> = ref(false);
    const cmsFields: UnwrapNestedRefs<DynamicDictionary> = reactive({});
    const facility: Ref<string> = ref('default-facility');
    const dataLayerEventType: Ref<string> = ref(DataLayerType.Lead);
    const moduleType: Ref<string> = ref('');
    const componentRefs: Ref<ComponentPublicInstance[]> = ref([]);


    const beforeModuleMounted = (): void => {
        if (!prepared.value) {
            user = User.getInstance();
            countries = Countries.getInstance();
            translations = Translations.getInstance();
            settingsService = SettingsService.getInstance();
            applyModuleType();
            const oneBaseService: OneBase = OneBaseService.getInstance();
            const onExternalDataIsReady: Observable<void> = oneBaseService.externalDataIsReady()
                ? of(void 0)
                : oneBaseService.onExternalDataIsReady;
            onExternalDataIsReady.subscribe((): void => {
                checkExternalDataStatus();
            });
            prepared.value = true;
        }
    }

    const moduleMounted = (): void => {
    }

    const localUser = (): User => {
        return user as User;
    }

    const localCountries = (): Countries => {
        return countries as Countries;
    }

    const localTranslations = (): Translations => {
        return translations as Translations;
    }

    const captcha = (): Captcha => {
        return Captcha.getInstance();
    }

    const localSettings = (): SettingsService => {
        return settingsService as SettingsService;
    }

    const appCountry = (): { id: string, iso: string } => {
        return {
            id: settingsService!.value('USER_ADDRESS_COUNTRY').toString(),
            iso: settingsService!.value('LOCALE_ISO').toString()
        };
    }

    const navigate = (url: string): void => {
        const redirectUrl: string = url.includes(translations?.language + '/') ?
            '/' + url : '/' + translations?.language + '/' + url;
        window.location.href = redirectUrl.replace(/\/\/+/g, '/');
    }

    const validResponse = (response: DynamicDictionary): boolean => {
        return response.status === ResponseCode.Ok &&
            !!response.data &&
            !!response.data.data &&
            response.data.data.status === 'OK';
    }

    const checkExternalDataStatus = (): void => {
        if (!ready.value &&
            settingsService!.ready && translations!.ready() &&
            user!.ready && countries!.ready
        ) {
            ready.value = true;
            nextTick(() => onModuleReady.next());
        }
    }

    const formattedUrl = (url: string): string => {
        return '/' + translations!.language + url;
    }

    const cmsFieldIsEnabled = (name: string): boolean => {
        const result: LimitedVariant = isSet(cmsFields.value[name]) &&
            cmsFields.value[name].enabled;

        return typeof result === 'boolean' ? result : result === '1';
    }

    const scrollToAlias = (event: VueEvent): void => {
        OneBaseService.getInstance().scrollToAlias(event);
    }

    const transformedVueSafeString = (vueSafe: string): string => {
        return vueSafe.replace(/\|o\|/g, '{{')
            .replace(/\|c\|/g, '}}')
            .replace(/\|s\|/g, '\\"');
    }

    const domRef = (refName: string): DynamicDictionary | undefined => {
        return OneBaseService.getInstance().domRef(refName);
    }

    const appendCmsFields = (fieldsSafeJson: string): void => {
        cmsFields.value = JSON.parse(transformedVueSafeString(fieldsSafeJson));
    }

    const hasCmsFields = (): boolean => {
        return typeof cmsFields.value !== 'undefined' && Object.keys(cmsFields.value).length > 0;
    }

    const applyFacility = (newFacility: string): void => {
        facility.value = newFacility;
    }

    const applyDataLayerEventType = (eventType: string): void => {
        dataLayerEventType.value = eventType;
    }

    const applyModuleType = (): void => {
        const body: JQuery = $('body');
        moduleTypes().forEach((currentModuleType: string): void => {
            if (body.hasClass(currentModuleType)) {
                moduleType.value = currentModuleType;
            }
        })
    }

    const moduleTypes = (): string[] => {
        return [
            'private',
            'business',
            'contacts',
        ];
    }

    const enableScrollToMode = (): void => {
        scrollMode = true;
    }

    const scrollModeEnabled = (): boolean => {
        return scrollMode;
    }

    return {
        ready,
        cmsFields,
        facility,
        moduleType,
        onModuleReady,
        dataLayerEventType,
        scrollModeEnabled,
        beforeModuleMounted,
        moduleMounted,
        enableScrollToMode,
        applyFacility,
        applyDataLayerEventType,
        hasCmsFields,
        localUser,
        localCountries,
        localTranslations,
        captcha,
        localSettings,
        domRef,
        cmsFieldIsEnabled,
        appCountry,
        navigate,
        validResponse,
        checkExternalDataStatus,
        formattedUrl,
        scrollToAlias,
        transformedVueSafeString,
        appendCmsFields,
        applyModuleType,
    }
}
