import ClickOutside from '@/Directives/ClickOutsideDirective';
import Uppercase from '@/Directives/UppercaseDirective';
import Lowercase from '@/Directives/LowercaseDirective';
import IframeResizer from '@/Directives/IframeResizerDirective';
import VisibilityState from '@/Directives/VisibilityStateDirective';
import MaskAnalytics from '@/Directives/MaskAnalyticsDirective';
import ResetFocus from '@/Directives/ResetFocusDirective';
import Directives from '@/Core/App/Interfaces/DirectivesInterface';
import HiddenIfEmptyDirective from '@/Directives/HiddenIfEmptyDirective';

export default {
    'click-outside': ClickOutside,
    'uppercase': Uppercase,
    'lowercase': Lowercase,
    'iframe-resizer': IframeResizer,
    'observe-visibility': VisibilityState,
    'mask-analytics': MaskAnalytics,
    'reset-focus': ResetFocus,
    'hidden-if-empty': HiddenIfEmptyDirective,
} as Directives;
