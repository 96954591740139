import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {SubmitParam} from '@/Types/SubmitParamType';

export const useStepsAssembler = (): StepsAssemblerParams => {
    let stepData: DynamicDictionary = {};
    let stepCustomData: DynamicDictionary = {};

    const addParam = (key: string, value: SubmitParam): void => {
        stepData[key] = value;
    }

    const addParams = (params: DynamicDictionary): void => {
        for (const key in params) {
            stepData[key] = params[key];
        }
    }

    const addCustomParam = (key: string, value: SubmitParam): void => {
        stepCustomData[key] = value;
    }

    const addCustomParams = (params: DynamicDictionary): void => {
        for (const key in params) {
            stepCustomData[key] = params[key];
        }
    }

    const clearParams = (): void => {
        stepData = {}
    }

    const clearCustomParams = (): void => {
        stepCustomData = {}
    }

    const assembledParams = (): DynamicDictionary => {
        const result: DynamicDictionary = stepData;
        if (Object.keys(stepCustomData).length) {
            for (const [key, value] of Object.entries(stepCustomData)) {
                result[customParam(key)] = value;
            }
        }

        return result;
    }

    const assembledCustomParams = (): DynamicDictionary => {
        return stepCustomData;
    }

    const customParam = (key: string): string => {
        return `customParam[${key}]`;
    }

    const removeCustomParam = (key: string): boolean => {
        return delete(stepCustomData[key])
            && delete(stepData[customParam(key)]);
    }

    return {
        addParam,
        addParams,
        addCustomParam,
        addCustomParams,
        clearParams,
        clearCustomParams,
        assembledParams,
        assembledCustomParams,
        removeCustomParam,
    }
}

export interface StepsAssemblerParams {
    addParam: (key: string, value: SubmitParam) => void;
    addParams: (params: DynamicDictionary) => void;
    addCustomParam: (key: string, value: SubmitParam) => void;
    addCustomParams: (params: DynamicDictionary) => void;
    clearParams: () => void;
    clearCustomParams: () => void;
    assembledParams: () => DynamicDictionary;
    assembledCustomParams: () => DynamicDictionary;
    removeCustomParam: (key: string) => boolean;
}
