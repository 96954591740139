import {LimitedVariant} from '@/Types/LimitedVariantType';
import moment from 'moment';
import {useStrings} from '@/Composables/Strings';

export default class LookupString {
    private targetValue: string[] = [];
    private searchTerm: string = '';
    private useDiacriticSearch: boolean = true;
    private matchAlgorythm!: Function;

    public withTargetValue(value: LimitedVariant): LookupString {
        if (typeof value === 'object') {
            if (value instanceof Date) {
                this.targetValue = [moment(value).format(this.defaultDateFormat)];
            } else {
                this.targetValue = Object.values(value)
                    .map((objval: LimitedVariant): string =>
                        objval instanceof Date ? moment(objval).format(this.defaultDateFormat) : String(objval));
            }
        } else {
            this.targetValue = [String(value)];
        }

        return this;
    }

    public withSearchTerm(value: string): LookupString {
        this.searchTerm = value;

        return this;
    }

    public useStringStartsWith(): LookupString {
        this.matchAlgorythm = this.stringStartsWith;

        return this;
    }

    public useStringIncludes(): LookupString {
        this.matchAlgorythm = this.stringIncludes;

        return this;
    }

    public useStrict(): LookupString {
        this.withoutDiacriticSearch();
        this.matchAlgorythm = this.stringEquals;

        return this;
    }

    public withoutDiacriticSearch(): LookupString {
        this.useDiacriticSearch = false;

        return this;
    }

    public match(): boolean {
        this.normalizeValues();

        return this.matchAlgorythm();
    }

    private normalizeValues(): void {
        if (this.useDiacriticSearch) {
            this.targetValue = this.targetValue.map((value: string): string => this.diacriticValue(value));
            this.searchTerm = this.diacriticValue(this.searchTerm);
        } else {
            if (this.matchAlgorythm.name !== 'stringEquals') {
                this.targetValue = this.targetValue.map((value: string): string => value.toLowerCase());
                this.searchTerm = this.searchTerm.toLowerCase();
            }
        }
    }

    private stringStartsWith(): boolean {
        return this.targetValue
            .concat(this.targetValue.map((value: string): string[] => value.split(' ')).flat())
            .map((element: string): string[] => [element].concat(element.split('-')))
            .flat()
            .some((fragment: string): boolean =>
                fragment.startsWith(this.searchTerm)
                || fragment.replace('-', '').startsWith(this.searchTerm));
    }

    private stringIncludes(): boolean {
        return this.targetValue
            .concat(this.targetValue.map((value: string): string[] => value.split(' ')).flat())
            .map((element: string): string[] => [element].concat(element.split('-')))
            .flat()
            .some((fragment: string): boolean =>
                fragment.includes(this.searchTerm)
                || fragment.replace('-', '').includes(this.searchTerm));
    }

    private stringEquals(): boolean {
        return this.targetValue.some((value: string): boolean => value === this.searchTerm);
    }

    private diacriticValue(value: string): string {
        const {diacriticString} = useStrings();

        return diacriticString(value).toLowerCase();
    }

    private get defaultDateFormat(): string {
        return 'DD.MM.YYYY';
    }
}
