<script setup lang="ts">
import { onMounted, PropType, ref, Ref } from 'vue';
import ProductBlockItem from '@/Components/ProductBlock/Interfaces/ProductBlockItemInterface';
import ProductBlockEmitters from '@/Components/ProductBlock/Enums/ProductBlockEmittersEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useTranslate } from '@/Composables/Translate';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import FormField from '@/assets/libraries/form/form-field';

const props = defineProps({
    componentName: {type: String, default: 'MovablePropertyMini'},
    products: {type: Array as PropType<ProductBlockItem[]>, default: () => []},
    selectedProduct: {
        type: Object as PropType<DynamicDictionary>, default: () => {
            return {
                selectedProductId: '',
                miniSelectedProductId: '',
                width: 0,
            };
        }
    },
});
const emit = defineEmits([ProductBlockEmitters.MiniPlanSelected]);
const {translateForType} = useTranslate();
const TranslationType: string = 'items_and_belongings';
const miniPlansField: FormField = new FormField('mini-plans');
let miniPlanOptions: Ref<InputOption[]> = ref([]);

onMounted((): void => {
    prepareMiniPlanOptions();
    applyDefaultOption();
});

function prepareMiniPlanOptions(): void {
    miniPlanOptions.value = [];
    props.products.forEach((product: ProductBlockItem): void => {
        const customText: string = translateForType('mini_plan_subtitle', TranslationType, {
            price: product.discountSum,
            frequency: translateForType('payment_frequency', TranslationType),
        })
        miniPlanOptions.value.push(
            new InputOptionBuilder()
                .setValue(product.id)
                .setName(product.productName)
                .setCustom(customText)
                .setBadgeText(product.badgeText)
                .build()
        );
    });
}

function applyDefaultOption(): void {
    let defaultId: string = props.products[0].id;
    const defaultMobileItem: ProductBlockItem | undefined = props.products
        .find((item: ProductBlockItem): boolean => item.defaultOnMobile);
    if (defaultMobileItem) {
        defaultId = defaultMobileItem.id;
    }
    miniPlansField.setValue(defaultId);
}

function onMiniPlanChange(productId: string): void {
    emit(ProductBlockEmitters.MiniPlanSelected, productId);
}
</script>

<template>
    <div class="products-block-mini">
        <app-input-radio-overlayed class="mini-plan-options"
                                   :form-field="miniPlansField"
                                   :options="miniPlanOptions"
                                   @change="onMiniPlanChange($event)">
        </app-input-radio-overlayed>
    </div>
</template>

<style lang="scss" scoped>
.products-block-mini {
    margin: var(--size-small) 0;

    @include respond-above('sm') {
        display: none;
    }

    .mini-plan-options {
        background: var(--white);
    }
}
</style>
