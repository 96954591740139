<script setup lang="ts">
    import FormField from '@/assets/libraries/form/form-field';
    import Sanitizer from '@/services/sanitizer.service';
    import Validation from '@/services/validation.service';
    import {onMounted} from 'vue';
    import AppInputText from '@/Components/InputText/InputText.vue';

    const props = defineProps({
      placeholder: {type: String, default: () => ''},
      formField: {type: FormField, default: () => new FormField('')},
      label: {type: String, default: ''},
      disabled: {type: Boolean, default: false},
      required: {type: Boolean, default: false},
      dataStoreDisabled: {type: Boolean, default: false},
      disableErrorText: {type: Boolean, default: false},
      supportTextMessage: {type: String, default: ''},
    });

    const emit = defineEmits(['change', 'input']);
    let feedback: string = '';

    onMounted((): void => {
      props.formField.addSanitizer(Sanitizer.cleanBankAccountNumber);
        props.formField.addValidators(baseValidators());
      if (!props.formField.isEmpty()) {
        onChange();
      }
    });

    function onInput(): void {
      emit('input', props.formField.value);
    }

    function onKeyUp(): void {
      onChange();
    }

    function onChange(): void {
        props.formField.touch();
        props.formField.sanitize();
        props.formField.validate().then(() => {
        resetFeedbackMessage();
        if (props.formField.isEmpty()) {
            props.formField.markAsUntouched();
        }
      });
      emit('change', props.formField.value);
    }

    function resetFeedbackMessage(): void {
      feedback = '';
    }

    function baseValidators(): object {
      return {
        isValidBankAccount: Validation.isValidIBANNumber,
      };
    }
</script>

<template>
  <app-input-text
      v-model="formField.value"
      :class="'input-bank-account'"
      :required="required"
      :name="formField.name"
      :form-field="formField"
      :feedback-message="feedback"
      :label="label"
      :autocomplete="'disabled'"
      :disabled="disabled"
      :placeholder="placeholder"
      :data-store-disabled="dataStoreDisabled"
      :disable-error-text="disableErrorText"
      @input="onInput"
      @change="onChange"
      @keyup="onKeyUp"
      v-uppercase>
    <template v-slot:app-tooltipster>
      <slot name="app-tooltipster"></slot>
    </template>
  </app-input-text>
</template>

<style lang="scss" scoped>
.input-bank-account {
  scroll-margin-top: 4em;

  @include respond-below('md') {
    width: 100%;
  }
}
</style>
