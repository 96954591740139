import Validation from '@/services/validation.service';
import Form from '@/assets/libraries/form/form';
import User from '@/services/user.service';

export default class AdditionalValidators {

    private form: Form = new Form();

    public init(form: Form): void {
        this.form = form;
    }

    public policyHolderPrivateRequired(fieldName: string): object {
        const user: User = User.getInstance();

        return {
            isRequired: (): boolean => {
                return user.isGuest()
                && this.form.field('policyHolderType').value.includes('private')
                    ? Validation.isValidCaption(this.form.field(fieldName).value)
                    : true;
            }
        };
    }

    public insuredPersonPrivateRequired(fieldName: string): object {
        return {
            isRequired: (): boolean => {
                return this.form.field('insuredPersonType').value.includes('private')
                    ? Validation.isValidCaption(this.form.field(fieldName).value)
                    : true;
            }
        };
    }

    public beneficiaryPrivateRequired(fieldName: string): object {
        return {
            isRequired: (): boolean => {
                return this.form.field('beneficiaryType').value.includes('private')
                    ? Validation.isValidCaption(this.form.field(fieldName).value)
                    : true;
            }
        };
    }

    public beneficiaryRequired(fieldName: string): object {
        return {
            isRequired: (): boolean => {
                return this.form.field('beneficiaryType').value.includes('bank')
                    ? true
                    : this.form.field(fieldName).isNotEmpty();
            }
        };
    }

    public policyHolderIdentity(): object {
        const user: User = User.getInstance();

        return {
            isRequired: (): boolean => {
                return user.isGuest()
                && this.form.field('policyHolderType').value.includes('private')
                    ? Validation.isValidNaturalPersonCode(this.form.field('policyHolderIdentityNumber').value)
                    : true;
            }
        };
    }

    public insuredPersonIdentity(): object {
        return {
            isRequired: (): boolean => {
                return this.form.field('insuredPersonType').value.includes('private')
                    ? Validation.isValidNaturalPersonCode(this.form.field('insuredPersonIdentityNumber').value)
                    : true;
            }
        };
    }

    public beneficiaryIdentity(): object {
        return {
            isRequired: (): boolean => {
                return this.form.field('beneficiaryType').value.includes('private')
                    ? Validation.isValidNaturalPersonCode(this.form.field('beneficiaryIdentityNumber').value)
                    : true;
            }
        };
    }

    public policyHolderPhone(): object {
        const user: User = User.getInstance();

        return {
            isRequired: (): boolean => {
                return user.isGuest()
                    ? this.form.field('policyHolderPhone').value.phone !== ''
                    : true;
            }
        };
    }

    public insuredPersonPhone(): object {
        return {
            isRequired: (): boolean => {
                return this.form.field('insuredPersonType').value.includes('private')
                && this.form.field('insuredPersonPhone').isTouched
                    ? this.form.field('insuredPersonPhone').value.phone !== ''
                    : true;
            }
        };
    }

    public beneficiaryPhone(): object {
        return {
            isRequired: (): boolean => {
                return this.form.field('beneficiaryType').value.includes('bank')
                    ? true
                    : this.form.field('beneficiaryPhone').value.phone !== '';
            }
        };
    }

    public policyHolderRegistration(): object {
        const user: User = User.getInstance();

        return {
            isRequired: (): boolean => {
                return user.isGuest()
                && this.form.field('policyHolderType').value.includes('corporate')
                    ? Validation.isValidCompanyRegistrationNumber(this.form.field('policyHolderRegistrationNumber').value)
                    : true;
            }
        };
    }

    public insuredPersonRegistration(): object {
        return {
            isRequired: (): boolean => {
                return this.form.field('insuredPersonType').value.includes('corporate')
                    ? Validation.isValidCompanyRegistrationNumber(this.form.field('insuredPersonRegistrationNumber').value)
                    : true;
            }
        };
    }

    public beneficiaryRegistration(): object {
        return {
            isRequired: (): boolean => {
                return this.form.field('beneficiaryType').value.includes('corporate')
                    ? Validation.isValidCompanyRegistrationNumber(this.form.field('beneficiaryRegistrationNumber').value)
                    : true;
            }
        };
    }

    public policyHolderCorporateRequired(fieldName: string): object {
        const user: User = User.getInstance();

        return {
            isRequired: (): boolean => {
                return user.isGuest()
                && this.form.field('policyHolderType').value.includes('corporate')
                    ? Validation.isValidCaption(this.form.field(fieldName).value)
                    : true;
            }
        };
    }

    public policyHolderCompanyType(): object {
        const user: User = User.getInstance();

        return {
            isRequired: (): boolean => {
                return user.isGuest()
                && this.form.field('policyHolderType').value.includes('corporate')
                    ? this.form.field('policyHolderCompanyType').isNotEmpty()
                    : true;
            }
        };
    }

    public insuredPersonCompanyType(): object {
        return {
            isRequired: (): boolean => {
                return this.form.field('insuredPersonType').value.includes('corporate')
                    ? this.form.field('insuredPersonCompanyType').isNotEmpty()
                    : true;
            }
        };
    }

    public beneficiaryCompanyType(): object {
        return {
            isRequired: (): boolean => {
                return this.form.field('beneficiaryType').value.includes('corporate')
                    ? this.form.field('beneficiaryCompanyType').isNotEmpty()
                    : true;
            }
        };
    }

    public insuredPersonCorporateRequired(fieldName: string): object {
        return {
            isRequired: (): boolean => {
                return this.form.field('insuredPersonType').value.includes('corporate')
                    ? Validation.isValidCaption(this.form.field(fieldName).value)
                    : true;
            }
        };
    }

    public beneficiaryCorporateRequired(fieldName: string): object {
        return {
            isRequired: (): boolean => {
                return this.form.field('beneficiaryType').value.includes('corporate')
                    ? Validation.isValidCaption(this.form.field(fieldName).value)
                    : true;
            }
        };
    }

    public beneficiaryBank(): object {
        return {
            isRequired: (): boolean => {
                return this.form.field('beneficiaryType').value.includes('bank')
                    ? this.form.field('beneficiaryBank').isNotEmpty()
                    : true;
            }
        };
    }
}
