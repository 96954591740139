<script setup lang="ts">
import Form from '@/assets/libraries/form/form';
import {computed, getCurrentInstance, onMounted, reactive, ref, Ref, UnwrapNestedRefs, nextTick } from 'vue';
import OneBase from '@/interfaces/OneBaseInterface';
import OneBaseService from '@/services/OneBaseService';
import {useTranslate} from '@/Composables/Translate';
import {Router, useRouter} from 'vue-router';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import OneDate from '@/assets/libraries/Date/OneDate';
import Validation from '@/services/validation.service';
import UserCredentials from '@/interfaces/user.credentials.interface';
import SettingsService from '@/services/settings.service';
import moment from 'moment/moment';
import ExtractDataService from '@/services/extract.data.service';
import User from '@/services/user.service';
import FormField from '@/assets/libraries/form/form-field';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import RentersService from '@/Apps/Renters/Services/RentersService';
import Sanitizer from '@/services/sanitizer.service';
import {useScroll} from '@/Composables/Scroll';
import RequestService from '@/services/request.service';
import Url from '@/Enums/UrlEnum';
import {AxiosResponse} from 'axios';
import {useDefine} from '@/Composables/Define';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import StepsGuard from '@/Apps/Renters/Services/StepsGuard';
import Validators from '@/Apps/Renters/Services/Validators';
import AppContentLoader from '@/Components/ContentLoader/ContentLoader.vue';

const {translate, translateForType} = useTranslate();
const router: Router = useRouter();
const btaBase: OneBase = OneBaseService.getInstance();
const form: Form = new Form();
const TranslationType: string = 'renters';
const extractDataService: ExtractDataService = new ExtractDataService();
const rentersService: RentersService = RentersService.getInstance();
let viewIsReady: Ref<boolean> = ref(false);
const addressFinderMode: Ref<string> = ref('mixed');

const isAuthenticated: Ref<boolean> = computed(() => {
    return !!form.field('insuredIsAuthenticated').value;
});

const birthDateRequired: Ref<boolean> = computed(() => {
    return isAnonymousNumber.value || requiresBirthDate();
});

const isAnonymousNumber: Ref<boolean> = computed(() => {
    return isResident.value && Validation.isValidPersonCodeWithoutDate(
        form.field('insuredIdentityNumber').value
    );
});

const isLithuanian: Ref<boolean> = computed(() => {
    return form.field('insuredCountry').value.iso === 'LT';
});

const isEstonian: Ref<boolean> = computed(() => {
    return form.field('insuredCountry').value.iso === 'EE';
});

const isResident: Ref<boolean> = computed(() => {
    return !!form.field('insuredIsResident').value;
});

const country: Ref<string> = computed(() => {
    return isResident.value ? 'LV' : form.field('insuredCountry').value.iso;
});

const isAddressFinderModeClear: Ref<boolean> = computed(() => {
    return addressFinderMode.value === '';
});
const validators: Validators = Validators.getInstance();

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    rentersService.updateRoute();
    setupForm();
    fetchProducts().then((): void => {
        applyFormValidationAndSanitization();
        useScroll().scrollToTop();
        viewIsReady.value = true;
        patchDefaultValues().then(() => {
            restoreValues()
        });
    });
    validators.init(form);
    StepsGuard.getInstance(rentersService).init();
});

function setupForm(): void {
    form.addField(new FormField('insuredIsResident'));
    form.addField(new FormField('insuredIsAuthenticated'));
    form.addField(new FormField('insuredAddress'));
    form.addField(new FormField('insuredFirstName'));
    form.addField(new FormField('insuredLastName'));
    form.addField(new FormField('insuredIdentityNumber'));
    form.addField(new FormField('insuredCountry'));
    form.addField(new FormField('insuredBirthDate'));
    form.setReady();
}

function fetchProducts(): Promise<void> {
    return new Promise(resolve => {
        RequestService.getInstance().get({
            uri: Url.Ajax.renters,
        }).then((response: AxiosResponse): void => {
            if (useDefine().validResponse(response)) {
                TransferStateService.getInstance().set('renters', response.data.data.body.products);
                rentersService.init();
                resolve();
            }
        })
    });
}

function applyFormValidationAndSanitization(): void {
    form.field('insuredFirstName')
        .addValidators(validators.captionValidator('insuredFirstName'));
    form.field('insuredLastName')
        .addValidators(validators.captionValidator('insuredLastName'));
    form.field('insuredIdentityNumber')
        .addValidators(validators.identityNumberValidator(
            'insuredIsResident',
            'insuredCountry',
            'insuredIdentityNumber'
        ));
    form.field('insuredFirstName').addSanitizer(Sanitizer.cleanUppercaseName);
    form.field('insuredLastName').addSanitizer(Sanitizer.cleanUppercaseName);
}

function requiresBirthDate(): boolean {
    return !isResident.value && !isLithuanian.value && !isEstonian.value;
}

function isAuthenticatedUser(): boolean {
    return User.getInstance().isLogged();
}

function patchUserFields(): void {
    const currentUser: UserCredentials = btaBase.user.current;
    form.field('insuredAddress').patchStandalone({
        addressCode: currentUser.addressCode,
        addressName: currentUser.address,
        label: currentUser.address,
        value: '',
        additional: currentUser.addressExtra,
        postCode: currentUser.postCode,
    });
    form.field('insuredFirstName').setValue(currentUser.firstname);
    form.field('insuredLastName').setValue(currentUser.lastname);
    form.field('insuredIdentityNumber').setValue(currentUser.personCode);
    patchHolderCountry();
}

function patchHolderCountry(): void {
    form.field('insuredCountry').setValue(residentCountry());
}

function residentCountry(): DynamicDictionary {
    const settingsInstance: SettingsService = SettingsService.getInstance();
    return {
        ic: '100000000',
        iso: settingsInstance.localeIso(),
        phoneCode: settingsInstance.phoneCode(),
    }
}

function patchGuestFields(): void {
    form.field('insuredFirstName').clear();
    form.field('insuredLastName').clear();
    form.field('insuredIdentityNumber').clear();
}

function onAuthenticatedClick(): void {
    if (isAuthenticated.value) {
        patchUserFields();
    } else {
        patchGuestFields();
    }
    storeValues();
}

function birthDate(): string {
    return isAuthenticated.value ? userDate() : guestDate();
}

function userDate(): string {
    return OneDate.iris(btaBase.user.current.birthDate);
}

function guestDate(): string {
    return birthDateRequired.value ? formFieldDate() : identityNumberDate();
}

function formFieldDate(): string {
    return OneDate.iris(moment(form.field('insuredBirthDate').value).toDate());
}

function identityNumberDate(): string {
    const extractedBirthDate: Date | undefined = extractDataService.birthDateFromPersonCode(
        form.field('insuredIdentityNumber').value,
        form.field('insuredCountry').value.iso
    );

    return extractedBirthDate ? OneDate.iris(extractedBirthDate) : '';
}

function onSubmitStep(): void {
    resetForm();
    touchAndRequireNecessaryFields();
    form.validate().then((): void => {
        if (form.isValid()) {
            prepareSubmit();
            router.push({name: 'renters-summary-and-payment'});
        } else {
            const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
            if (invalidElements.length > 0) {
                invalidElements[0].scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
            }
        }
    });
}

function prepareSubmit(): void {
    rentersService.fields.insuredBirthDate = birthDate();
    rentersService.fields.insuredAddress = form.field('insuredAddress').value;
    rentersService.fields.insuredFirstName = form.field('insuredFirstName').value;
    rentersService.fields.insuredLastName = form.field('insuredLastName').value;
    rentersService.fields.insuredIdentityNumber = form.field('insuredIdentityNumber').value;
    rentersService.fields.insuredIsAuthenticated = form.field('insuredIsAuthenticated').value;
    rentersService.fields.insuredIsResident = form.field('insuredIsResident').value;
    rentersService.fields.insuredCountry = form.field('insuredCountry').value;
    if (isResident.value) {
        rentersService.fields.insuredCountry = residentCountry();
    }
}

function resetForm(): void {
    form.fields().forEach((field: FormField): void => {
        field.clearValidators();
        field.markAsUntouched();
    });
    if (!birthDateRequired.value) {
        form.field('insuredBirthDate').clear().then();
    }
}

function touchAndRequireNecessaryFields(): void {
    if (isAuthenticated.value) {
        touchAndRequireUserFields();
    } else {
        touchAndRequireGuestFields();
    }
}

function touchAndRequireUserFields(): void {
    [
        'insuredAddress',
    ].forEach((field: string): void => {
        form.field(field)
            .addValidators('required')
            .touch();
    })
}

function touchAndRequireGuestFields(): void {
    const guestFields: string[] = [
        'insuredAddress',
        'insuredFirstName',
        'insuredLastName',
        'insuredIdentityNumber',
    ];
    if (!isResident.value) {
        guestFields.push('insuredCountry');
    }
    if (birthDateRequired.value) {
        guestFields.push('insuredBirthDate');
    }
    guestFields.forEach((field: string): void => {
        form.field(field)
            .addValidators('required')
            .touch();
    });
}

function patchDefaultValues(): Promise<void> {
    return new Promise<void>((resolve) => {
        form.field('insuredIsResident').setValue(true);
        if (isAuthenticatedUser()) {
            form.field('insuredIsAuthenticated').setValue(true);
            addressFinderMode.value = '';
            patchUserFields();
        } else {
            patchHolderCountry();
        }
        resolve();
    });
}

function restoreValues(): void {
    const storedValues: DynamicDictionary = rentersService.fields;
    Object.keys(storedValues).forEach((key: string): void => {
        if (useDefine().isSet(storedValues[key])) {
            form.field(key).patchStandalone(storedValues[key]);
        }
    });
    if (form.field('insuredAddress').value.detailed !== '' && useDefine().isSet(form.field('insuredAddress').value.detailed)) {
        transformAddressLabel();
    }
}

function storeValues(): void {
    const existingValues: DynamicDictionary = JSON.parse(JSON.stringify(rentersService.fields));
    Object.assign(rentersService.fields, {
        ...existingValues,
        insuredAddress: form.field('insuredAddress').value,
        insuredFirstName: form.field('insuredFirstName').value,
        insuredLastName: form.field('insuredLastName').value,
        insuredIdentityNumber: form.field('insuredIdentityNumber').value,
        insuredCountry: form.field('insuredCountry').value,
        insuredBirthDate: form.field('insuredBirthDate').value,
        insuredIsAuthenticated: form.field('insuredIsAuthenticated').value,
        insuredIsResident: form.field('insuredIsResident').value,
    });
}

function continueButtonParams(): ButtonWithCallbackParams {
    return {
        title: translate('btar_continue'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconColor: ButtonIconColor.White,
        iconPosition: ButtonIconPosition.Right,
    };
}

function transformAddressLabel(): void {
    addressFinderMode.value = '';
    rentersService.fields.insuredAddress!.label = [form.field('insuredAddress').value.addressName, form.field('insuredAddress').value.detailed].join(', ');
}

function onAddressEditClick(): void {
    addressFinderMode.value = 'mixed';
}

</script>

<template>
    <div class="step-container">
        <div class="loading" v-if="!form.isReady()">
            <app-content-loader></app-content-loader>
        </div>
        <app-custom-form else
                         :form="form"
                         v-if="viewIsReady"
                         :formSanitizeSkip="true"
                         class="form">
            <a class="to-previous-step" @click="router.back()">
                <img src="images/one/arrow-left.svg" alt="back">
                <span>{{ translate('back_button') }}</span>
            </a>
            <section class="header">
                <h2 class="title">{{ translateForType('provide_details', TranslationType) }}</h2>
            </section>
            <div class="summary-form">
                <section class="whiteboard">
                    <div class="whiteboard-title">{{ translateForType('enter_property_address', TranslationType) }}
                    </div>
                    <app-address-finder
                        :class="{'address-finder': isAddressFinderModeClear}"
                        :form-field="form.field('insuredAddress')"
                        :label="translate('btar_property_address')"
                        :mobile-mode-enabled="true"
                        :skip-own-validation="false"
                        :reset-on-enable="true"
                        :address-label="translate('btar_address_placeholder')"
                        :skip-apartment-validation="true"
                        :mode="addressFinderMode"
                        @edit-click="onAddressEditClick">
                    </app-address-finder>
                </section>
                <section class="whiteboard">
                    <div class="whiteboard-title">{{ translateForType('insured_person_details', TranslationType) }}
                    </div>
                    <app-input-checkbox
                        v-if="isAuthenticatedUser()"
                        :form-field="form.field('insuredIsAuthenticated')"
                        @click="onAuthenticatedClick">
                        {{ translateForType('authenticated_person', TranslationType) }}
                    </app-input-checkbox>
                    <app-input-checkbox
                        v-show="!isAuthenticated || !isAuthenticatedUser()"
                        :form-field="form.field('insuredIsResident')"
                        @click="storeValues()">
                        {{ translateForType('is_resident', TranslationType) }}
                    </app-input-checkbox>
                    <app-input-country
                        v-show="!isResident"
                        :include-default-country="false"
                        :patch-default-country="false"
                        :disable-error-text="true"
                        :form-field="form.field('insuredCountry')"
                        :label="translate('btar_country')"
                        :placeholder="translate('btar_country_placeholder')"
                        @close="storeValues()">
                    </app-input-country>
                    <div class="credentials three-columns">
                        <app-input-text
                            :disabled="isAuthenticated && isAuthenticatedUser()"
                            :label="translate('btar_name')"
                            :disable-error-text="true"
                            :form-field="form.field('insuredFirstName')"
                            :placeholder="translate('btar_first_name_placeholder')"
                            @input="storeValues()">
                        </app-input-text>
                        <app-input-text
                            :disabled="isAuthenticated && isAuthenticatedUser()"
                            :label="translate('btar_surname')"
                            :disable-error-text="true"
                            :form-field="form.field('insuredLastName')"
                            :placeholder="translate('btar_last_name_placeholder')"
                            :autocomplete="'<?php echo AutocompleteAttributes::LastName; ?>'"
                            @input="storeValues()">
                        </app-input-text>
                        <app-input-identity-number
                            :disabled="isAuthenticated && isAuthenticatedUser()"
                            :form-field="form.field('insuredIdentityNumber')"
                            :country="country"
                            :label="translate('btar_personal_code')"
                            :placeholder="translate('btar_identity_number_placeholder')"
                            @input="storeValues()">
                        </app-input-identity-number>
                    </div>
                    <div class="birth-date"
                         v-if="birthDateRequired">
                        <app-input-date
                            :validate-birth-date="true"
                            :include-default-country="false"
                            :form-field="form.field('insuredBirthDate')"
                            :disable-error-text="true"
                            :label="translate('btar_birth_date')"
                            @change="storeValues()">
                        </app-input-date>
                        <div
                            class="date-hint">
                            {{ translateForType('day_month_year', TranslationType) }}
                        </div>
                    </div>
                </section>
                <section class="button-container">
                    <div class="continue-button">
                        <app-button-with-callback
                            v-bind="continueButtonParams()"
                            @button-callback-click="onSubmitStep()">
                        </app-button-with-callback>
                    </div>
                </section>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.step-container {
    margin-bottom: var(--size-big);
    margin-top: calc(56px + var(--size-big));
    width: 100%;

    .header {
        padding: 0 var(--size-small);

        @include respond-above('sm') {
            padding: 0 var(--size-big);
        }

        .title,
        .description {
            text-align: center;
        }

        .title {
            font-size: var(--font-size-big);
        }
    }

    .summary-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--size-small);
        margin-top: var(--size-big);
        padding: var(--size-small);
        padding-top: 0;

        @include respond-above('sm') {
            gap: var(--size-normal);
            align-items: center;
        }

        .whiteboard {
            display: flex;
            padding: var(--size-small);
            flex-direction: column;
            align-items: flex-start;
            border-radius: var(--size-tiny);
            background: var(--component-color-background-base);
            gap: var(--size-small);
            width: 100%;
            max-width: 800px;

            @include respond-above('sm') {
                padding: var(--size-big);
            }

            .whiteboard-title {
                color: var(--text-color-default);
                font-size: var(--font-size-small);
                font-weight: 700;
                word-break: break-word
            }

            :deep(.address-finder) {
                .fields {
                    .fields-group {
                        justify-content: initial;
                        width: 100%;

                        #address {
                            @include respond-above('sm') {
                                width: 100%;
                            }
                        }

                        .sub-group {
                            @include respond-above('sm') {
                                width: 0;
                            }
                        }
                    }
                }
            }

            :deep(.change-address) {
                color: var(--text-color-link);
                font-weight: 600;
            }


            .input-date-with-calendar {
                width: 100%;
            }

            .input-date {
                .wrapper {
                    height: 52px;
                    justify-content: left;
                    padding-left: var(--size-small);
                }
            }

            .input-country {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
            }

            .credentials {
                display: flex;
                width: 100%;
                justify-content: space-between;
                flex-direction: column;
                align-items: stretch;
                gap: inherit;

                @include respond-above('sm') {
                    flex-direction: row;
                    gap: var(--size-small);
                }

                &.three-columns {
                    .input {
                        @include respond-above('sm') {
                            width: 100%;
                        }
                    }
                }
            }

            .birth-date {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--size-nano);
                align-self: stretch;

                :deep(.input-date) {
                    width: 100%;

                    .wrapper {
                        padding: var(--size-small);
                        height: 52px;
                        justify-content: left;
                    }
                }

                .date-hint {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    align-self: stretch;
                    color: var(--text-color-subtlest);
                    font-feature-settings: 'liga' off;
                    font-size: var(--font-size-nano);
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    flex: 100;
                }
            }
        }

        .button-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--size-small);
            width: 100%;
            max-width: 800px;

            .continue-button {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--size-small);
                align-self: stretch;

                @include respond-above('sm') {
                    width: 440px;
                    padding: 0;
                }

                .button-with-callback {
                    display: flex;
                    height: 52px;
                    padding: 0 var(--button-horizontal-padding);
                    justify-content: center;
                    align-items: center;
                    gap: var(--size-nano);
                    align-self: stretch;


                    @include respond-above('sm') {
                        width: 240px;
                        justify-content: center;
                        align-items: center;
                        align-self: flex-start;
                    }
                }
            }
        }
    }

    @include respond-above('sm') {
        padding: 0 var(--size-big);
        margin-bottom: var(--size-huge);
    }

    .full-width {
        width: 100%;
    }

    .half-children {
        .input {
            @include respond-above('sm') {
                width: 50%;
            }
        }
    }

    .flex {
        display: flex;
        gap: var(--size-small);

        &.column {
            flex-direction: column;
        }

        &.mobile-column {
            flex-direction: column;

            @include respond-above('sm') {
                flex-direction: row;
            }
        }
    }
}
</style>
