import {markRaw, nextTick} from 'vue';
import Form from '@/assets/libraries/form/form';
import FormField from '@/assets/libraries/form/form-field';
import Sanitizer from '@/services/sanitizer.service';
import PersonState from '@/Enums/PersonStateEnum';
import Translations from '@/services/translations.service';
import moment, { Moment } from 'moment';
import ExtractDataService from '@/services/extract.data.service';
import StatusIcon from '@/Components/StatusIcon/StatusIcon.enum';
import Debounce from '@/services/debounce.service';
import BottomNotificationLevel from '@/Enums/BottomNotificationLevelEnum';
import BottomNotification from '@/services/bottom.notification.service';
import Url from '@/Enums/UrlEnum';
import Method from '@/Enums/MethodEnum';
import Value from '@/assets/libraries/form/value';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface'
import PersonCodeGenerator from '@/assets/libraries/generators/person.code.generator';
import LookupString from '@/assets/libraries/search/lookup-string';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import { InputSearchExposable } from '@/Components/InputSearch/InputSearch';
import ModalTab from '@/pages/LegalPerson/Workbench/Common/Tabs/ModalTab';
import LegalInsuredPerson from '@/pages/LegalPerson/Workbench/Interfaces/LegalInsuredPersonInterface';
import AddPersonValidators from '@/pages/LegalPerson/Workbench/Common/Validators/AddPersonValidators';
import AddPersonsFromExcelTab from '@/pages/LegalPerson/Workbench/Common/Tabs/AddPersonsFromExcelTab';
import LegalPersonModalState from '@/pages/LegalPerson/Workbench/Enums/LegalPersonModalStateEnum';
import Validation from '@/services/validation.service';
import { InputOption } from '@/interfaces/InputOptionInterface';
import AppCountry from '@/assets/libraries/app/app-country';

export default class AddPersonTab extends ModalTab {
    public form: Form = markRaw(new Form());
    public rows: LegalInsuredPerson[] = [];

    private readonly RowStatusCheckInterval: number = 1000;
    private readonly MiddleDot: string = ' &middot; ';
    private readonly DuplicateRowIcon: string = StatusIcon.Duplicate;

    public onSelectedClick: Function = Debounce.getInstance().applyDebounce(this.updateSelectedCount, this);
    public onUpdateValidators: Function = Debounce.getInstance().applyDebounce(this.applyFormFieldValidators, this);

    private filter: string = '';
    private removeSelectedButtonTitle: string = this.translated('popup_remove_selected_rows');
    private removeInvalidButtonTitle: string = this.translated('popup_remove_invalid_rows');
    private showInvalidTitle: string = this.translated('popup_show_errored_rows');
    private showAllTitle: string = this.translated('popup_show_all_rows');
    private duplicateInRowsMessage: string = this.translated('popup_duplicate_in_rows');
    private duplicateInListMessage: string = this.translated('popup_duplicate_in_list');
    private selectedCount: number = 0;
    private rowStatusHandlerInterval!: ReturnType<typeof setInterval>;
    private validators: AddPersonValidators;
    private loading: boolean = false;
    private static instance: AddPersonTab;

    public constructor() {
        super();
        this.validators = new AddPersonValidators();
        this.validators.addContext(this);
    }

    public static getInstance(): AddPersonTab {
        if (!AddPersonTab.instance) {
            AddPersonTab.instance = new AddPersonTab();
        }

        return AddPersonTab.instance;
    }

    public get isSelectAllDisabled(): boolean {
        return this.rows.length === 0;
    }

    public get removeSelectedRowsButtonTitle(): string {
        const suffix: string = this.selectedCount
            ? this.MiddleDot + this.selectedCount : '';

        return this.removeSelectedButtonTitle + suffix;
    }

    public get removeInvalidRowsButtonTitle(): string {
        const suffix: string = this.invalidPersonsCount
            ? this.MiddleDot + this.invalidPersonsCount : '';

        return this.removeInvalidButtonTitle + suffix;
    }

    public get showAllRowsButtonTitle(): string {
        const suffix: string = this.rows.length
            ? this.MiddleDot + this.rows.length : '';

        return this.showAllTitle + suffix;
    }

    public get showInvalidRowsButtonTitle(): string {
        const suffix: string = this.invalidPersonsCount
            ? this.MiddleDot + this.invalidPersonsCount : '';

        return this.showInvalidTitle + suffix;
    }

    public get invalidPersonsCount(): number {
        return this.visibleInvalidRows().length;
    }

    public get submitPersonsButtonParams(): ButtonWithCallbackParams {
        return {
            title: this.translated('submit_x_person').replace('%count%', String(this.rows.length || '')),
            icon: ButtonIcon.Check,
            backgroundColor: ButtonBackground.Green,
            backgroundColorHover: ButtonBackground.Green,
            textColor: ButtonTextColor.White,
            iconColor: ButtonIconColor.White,
        };
    }

    public get minPolicyStartDate(): Date {
        return new AppCountry().isLT() ? moment().toDate() : moment().add(1, 'day').toDate();
    }

    public get maxPolicyStartDate(): Date {
        return moment(this.modal.app.currentAgreement!.endDate.date).toDate();
    }

    public get excelRows() {
        return AddPersonsFromExcelTab.getInstance().excelRows;
    }

    public init(): void {
        if (this.rows.length === 0) {
            this.addRow().then((): void => {
                this.form.addField(new FormField('selectAll'));
                this.form.setReady();
                this.applyRowStatusChecks();
            });
        }
    }

    public destroy(): void {
        this.resetRows();
        this.resetFilter();
        this.resetSelected();
        this.form.destroy();
        this.removeRowStatusChecks();
    }

    public onAddPersonsClick(): void {
        this.addRow().then();
    }

    public isRowVisible(rowIndex: number): boolean {
        return this.rows[rowIndex].isVisible as boolean;
    }

    public duplicateEntryMessage(rowIndex: number): string {
        let result: string = '';
        if (this.hasDuplicateInRows(rowIndex)) {
            result = this.duplicateInRowsMessage;
        }
        if (this.hasDuplicateInList(rowIndex)) {
            result = this.duplicateInListMessage;
        }

        return result;
    }

    public hasDuplicate(rowIndex: number): boolean {
        return this.hasDuplicateInRows(rowIndex) || this.hasDuplicateInList(rowIndex);
    }

    public hasDuplicateInList(rowIndex: number): boolean {
        const personCodes: string[] = this.modal.app.insuredPersons.persons
            .filter((person: LegalInsuredPerson): boolean => person.status !== PersonState.Ended)
            .map((person: LegalInsuredPerson): string => person.personCode.replace('-', ''));
        const rowPersonCode: string = String(this.form.field('personCode_' + rowIndex).value)
            .replace('-', '');

        return personCodes.includes(rowPersonCode);
    }

    public hasDuplicateInRows(rowIndex: number): boolean {
        const currentFieldName: string = 'personCode_' + rowIndex;
        const personCodes: string[] = this.form.fields()
            .filter((field: FormField): boolean =>
                field.name.includes('personCode')
                && field.name !== currentFieldName
                && rowIndex > this.fieldIndex(field)
                && field.isNotEmpty())
            .map((field: FormField): string => String(field.value)
                .replace('-', ''));
        const rowPersonCode: string = String(this.form.field('personCode_' + rowIndex).value)
            .replace('-', '');

        return personCodes.length > 0 ? personCodes.includes(rowPersonCode) : false;
    }

    public storeRowFieldValues(): Promise<void> {
        this.rows.forEach((row: LegalInsuredPerson, index: number): void => {
            row.isSelected = this.form.field('selected_' + index).value;
            row.firstName = this.form.field('firstName_' + index).value;
            row.lastName = this.form.field('lastName_' + index).value;
            row.resident = !this.form.field('nonResident_' + index).value;
            row.email = this.form.field('email_' + index).value;
            row.country = this.form.field('countryIc_' + index).value;
            row.personCode = this.form.field('personCode_' + index).value;
            row.birthDate = this.form.field('birthDate_' + index).value;
            row.startDate = this.form.field('startDate_' + index).value;
            row.endDate = this.form.field('endDate_' + index).value;
            row.insuranceProgram = this.form.field('insuranceProgram_' + index).value;
            row.premium = this.form.field('premium_' + index).value;
        });
        return this.resetForm();
    }

    public onRemoveSelectedRowsClick(): void {
        this.loading = true;
        this.storeRowFieldValues().then((): void => {
            if (this.selectedCount > 0) {
                this.removePersons().then((): void => {
                    this.form.field('selectAll').patch(false);
                    this.patchFieldsFromRows().then((): void => {
                        this.resetSelected();
                        this.onUpdateValidators();
                        this.showNotification('popup_notification_removed_selected_rows');
                    });
                });
            } else {
                this.showNotification('popup_notification_selected_rows_unavailable');
            }
        });
    }

    public onRemoveInvalidRowsClick(): void {
        this.loading = true;
        this.storeRowFieldValues().then((): void => {
            if (this.invalidPersonsCount > 0) {
                this.removeInvalidRows().then((): void => {
                    this.patchFieldsFromRows().then((): void => {
                        this.onUpdateValidators();
                        this.showNotification('popup_notification_removed_invalid_rows');
                    });
                });
            } else {
                this.showNotification('popup_notification_invalid_rows_unavailable');
            }
        });
    }

    public onShowInvalidRowsClick(): void {
        this.loading = true;
        if (this.invalidPersonsCount > 0) {
            this.showInvalidRows().then((): void => {
                this.showNotification('popup_notification_showing_invalid_rows');
            });
        } else {
            this.showNotification('popup_notification_invalid_rows_unavailable');
        }
        this.loading = false;
    }

    public onShowAllClick(): void {
        this.loading = true
        const inputSearch: DynamicDictionary | undefined = this.modal.app.componentRef('addPersonSearch');
        if (inputSearch) {
            (inputSearch as InputSearchExposable).onClearClick();
        }
        this.resetFilter();
        if (this.rows.length > 0) {
            this.rows.forEach((row: LegalInsuredPerson): void => {
                row.isVisible = true;
            });
            this.showNotification('popup_notification_showing_all_rows');
        } else {
            this.showNotification('popup_notification_rows_unavailable');
        }
        this.loading = false;
    }

    public onExcelImportClick(): void {
        this.removeBlankRow().then((): void => {
            this.modal.app.businessModal.preserveModalQueue(LegalPersonModalState.AddPersonsFromExcel);
            this.resetFilter();
        });
    }

    public onSelectAllClick(checked: boolean): void {
        this.form.fields()
            .filter((field: FormField): boolean => field.name.includes('selected_'))
            .forEach((field: FormField): void => {
                if (this.isRowVisible(this.fieldIndex(field))) {
                    field.patch(checked);
                }
            });
        this.rows.forEach((row: LegalInsuredPerson): void => {
            row.isSelected = checked;
        });
        this.onSelectedClick();
    }

    public onResidentClick(index: number): void {
        this.form.field('personCode_' + index).validate().then((): void => {
            this.onIdentityNumberBlur(index);
        });
        if (this.form.field('birthDate_' + index).value !== '') {
            this.generatePersonCodeOnChange(index);
        }
        if (this.form.field('nonResident_' + index).value) {
            this.form.field('countryIc_' + index).clear().then();
        } else {
            this.form.field('countryIc_' + index).patch({iso: new AppCountry().iso()});
            this.form.field('email_' + index).clear().then();
        }
    }

    public onSubmitPersonsClick(): void {
        if (this.rows.length > 0) {
            this.applyFormFieldValidators();
            this.form.touch().then((): void => {
                this.form.validate().then((): void => {
                    if (this.form.isValid()) {
                        if (this.listHasDuplicates()) {
                            this.showNotification('popup_notification_duplicate_rows',
                                {'count': this.rows.length}, BottomNotificationLevel.Error)
                        } else {
                            this.postInsuredPersons().then((response: DynamicDictionary): void => {
                                if (response.data && response.data.errors) {
                                    this.modal.showSimpleErrorPopup();
                                } else {
                                    this.modal.app.fetchPolicyDetailsWithoutCache().then((): void => {
                                        BottomNotification.getInstance()
                                            .pushNotification(BottomNotificationLevel.Success,
                                                this.modal.app.localized(
                                                    'toastr_success_person_add',
                                                    '',
                                                    {count: this.rows.length}
                                                )
                                            );
                                        this.modal.hideModalPopup();
                                    });
                                }
                            });
                        }
                    }
                });
            });
        } else {
            this.showNotification('popup_notification_rows_unavailable');
        }
    }

    public onCancelClick(): void {
        if (this.rows.length > 0) {
            this.modal.markDataAsEdited();
        }
        this.modal.closeModalPopup();
    }

    public onManualSearch(searchTerm: string): void {
        this.filter = searchTerm;
        this.resetRowVisibility();
        if (searchTerm !== '') {
            this.updateRowVisibility();
        }
        this.updateSelectedCount();
    }

    public onIdentityNumberBlur(fieldIndex: number): void {
        const personCode: string = String(this.form.field('personCode_' + fieldIndex).value);
        if (this.canAttemptDateExtraction(personCode)) {
            if (this.form.field('personCode_' + fieldIndex).isValid
                && this.form.field('personCode_' + fieldIndex).isNotEmpty()
                && !this.form.field('nonResident_' + fieldIndex).value) {
                const extractedDate: Date | undefined = new ExtractDataService()
                    .birthDateFromPersonCode(this.form.field('personCode_' + fieldIndex).value);
                if (extractedDate) {
                    this.form.field('birthDate_' + fieldIndex).patch(extractedDate);
                }
            }
        }
        this.form.field('birthDate_' + fieldIndex).validate().then();
    }

    public onSelectRowClick(checked: boolean, rowIndex: number): void {
        this.rows[rowIndex].isSelected = checked;
        this.onSelectedClick();
    }

    public updateSelectedCount(): void {
        this.selectedCount = this.selectedRows().length;
    }

    public patchFieldsFromRows(): Promise<void> {
        return new Promise(resolve => {
            this.rows.forEach((row: LegalInsuredPerson, index: number): void => {
                this.addRowFields(index).then((): void => {
                    this.loading = false;
                    this.form.field('firstName_' + index).patch(row.firstName.toUpperCase());
                    this.form.field('lastName_' + index).patch(row.lastName.toUpperCase());
                    this.form.field('nonResident_' + index).patch(!row.resident as boolean);
                    this.form.field('email_' + index).patch(row.email);
                    this.form.field('countryIc_' + index).patch(row.country);
                    this.form.field('personCode_' + index).patch(String(row.personCode));
                    this.form.field('birthDate_' + index).patch(moment(row.birthDate).toDate());
                    this.form.field('startDate_' + index).patch(row.startDate as unknown as DynamicDictionary);
                    this.form.field('endDate_' + index).patch(row.endDate as string);
                    this.form.field('insuranceProgram_' + index).patch(row.insuranceProgram);
                });
            });
            resolve();
        });

    }

    public patchRows(): void {
        this.modal.markDataAsEdited();
        const startIndex: number = this.rows.length;
        const supportedFormats: string[] = ['DD/MM/YYYY', 'DD.MM.YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD'];
        this.joinRows().then((): void => {
            this.updateForm().then((): void => {
                this.rows.forEach((row: LegalInsuredPerson, index: number): void => {
                    if (index >= startIndex) {
                        this.form.field('firstName_' + index).patch(row.firstName.toUpperCase());
                        this.form.field('lastName_' + index).patch(row.lastName.toUpperCase());
                        this.form.field('nonResident_' + index).patch(!row.resident as boolean);
                        if (!row.resident) {
                            if (row.countryIc !== '') {
                                this.form.field('countryIc_' + index).patch({ic: row.countryIc});
                            } else {
                                this.form.field('countryIc_' + index).clear().then();
                            }
                            this.form.field('email_' + index).patch(row.email);
                        }
                        this.form.field('personCode_' + index).patch(String(row.personCode));
                        const birthDateFromRow: moment.Moment = moment(row.birthDate, supportedFormats);
                        if (birthDateFromRow.isValid()) {
                            this.form.field('birthDate_' + index).patch(birthDateFromRow.toDate());
                        } else {
                            if (!Validation.isValidPersonCodeWithoutDate(String(row.personCode))) {
                                const extractedBirthDate: Date | undefined = new ExtractDataService()
                                    .birthDateFromPersonCode(row.personCode);
                                if (extractedBirthDate) {
                                    this.form.field('birthDate_' + index).patch(extractedBirthDate);
                                }
                            } else {
                                this.form.field('birthDate_' + index).touch();
                            }
                        }
                        this.form.field('startDate_' + index).patch({
                            startDate: moment(row.startDate, supportedFormats).toDate(),
                            endDate: '',
                        });
                        this.form.field('insuranceProgram_' + index).patch(row.insuranceProgram.id);
                    }
                });
            });
        });
    }

    public removeBlankRow(): Promise<void> {
        return new Promise(resolve => {
            if (this.rows.length === 1 && this.rows[0].icon === StatusIcon.InProgress) {
                this.resetRows();
                this.form.removeRow(0);
            }
            resolve();
        });
    }

    public agreementEndDate(): Moment {
        return this.modal.app.currentAgreementEndDate();
    }

    public agreementStartDate(): Moment {
        return moment().add(1, 'day');
    }

    private postInsuredPersons(): Promise<DynamicDictionary> {
        const assembledPersons: DynamicDictionary[] = this.assembledPersons();
        return this.modal.app.axiosFetch(Url.Ajax.legalPersonAddPersons, assembledPersons, Method.Post, true)
            .then((response: DynamicDictionary): DynamicDictionary => {
                return response;
            });
    }

    private resetForm(): Promise<void> {
        this.form.destroy();
        return nextTick((): void => {
            this.form = markRaw(new Form());
            this.form.addField(new FormField('selectAll'));
            this.form.setReady();
        });
    }

    private assembledPersons(): DynamicDictionary[] {
        return this.rows.map((row: LegalInsuredPerson, index: number): DynamicDictionary => {
            return {
                firstName: this.form.field('firstName_' + index).value,
                lastName: this.form.field('lastName_' + index).value,
                personCode: this.form.field('personCode_' + index).value,
                resident: !this.form.field('nonResident_' + index).value,
                email: this.form.field('email_' + index).value,
                countryIc: this.form.field('countryIc_' + index).value.ic,
                birthDate: this.dateString(this.form.field('birthDate_' + index).value),
                startDate: this.dateString(this.form.field('startDate_' + index).value.startDate),
                insuranceProgram: {id: this.form.field('insuranceProgram_' + index).value},
                agreement: {id: this.modal.app.currentAgreement!.id, number: this.modal.app.policyId}
            };
        });
    }

    private canAttemptDateExtraction(identityNumber: string): boolean {
        let result: boolean;
        switch (new AppCountry().iso()) {
            case 'LV':
                result = !Validation.isValidPersonCodeWithoutDate(identityNumber);
                break;
            case 'LT':
            case 'EE':
                result = Validation.isValidNaturalPersonCode(identityNumber);
                break;
            default:
                result = false;
                break;
        }

        return result;
    }

    private dateString(date: Date): string {
        return moment(date).format('YYYY-MM-DD');
    }

    public generatePersonCodeOnChange(index: number): void {
        if (this.form.field('nonResident_' + index).value) {
            const insuredPersonsCodes: string[] = this.modal.app.insuredPersons.persons.map(
                (person: LegalInsuredPerson) => person.personCode);
            const addedPersonsCodes: string[] = this.form.fields()
                .filter((field: FormField): boolean => field.name.includes('personCode'))
                .map((field: FormField): string => field.value);
            const code: string = new PersonCodeGenerator(new AppCountry().iso())
                .withExistingCodes([...insuredPersonsCodes, ...addedPersonsCodes])
                .withBirthDate(this.form.field('birthDate_' + index).value)
                .withPersonCode(this.form.field('personCode_' + index).value)
                .generate();
            this.form.field('personCode_' + index).patch(code);
        }
    }

    private joinRows(): Promise<void> {
        return new Promise(resolve => {
            this.rows = this.rows.concat(JSON.parse(JSON.stringify(this.excelRows)));
            resolve();
        });
    }

    private applyRowStatusChecks(): void {
        this.rowStatusHandlerInterval = setInterval(
            this.updateStatusIcons,
            this.RowStatusCheckInterval,
            this
        );
    }

    private removeRowStatusChecks(): void {
        clearInterval(this.rowStatusHandlerInterval);
    }

    private updateForm(): Promise<void> {
        return new Promise(resolve => {
            this.rows.forEach((row: LegalInsuredPerson, index: number): void => {
                this.addRowFields(index).then();
            });
            resolve();
        });
    }

    private updateStatusIcons(tab: AddPersonTab): void {
        const indexDelimiter: string = '_';
        tab.rows.forEach((row: LegalInsuredPerson, index: number): void => {
            if (row.icon !== StatusIcon.Valid) {
                const rowInProgress: FormField | undefined = tab.form.fields()
                    .find((field: FormField): boolean => {
                        const fieldName: string = field.name
                            .substring(0, field.name.indexOf(indexDelimiter));

                        return tab.necessaryFields().includes(fieldName)
                            && field.name.substring(field.name.indexOf(indexDelimiter) + 1) === String(index)
                            && (!field.isTouched
                                || !field.isValid
                                || new Value(field.value).isEmpty());
                    });
                if (typeof rowInProgress === 'undefined') {
                    tab.rows[index].icon = StatusIcon.Valid;
                }
            }
            if (row.icon === StatusIcon.Error && tab.form.invalidRows().length === 0) {
                row.icon = StatusIcon.InProgress;
            }
            if (tab.form.invalidRows().includes(index) || tab.hasDuplicate(index)) {
                tab.rows[index].icon = StatusIcon.Error;
            }
        });
    }

    private showNotification(text: string, replacements?: DynamicDictionary,
                             level: string = BottomNotificationLevel.Info): void {
        BottomNotification
            .getInstance()
            .pushNotification(level, this.translated(text, replacements));
    }

    private get personPlaceholder(): LegalInsuredPerson {
        return {
            status: PersonState.Active,
            firstName: '',
            lastName: '',
            personCode: '',
            resident: true,
            email: '',
            countryIc: '',
            insuranceProgram: {id: '', name: ''},
            premium: {amount: 0, currency: ''},
            isVisible: true,
            isSelected: false,
            icon: StatusIcon.InProgress,
            processingRecords: []
        } as unknown as LegalInsuredPerson;
    }

    private necessaryFields(): string[] {
        return [
            'firstName',
            'lastName',
            'personCode',
            'birthDate',
            'startDate',
            'insuranceProgram',
        ];
    }

    private fieldIndex(field: FormField): number {
        return Number(field.name.substring(field.name.indexOf('_') + 1));
    }

    private get selectedVisibleRows(): LegalInsuredPerson[] {
        return this.rows
            .filter((row: LegalInsuredPerson): boolean => row.isVisible as boolean && row.isSelected as boolean);
    }

    private visibleInvalidRows(): LegalInsuredPerson[] {
        return this.rows
            .filter((row: LegalInsuredPerson) =>
                row.isVisible && row.icon === StatusIcon.Error);
    }

    private listHasDuplicates(): boolean {
        let result: boolean = false;
        this.rows.forEach((row: LegalInsuredPerson, index: number): void => {
            if (this.hasDuplicate(index)) {
                result = true;
            }
        });

        return result;
    }

    private nextRowIndex(): number {
        return this.rows.length - 1;
    }

    private translated(key: string, replacements?: DynamicDictionary): string {
        return Translations.getInstance().localized(key, 'legal', replacements);
    }

    private addRow(): Promise<void> {
        return new Promise(resolve => {
            this.rows.push(this.personPlaceholder);
            this.addRowFields().then();
            resolve();
        });
    }

    private addRowFields(startIndex?: number): Promise<void> {
        return new Promise(resolve => {
            const nextRowIndex: number = typeof startIndex === 'undefined'
                ? this.nextRowIndex() : startIndex;
            const insurancePrograms: InputOption[] = this.modal.app.legalAvailableProgramInputOptions;
            this.form.addField(new FormField('selected_' + nextRowIndex).touch());
            this.form.addField(new FormField('firstName_' + nextRowIndex, '', '', Sanitizer.cleanUppercaseName));
            this.form.addField(new FormField('lastName_' + nextRowIndex, '', '', Sanitizer.cleanUppercaseName));
            this.form.addField(new FormField('nonResident_' + nextRowIndex).touch());
            this.form.addField(new FormField('countryIc_' + nextRowIndex));
            this.form.addField(new FormField('email_' + nextRowIndex, '', '', Sanitizer.cleanEmail));
            this.form.addField(new FormField('personCode_' + nextRowIndex, '', '', Sanitizer.cleanPersonCode));
            this.form.addField(new FormField('birthDate_' + nextRowIndex));
            this.form.addField(new FormField('startDate_' + nextRowIndex));
            this.form.addField(new FormField('endDate_' + nextRowIndex,
                moment(this.modal.app.currentAgreement!.endDate.date).format(this.modal.app.DateFormat)));
            this.form.addField(new FormField('insuranceProgram_' + nextRowIndex));
            if (insurancePrograms.length === 1) {
                this.form.field('insuranceProgram_' + nextRowIndex)
                    .setValue(insurancePrograms[0].value);
            }
            this.form.addField(new FormField('premium_' + nextRowIndex, '-').touch());
            this.freezeFields();
            resolve();
        });
    }

    private updateRowVisibility(): void {
        const searchIndexes: number[] = [];
        const foundFields: FormField[] = this.form.fields()
            .filter((field: FormField): boolean =>
                new LookupString()
                    .withSearchTerm(this.filter)
                    .withTargetValue(field.value)
                    .useStringStartsWith()
                    .match());
        foundFields.forEach((field: FormField): void => {
            searchIndexes.push(this.fieldIndex(field));
        });
        this.rows.forEach((row: LegalInsuredPerson, index: number): void => {
            if (![...new Set(searchIndexes)].includes(index)) {
                row.isVisible = false;
            }
        });
    }

    private resetRowVisibility(): void {
        this.rows.forEach((row: LegalInsuredPerson): void => {
            row.isVisible = true;
        });
    }

    private resetFilter(): void {
        this.filter = '';
        this.resetRowVisibility();
    }

    private resetRows(): void {
        this.rows = [];
    }

    private resetSelected(): void {
        this.selectedCount = 0;
    }

    private removePersons(): Promise<void> {
        return new Promise(resolve => {
            const rowsToRemove: number[] = this.selectedRows()
                .map((row: LegalInsuredPerson): number => this.rows.indexOf(row));
            rowsToRemove.reverse().forEach((rowIndex: number): void => {
                this.rows.splice(rowIndex, 1);
            });
            resolve();
        });
    }

    public selectedRows(): LegalInsuredPerson[] {
        return this.rows
            .filter((row: LegalInsuredPerson): boolean => row.isVisible as boolean && row.isSelected as boolean);
    }

    private removeInvalidRows(): Promise<void> {
        return new Promise(resolve => {
            const rowsToRemove: number[] = this.rows
                .filter((row: LegalInsuredPerson): boolean => row.icon === StatusIcon.Error)
                .map((row: LegalInsuredPerson): number => this.rows.indexOf(row));
            rowsToRemove.reverse().forEach((rowIndex: number): void => {
                if (this.isRowVisible(rowIndex)) {
                    this.rows.splice(rowIndex, 1);
                }
            });
            resolve();
        });
    }

    private showInvalidRows(): Promise<void> {
        return new Promise(resolve => {
            this.rows.forEach((row: LegalInsuredPerson): void => {
                if (row.icon !== StatusIcon.Error) {
                    row.isVisible = false;
                }
            });
            resolve();
        });
    }

    private freezeFields(): void {
        this.form.fields()
            .filter((field: FormField): boolean => !Object.isFrozen(field))
            .forEach((field: FormField): Readonly<FormField> => Object.freeze(field));
    }

    private addIdentityNumberFieldValidators(): void {
        this.form.fields()
            .filter((field: FormField): boolean => field.name.includes('personCode'))
            .forEach((field: FormField): void => {
                field.addValidators(this.validators.identityNumberValidator(field.name))
            });
    }

    private addPolicyStartDateFieldValidators(): void {
        this.form.fields()
            .filter((field: FormField): boolean => field.name.includes('startDate'))
            .forEach((field: FormField): void => {
                field.addValidators(this.validators.policyStartDateValidator(field.name))
            });
    }

    private addBirthDateFieldValidators(): void {
        this.form.fields()
            .filter((field: FormField): boolean => field.name.includes('birthDate'))
            .forEach((field: FormField): void => {
                field.addValidators(this.validators.birthDateValidator(field.name))
            });
    }

    private addCountryFieldValidators(): void {
        this.form.fields()
            .filter((field: FormField): boolean => field.name.includes('countryIc'))
            .forEach((field: FormField): void => {
                field.addValidators(this.validators.countryValidator(field.name))
            });
    }

    private addEmailFieldValidators(): void {
        this.form.fields()
            .filter((field: FormField): boolean => field.name.includes('email'))
            .forEach((field: FormField): void => {
                field.addValidators(this.validators.emailValidator(field.name))
            });
    }

    private addRequiredValidators(): void {
        this.form.fields()
            .filter((field: FormField): boolean =>
                field.name.includes('insuranceProgram')
                || field.name.includes('firstName')
                || field.name.includes('lastName')
                || field.name.includes('birthDate')
                || field.name.includes('startDate'))
            .forEach((field: FormField): FormField =>
                field.addValidators('required'));
    }

    private applyFormFieldValidators(): void {
        this.addIdentityNumberFieldValidators();
        this.addPolicyStartDateFieldValidators();
        this.addBirthDateFieldValidators();
        this.addCountryFieldValidators();
        this.addEmailFieldValidators();
        this.addRequiredValidators();
    }
}
