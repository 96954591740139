<script setup lang="ts">
import Sanitizer from '@/services/sanitizer.service';
import FormField from '@/assets/libraries/form/form-field';
import Form from '@/assets/libraries/form/form';
import Value from '@/assets/libraries/form/value';
import {Subscription} from 'rxjs';
import {computed, ComputedRef, onBeforeUnmount, PropType, ref, watch} from 'vue';
import {useTranslate} from "@/Composables/Translate";
import {useInputErrorMessage} from "@/Composables/InputErrorMessage";
import AppInputText from '@/Components/InputText/InputText.vue';

const props = defineProps({
    formField: {type: Object as PropType<FormField<string>>, default: () => new FormField('')},
    label: {type: String, default: ''},
    dataStoreDisabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    placeholder: {type: String, default: ''},
    supportTextMessage: {type: String, default: ''},
    feedbackMessage: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    mode: {type: String, default: ''},
    disableErrorText: {type: Boolean, default: false},
});
const emit = defineEmits(['change']);

const placeholderHours: string = 'HH';
const placeholderMinutes: string = 'MM';
const MaxLength: number = 2;
const Hours: string = 'hours';
const Minutes: string = 'minutes';
const HoursFormat: string = 'HH';
const MinutesFormat: string = 'mm';
const TimeSeparator: string = ':';
const defaultPlaceholder: string = 'HH:MM';

const form: Form = new Form();
const isDataRestored = ref(props.formField.value.isRestored);
const {translate} = useTranslate();

let clearSubscribe: Subscription | null = null;
let touchSubscribe: Subscription | null = null;

const inputErrors = computed(() => useInputErrorMessage(props.formField, props.disableErrorText, {
    supportTextMessage: supportTextMessage.value,
    feedbackMessage: feedBackMessage.value
}));
const isSplitMode: ComputedRef<boolean> = computed(() =>
    props.mode === 'split'
);
const fieldId: ComputedRef<string> = computed(() =>
    props.formField.name + '-text'
);
const feedBackMessage: ComputedRef<string> = computed(() =>
    props.feedbackMessage
);
const supportTextMessage: ComputedRef<string> = computed(() =>
    props.supportTextMessage
);
const timeFormat: ComputedRef<string> = computed(() =>
    [HoursFormat, MinutesFormat].join(TimeSeparator)
);
const hoursInput: ComputedRef<JQuery> = computed(() =>
    $('#hours-text')
);

const minutesInput: ComputedRef<JQuery> = computed(() =>
    $('#minutes-text')
);

props.formField.addSanitizer(Sanitizer.cleanTime);
props.formField.addValidators('time');
if (isSplitMode.value) {
    form.addField(new FormField(Hours, '', Sanitizer.cleanInputNumber));
    form.addField(new FormField(Minutes, '', Sanitizer.cleanInputNumber));
}
clearSubscribe = props.formField.onClear.subscribe(() => {
    form.field(Hours).clear();
    form.field(Minutes).clear();
});
touchSubscribe = props.formField.onTouch.subscribe(() => {
    form.field(Hours).touch();
    form.field(Minutes).touch();
});
patchChildFields();

watch(() => props.formField.isRestored, () => {
    if (isSplitMode.value) {
        patchChildFields();
    }
});

onBeforeUnmount((): void => {
    if (clearSubscribe) {
        clearSubscribe.unsubscribe();
    }
    if (touchSubscribe) {
        touchSubscribe.unsubscribe();
    }
});

function onChange(): void {
    props.formField.touch();
    emit('change', props.formField.value);
}

function onInput(): void {
    const maxValueFilter: RegExp = /^([3-9])(?!:)(?!$)/;
    const allowOnlyTwoDigits: RegExp = /^(.{2})(?!:)(?!$)/;
    props.formField.value = props.formField.value.replace(maxValueFilter, '0$1:');
    props.formField.value = props.formField.value.replace(allowOnlyTwoDigits, '$1:');
}

function onHoursInput(): void {
    if (fieldIsFilled(Hours)) {
        triggerFocusOnElement(minutesInput.value);
    }
    patchFormFieldValue();
}

function onMinutesInput(): void {
    if (fieldIsEmpty(Minutes) && !fieldIsFilled(Hours)) {
        triggerFocusOnElement(hoursInput.value);
    }
    patchFormFieldValue();
}

function patchFormFieldValue(): void {
    const timeFieldsCombined: string[] = [
        form.field(Hours).value,
        form.field(Minutes).value
    ];
    props.formField.patch(timeFieldsCombined.join(TimeSeparator));
}

function patchChildFields() {
    if (!props.formField.isEmpty()) {
        const values: string[] = String(props.formField.value).split(TimeSeparator);
        if (!new Value(values[0]).isEmpty()) {
            form.field(Hours).patch(values[0]);
        }
        if (!new Value(values[1]).isEmpty()) {
            form.field(Minutes).patch(values[1]);
        }
    }
}

function fieldIsFilled(fieldName: string): boolean {
    return form.field(fieldName).value.length === 2;
}

function fieldIsEmpty(fieldName: string): boolean {
    const minLength: number = 0;

    return form.field(fieldName).value.length === minLength;
}

function triggerFocusOnElement(element: JQuery): void {
    element.trigger('focus');
}

</script>

<template>
    <div class="input input-time"
         :id="formField.name"
         :class="{...formField.classes(), 'disabled': disabled}"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : formField.value">
        <div class="label informative"
             v-if="label" :class="{ 'informative': !isSplitMode, 'split-informative': isSplitMode }">
            <label :for="fieldId">
                {{ label }}<span v-if="required" class="asterisk">&#42</span>
            </label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div v-if="!isSplitMode" class="wrapper">
            <input type="text"
                   maxlength="5"
                   :id="fieldId"
                   v-model="formField.value"
                   :name="formField.name"
                   :disabled="disabled"
                   :placeholder="placeholder || defaultPlaceholder"
                   @change="onChange"
                   @input="onInput">
        </div>
        <div v-if="isSplitMode" class="split-wrapper">
            <app-input-text type="text" class="input-time"
                            :maxLength="MaxLength"
                            :sanitizer="Sanitizer.cleanNumber"
                            :id="fieldId + '-hours'"
                            :form-field="form.field(Hours)"
                            :name="formField.name + '-hours'"
                            :disabled="disabled"
                            :placeholder="placeholderHours"
                            :labelUnder="true"
                            :data-store-disabled="true"
                            :label="translate('time_hours')"
                            @change="onChange"
                            @input="onHoursInput">
            </app-input-text>
            <div class="separator">:</div>
            <app-input-text type="text" class="input-time"
                            :maxLength="MaxLength"
                            :sanitizer="Sanitizer.cleanNumber"
                            :id="fieldId + '-minutes'"
                            :form-field="form.field(Minutes)"
                            :name="formField.name + '-minutes'"
                            :disabled="disabled"
                            :placeholder="placeholderMinutes"
                            :labelUnder="true"
                            :data-store-disabled="true"
                            :label="translate('time_minutes')"
                            @change="onChange"
                            @input="onMinutesInput">
            </app-input-text>
        </div>
        <div v-if="inputErrors.infoMessageIsVisible()" v-html="inputErrors.infoMessage()" class="feedback"></div>
    </div>
</template>

<style lang="scss" scoped>
.input-time {
    .wrapper {
        width: 130px;
    }

    .wrapper-split {
        display: inline-block;
        width: 180px;
        padding: 10px 0 0;
    }

    .input-time {
        width: 70px;
    }

    :deep(.input input) {
        width: 70px;
        text-align: center;
        padding: 0 20px;
    }

    :deep(.input label) {
        padding: 5px 0 0;
        font-size: 10px;
        color: var(--black-500);
        margin-bottom: 0;
    }

    .separator {
        display: inline-block;
        font-weight: bolder;
        text-align: center;
        width: 20px;
    }

    .split-informative {
        font-weight: bolder;
        color: var(--text-color-default);
        font-size: var(--font-size-small);
    }
}

.feedback {
    position: absolute;
    top: 90%;
    width: 100%;
    color: var(--brand-red);
    font-size: var(--font-size-pico);
    margin-top: 0;
}
</style>
