<script lang="ts">

import {defineComponent, Ref, ref, nextTick} from 'vue';
import Error from '@/services/error.service';
import OneBaseService from '@/services/OneBaseService';
import {ClaimSubmissionParams, useClaimSubmission} from
        '@/Modules/ClaimsSubmissionForm/Composables/ClaimSubmission';
import {useModule} from '@/Composables/Module';
import OneModule from '@/interfaces/OneModuleInterface';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Captcha from '@/services/captcha.service';
import moment from 'moment/moment';
import FormField from '@/assets/libraries/form/form-field';
import Sanitizer from '@/services/sanitizer.service';
import {Observable, of} from 'rxjs';
import Validation from '@/services/validation.service';

export default defineComponent({
    setup() {
        const captcha: Captcha = Captcha.getInstance();


        const module: OneModule = useModule();
        const claimSubmission: ClaimSubmissionParams = useClaimSubmission();


        const ready: Ref<boolean> = ref(false);


        const SecondStep: number = 2;
        const claimType: string = 'accident';
        const uploader: string = 'accident';
        const renew: boolean = true;


        const create = (): void => {
        }

        const beforeModuleMounted = () => {
            module.beforeModuleMounted();
            if (!ready.value) {
                ready.value = true;
                const onExternalDataIsReady: Observable<void> = module.ready.value ?
                    of(void 0) : module.onModuleReady;
                onExternalDataIsReady.subscribe((): void => {
                    nextTick((): void => {
                        setupUserForm();
                        setupClaimForm();
                        claimSubmission.pushDataLayer(claimType, false);
                        claimSubmission.filesRequired.value = false;
                        claimSubmission.patchUserFields();
                        module.applyModuleType();
                    });
                });
            }
        }

        const moduleMounted = () => {
            module.moduleMounted();
        }

        const secondStep = (): void => {
            claimSubmission.selectStep(SecondStep);
            claimSubmission.pushDataLayer(claimType, false);
        }

        const prepareAndSubmit = (): void => {
            captcha.executeCaptcha(doSubmit).then().catch((reason: string) => {
                Error.log(ErrorType.Error, 'prepareAndSubmit', reason, true);
            });
        }

        const doSubmit = (token: string): void => {
            const params = {
                'claimType': claimType,
                'uploader': uploader,
                'firstName': claimSubmission.userForm.field('firstName').value,
                'lastName': claimSubmission.userForm.field('lastName').value,
                'email': claimSubmission.userForm.field('email').value,
                'phone': '+' + claimSubmission.getPhoneCountry() + claimSubmission.userForm.field('phone').value.phone,
                'date': moment(claimSubmission.claimForm.field('date').value).format('DD/MM/YYYY'),
                'address': claimSubmission.claimForm.field('address').value,
                'description': claimSubmission.claimForm.field('description').value,
                'g-recaptcha-response': token
            };
            claimSubmission.submit(params);
        }

        const setupUserForm = (): void => {
            claimSubmission.userForm.addField(
                new FormField('firstName', '', personNameValidator(), Sanitizer.cleanName));
            claimSubmission.userForm.addField(
                new FormField('lastName', '', personNameValidator(), Sanitizer.cleanName));
            claimSubmission.userForm.addField(new FormField('email', '', 'required'));
            claimSubmission.userForm.addField(new FormField('phone', '', 'required'));
        }

        const setupClaimForm = (): void => {
            claimSubmission.claimForm.addField(new FormField('date', '', dateBeforeNowValidator()));
            claimSubmission.claimForm.addField(new FormField('address', '', 'required'));
            claimSubmission.claimForm.addField(new FormField('description'));
        }

        const personNameValidator = (): object => {
            return {
                validPersonNameField: (value: string) => Validation.isValidCaption(value)
            }
        }

        const dateBeforeNowValidator = (): object => {
            return {
                validBeforeDate: (value: string) => {
                    return moment(value).isBefore(moment())
                }
            }
        }


        return {
            ...module,
            ...{
                renew,
                SecondStep,
                claimType,
                uploader,
                claimSubmission,
                create,
                beforeModuleMounted,
                moduleMounted,
                secondStep,
                prepareAndSubmit,
            }
        };
    },

    mounted() {
        OneBaseService.getInstance().applyApp(this);
    }
});
</script>
