<script setup lang="ts">
import {PropType} from 'vue';
import PlaceholderOptionEmitters from '@/Components/AdditionalOptionsList/Enums/PlaceholderOptionEmitters';
import PlaceholderOption from '@/Components/AdditionalOptionsList/Interfaces/PlaceholderOption';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';

const props = defineProps({
    option: {
        type: Object as PropType<PlaceholderOption>, default: () => {
        }
    },
});
const emit = defineEmits([
    PlaceholderOptionEmitters.Click
]);

function continueButtonParams(): ButtonWithCallbackParams {
    return {
        title: props.option.buttonText,
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.DarkGreen,
        backgroundColorHover: ButtonBackground.DarkGreen
    };
}

function onButtonClick(): void {
    emit(PlaceholderOptionEmitters.Click);
}

</script>

<template>
    <div class="placeholder-option">
        <div class="title flex column">
            <div>{{option.text}}</div>
            <app-button-with-callback
                @button-callback-click="onButtonClick"
                v-bind="continueButtonParams()">
            </app-button-with-callback>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.placeholder-option {
    background: var(--white);
    width: 100%;
    border-radius: var(--size-pico);
    padding: var(--size-small);
    border: 2px solid transparent;

    .title {
        gap: var(--size-small);
        font-weight: 700;
        font-size: var(--font-size-small);
    }

    .flex {
        display: flex;

        &.column {
            flex-direction: column;
        }
    }

    :deep(button) {
        height: 52px;
    }

    @include respond-above('sm') {
        width: 440px;
    }
}
</style>
