import {AxiosResponse} from 'axios';
import Translations from '@/services/translations.service';
import {LimitedVariant} from '@/Types/LimitedVariantType';

export default class BaseApi {
    private readonly translations = Translations.getInstance();

    protected formattedUrl(url: string): string {
        return '/' + this.language() + url;
    }

    protected language(): string {
        return this.translations.language;
    }

    protected isValidResponse(response: AxiosResponse): boolean {
        return this.isSet(response.data.data);
    }

    protected isSet(property: LimitedVariant | undefined): boolean {
        return typeof property !== 'undefined' && property !== null;
    }
}
