<script setup lang="ts">
import {onMounted, PropType} from 'vue';
import ProductBlockItem from '@/Components/ProductBlock/Interfaces/ProductBlockItemInterface';
import CssClass from '@/Enums/CssClassEnum';
import ProductBlockEmitters from '@/Components/ProductBlock/Enums/ProductBlockEmittersEnum';
import ProductBlockSumType from '@/Components/ProductBlock/Enums/ProductBlockSumTypeEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useTranslate} from '@/Composables/Translate';
import {usePrice} from '@/Composables/Price';
import {useStrings} from '@/Composables/Strings';

const {sparse} = usePrice();
const {translateForType} = useTranslate();
const {capitalize} = useStrings();
const props = defineProps({
    componentName: {type: String, default: 'RentersMini'},
    products: {type: Array as PropType<ProductBlockItem[]>, default: () => []},
    selectedProduct: {
        type: Object as PropType<DynamicDictionary>, default: () => {
            return {
                selectedProductId: '',
                miniSelectedProductId: '',
                width: 0,
            };
        }
    },
    productSumType: {type: String, default: ProductBlockSumType.None},
});
const emit = defineEmits([ProductBlockEmitters.MiniPlanSelected]);
const TranslationType: string = 'product_block';

onMounted((): void => {
    applyDefaultOption();
});

function applyDefaultOption(): void {
    const defaultProduct: ProductBlockItem | undefined = props.products.find((product: ProductBlockItem): boolean => product.defaultOnMobile);
    if (defaultProduct) {
        onMiniPlanClick(defaultProduct);
    }
}

function miniPlanClasses(product: ProductBlockItem): string {
    const result: string[] = [];
    if (props.selectedProduct.miniSelectedProductId === product.id) {
        result.push(CssClass.Active);
    }

    return result.join(' ');
}

function onMiniPlanClick(product: ProductBlockItem): void {
    emit(ProductBlockEmitters.MiniPlanSelected, product.id);
}

function isProductDiscountAvailable(product: ProductBlockItem): boolean {
    return product.discountSavedSum > 0;
}

function sparsePrice(product: ProductBlockItem): string {
    return sparse(product.discountSum, false);
}

function paymentFrequency(): string {
    return translateForType(props.productSumType, TranslationType);
}
</script>

<template>
    <div class="products-block-mini">
        <button class="mini-plan"
                :key="index"
                :class="miniPlanClasses(product)"
                v-for="(product, index) in products"
                @click="onMiniPlanClick(product)">
            <div class="mini-plan-header">{{ product.productName }}</div>
            <div class="mini-plan-price">
                <div class="price">
                    <div class="price-from">{{ translateForType('price_from', TranslationType) }}</div>
                    <span class="num">
                        {{ sparsePrice(product) }}
                    <span class="currency">&nbsp;&euro;/{{ paymentFrequency() }}</span>
                    </span>
                </div>
                <div class="price-and-discount" v-if="isProductDiscountAvailable(product)">
                    <s class="strike"><span class="base-price">{{ product.discountFullSum }} &euro;</span></s>
                    <span class="base-discount">{{ product.discountSavedSumText }}</span>
                </div>
                <svg class="check" width="25" height="24" viewBox="0 0 25 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g id="checkbox-checked">
                        <rect id="Rectangle 106" x="0.5" width="24" height="24" rx="12" fill="#007A76"/>
                        <path id="Vector 35" d="M8 12.6L10.5714 15L17 9" stroke="white" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                </svg>
            </div>
        </button>
    </div>
</template>

<style lang="scss" scoped>
.products-block-mini {
    padding: 0 var(--size-normal);
    margin-bottom: 50px;

    @media (max-width: 350px) {
        padding: 0 var(--size-pico);
    }

    display: flex;
    justify-content: center;
    gap: var(--size-pico);

    @include respond-above('sm') {
        display: none;
    }

    .mini-plan {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        max-width: 163px;
        border-radius: var(--size-pico);
        border: 1px solid var(--black-100);
        background: var(--white);
        color: var(--text-color-subtle);
        height: fit-content;

        .mini-plan-header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: var(--size-big);
            color: var(--text-color-default);
            font-size: var(--font-size-tiny);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            &::after {
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                bottom: 0;
                left: inherit;
                background-color: var(--black-100);
            }
        }

        .mini-plan-price {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: var(--text-color-default);
            font-size: var(--font-size-tiny);
            padding: var(--size-nano);
            gap: var(--size-femto);

            .price {
                display: flex;
                flex-direction: column;
                gap: var(--size-femto);

                .price-from {
                    color: var(--text-color-subtle);
                    font-size: var(--font-size-nano);
                }

                .num {
                    display: flex;
                    align-items: center;
                    color: var(--system-color-success-dark);
                    font-size: var(--font-size-medium);
                    font-weight: 700;

                    .currency {
                        font-size: var(--font-size-small);
                    }
                }

                .frequency {
                    font-size: var(--font-size-nano);
                    font-weight: 500;
                    line-height: 120%;
                    color: var(--text-color-subtle);
                }
            }

            .check {
                display: none;
            }

            .price-and-discount {
                font-size: var(--font-size-nano);
                color: var(--black-500);
                display: flex;
                flex-direction: column;

                .strike {
                    color: var(--brand-red);

                    .base-price {
                        color: var(--black-500) !important;
                    }
                }
            }
        }

        .mini-plan-header,
        .mini-plan-price {
            width: 100%;
        }

        &.active {
            border-color: var(--system-color-success-dark);
            background: linear-gradient(0deg, var(--system-color-success-light) 0%,
                var(--system-color-success-light) 100%), var(--component-color-background-base);

            .mini-plan-price {
                .check {
                    display: block;
                    margin-top: var(--size-pico);
                }
            }

            .mini-plan-header {
                background-color: var(--system-color-success-dark);
                border-color: var(--system-color-success-dark);
                color: var(--white);
                height: 42px;

                &::after {
                    display: none;
                }
            }
        }
    }
}
</style>
