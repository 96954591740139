import 'vite/modulepreload-polyfill';
import 'moment/dist/locale/lv'
import 'moment/dist/locale/lt'
import 'moment/dist/locale/et'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/ru'
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import AutoAdditionalApp from '@/pages/Auto/PolicyAdditional/PolicyAdditional.vue';
import AutoSummaryApp from '@/pages/Auto/Summary/Summary.vue';
import ProductsAccidentApp from '@/pages/Products/Accident/AccidentProduct.vue';
import ProductsGeneralCivilLiabilityApp from '@/pages/Products/GeneralCivilLiability/GeneralCivilLiabilityProduct.vue';
import ProductsHealthApp from '@/pages/Products/Health/HealthProduct.vue';
import SpecialRiskApp from '@/pages/Products/SpecialRisk/SpecialRiskProduct.vue';
import GuaranteeApp from '@/pages/Products/Guarantee/GuaranteeProduct.vue';
import ConstructionApp from '@/pages/Products/Construction/ConstructionProduct.vue';
import BusinessApp from '@/pages/Products/Business/BusinessProduct.vue';
import ProductsBicycleApp from '@/pages/Products/Bicycle/BicycleProduct.vue';
import DevComponentsPreviewApp from '@/pages/DevComponentsPreview/app.vue';
import ClaimsCascoLandingApp from '@/pages/ClaimsCasco/Landing/Landing.vue';
import ClaimsCascoVehicleApp from '@/pages/ClaimsCasco/Vehicle/Vehicle.vue';

import DefaultApp from '@/pages/Default/Default.vue';
import PromoApp from '@/pages/Promo/Promo.vue';
import BranchesApp from '@/pages/Branches/Branches.vue';
import SelectAccountEntitiesApp from '@/pages/SelectAccount/Entities/Entities.vue';
import TravelLandingApp from '@/pages/Travel/Insurance/Insurance.vue';
import TravelPolicyApp from '@/pages/Travel/Policy/Policy.vue';
import TravelAdditionalApp from '@/pages/Travel/AdditionalRisks/AdditionalRisks.vue';
import TravelTravellerApp from '@/pages/Travel/Traveller/Traveller.vue'
import TravelSummaryApp from '@/pages/Travel/Summary/Summary.vue';
import PropertyLandingApp from '@/pages/Property/Insurance/Insurance.vue';
import PropertyPolicyApp from '@/pages/Property/Policy/Policy.vue';
import PropertyAdditionalApp from '@/pages/Property/Additional/Additional.vue';
import PropertyInformationApp from '@/pages/Property/Information/Information.vue';
import PropertyOptionsApp from '@/pages/Property/Options/Options.vue';
import PropertySummaryApp from '@/pages/Property/Summary/Summary.vue';
import ClaimsContractualInstitutionsApp from '@/pages/Claims/ContractualInstitutions/ContractualInstitutions.vue';
import ClaimsTravelApp from '@/pages/Claims/Travel/TravelClaim.vue';
import ClaimsPartnerRepairShopsApp from '@/pages/Claims/PartnerRepairShops/ClaimsPartnerRepairShops.vue';
import ClaimsOtherApp from '@/pages/Claims/Other/ClaimsOther.vue';
import ClaimsTransportApp from '@/pages/Claims/Transport/ClaimsTransportApp.vue';
import ClaimsHealthApp from '@/pages/Claims/Health/ClaimsHealth.vue';
import ClaimsThankYouApp from '@/pages/Claims/ThankYou/ClaimsThankYou.vue';
import ClaimsAccidentApp from '@/pages/Claims/Accident/ClaimsAccident.vue';
import FilesByLinkGenerateApp from '@/pages/FilesByLink/Generate/FilesByLinkGenerate.vue';
import FilesByLinkUploadApp from '@/pages/FilesByLink/Upload/FilesByLinkUpload.vue';
import MobileUploadApp from '@/pages/MobileUpload/MobileUpload.vue';
import PaymentsWaitingApp from '@/pages/Payments/Waiting/PaymentsWaiting.vue';
import PaymentConfirmedApp from '@/pages/Payments/Confirmed/PaymentsConfirmed.vue';
import PurchaseThankYouApp from '@/pages/Purchase/ThankYou/PurchaseThankYou.vue';
import ItemsAndBelongingsInsuranceApp from '@/pages/ItemsAndBelongings/Insurance/Insurance.vue';
import ItemsAndBelongingsCoverageApp from '@/pages/ItemsAndBelongings/Coverage/Coverage.vue';
import ItemsAndBelongingsAdditionalRisksApp from '@/pages/ItemsAndBelongings/AdditionalRisks/AdditionalRisks.vue';
import ItemsAndBelongingsInfoAboutDeviceApp from '@/pages/ItemsAndBelongings/InfoAboutDevice/InfoAboutDevice.vue';
import ItemsAndBelongingsSummaryAndPaymentApp from '@/pages/ItemsAndBelongings/SummaryAndPayment/SummaryAndPayment.vue';
import BicycleInsuranceApp from '@/pages/Bicycle/Insurance/Insurance.vue';
import BicyclePersonDataApp from '@/pages/Bicycle/PersonData/PersonData.vue';
import BicycleSummaryAndPaymentApp from '@/pages/Bicycle/SummaryAndPayment/SummaryAndPayment.vue';
import AccidentSingleDayInsuranceApp from '@/pages/AccidentSingleDay/Insurance/Insurance.vue';
import AccidentSingleDayPersonsApp from '@/pages/AccidentSingleDay/Persons/Persons.vue';
import AccidentSingleDaySummaryAndPaymentApp from '@/pages/AccidentSingleDay/Summary/Summary.vue';
import OneDashboardInsurancesApp from '@/pages/OneDashboard/Insurances/Insurances.vue';
import OneDashboardPaymentsApp from '@/pages/OneDashboard/Payments/Payments.vue';
import OneDashboardSrsStatementApp from '@/pages/OneDashboard/SrsStatement/Srs.vue';
import OneDashboardClaimsApp from '@/pages/OneDashboard/Claims/Claims.vue';
import LegalPersonApp from '@/pages/LegalPerson/Workbench/Dashboard.vue';
import LegalPersonWelcomeApp from '@/pages/LegalPerson/Welcome/Welcome.vue';
import OneDashboardAccountApp from '@/pages/OneDashboard/Account/Account.vue';
import ClaimsPropertyLandingApp from '@/pages/ClaimsProperty/Landing/Landing.vue';
import ClaimsPropertyDateAndPlaceApp from '@/pages/ClaimsProperty/DateAndPlace/DateAndPlace.vue';
import ClaimsPropertyEventDetailsApp from '@/pages/ClaimsProperty/EventDetails/Details.vue';
import ClaimsPropertyUploadDocumentsApp from '@/pages/ClaimsProperty/UploadDocuments/Upload.vue';
import ClaimsPropertyRecipientApp from '@/pages/ClaimsProperty/Recipient/Recipient.vue';
import ClaimsCascoAccidentDataApp from '@/pages/ClaimsCasco/AccidentData/AccidentData.vue';
import FlashPaymentsGenerateApp from '@/pages/FlashPayments/Generate/Generate.vue';
import FlashPaymentsAfterApp from '@/pages/FlashPayments/After/After.vue';
import ClaimsCascoExtraDetailsApp from '@/pages/ClaimsCasco/ExtraDetails/ExtraDetails.vue';
import ClaimsAccidentLandingApp from '@/pages/ClaimsAccident/Landing/Landing.vue';
import ClaimsAccidentAccidentDataApp from '@/pages/ClaimsAccident/AccidentData/AccidentData.vue';
import ClaimsAccidentAdditionalInformationApp
    from '@/pages/ClaimsAccident/AdditionalInformation/AdditionalInformation.vue';
import ClaimsAccidentUploadFilesApp from '@/pages/ClaimsAccident/UploadFiles/UploadFiles.vue';
import ClaimsAccidentRecipientDataApp from '@/pages/ClaimsAccident/RecipientData/RecipientData.vue';
import DashboardContactsApp from '@/pages/Dashboard/Contacts/Contacts.vue';
import DashboardOnboardingApp from '@/pages/Dashboard/Onboard/Board.vue';
import DashboardUpdateNamesApp from '@/pages/Dashboard/UpdateUser/Update.vue';
import DashboardUpdateDetailsApp from '@/pages/Dashboard/UpdateUser/Update.vue';
import ClaimsCascoRecipientDataApp from '@/pages/ClaimsCasco/RecipientData/Recipient.vue';
import ClaimsCascoUploadFilesApp from '@/pages/ClaimsCasco/UploadFiles/UploadFiles.vue';
import ClaimsCascoContactDetailsApp from '@/pages/ClaimsCasco/ContactDetails/ContactDetails.vue';
import AutoLandingApp from '@/pages/Auto/Landing/Landing.vue';
import AutoPolicyApp from '@/pages/Auto/Policy/Policy.vue';
import GlobalComponents from '@/Components/Components';
import GlobalDirectives from '@/Directives/Directives';
import VueApp from '@/Core/Routing/VueApp';

// SPA apps
import PayByLinkPayMain from '@/Apps/PayByLink/Pay/main';
import ClaimsMtplMain from '@/Apps/ClaimsMtpl/main';
import SolarPanelsMain from '@/Apps/SolarPanels/main';
import RentersMain from '@/Apps/Renters/main';
import TwoWayCommunicationMain from '@/Apps/TwoWayCommunication/main';

export namespace Pages {
    export const SolarPanels = SolarPanelsMain;

    export const Renters = RentersMain;

    export namespace PayByLink {
        export const Pay = PayByLinkPayMain;
    }

    export namespace ClaimsMtpl {
        export const Spa = ClaimsMtplMain;
    }

    export namespace TwoWayCommunication {
        export const Spa = TwoWayCommunicationMain;
    }

    export class Default {
        public app(selector: string) {
            return new VueApp(DefaultApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
        }
    }

    export class Promo {
        public app(selector: string) {
            return new VueApp(PromoApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
        }
    }

    export namespace Branch {
        export class Branches {
            public app(selector: string) {
                return new VueApp(BranchesApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace LegalPerson {
        // tslint:disable-next-line:no-shadowed-variable
        export class Workbench {
            public app(selector: string) {
                return new VueApp(LegalPersonApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Welcome {
            public app(selector: string) {
                return new VueApp(LegalPersonWelcomeApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace SelectAccount {
        export class Entities {
            public app(selector: string) {
                return new VueApp(SelectAccountEntitiesApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace Travel {
        export class Insurance {
            public app(selector: string) {
                return new VueApp(TravelLandingApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Policy {
            public app(selector: string) {
                return new VueApp(TravelPolicyApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class AdditionalRisks {
            public app(selector: string) {
                return new VueApp(TravelAdditionalApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Traveller {
            public app(selector: string) {
                return new VueApp(TravelTravellerApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Summary {
            public app(selector: string) {
                return new VueApp(TravelSummaryApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

    }

    export namespace Property {
        export class Insurance {
            public app(selector: string) {
                return new VueApp(PropertyLandingApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Information {
            public app(selector: string) {
                return new VueApp(PropertyInformationApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Policy {
            public app(selector: string) {
                return new VueApp(PropertyPolicyApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Options {
            public app(selector: string) {
                return new VueApp(PropertyOptionsApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Additional {
            public app(selector: string) {
                return new VueApp(PropertyAdditionalApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Summary {
            public app(selector: string) {
                return new VueApp(PropertySummaryApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export class DevComponentsPreview {
        public app(selector: string) {
            return new VueApp(DevComponentsPreviewApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
        }
    }

    export namespace Dashboard {
        export class Onboarding {
            public app(selector: string) {
                return new VueApp(DashboardOnboardingApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class UpdateName {
            public app(selector: string) {
                return new VueApp(DashboardUpdateNamesApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class UpdateDetails {
            public app(selector: string) {
                return new VueApp(DashboardUpdateDetailsApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Contacts {
            public app(selector: string) {
                return new VueApp(DashboardContactsApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace Auto {
        export class Landing {
            public app(selector: string) {
                return new VueApp(AutoLandingApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Policy {
            public app(selector: string) {
                return new VueApp(AutoPolicyApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Additional {
            public app(selector: string) {
                return new VueApp(AutoAdditionalApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Summary {
            public app(selector: string) {
                return new VueApp(AutoSummaryApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace AccidentSingleDay {
        export class Insurance {
            public app(selector: string) {
                return new VueApp(AccidentSingleDayInsuranceApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Persons {
            public app(selector: string) {
                return new VueApp(AccidentSingleDayPersonsApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class SummaryAndPayment {
            public app(selector: string) {
                return new VueApp(AccidentSingleDaySummaryAndPaymentApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace ItemsAndBelongings {
        export class Insurance {
            public app(selector: string) {
                return new VueApp(ItemsAndBelongingsInsuranceApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Coverage {
            public app(selector: string) {
                return new VueApp(ItemsAndBelongingsCoverageApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class AdditionalRisks {
            public app(selector: string) {
                return new VueApp(ItemsAndBelongingsAdditionalRisksApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class InfoAboutDevice {
            public app(selector: string) {
                return new VueApp(ItemsAndBelongingsInfoAboutDeviceApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class SummaryAndPayment {
            public app(selector: string) {
                return new VueApp(ItemsAndBelongingsSummaryAndPaymentApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace Bicycle {
        export class Insurance {
            public app(selector: string) {
                return new VueApp(BicycleInsuranceApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class PersonData {
            public app(selector: string) {
                return new VueApp(BicyclePersonDataApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class SummaryAndPayment {
            public app(selector: string) {
                return new VueApp(BicycleSummaryAndPaymentApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace ClaimsProperty {
        export class Landing {
            public app(selector: string) {
                return new VueApp(ClaimsPropertyLandingApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class DateAndPlace {
            public app(selector: string) {
                return new VueApp(ClaimsPropertyDateAndPlaceApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class EventDetails {
            public app(selector: string) {
                return new VueApp(ClaimsPropertyEventDetailsApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class UploadDocuments {
            public app(selector: string) {
                return new VueApp(ClaimsPropertyUploadDocumentsApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Recipient {
            public app(selector: string) {
                return new VueApp(ClaimsPropertyRecipientApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace ClaimsCasco {
        export class Landing {
            public app(selector: string) {
                return new VueApp(ClaimsCascoLandingApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class AccidentData {
            public app(selector: string) {
                return new VueApp(ClaimsCascoAccidentDataApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Vehicle {
            public app(selector: string) {
                return new VueApp(ClaimsCascoVehicleApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class ExtraDetails {
            public app(selector: string) {
                return new VueApp(ClaimsCascoExtraDetailsApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class UploadFiles {
            public app(selector: string) {
                return new VueApp(ClaimsCascoUploadFilesApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class RecipientData {
            public app(selector: string) {
                return new VueApp(ClaimsCascoRecipientDataApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class ContactDetails {
            public app(selector: string) {
                return new VueApp(ClaimsCascoContactDetailsApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace ClaimsAccident {
        export class Landing {
            public app(selector: string) {
                return new VueApp(ClaimsAccidentLandingApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class AccidentData {
            public app(selector: string) {
                return new VueApp(ClaimsAccidentAccidentDataApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class AdditionalInformation {
            public app(selector: string) {
                return new VueApp(ClaimsAccidentAdditionalInformationApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class UploadFiles {
            public app(selector: string) {
                return new VueApp(ClaimsAccidentUploadFilesApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class RecipientData {
            public app(selector: string) {
                return new VueApp(ClaimsAccidentRecipientDataApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace Claims {
        export class Transport {
            public app(selector: string) {
                return new VueApp(ClaimsTransportApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class PartnerRepairShops {
            public app(selector: string) {
                return new VueApp(ClaimsPartnerRepairShopsApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Health {
            public app(selector: string) {
                return new VueApp(ClaimsHealthApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        // tslint:disable-next-line:no-shadowed-variable
        export class Travel {
            public app(selector: string) {
                return new VueApp(ClaimsTravelApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Accident {
            public app(selector: string) {
                return new VueApp(ClaimsAccidentApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Other {
            public app(selector: string) {
                return new VueApp(ClaimsOtherApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class ContractualInstitutions {
            public app(selector: string) {
                return new VueApp(ClaimsContractualInstitutionsApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class ThankYou {
            public app(selector: string) {
                return new VueApp(ClaimsThankYouApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace FilesByLink {
        export class Generate {
            public app(selector: string) {
                return new VueApp(FilesByLinkGenerateApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Upload {
            public app(selector: string) {
                return new VueApp(FilesByLinkUploadApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace FlashPayments {
        export class Generate {
            public app(selector: string) {
                return new VueApp(FlashPaymentsGenerateApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class After {
            public app(selector: string) {
                return new VueApp(FlashPaymentsAfterApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace Products {
        // tslint:disable-next-line:no-shadowed-variable
        export class Travel {
            public app(selector: string) {
                return new VueApp(TravelLandingApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        // tslint:disable-next-line:no-shadowed-variable
        export class Property {
            public app(selector: string) {
                return new VueApp(PropertyLandingApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Accident {
            public app(selector: string) {
                return new VueApp(ProductsAccidentApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class GeneralCivilLiability {
            public app(selector: string) {
                return new VueApp(ProductsGeneralCivilLiabilityApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Health {
            public app(selector: string) {
                return new VueApp(ProductsHealthApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class SpecialRisks {
            public app(selector: string) {
                return new VueApp(SpecialRiskApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Guarantee {
            public app(selector: string) {
                return new VueApp(GuaranteeApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Construction {
            public app(selector: string) {
                return new VueApp(ConstructionApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Business {
            public app(selector: string) {
                return new VueApp(BusinessApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        // tslint:disable-next-line:no-shadowed-variable
        export class Bicycle {
            public app(selector: string) {
                return new VueApp(ProductsBicycleApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace Mobile {
        export class Upload {
            public app(selector: string) {
                return new VueApp(MobileUploadApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace Payments {
        export class Waiting {
            public app(selector: string) {
                return new VueApp(PaymentsWaitingApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Confirmed {
            public app(selector: string) {
                return new VueApp(PaymentConfirmedApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace Purchase {
        export class ThankYou {
            public app(selector: string) {
                return new VueApp(PurchaseThankYouApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }

    export namespace OneDashboard {
        // tslint:disable-next-line:no-shadowed-variable

        export class Insurances {
            public app(selector: string) {
                return new VueApp(OneDashboardInsurancesApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        // tslint:disable-next-line:no-shadowed-variable
        export class Payments {
            public app(selector: string) {
                return new VueApp(OneDashboardPaymentsApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        // tslint:disable-next-line:no-shadowed-variable
        export class Claims {
            public app(selector: string) {
                return new VueApp(OneDashboardClaimsApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class Account {
            public app(selector: string) {
                return new VueApp(OneDashboardAccountApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }

        export class SrsStatement {
            public app(selector: string) {
                return new VueApp(OneDashboardSrsStatementApp).withComponents(GlobalComponents).withDirectives(GlobalDirectives).create().mount(selector);
            }
        }
    }
}

(window as DynamicDictionary).One = {Pages};
