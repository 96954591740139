<script setup lang="ts">
import {computed, onMounted, PropType, Ref} from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import {useStrings} from '@/Composables/Strings';
import {useTranslate} from '@/Composables/Translate';
import Attachment from '@/Components/Message/Interfaces/Attachment';


const {translateForType} = useTranslate();


const props = defineProps({
    formField: {type: Object as PropType<FormField<string>>, default: () => new FormField('')},
    disabled: {type: Boolean, default: false},
    translationType: {type: String, default: () => ''},
    claimCaseNumber: {type: String, default: () => ''},
    maxLength: {type: Number, default: () => 4000},
    minLength: {type: Number, default: () => 10},
    claimCaseNumberLabel: {type: String, default: () => ''},
    attachments: {
        type: Array as PropType<Attachment[]>, default: () => {
            return [];
        }
    },
});


const emit = defineEmits(['delete-file', 'send-message']);


const messageCapacityText: Ref<string> = computed((): string => {
    return props.formField.value.length + '/' + String(props.maxLength);
});


function attachButtonParams(): ButtonWithCallbackParams {
    return {
        title: translated('attach_files'),
        textColor: ButtonTextColor.Black,
        backgroundColor: ButtonBackground.White,
        backgroundColorHover: ButtonBackground.White,
        icon: ButtonIcon.Paperclip,
        iconColor: ButtonIconColor.Black,
    };
}

function sendMessageButtonParams(): ButtonWithCallbackParams {
    return {
        title: translated('send_message'),
        icon: ButtonIcon.LongArrowRight,
        backgroundColor: ButtonBackground.Red,
        textColor: ButtonTextColor.White,
        iconPosition: ButtonIconPosition.Right,
        disabled: !props.formField.isValid,
    };
}

function setupForm(): void {
    props.formField.addValidators(messageValidator());
}

function messageValidator(): object {
    return {
        isMessageValid: (value: string) => {
            const multibyteLength: number = useStrings().multibyteLength(value);
            const valueLengthOutOfBounds: boolean = multibyteLength < props.minLength || multibyteLength > props.maxLength;
            const valueHasInvalidText: boolean = !useStrings().isValidWordString(value);

            return !valueLengthOutOfBounds && !valueHasInvalidText && props.formField.isTouched;
        }
    }
}

function translated(key: string): string {
    return translateForType(key, props.translationType);
}

function onAttachmentDeleteClick(attachmentName: string): void {
    emit('delete-file', attachmentName);
}

//TODO: update upload logic when decision made of how to create it from bta
function onAttachButtonClick(): void {
    console.log('clicked attach button');
}

function onSendMessageButtonClick(): void {
    emit('send-message');
}


onMounted((): void => {
    setupForm();
});
</script>

<template>
    <div class="new-message"
         v-bind:id="formField.name"
         v-bind:class="{...formField.classes(), 'disabled': disabled}"
         :data-store="formField.name"
         :data-store-value="`${formField.value}`">
        <div class="claim-case-message-data"
             v-if="props.claimCaseNumber">
            <div class="claim-case-number-label">{{ props.claimCaseNumberLabel }}</div>
            <div class="claim-case-number">{{ props.claimCaseNumber }}</div>
        </div>
        <div class="message-title">{{ translated('message_title') }}</div>
        <app-input-textarea
            :disabled="props.disabled"
            :placeholder="translated('message_placeholder')"
            :form-field="formField">
        </app-input-textarea>
        <div class="message-capacity">{{ messageCapacityText }}</div>
        <div class="attachments">
            <template v-if="props.attachments.length">
                <div class="attached-files-heading-row">
                    <div class="attached-files-title">{{ translated('attached_files_title') }}</div>
                    <div class="attachment-count">{{ props.attachments.length }}</div>
                </div>
                <ul>
                    <li v-for="(attachment, key) in props.attachments"
                        :key="key">
                        <div class="attachment-name">{{ attachment.name }}</div>
                        <img class="attachment-delete"
                             alt=""
                             src="/images/icons/trashcan.svg"
                             @click="onAttachmentDeleteClick(attachment.id)"/>
                    </li>
                </ul>
            </template>
            <div class="attach-files">
                <app-button-with-callback
                    v-bind="attachButtonParams()"
                    @button-callback-click="onAttachButtonClick">
                </app-button-with-callback>
                <div class="file-formats">{{ translated('can_upload_following_file_formats') }}</div>
            </div>
        </div>
        <app-button-with-callback
            class="send-message"
            v-bind="sendMessageButtonParams()"
            @button-callback-click="onSendMessageButtonClick()">
        </app-button-with-callback>
    </div>
</template>

<style lang="scss" scoped>
.new-message {
    width: 100%;

    @include respond-above('sm') {
        max-width: 640px;
    }

    .claim-case-message-data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: var(--font-size-nano);
        background-color: var(--component-color-background-base);
        border-radius: var(--size-pico);
        margin-bottom: var(--size-small);
        min-height: 49px;
        padding-left: var(--size-normal);
        padding-right: var(--size-normal);

        @include respond-above('sm') {
            margin-bottom: var(--size-normal);
        }

        .claim-case-number-label {
            color: var(--text-color-subtle);
        }

        .claim-case-number {
            font-weight: 600;
        }
    }

    .message-title {
        font-size: var(--font-size-small);
        font-weight: 700;
        padding-bottom: var(--size-nano);
    }

    #message {
        padding-bottom: var(--size-pico);
    }

    .message-capacity {
        color: var(--text-color-subtle);
        font-size: var(--font-size-nano);
        padding-bottom: var(--size-normal);
    }

    .attachments {
        .attached-files-heading-row {
            padding-bottom: var(--size-nano);
            display: flex;
            justify-content: space-between;

            .attached-files-title {
                font-size: var(--font-size-small);
                font-weight: 700;
            }

            .attachment-count {
                color: var(--text-color-subtle);
                font-size: var(--font-size-nano);
            }
        }

        ul {
            padding-bottom: var(--size-tiny);

            li {
                display: flex;
                gap: var(--size-small);
                justify-content: space-between;
                border-top: 1px solid var(--black-100);
                padding: var(--size-nano) 0;
                font-size: var(--font-size-nano);
                align-items: center;

                &:last-of-type {
                    border-bottom: 1px solid var(--black-100);
                }

                img {
                    cursor: pointer;
                }
            }
        }

        .attach-files {
            display: flex;
            flex-direction: column;
            gap: var(--size-tiny);
            width: 100%;
            padding-bottom: var(--size-big);
            align-items: center;

            @include respond-above('sm') {
                flex-direction: row;
                padding-bottom: var(--size-medium);
            }

            button {
                width: 100%;

                @include respond-above('sm') {
                    min-width: 240px;
                    max-width: 240px;
                }
            }

            .file-formats {
                font-size: var(--font-size-nano);
                color: var(--text-color-subtlest);
            }
        }
    }

    .send-message {
        width: 100%;
    }

    .button-with-callback {
        height: 52px;
    }
}
</style>
