import {Ref, ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import SettingsService from '@/services/settings.service';
import User from '@/services/user.service';
import Url from '@/Enums/UrlEnum';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import UrlBuilder from '@/assets/libraries/url/url-builder';

import moment from 'moment';

export default class SessionTimeout {
    private static instance: SessionTimeout;
    private sessionTimer: Worker = new Worker('js/timer.js?rev=rand()');
    private sessionTimeoutSeconds: number = 0;
    private logoutTimeoutSeconds: number = 0;
    public formattedTime: Ref<string> = ref('');
    private timerSeconds: number = 0;
    private hold: boolean = false;

    public static getInstance(): SessionTimeout {
        if (!SessionTimeout.instance) {
            SessionTimeout.instance = new SessionTimeout();
        }

        return SessionTimeout.instance;
    }

    public init(): void {
        const miliseconds: number = 1000;
        this.setupSessionTimer();
        this.addEventsListerners();
        if (User.getInstance().isLogged()) {
            this.sessionTimer.onmessage = (event: MessageEvent) => {
                this.timerSeconds = event.data.seconds;
                this.formattedTime.value = moment(this.logoutTimeoutSeconds * miliseconds).format('mm:ss');
                if (this.timerSeconds === this.sessionTimeoutSeconds) {
                    PopupService.getInstance().show(new OnePopup().withType().sessionEnd);
                    this.hold = true;
                }
                if (this.hold) {
                    this.logoutTimeoutSeconds--;
                    if (this.logoutTimeoutSeconds === 0) {
                        this.onLogout();
                    }
                }
            };
        }
    }

    public onContinue(): void {
        PopupService.getInstance().hide();
        this.resetTimer();
        this.hold = false;
    }

    public onLogout(): void {
        PopupService.getInstance().show(new OnePopup().withType().loading);
        const logoutHash: string = OneBaseService.getInstance().showSessionTimeoutLogoffHash;
        window.location.href = (new UrlBuilder())
            .withUri(Url.Api.logout + logoutHash)
            .build();
    }

    private setupSessionTimer(): void {
        const sessionTimeoutMinutes: number = parseInt(SettingsService.getInstance().sessionTimeout(), 10);
        const logoutTimeoutSeconds: number = parseInt(SettingsService.getInstance().logoutTimeout(), 10);
        this.sessionTimeoutSeconds = this.transformedSecondsFromMinutes(sessionTimeoutMinutes);
        this.logoutTimeoutSeconds = this.transformedSecondsFromMinutes(logoutTimeoutSeconds);
    }

    private addEventsListerners(): void {
        window.addEventListener('mousemove', this.mouseMoveResetReceiver.bind(this));
        window.addEventListener('keypress', this.keyPressResetReceiver.bind(this));
    }

    private mouseMoveResetReceiver(): void {
        this.resetTimer();
    }

    private keyPressResetReceiver(): void {
        this.resetTimer();
    }

    private transformedSecondsFromMinutes(minutes: number): number {
        const secondsInMinute: number = 60;

        return minutes * secondsInMinute;
    }

    private resetTimer(): void {
        if (!this.hold) {
            this.sessionTimer.postMessage('reset');
            this.formattedTime.value = '';
        }
    }
}
