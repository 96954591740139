import VueApp from '@/Core/Routing/VueApp';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import {App} from 'vue';
import {createPinia} from 'pinia';
import {ComponentPublicInstance} from '@vue/runtime-core';
import Router from '@/Core/Routing/Router';
import GlobalDirectives from '@/Directives/Directives';
import AppComponent from '@/Apps/SolarPanels/App.vue';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/RouteStepper/RouteStepper.vue';
import InsurancePage from '@/Apps/SolarPanels/Pages/InsurancePage.vue';
import CoveragePage from '@/Apps/SolarPanels/Pages/CoveragePage.vue';
import AddressPage from '@/Apps/SolarPanels/Pages/AddressPage.vue';
import SummaryAndPaymentPage from '@/Apps/SolarPanels/Pages/SummaryAndPaymentPage.vue';
import CoveredPopupSolarPanelsMulti from '@/Components/CoveredPopup/CoveredPopupSolarPanelsMulti.vue';
import SolarPanelsMini from '@/Components/ProductBlock/Components/MiniPlans/SolarPanelsMini.vue';
import ProductBlockInnerSolarPanelsPolicy from '@/Components/ProductBlock/Components/ProductBlockInnerSolarPanelsPolicy.vue';
import TextWithTip from '@/Components/TextWithTip/TextWithTip.vue';
import ButtonWithCallback from '@/Components/ButtonWithCallback/ButtonWithCallback.vue';
import InputDateWithCalendar from '@/Components/InputDateWithCalendar/InputDateWithCalendar.vue';
import PopupOpener from '@/Components/PopupOpener/PopupOpener.vue';
import InputCheckbox from '@/Components/InputCheckbox/InputCheckbox.vue';
import InputIdentityNumber from '@/Components/InputIdentityNumber/InputIdentityNumber.vue';
import InputEmail from '@/Components/InputEmail/InputEmail.vue';
import PhoneWithCountry from '@/Components/PhoneWithCountry/PhoneWithCountry.vue';
import InputText from '@/Components/InputText/InputText.vue';
import LanguageSwitcher from '@/Components/LanguageSwitcher/LanguageSwitcher.vue';
import Popup from '@/Components/Popup/Popup.vue';
import Tooltipster from '@/Components/Tooltipster/Tooltipster.vue';
import InputRadio from '@/Components/InputRadio/InputRadio.vue';
import InputRadioOverlayed from '@/Components/InputRadioOverlayed/InputRadioOverlayed.vue';
import ContentLoader from '@/Components/ContentLoader/ContentLoader.vue';
import CustomForm from '@/Components/CustomForm/CustomForm.vue';
import InputCountry from '@/Components/InputCountry/InputCountry.vue';
import InputDate from '@/Components/InputDate/InputDate.vue';
import PersonListWithSearch from '@/Components/PersonListWithSearch/PersonListWithSearch.vue';
import InputSelect from '@/Components/InputSelect/InputSelect.vue';
import InputDateWithDropdown from '@/Components/InputDateWithDropdown/InputDateWithDropdown.vue';
import InputNumber from '@/Components/InputNumber/InputNumber.vue';
import Drawer from '@/Components/Drawer/Drawer.vue';
import ProductBlock from '@/Components/ProductBlock/ProductBlock.vue';
import AddressFinder from '@/Components/AddressFinder/AddressFinder.vue';
import ConsentsList from '@/Components/ConsentsList/ConsentsList.vue';
import CrediCardDetails from '@/Components/CreditCardDetails/CreditCardDetails.vue';
import SelectAccountDropdown from '@/Components/SelectAccountDropdown/SelectAccountDropdown.vue';
import Badge from '@/Components/Badge/Badge.vue';
import ItemsListWithIcons from '@/Components/ItemsListWithIcons/ItemsListWithIcons.vue';

const pages: Components = {
    InsurancePage: InsurancePage,
    CoveragePage: CoveragePage,
    AddressPage: AddressPage,
    SummaryAndPaymentPage: SummaryAndPaymentPage,
}

const components: Components = {
    AppStepperLayout: StepperLayout,
    AppRouteStepper: RouteStepper,
    AppTextWithTip: TextWithTip,
    AppButtonWithCallback: ButtonWithCallback,
    AppProductBlockInnerSolarPanelsPolicy: ProductBlockInnerSolarPanelsPolicy,
    AppSolarPanelsMini: SolarPanelsMini,
    AppCoveredPopupSolarPanelsMulti: CoveredPopupSolarPanelsMulti,
    AppInputDateWithCalendar: InputDateWithCalendar,
    AppPopupOpener: PopupOpener,
    AppInputText: InputText,
    AppInputCheckbox: InputCheckbox,
    AppInputIdentityNumber: InputIdentityNumber,
    AppInputEmail: InputEmail,
    AppPhoneWithCountry: PhoneWithCountry,
    AppLanguageSwitcher: LanguageSwitcher,
    AppPopup: Popup,
    AppTooltipster: Tooltipster,
    AppContentLoader: ContentLoader,
    AppDrawer: Drawer,
    AppProductBlock: ProductBlock,
    AppCustomForm: CustomForm,
    AppInputRadio: InputRadio,
    AppInputRadioOverlayed: InputRadioOverlayed,
    AppInputSelect: InputSelect,
    AppInputNumber: InputNumber,
    AppInputDateWithDropdown: InputDateWithDropdown,
    AppInputCountry: InputCountry,
    AppInputDate: InputDate,
    AppPersonListWithSearch: PersonListWithSearch,
    AppAddressFinder: AddressFinder,
    AppConsentsList: ConsentsList,
    AppCreditCardDetails: CrediCardDetails,
    AppSelectAccountDropdown: SelectAccountDropdown,
    AppBadge: Badge,
    AppItemsListWithIcons: ItemsListWithIcons,
}

export default class {
    public app(selector: string, routes: BackendRoute[]): ComponentPublicInstance {
        const app: App = new VueApp(AppComponent)
            .withComponents({...pages, ...components})
            .withDirectives(GlobalDirectives)
            .create();
        app.use(new Router(app, routes).create());
        app.use(createPinia());
        return app.mount(selector);
    }
}
