import Validation from '@/services/validation.service';
import PolicyHolderType from '@/Enums/PolicyHolderTypeEnum';
import Form from '@/assets/libraries/form/form';
import AppCountry from '@/assets/libraries/app/app-country';
import {Ref, ref} from 'vue';
import Value from '@/assets/libraries/form/value';

export default class SummaryValidators {
    private form: Form = new Form();
    private isRequiredBirthDate: Ref<boolean> = ref(false);

    public init(form: Form,
                isRequiredBirthDate: boolean,
    ): void {
        this.form = form;
        this.isRequiredBirthDate.value = isRequiredBirthDate;
    }

    public nameValidator(assignedToField: string, policyHolderType: string): object {
        return {
            isRequiredForCurrentHolder: () => this.isFieldRequired(assignedToField, policyHolderType)
        }
    }

    public birthDateValidator(): object {
        return {
            isRequiredForCurrentHolder: () => {
                return this.isRequiredBirthDate.value
                    ? !this.form.field('policyHolderBirthDate').isEmpty()
                    : true;
            }
        }
    }

    public countryValidator(assignedToField: string, policyHolderType: string, dependsOnResident: boolean): object {
        return {
            isRequiredForCurrentHolder: () => this.isFieldRequired(assignedToField, policyHolderType, dependsOnResident)
        }
    }

    public companyTypeValidator(assignedToField: string, policyHolderType: string, dependsOnResident: boolean): object {
        return {
            isRequiredForCurrentHolder: () => this.isFieldRequired(assignedToField, policyHolderType, dependsOnResident)
        }
    }

    public companyNumberValidator(): object {
        return {
            isValidCompanyNumberForResidenceCountry: () => {
                let isValidCompanyNumber: boolean = true;
                if (this.policyHolderType === 'corporate') {
                    if (this.form.field('policyHolderIsResident').value) {
                        isValidCompanyNumber = Validation.isValidCompanyRegistrationNumber(
                            this.form.field('policyHolderCompanyNumber').value);
                    } else {
                        switch (this.form.field('policyHolderCountryCorporate').value.iso) {
                            case 'LV':
                                isValidCompanyNumber = Validation.isValidCompanyRegistrationNumber(
                                    this.form.field('policyHolderCompanyNumber').value, 'LV');
                                break;
                            case 'EE':
                                isValidCompanyNumber = Validation.isValidCompanyRegistrationNumber(
                                    this.form.field('policyHolderCompanyNumber').value, 'EE');
                                break;
                            case 'LT':
                                isValidCompanyNumber = Validation.isValidCompanyRegistrationNumber(
                                    this.form.field('policyHolderCompanyNumber').value, 'LT');
                                break;
                            default:
                        }
                    }
                }

                return isValidCompanyNumber;
            }
        }
    }

    public personCodeValidator(): object {
        return {
            validPersonCode: (): boolean => this.policyHolderType === PolicyHolderType.Personal
                ? Validation.isValidNaturalPersonCode(
                    this.form.field('policyHolderIdentityNumber').value, this.policyHolderResidenceCountry)
                : true,
        }
    }

    public get policyHolderResidenceCountry(): string {
        let residenceCountry: string = new AppCountry().iso();
        if (this.form.exists('policyHolderIsResident') && !this.form.field('policyHolderIsResident').value) {
            residenceCountry = this.isPolicyHolderTypePrivate
                ? this.form.field('policyHolderCountryPrivate').value.iso
                : this.form.field('policyHolderCountryCorporate').value.iso;
        }

        return residenceCountry;
    }

    public get isPolicyHolderTypePrivate(): boolean {
        return this.policyHolderType === PolicyHolderType.Personal;
    }

    public get policyHolderType(): string {
        return this.form.field('policyHolderType').value;
    }

    public get isResident(): string {
        return this.form.field('policyHolderIsResident').value;
    }

    private isFieldRequired(
        assignedToField: string,
        policyHolderType: string,
        dependsOnResident: boolean = false
    ): boolean {
        if (dependsOnResident) {
            return !this.isResident && this.policyHolderType === policyHolderType
                ? new Value(this.form.field(assignedToField).value).isNotEmpty()
                : true;
        } else {
            return this.policyHolderType === policyHolderType
                ? new Value(this.form.field(assignedToField).value).isNotEmpty()
                : true;
        }
    }

    public isValidPersonCodeWithoutDate(value: string, residenceCountry: string): boolean {
        return value
            ? Validation.isValidPersonCodeWithoutDate(value, residenceCountry)
            : false;
    }
}
