<script setup lang="ts">
    import StatusIcon from '@/Components/StatusIcon/StatusIcon.enum';
    import {computed, Ref} from 'vue';

    const props = defineProps({
        icon: {type: String, default: StatusIcon.InProgress},
        hoverMessageText: {type: String, default: ''},
    });
    const iconSrc: Ref<string> = computed((): string => {
        return `images/one/components/status-icon/${props.icon}.svg`;
    });
</script>
<template>
    <div class="status-icon">
        <img :src="iconSrc"
             class="icon"
             alt="">
        <span v-if="hoverMessageText"
              class="hover"
              v-html="hoverMessageText">
        </span>
    </div>
</template>
<style lang="scss" scoped>
.status-icon {
    width: fit-content;
    position: relative;

    .hover {
        position: absolute;
        left: -26px;
        bottom: -32px;
        display: none;
        padding: var(--size-pico) 12px;
        width: 170px;
        height: 30px;
        background-color: var(--brand-red);
        color: var(--white);
        border-radius: 4px;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        .hover {
            display: flex;
        }
    }

    .icon {
        width: 20px;
        height: 20px;
    }
}
</style>
