import Router from '@/Core/Routing/Router';
import VueApp from '@/Core/Routing/VueApp';
import {App} from 'vue';
import AppComponent from '@/Apps/TwoWayCommunication/App.vue';
import GlobalDirectives from '@/Directives/Directives';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Pages/TwoWayCommunication.vue';
import TwoWayCommunicationNew from '@/Apps/TwoWayCommunication/Pages/TwoWayCommunicationNew.vue';
import TwoWayCommunicationSingle from '@/Apps/TwoWayCommunication/Pages/TwoWayCommunicationSingle.vue';
import SelectAccountDropdown from '@/Components/SelectAccountDropdown/SelectAccountDropdown.vue';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/RouteStepper/RouteStepper.vue';
import CustomForm from '@/Components/CustomForm/CustomForm.vue';
import LanguageSwitcher from '@/Components/LanguageSwitcher/LanguageSwitcher.vue';
import Popup from '@/Components/Popup/Popup.vue';
import Tooltipster from '@/Components/Tooltipster/Tooltipster.vue';
import Drawer from '@/Components/Drawer/Drawer.vue';
import {ComponentPublicInstance} from '@vue/runtime-core';
import PersonListWithSearch from '@/Components/PersonListWithSearch/PersonListWithSearch.vue';
import CommunicationNotificator from '@/Components/CommunicationNotificator/CommunicationNotificator.vue';
import Message from '@/Components/Message/Message.vue';
import InfoWithActions from '@/Components/InfoWithActions/InfoWithActions.vue';
import ButtonWithCallback from '@/Components/ButtonWithCallback/ButtonWithCallback.vue';
import UserMessageCard from '@/Components/MessageCard/UserMessageCard.vue';
import Badge from '@/Components/Badge/Badge.vue';
import InputRadio from '@/Components/InputRadio/InputRadio.vue';
import ClaimBlock from '@/Components/ClaimBlock/ClaimBlock.vue';
import NewMessage from '@/Components/NewMessage/NewMessage.vue';
import InputTextarea from '@/Components/InputTextarea/InputTextarea.vue';


const pages: Components = {
    TwoWayCommunication: TwoWayCommunication,
    TwoWayCommunicationNew: TwoWayCommunicationNew,
    TwoWayCommunicationSingle: TwoWayCommunicationSingle,
}

const components: Components = {
    AppCommunicationNotificator: CommunicationNotificator,
    AppSelectAccountDropdown: SelectAccountDropdown,
    AppPersonListWithSearch: PersonListWithSearch,
    AppCustomForm: CustomForm,
    AppLanguageSwitcher: LanguageSwitcher,
    AppPopup: Popup,
    AppTooltipster: Tooltipster,
    AppStepperLayout: StepperLayout,
    AppDrawer: Drawer,
    AppRouteStepper: RouteStepper,
    AppMessage: Message,
    AppInfoWithActions: InfoWithActions,
    AppButtonWithCallback: ButtonWithCallback,
    AppUserMessageCard: UserMessageCard,
    AppBadge: Badge,
    AppInputRadio: InputRadio,
    AppClaimBlock: ClaimBlock,
    AppNewMessage: NewMessage,
    AppInputTextarea: InputTextarea,
}

export default class {
    public app(selector: string, routes: BackendRoute[]): ComponentPublicInstance {
        const app: App = new VueApp(AppComponent)
            .withComponents({...pages, ...components})
            .withDirectives(GlobalDirectives)
            .create();
        app.use(new Router(app, routes).create());

        return app.mount(selector);
    }
}
