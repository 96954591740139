<script setup lang="ts">
    import {PropType} from 'vue';

    const props = defineProps({
        steps: {type: Array as PropType<string[]>, default: () => ['']},
        currentStep: {type: Number, default: 1},
    });
    const emit = defineEmits(['select']);

    function selectIndex(stepIndex: number): void {
        if (isPrevStep(stepIndex) || isCurrentStep(stepIndex)) {
            emit('select', stepNumber(stepIndex));
        }
    }

    function stepNumber(stepIndex: number): number {
        return stepIndex + 1;
    }

    function isCurrentStep(stepIndex: number): boolean {
        return stepNumber(stepIndex) === props.currentStep;
    }

    function isNextStep(stepIndex: number): boolean {
        return stepNumber(stepIndex) > props.currentStep;
    }

    function isPrevStep(stepIndex: number): boolean {
        return stepNumber(stepIndex) < props.currentStep;
    }
</script>
<template>
    <div class="application-steps">
        <template v-for="(step, index) in steps" :key="step">
            <div class="application-step"
                 :data-id="'step-' + index"
                 :class="{'done': isPrevStep(index), 'current': isCurrentStep(index), 'next': isNextStep(index)}"
                 @click="selectIndex(index)">
                <div v-if="isPrevStep(index)" class="preview icon">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="24" height="24" rx="12" stroke="#00B4AD" stroke-width="2"></rect>
                        <path d="M8 13.7333L11.1818 17L18 10" stroke="#00B4AD" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                    </svg>
                </div>
                <div v-if="!isPrevStep(index)"
                     class="preview">{{ stepNumber(index) }}
                </div>
                <div class="title">{{ step }}</div>
            </div>
            <aside :key="index"
                   v-if="index < steps.length - 1"
                   class="line">
            </aside>
        </template>
    </div>
</template>
<style lang="scss" scoped>
.application-steps {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: auto;
    box-shadow: none;
    background-color: transparent;
    padding-left: 10px;
    padding-right: 10px;
}
</style>
