<script setup lang="ts">
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {CancelTokenSource} from 'axios';
import {useTranslate} from '@/Composables/Translate';
import {ref, Ref} from 'vue';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import {useHtml} from '@/Composables/Html';
import {usePrice} from '@/Composables/Price';

const request: AxiosParams = useAxios();
const {translateForType, translate} = useTranslate();
const {imgTag} = useHtml();
const {price} = usePrice();

const props = defineProps({
    translationsType: {type: String, default: 'dashboard'},
    content: {
        type: Object, default: () => {
            return {};
        }
    },
});

const fetchIsInProgress: Ref<boolean> = ref(false);
const cancelToken: Ref<CancelTokenSource | null> = ref(null);

function translated(key: string, replacements?: DynamicDictionary): string {
    return translateForType(key, props.translationsType, replacements);
}

</script>

<template>
    <div class="policy-details-overview">
        <div class="overview-block" v-if="content.statusBlock.enabled">
            <div class="header header-with-badges">
                <div class="title">{{translated('claim_status')}}</div>
            </div>
            <app-progress-steps
                :step-names="content.statusBlock.progressSteps"
                :step="content.statusBlock.currentStep">
            </app-progress-steps>
        </div>
        <div class="overview-block">
            <div class="header header-with-badges">
                <div class="title">{{translated('claim_details')}}</div>
            </div>
            <div class="details-block">
                <div class="details-line" v-if="content.detailsBlock.claimType">
                    <span>{{translated('claim_type')}}</span>
                    <span class="right-part">{{ content.detailsBlock.claimType }}</span>
                </div>
                <div class="details-line">
                    <span>{{translated('claim_case_number')}}</span>
                    <span class="right-part">{{ content.detailsBlock.claimNumber }}</span>
                </div>
                <div class="details-line">
                    <span>{{translated('submitted_at')}}</span>
                    <span class="right-part">{{ content.detailsBlock.submitDate }}</span>
                </div>
                <div class="details-line">
                    <span>{{translated('policy_number')}}</span>
                    <span class="right-part">{{ content.detailsBlock.policyNumber }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.policy-details-overview {
    .overview-block {
        width: 100%;
        padding: var(--size-normal);
        border-radius: 16px;
        background-color: var(--white);
        box-shadow: none;
        margin-bottom: 20px;

        .header {
            display: flex;
            align-items: center;
            margin-bottom: var(--size-small);

            &.header-with-border {
                padding-bottom: 30px;
                border-bottom: 1px solid var(--black-300);
            }

            &.small-margin {
                margin-bottom: 20px;
            }

            .title {
                font-size: var(--font-size-small);
                font-weight: 700;
                max-width: 50%;
            }
        }

        .details-block {
            .details-line {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                font-size: var(--font-size-nano);

                &.column {
                    flex-direction: column;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .right-part {
                    font-weight: 600;
                    text-align: right;
                }

                .red {
                    color: var(--brand-red);
                    font-size: var(--font-size-small);
                    align-self: baseline;
                }

                .tiny {
                    font-size: var(--font-size-tiny);
                    align-self: baseline;
                }
            }

            .grey-line {
                border-top: solid 1px var(--black-50);
                margin-bottom: 20px;

                &.extra-margins {
                    margin-top: var(--size-small);
                    margin-bottom: var(--size-big);
                }
            }
        }
    }
}
</style>
