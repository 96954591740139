import iframeResize from 'iframe-resizer/js/iframeResizer';

export default {
    beforeMount(el: HTMLElement, {value = {}}): void {
        el.addEventListener('load', () => iframeResize(value, el))
    },

    unmounted(el: HTMLElement, {value = {}}): void {
        el.removeEventListener('load', () => iframeResize(value, el))
    }
}
