import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import VueApp from '@/Core/Routing/VueApp';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import {ComponentPublicInstance} from '@vue/runtime-core';
import {App} from 'vue';
import Router from '@/Core/Routing/Router';
import {createPinia} from 'pinia';
import GlobalDirectives from '@/Directives/Directives';
import AppComponent from '@/Apps/ClaimsMtpl/App.vue';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/RouteStepper/RouteStepper.vue';
import AccidentDataPage from '@/Apps/ClaimsMtpl/Pages/AccidentDataPage.vue';
import DamagePage from '@/Apps/ClaimsMtpl/Pages/DamagePage.vue';
import AccidentDetailsPage from '@/Apps/ClaimsMtpl/Pages/AccidentDetailsPage.vue';
import ExtraDetailsPage from '@/Apps/ClaimsMtpl/Pages/ExtraDetailsPage.vue';
import UploadFilesPage from '@/Apps/ClaimsMtpl/Pages/UploadFilesPage.vue';
import InsuranceIndemnityReceiverPage from '@/Apps/ClaimsMtpl/Pages/InsuranceIndemnityReceiverPage.vue';
import YourContactDetailsPage from '@/Apps/ClaimsMtpl/Pages/YourContactDetailsPage.vue';
import BelongingsDamage from '@/Apps/ClaimsMtpl/Components/BelongingsDamage.vue';
import PersonDamage from '@/Apps/ClaimsMtpl/Components/PersonDamage.vue';
import PropertyDamage from '@/Apps/ClaimsMtpl/Components/PropertyDamage.vue';
import VehicleDamage from '@/Apps/ClaimsMtpl/Components/VehicleDamage.vue';
import CustomForm from '@/Components/CustomForm/CustomForm.vue';
import InsuredObjectsBlocks from '@/Components/InsuredObjectsBlocks/InsuredObjectsBlocks.vue';
import LanguageSwitcher from '@/Components/LanguageSwitcher/LanguageSwitcher.vue';
import Popup from '@/Components/Popup/Popup.vue';
import Tooltipster from '@/Components/Tooltipster/Tooltipster.vue';
import ContentLoader from '@/Components/ContentLoader/ContentLoader.vue';
import InputRadio from '@/Components/InputRadio/InputRadio.vue';
import InputText from '@/Components/InputText/InputText.vue';
import InputEmail from '@/Components/InputEmail/InputEmail.vue';
import InputDateWithCalendar from '@/Components/InputDateWithCalendar/InputDateWithCalendar.vue';
import InputMultiCheckbox from '@/Components/InputMultiCheckbox/InputMultiCheckbox.vue';
import ButtonWithCallback from '@/Components/ButtonWithCallback/ButtonWithCallback.vue';
import PersonListWithSearch from '@/Components/PersonListWithSearch/PersonListWithSearch.vue';
import PhoneWithCountry from '@/Components/PhoneWithCountry/PhoneWithCountry.vue';
import InputCheckbox from '@/Components/InputCheckbox/InputCheckbox.vue';
import InputSelect from '@/Components/InputSelect/InputSelect.vue';
import InputDateWithDropdown from '@/Components/InputDateWithDropdown/InputDateWithDropdown.vue';
import InputNumber from '@/Components/InputNumber/InputNumber.vue';
import FilesUploader from '@/Components/FilesUploader/FilesUploader.vue';
import Drawer from '@/Components/Drawer/Drawer.vue';
import OptionsSmartList from '@/Components/OptionsSmartList/OptionsSmartList.vue';
import InputTime from '@/Components/InputTime/InputTime.vue';
import InputPlateNumber from '@/Components/InputPlateNumber/InputPlateNumber.vue';
import ObjectList from '@/Components/ObjectList/ObjectList.vue';
import InputRadioOverlayed from '@/Components/InputRadioOverlayed/InputRadioOverlayed.vue';
import InputDate from '@/Components/InputDate/InputDate.vue';
import InputTextarea from '@/Components/InputTextarea/InputTextarea.vue';
import MapWithAddress from '@/Components/MapWithAddress/MapWithAddress.vue';
import OptionsList from '@/Components/OptionsList/OptionsList.vue';
import MapWithPlaces from '@/Components/MapWithPlaces/MapWithPlaces.vue';
import InputCountry from '@/Components/InputCountry/InputCountry.vue';
import InputCompanyType from '@/Components/InputCompanyType/InputCompanyType.vue';
import InputBankAccount from '@/Components/InputBankAccount/InputBankAccount.vue';
import SelectAccountDropdown from '@/Components/SelectAccountDropdown/SelectAccountDropdown.vue';

const pages: Components = {
    AccidentDataPage: AccidentDataPage,
    DamagePage: DamagePage,
    AccidentDetailsPage: AccidentDetailsPage,
    ExtraDetailsPage: ExtraDetailsPage,
    UploadFilesPage: UploadFilesPage,
    InsuranceIndemnityReceiverPage: InsuranceIndemnityReceiverPage,
    YourContactDetailsPage: YourContactDetailsPage,
}

const components: Components = {
    AppStepperLayout: StepperLayout,
    AppRouteStepper: RouteStepper,
    AppBelongingsDamage: BelongingsDamage,
    AppPersonDamage: PersonDamage,
    AppPropertyDamage: PropertyDamage,
    AppVehicleDamage: VehicleDamage,
    AppCustomForm: CustomForm,
    AppInsuredObjectsBlocks: InsuredObjectsBlocks,
    AppLanguageSwitcher: LanguageSwitcher,
    AppPopup: Popup,
    AppTooltipster: Tooltipster,
    AppContentLoader: ContentLoader,
    AppInputRadio: InputRadio,
    AppInputText: InputText,
    AppInputEmail: InputEmail,
    AppInputMultiCheckbox: InputMultiCheckbox,
    AppButtonWithCallback: ButtonWithCallback,
    AppInputDateWithCalendar: InputDateWithCalendar,
    AppPersonListWithSearch: PersonListWithSearch,
    AppPhoneWithCountry: PhoneWithCountry,
    AppInputCheckbox: InputCheckbox,
    AppInputSelect: InputSelect,
    AppInputDateWithDropdown: InputDateWithDropdown,
    AppInputNumber: InputNumber,
    AppFilesUploader: FilesUploader,
    AppDrawer: Drawer,
    AppOptionsSmartList: OptionsSmartList,
    AppInputTime: InputTime,
    AppInputPlateNumber: InputPlateNumber,
    AppObjectList: ObjectList,
    AppInputRadioOverlayed: InputRadioOverlayed,
    AppInputDate: InputDate,
    AppInputTextarea: InputTextarea,
    AppMapWithAddress: MapWithAddress,
    AppOptionsList: OptionsList,
    AppMapWithPlaces: MapWithPlaces,
    AppInputCountry: InputCountry,
    AppInputCompanyType: InputCompanyType,
    AppInputBankAccount: InputBankAccount,
    AppSelectAccountDropdown: SelectAccountDropdown,
}

export default class {
    public app(selector: string, routes: BackendRoute[]): ComponentPublicInstance {
        const app: App = new VueApp(AppComponent)
            .withComponents({...pages, ...components})
            .withDirectives(GlobalDirectives)
            .create();
        app.use(new Router(app, routes).create());
        app.use(createPinia());
        return app.mount(selector);
    }
}
