import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import GridContent from '@/Components/SmartGrid/Interfaces/GridContentInterface';
import ColumnsDefinitions from '@/Components/SmartGrid/Interfaces/ColumnsDefinitionsInterface';
import { TemplateCellRenderer } from '@/Components/SmartGrid/Renderers/TemplateCellRenderer';
import CellRendererTemplate from '@/Components/SmartGrid/Interfaces/CellRendererTemplateInterface';
import ExcelFaker from '@/pages/LegalPerson/Workbench/Common/ExcelFaker';
import FakePerson from '@/interfaces/FakePersonInterface';

export default class ExcelTemplates {
    private addUsers: GridContent = new class implements GridContent {
        public columnDefs: ColumnsDefinitions[] = [];
        public rowData: DynamicDictionary[] = [];
    }

    private deleteUsers: GridContent = new class implements GridContent {
        public columnDefs: ColumnsDefinitions[] = [];
        public rowData: DynamicDictionary[] = [];
    }

    private countryIso: string = '';

    public addContent(content: DynamicDictionary, countryIso: string): void {
        this.countryIso = countryIso;
        this.buildGrid(content);
    }

    private buildGrid(content: DynamicDictionary): void {
        const addUsersColumns: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
        const cellsMaxWidthForAddUsers: number[] = ExcelTemplates.cellWidthsByCountry()
            .addTemplate[this.countryIso];
        this.addUsers.columnDefs = this.columnDefinitions(addUsersColumns, cellsMaxWidthForAddUsers);
        this.addUsers.rowData = this.rowData(
            content.addPersons,
            addUsersColumns,
            this.addUsers.columnDefs,
            this.addPersonsFakeByIndex.bind(this),
            [1, 2, 3]
        );
        const deleteUsersColumns: string[] = ['A', 'B'];
        const cellsMaxWidthForDeleteUsers: number[] = ExcelTemplates.cellWidthsByCountry()
            .removeTemplate[this.countryIso];
        this.deleteUsers.columnDefs = this.columnDefinitions(deleteUsersColumns, cellsMaxWidthForDeleteUsers);
        this.deleteUsers.rowData = this.rowData(
            content.deletePersons,
            deleteUsersColumns,
            this.deleteUsers.columnDefs,
            this.deletePersonsFakeByIndex.bind(this),
            [1, 2]
        );
    }

    public addPersonsFakeByIndex(index: number, rowNumber: number): string {
        const personIndex: number = rowNumber - 2;
        const fakePerson: FakePerson = new ExcelFaker(this.countryIso).fakePerson(personIndex);
        return [
            String(rowNumber),
            fakePerson.resident,
            fakePerson.countryIc,
            fakePerson.email,
            fakePerson.identityNumber,
            fakePerson.birthDate,
            fakePerson.firstName,
            fakePerson.lastName,
            fakePerson.program,
            fakePerson.startDate,
        ][index];
    }

    public deletePersonsFakeByIndex(index: number, rowNumber: number): string {
        const personIndex: number = rowNumber === 3
            ? 2 : 0;
        const fakePerson: FakePerson = new ExcelFaker(this.countryIso).fakePerson(personIndex);
        let result: string = '';
        switch (index) {
            case 1:
                result = fakePerson.identityNumber;
                break;
            case 2:
                result = fakePerson.endDate;
                break;
            default:
                result = String(rowNumber);
        }

        return result;
    }

    private columnDefinitions(columns: string[], cellsMaxWidth: number[]): ColumnsDefinitions[] {
        const result: ColumnsDefinitions[] = [];
        const maxWidthForIndex: number = 50;
        const indexColumn: ColumnsDefinitions = {
            field: 'index',
            headerName: ' ',
            maxWidth: maxWidthForIndex,
            sortable: false,
        }
        result.push(indexColumn);
        columns.forEach((header: string, index: number): void => {
            const column: ColumnsDefinitions = {
                field: columns[index],
                headerName: columns[index],
                sortable: false,
                maxWidth: cellsMaxWidth[index],
                cellRenderer: TemplateCellRenderer,
                cellRendererParams: this.boldRenderer,
            }
            result.push(column);
        });

        return result;
    }

    private rowData(
        content: string[],
        columns: string[],
        columnDefs: ColumnsDefinitions[],
        faker: Function,
        rows: number[],
    ): DynamicDictionary[] {
        const result: DynamicDictionary[] = [];
        const row: DynamicDictionary = {};
        row['index'] = 1;
        columns.forEach((header: string, headerIndex: number): void => {
            row[header] = content[headerIndex];
        });
        result.push(row);
        rows.forEach((rowNumber: number): void => {
            const fakeRow: DynamicDictionary = {};
            columnDefs.forEach((column: ColumnsDefinitions, index: number): void => {
                fakeRow[column.field] = faker(index, (rowNumber + 1));
            });
            result.push(fakeRow);
        });

        return result;
    }

    private boldRenderer(params: DynamicDictionary): CellRendererTemplate {
        return new class implements CellRendererTemplate {
            public template: string = params.rowIndex === 0 ?
                '<strong>' + params.value + '</strong>' : params.value;
        };
    }

    private static cellWidthsByCountry(): DynamicDictionary {
        const cellWidth80: number = 80;
        const cellWidth90: number = 90;
        const cellWidth120: number = 120;
        const cellWidth128: number = 128;
        const cellWidth150: number = 150;
        const cellWidth170: number = 170;
        const cellWidth190: number = 190;
        const cellWidth220: number = 220;
        const cellWidth230: number = 230;
        const cellWidthWide: number = 10000;
        const addUsersDefault: number[] = [
            cellWidth90,
            cellWidth150,
            cellWidth190,
            cellWidth120,
            cellWidth150,
            cellWidth80,
            cellWidth80,
            cellWidth120,
            cellWidth128,
        ];
        const removeUsersDefault: number[] = [
            cellWidth120,
            cellWidthWide,
        ];
        return {
            addTemplate: {
                LV: addUsersDefault,
                EE: [
                    cellWidth150,
                    cellWidth230,
                    cellWidth230,
                    cellWidth190,
                    cellWidth170,
                    cellWidth150,
                    cellWidth190,
                    cellWidth170,
                    cellWidth220,
                ],
                LT: addUsersDefault,
            },
            removeTemplate: {
                LV: removeUsersDefault,
                EE: [
                    cellWidth190,
                    cellWidthWide,
                ],
                LT: removeUsersDefault,
            }
        }
    }
}
