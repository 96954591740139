import {App, Component, createApp} from 'vue';
import {ComponentPublicInstance} from '@vue/runtime-core';


export default class VueModule {
    constructor(private readonly appComponent: Component) {
    }

    public mount(): ComponentPublicInstance {
        const container: HTMLDivElement = document.createElement('div');
        (this.appComponent as any).render = (): string => '';
        const app: App = createApp(this.appComponent);
        return app.mount(container);
    }
}
