<script lang="ts">
import {computed, defineComponent, ref, Ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import Translations from '@/services/translations.service';
import {useTranslate} from '@/Composables/Translate';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useDefine} from '@/Composables/Define';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import {InsuredObjectRisk} from '@/interfaces/one_policy/insured.object.risk.interface';
import AccidentTypeEnum from '@/Enums/ClaimsAccidentAccidentTypeEnum';
import Url from '@/Enums/UrlEnum';
import axios, {AxiosResponse} from 'axios';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const {translateForType} = useTranslate();
        const {isSet} = useDefine();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 4;
        const Facility: string = 'one-claims-accident';
        const uploader: string = 'claims-accident';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const filesUploader: Ref<DynamicDictionary | null> = ref(null);
        const renew: Ref<boolean> = ref(false);

        const uploadCategories: Ref<string[]> = ref([]);
        const riskIc: Ref<string> = ref('');
        const injuryType: Ref<string> = ref('');
        const risksOfSelectedPolicy: Ref<InsuredObjectRisk[]> = ref([]);
        const payoutTable: Ref<string> = ref('');
        const injuredParts: Ref<InjuredParts[]> = ref([]);
        const expenses: Ref<string[]> = ref([]);

        const uploadInProgress: Ref<boolean> = computed(() => {
            return filesUploader.value ? !filesUploader.value.uploadsSuccessful : true;
        });

        const uploaderIsReady: Ref<boolean> = computed(() => {
            return filesUploader.value ? filesUploader.value.componentIsReady : false;
        });

        const isUploaded: Ref<boolean> = computed(() => {
            return filesUploader.value
                ? !filesUploader.value.filesNotUploaded && filesUploader.value.uploadsSuccessful
                : false;
        });

        const documentsListDescription: Ref<string[]> = computed(() => {
            const result: string[] = [];
            uploadCategories.value.forEach((category: string) => {
                if (category !== 'claims_accident_damages_photos_and_other_documents') {
                    result.push(translateForType(category, Translations.getInstance().type));
                }
            });

            return result;
        });

        function setupStepData(): void {
            const decodedJson: DynamicDictionary = JSON.parse(btaBase.userStorage.storageJson);
            riskIc.value = decodedJson.stepStorageData.riskIc;
            risksOfSelectedPolicy.value = decodedJson.stepStorageData.risksOfSelectedPolicy;
            if (decodedJson.stepStorageData.type === AccidentTypeEnum.Injuries) {
                injuryType.value = decodedJson.stepStorageData.injuryType.selected;
                injuredParts.value = decodedJson.stepStorageData.injuredParts as InjuredParts[];
                const injuries: InsuredObjectRisk = risksOfSelectedPolicy.value
                    .find((risk: InsuredObjectRisk) => risk.agrrisk === riskIc.value) as InsuredObjectRisk;
                if (isSet(injuries) && isSet(injuries.payouttable)) {
                    payoutTable.value = injuries.payouttable as string;
                }
                if (isSet(decodedJson.stepStorageData.expenses)
                    && isSet(decodedJson.stepStorageData.expenses.selected)) {
                    const selectedExpenses: string = decodedJson.stepStorageData.expenses.selected as string;
                    if (selectedExpenses !== '') {
                        expenses.value = selectedExpenses.split('@#@');
                    }
                }
            }
            fetchUploadCategories();
        }

        function fetchUploadCategories(): void {
            const injuredPartData: string = injuredParts.value.length > 0
                ? JSON.stringify(injuredParts.value)
                : '';
            const expensesData: string = expenses.value.length > 0
                ? JSON.stringify(expenses.value)
                : '';
            const params: Record<string, number | string> = {
                riskIc: riskIc.value,
                injuryType: injuryType.value,
                injuredParts: injuredPartData,
                expenses: expensesData,
                payoutTable: payoutTable.value,
            };
            axios.get('en' + Url.AccidentClaims.uploadCategories, {params})
                .then((response: AxiosResponse) => {
                    uploadCategories.value = response.data.data.body.uploadCategories as string[];
                })
                .catch((reason: string | DynamicDictionary) => {
                    btaBase.error.show(
                        ErrorType.Error,
                        'Accident claims upload categories fetch',
                        reason
                    );
                });
        }

        function setupForm(): void {
            form.setReady();
            formIsReady.value = true;
        }

        function applyStepUrls(next: string, previous: string): void {
            SubmitterUrls.getInstance().applyStepUrls(next, previous);
        }

        function onSubmitStep(): void {
            btaBase.captcha.executeCaptcha(submit).then().catch((reason: string) => {
                Error.log(ErrorType.Error, 'onSubmitStep', reason, true);
            });
        }

        function submit(token: string): void {
            (filesUploader.value as DynamicDictionary).touch();
            prepareSubmit(token);
            stepsSubmitter.proceedStep('', 0);
        }

        function prepareSubmit(token: string): void {
            stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('filesUploaded', isUploaded.value);
            stepsSubmitter.addSubmitCustomParam('g-recaptcha-response', token);
        }


        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                filesUploader,
                renew,
                uploader,
                isUploaded,
                uploadInProgress,
                documentsListDescription,
                uploaderIsReady,
                applyStepUrls,
                setupForm,
                onSubmitStep,
                submit,
                prepareSubmit,
                setupStepData,
                fetchUploadCategories,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        Translations.getInstance().addType('claims_accident');
        this.setupStepData();
        this.setupForm();
        this.dynamicStepper.enableAll();
    }
});

interface InjuredParts {
    type: string;
    name: string;
    risk_ic: string;
    subrisk: string;
    selected?: boolean;
}
</script>
