<script setup lang="ts">
import {computed, ref, Ref} from 'vue';
import {TranslateReplaceParts, useTranslate} from '@/Composables/Translate';
import ButtonWithCallback from '@/Components/ButtonWithCallback/ButtonWithCallback.vue';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import DataLayerType from '@/Enums/DataLayerTypeEnum';

const props = defineProps({
    title: {type: String, default: ''},
    subtitle: {type: String, default: ''},
    translationType: {type: String, default: ''},
    uploader: {type: String, default: ''},
    files: {type: Array, default: []},
    withDocumentList: {type: Boolean, default: false},
    uploadFilesCallback: {type: Function, default: null}
});
const {translate, translateForType} = useTranslate();

const uploaderIsVisible: Ref<boolean> = ref(false);
const isUploadRestricted: boolean = false;

const button: Ref<ButtonWithCallbackParams> = computed(() => {
    return {
        title: uploaderIsVisible.value ? localized('submit_documents') : localized('add_documents'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: uploaderIsVisible.value ? ButtonIcon.LongArrowRight : ButtonIcon.AddFile,
        iconPosition: uploaderIsVisible.value ? ButtonIconPosition.Right : ButtonIconPosition.Left,
        callback: uploaderIsVisible.value ? onSubmitDocumentsClick : onAddDocumentsClick,
    };
})

const withUploadedDocuments: Ref<boolean> = computed(() => {
    return props.files.length > 0 && props.withDocumentList;
});

function onAddDocumentsClick(): void {
    uploaderIsVisible.value = true;
}

function onSubmitDocumentsClick(): void {
    props.uploadFilesCallback();
    uploaderIsVisible.value = false;
}

function localized(key: string, replaceParts?: TranslateReplaceParts): string {
    return translateForType(key, props.translationType, replaceParts);
}
</script>
<template>
    <div class="compact-file-uploader">
        <span class="title">{{title}}</span>
        <span class="subtitle">{{subtitle}}</span>
        <div class="document-list" v-if="withUploadedDocuments">
            <button-with-callback v-for="(file) in files"
                :title="file.name"
                :subtitle="file.mime.toUpperCase()"
                :url="file.link"
                :icon="ButtonIcon.Download"
                :icon-color="ButtonIconColor.Black"
                :background-color="ButtonBackground.White"
                :background-color-hover="ButtonBackground.Transparent">
            </button-with-callback>
        </div>
        <app-files-uploader
            v-if="uploaderIsVisible"
            :uploader="uploader"
            :is-upload-restricted="isUploadRestricted"
            :data-layer-event="DataLayerType.ClaimUploadFiles">
        </app-files-uploader>
        <button-with-callback class="buttons" v-bind="button"></button-with-callback>
    </div>
</template>
<style lang="scss" scoped>
.compact-file-uploader {
    display: flex;
    flex-direction: column;
    padding: var(--size-medium);
    border-radius: var(--size-tiny);
    background: var(--background-base);

    .document-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--size-nano);
        margin-bottom: var(--size-small);

        .button-with-callback {
            width: 100%;
            height: 52px;
            justify-content: flex-start;
        }
    }

    .buttons {
        height: 52px;
    }

    .title {
        font-weight: 700;
        font-size: var(--font-size-small);
        margin-bottom: var(--size-nano);
    }

    .subtitle {
        font-weight: 500;
        font-size: var(--font-size-tiny);
        color: var(--text-color-default);
        margin-bottom: var(--size-small);
    }

    .files-uploader {
        margin-bottom: var(--size-small);

        :deep() .files-uploader-area {
            gap: var(--size-normal);

            .item {
                &.upload-container {
                    width: 50%;
                    margin-right: 0;
                }

                &.qr {
                    width: 50%;
                    display: flex;
                }
            }
        }
    }
}
</style>
