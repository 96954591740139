<script lang="ts">
import {computed, defineComponent, reactive, ref, Ref, nextTick, UnwrapNestedRefs} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import {DefineParams, useDefine} from '@/Composables/Define';
import {useAxios} from '@/Composables/Axios';
import ClaimsOptions from '@/interfaces/claims.options.interface';
import ClaimOption from '@/interfaces/claim.option.interface';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import {InputOption} from '@/interfaces/InputOptionInterface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import CascoClaimsPanelsNames from '@/Enums/CascoClaimsPanelsNamesEnum';
import {Subscription} from 'rxjs';
import OneDate from '@/assets/libraries/Date/OneDate';
import moment from 'moment/moment';
import CascoClaimsMandatoryReport from '@/Enums/CascoClaimsMandatoryReportEnum';
import Url from '@/Enums/UrlEnum';
import {TranslateParams, useTranslate} from '@/Composables/Translate';
import Translations from '@/services/translations.service';
import DateRange from '@/interfaces/date.range.interface';
import FormField from '@/assets/libraries/form/form-field';
import {AxiosResponse} from 'axios';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import OptionsSmartCustom from '@/Components/OptionsSmartList/Interfaces/OptionsSmartCustomInterface';
import OptionsSmartTypes from '@/Components/OptionsSmartList/Enums/OptionsSmartTypesEnum';
import OptionsSmartCustomSelectState
    from '@/Components/OptionsSmartList/Interfaces/OptionsSmartCustomSelectStateInterface';
import OptionSmartColor from '@/Components/OptionsSmartList/Enums/OptionSmartColorEnum';
import {InsuredObjectRisk} from '@/interfaces/one_policy/insured.object.risk.interface';
import {useStrings} from '@/Composables/Strings';
import AppCountry from '@/assets/libraries/app/app-country';
import SettingsService from '@/services/settings.service';
import Coordinates = JQuery.Coordinates;

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const {translate, translateForType}: TranslateParams = useTranslate();
        const {isSet, validResponse}: DefineParams = useDefine();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 2;
        const Facility: string = 'one-claims-casco';
        const TimeFormat: string = 'HH:mm';
        const TextSeparator: string = '::';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);

        const customFeedbackMessage: Ref<string> = ref('');

        //Move back to composable when possible to override functions
        const claimsCascoRecipientDataUrl: Ref<string> = ref('');
        const claimsCascoContactDetailsUrl: Ref<string> = ref('');
        const lastPreparationsName: Ref<string> = ref('');
        const canClearFormsAhead: Ref<boolean> = ref(false);
        const isSearchInProgress: Ref<boolean> = ref(false);
        const showStolenCar: Ref<boolean> = ref(false);
        const stolenCarText: Ref<string> = ref('');
        const stolenCarWhatToDo: Ref<string> = ref('');
        const singleAutoSubmitOptions: Ref<DynamicDictionary> = ref({});
        const skippedPanel: Ref<string> = ref('');
        const featuredServicesCount: Ref<number> = ref(0);
        const lastScrollTarget: Ref<string> = ref('');
        const accidentCountryIso: Ref<string> = ref('');

        const inputOptions: UnwrapNestedRefs<ClaimsOptions> = reactive(new class implements ClaimsOptions {
            [key: string]: ClaimOption;

            public 'when-event-happened': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }
            public 'what-time-did-it-happened': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }
            public 'what-vehicle-was-involved': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }
            public 'select-damage-type': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }

            public 'damage-subtype': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }
            public 'where-accident-happened': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }
            public 'did-you-damage-third-party-property': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }
            public 'how-accident-is-reported': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }
            public 'report-the-accident': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }
            public 'description-of-accident': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }
        });

        const claimsCascoObjects: Ref<Map<string, DynamicDictionary[]>> = ref(new Map());
        const additionalFormFields: Ref<string[]> = ref([
            CascoClaimsPanelsNames.RepairServiceDetails,
        ]);
        const insuredObjects: Ref<InputOption[]> = ref([]);
        const cascoObjectResponse: Ref<DynamicDictionary[]> = ref([]);
        const claimsCascoExtraDetailsUrl: Ref<string> = ref('');
        const mandatoryReportInfoText: Ref<string[]> = ref([]);
        const describeAccidentHtml: Ref<string> = ref('');
        const reportTheAccidentInfoText: Ref<string> = ref('');

        const cascoClaimsMandatoryReport: Ref<typeof CascoClaimsMandatoryReport> = computed(() => {
            return CascoClaimsMandatoryReport;
        });

        const objectFetchUrl: Ref<string> = computed(() => {
            return Url.Ajax.claimsCascoObject;
        });

        const calendarMinDate: Ref<Date> = computed(() => {
            const minDateYears: number = 5;
            return moment().subtract(minDateYears, 'years').toDate();
        });

        const calendarMaxDate: Ref<Date> = computed(() => {
            return moment().toDate();
        });

        const mandatoryReport: Ref<string[]> = computed(() => {
            return mandatoryReportInfoText.value;
        });

        const describeAccidentContent: Ref<string> = computed(() => {
            return translateForType(describeAccidentHtml.value, Translations.getInstance().type);
        });

        const feedbackText: Ref<string> = computed(() => {
            return customFeedbackMessage.value;
        });

        const selectDate: Ref<string> = computed(() => {
            return translateForType('claims_select_date', Translations.getInstance().type);
        });

        const reportTheAccidentText: Ref<string> = computed(() => {
            return translateForType(reportTheAccidentInfoText.value, Translations.getInstance().type);
        });

        const propertyObjectId: Ref<string> = computed(() => {
            const value: DynamicDictionary = form.field(CascoClaimsPanelsNames.WhatVehicleWasInvolved).value;

            return isSet(value.object) ? value.object as string : '';
        });

        const risks: Ref<string[]> = computed(() => {
            const params: string[] = [];
            cascoObjectResponse.value.forEach((object: DynamicDictionary): void => {
                if (object.id === propertyObjectId.value) {
                    (object.risks as InsuredObjectRisk[]).forEach((risk: DynamicDictionary): void => {
                        params.push((risk as InsuredObjectRisk).agrrisk);
                    });
                }
            });

            return params;
        });

        const isSetCustom = (property: LimitedVariant | undefined): boolean => {
            return typeof property !== 'undefined' && property !== null;
        }

        //INFO Functions from composable
        //Move back to composable when possible to override functions
        const buildInitialOptions = (params: DynamicDictionary): void => {
            if (isSet(params.options.enabled)) {
                enablePanels(params.options.enabled).then((): void => {
                    if (isSet(params.objects)) {
                        buildInsuredObjects(params.objects);
                    }
                    if (isSet(params.options.damageTypes)) {
                        buildDamageTypes(params.options.damageTypes);
                    }
                    if (isSet(params.options.happened)) {
                        buildWhatHappened(params.options);
                    }
                    if (isSet(params.options.report)) {
                        buildReportTheAccident(params.options);
                    }
                    if (isSet(params.options.reportTo)) {
                        fetchReportTheAccident().then();
                        fetchDescribeAccident().then();
                        buildHowAccidentIsReported(params.options);
                        howAccidentIsReportedOptionFetch(CascoClaimsPanelsNames.HowAccidentIsReported);
                    }
                    if (isSet(params.options.describe)) {
                        buildDescribeAccident(params.options);
                    }
                    buildStaticOptions();
                    applyPredefinedValues();
                    showInitialEnabledPanels();
                    nextTick((): void => {
                        canClearFormsAhead.value = true;
                        scrollToDataOffset(lastVisiblePanel(), true);
                        calculateStepperVisibility();
                        resetObjectsIfHasErrors(params.objects);
                    });
                });
            }
        }

        const proceedButtonIsVisible = (panel: string): boolean => {
            let result: boolean = true;
            let allowNextItemVisibilityCheck: boolean = false;
            Object.keys(inputOptions).forEach((field: string): void => {
                if (allowNextItemVisibilityCheck &&
                    inputOptions[field].enabled && inputOptions[field].visible) {
                    result = false;
                    allowNextItemVisibilityCheck = false;
                }
                if (field === panel) {
                    allowNextItemVisibilityCheck = true;
                }
            });

            return result;
        }

        const applyStolenCarInfo = (newStolenCarText: string, callText: string): void => {
            stolenCarText.value = newStolenCarText;
            stolenCarWhatToDo.value = callText;
        }

        const onProceed = (panel: string, initial: boolean = false, skipValidate: boolean = false): void => {
            validatePanel(panel, skipValidate).then((): void => {
                blockOnStolenCar(panel).then((): void => {
                    applyPassedOnOption(panel, true);
                    fetchEnabledPanels(initial).then((visibilities: string[]): void => {
                        enablePanels(visibilities).then((): void => {
                            showNextPanel(panel, initial);
                        }).catch((reason: DynamicDictionary): void => {
                            redirectToStep(reason);
                        });
                    });
                }).catch((reason): void => {
                    Error.log(ErrorType.Error, 'onProceed', reason, true);
                });
            }).catch((): void => {
                applyPassedOnOption(panel, false);
            }).finally((): void => {
                clearAllValidators();
            });
        }

        const proceedDescriptionOfAccidentButtonIsDisabled = (): boolean => {
            const descriptionText: string = form.field(CascoClaimsPanelsNames.DescriptionOfAccident).value;
            const multibyteLength: number = useStrings().multibyteLength(descriptionText);
            const minLength: number = btaBase.settings.claimsSettings().casco.descriptionFieldMinLength;
            const maxLength: number = btaBase.settings.claimsSettings().casco.descriptionFieldMaxLength;
            const valueLengthOutOfBounds: boolean = multibyteLength < minLength || multibyteLength > maxLength;
            const valueHasInvalidText: boolean = !useStrings().isValidWordString(descriptionText);

            return [valueLengthOutOfBounds, valueHasInvalidText].some((newValue: boolean): boolean => newValue);
        }

        const formHasObjectValue = (field: string): boolean => {
            return form.field(field).value.selected !== '';
        }

        const lastVisiblePanel = (): string => {
            let result: string = '';
            Object.keys(inputOptions).forEach((field: string): void => {
                if (inputOptions[field].enabled && inputOptions[field].visible) {
                    result = field;
                }
            });

            return result;
        }

        const isValidResponse = (response: DynamicDictionary): boolean => {
            return isValidBodyResponse(response) &&
                !hasBodyErrorResponse(response.data.data.body);
        }

        const applyValueOnOption = (option: string, newValue: LimitedVariant): void => {
            if (optionIsPresent(option)) {
                inputOptions[option].value = newValue;
            }
        }

        const applyOptionsOnOption = (option: string, options: InputOption[]): void => {
            if (optionIsPresent(option)) {
                inputOptions[option].options = options;
            }
        }

        const applyEnabledOnOption = (option: string, enabled: boolean): void => {
            if (optionIsPresent(option)) {
                inputOptions[option].enabled = enabled;
            }
        }

        const applyVisibleOnOption = (option: string, visible: boolean): void => {
            if (optionIsPresent(option)) {
                inputOptions[option].visible = visible;
            }
            calculateStepperVisibility();
        }

        const scrollToDataOffset = (target: string, forced: boolean = false): void => {
            if (lastScrollTarget.value !== target || forced) {
                lastScrollTarget.value = target;
                nextTick((): void => {
                    const $panel: JQuery = $('[data-scroll="' + target + '"]');
                    if ($panel.length > 0) {
                        const scrollSpeed: number = 350;
                        const offset: Coordinates | undefined = $panel.offset();
                        const verticalOffset: number = offset ? offset.top - headerOffsetForScroll.value : 0;
                        if (offset && verticalOffset > 0) {
                            $('html,body')
                                .stop(true, false)
                                .animate({scrollTop: verticalOffset}, scrollSpeed);
                            const textField: JQuery = $panel.find('input[type=text]').first();
                            const textFieldInFocus: boolean = textField.is(":focus");
                            if (textField && !textFieldInFocus) {
                                textField.trigger('focus');
                            }
                        }
                    }
                });
            }
        }

        const isValidBodyResponse = (response: DynamicDictionary): boolean => {
            return isSet(response.data) &&
                isSet(response.data.data) &&
                isSet(response.data.data.body);
        }

        const validatePanel = (panel: string, skipValidate: boolean = false): Promise<void> => {
            let result: Promise<void> = Promise.resolve();
            if (!skipValidate) {
                form.field(panel).clearValidators();
                form.field(panel).clearCustomValidators();
                touchAndRequireNecessaryField(panel);
                result = form.field(panel).validate().then((): Promise<void> => {
                    return form.field(panel).isValid ? Promise.resolve() : Promise.reject();
                });
            }

            return result;
        }

        const applySingleDamageSubtype = (): void => {
            const field: string = CascoClaimsPanelsNames.DamageSubtype;
            if (isSet(inputOptions[field])) {
                let newValue: LimitedVariant = inputOptions[field].value;
                if (newValue === '' &&
                    inputOptions[field].options.length === 1 &&
                    field === CascoClaimsPanelsNames.DamageSubtype) {
                    newValue = {
                        selected: inputOptions[field].options[0].value
                    };
                }
                stepsSubmitter.addSubmitCustomParam(field, newValue as DynamicDictionary);
            }
        }

        const isVisiblePanel = (panel: string): boolean => {
            let result: boolean;
            if (panel === CascoClaimsPanelsNames.ReportTheAccident) {
                const canShowPanel: boolean = inputOptions[panel].enabled && inputOptions[panel].visible &&
                    inputOptions[CascoClaimsPanelsNames.HowAccidentIsReported].passed &&
                    !isAccidentReported();
                inputOptions[panel].visible = canShowPanel;
                result = canShowPanel && !skipPanel(panel);
            } else {
                const canUsePanel: boolean = inputOptions[panel].enabled && inputOptions[panel].visible;
                const currentSkipPanel: boolean = skipPanel(panel);
                result = canUsePanel && !currentSkipPanel
            }

            return result;
        }

        const clearAllValidators = (): void => {
            Object.keys(inputOptions).forEach((panel: string): void => {
                form.field(panel).clearValidators();
                if (panel !== CascoClaimsPanelsNames.SelectDamageType) {
                    form.field(panel).clearCustomValidators();
                }
            });
        }

        const clearFormsAhead = (field: string): void => {
            let clear: boolean = false;
            for (const key in inputOptions) {
                if (clear) {
                    applyResetOnOption(key);
                }
                if (key === field) {
                    clear = true;
                    applyPassedOnOption(key, false);
                    lastPreparationsName.value = '';
                }
            }
            lastScrollTarget.value = '';
        }

        const enablePanels = (options: string[] | DynamicDictionary): Promise<void> => {
            let result: Promise<void>;
            if ((<DynamicDictionary>options).redirect) {
                const reason: DynamicDictionary = {
                    type: 'redirect',
                    url: (<DynamicDictionary>options).redirect
                };
                result = Promise.reject(reason);
            } else {
                disableAllOptions();
                options.forEach((option: string): void => {
                    applyEnabledOnOption(option, true);
                });
                result = Promise.resolve();
            }

            return result;
        }

        const value = (name: string): LimitedVariant => {
            let result: LimitedVariant = '';
            if (inputOptions[name].value) {
                result = inputOptions[name].value as LimitedVariant;
            }

            return result;
        }

        const showInitialEnabledPanels = (): void => {
            disablePanelsWithSingleOptions();
            disablePanelsSkipped();
            Object.keys(inputOptions).forEach((field: string): void => {
                if (hasInitialEnabledPanel(field)) {
                    validatePanel(field).then((): void => {
                        applyVisibleOnOption(field, true);
                        applyPassedOnOption(field, true);
                        showNextPanel(field, true);
                    }).catch(() => {
                        applyVisibleOnOption(field, true);
                        form.field(field).touch();
                    });
                } else {
                    if (formHasValue(field) && previousFormHasValue(field)) {
                        applyVisibleOnOption(field, true);
                        if (!hasRedirectAction(field)) {
                            applyPassedOnOption(field, true);
                            showNextPanel(field, true);
                        }
                    }
                }
            });
        }

        const hasInitialEnabledPanel = (key: string): boolean => {
            let result: boolean = form.field(key).name === CascoClaimsPanelsNames.WhatTimeDidItHappened;
            if (hasAccidentCountryIso() && sameCountryIso() && hasSkippedRepairVehicleAbroadPanel()) {
                const hasPleaseChooseRepairService: boolean =
                    form.field(key).name === CascoClaimsPanelsNames.PleaseChooseRepairService;
                result = result || hasPleaseChooseRepairService;
            }

            return result;
        }

        const disablePanelsSkipped = (): void => {
            if (Object.keys(inputOptions).includes(skippedPanel.value)) {
                inputOptions[skippedPanel.value].enabled = false;
                inputOptions[skippedPanel.value].visible = false;
                if (panelHasSingleOption(skippedPanel.value)) {
                    addSingleOptionToAutoSubmit(skippedPanel.value);
                }
            }

            Object.keys(inputOptions).forEach((field: string): void => {
                disableOptionsIfItHasSingleOption(field);
            });
        }

        const redirectToStep = (params: DynamicDictionary): void => {
            if (isSet(params.type) && params.type === 'redirect') {
                PopupService.getInstance().show(new OnePopup().withType().loading);
                const isExtraDetails: boolean = params.url.includes('extra-details');
                if (isExtraDetails) {
                    removeVehicleStepInStepper();
                }
                const extraDetailsStepNumber: number = 4;
                const vehicleStepNumber: number = 3;
                prepareSubmit();
                stepsSubmitter.proceedStep(params.url, isExtraDetails ?
                    extraDetailsStepNumber :
                    vehicleStepNumber);
            }
        }

        const disablePanelsWithSingleOptions = (): void => {
            Object.keys(inputOptions).forEach((field: string): void => {
                disableOptionsIfItHasSingleOption(field);
            });
        }

        const disableOptionsIfItHasSingleOption = (field: string): void => {
            if (panelHasSingleOption(field)) {
                inputOptions[field].enabled = false;
                inputOptions[field].visible = false;
                addSingleOptionToAutoSubmit(field);
            }
        }

        const panelHasSingleOption = (panel: string): boolean => {
            return inputOptions[panel].options.length === 1;
        }

        const addSingleOptionToAutoSubmit = (panel: string): void => {
            const newValue: DynamicDictionary = {selected: inputOptions[panel].options[0].value};
            singleAutoSubmitOptions.value[panel] = newValue;
            form.field(panel).patch(newValue);
        }

        const showNextPanel = (panel: string, initial: boolean = false): void => {
            initial ? showNextPanelInitial(panel) : showNextPanelRegular(panel);
        }

        const showNextPanelInitial = (panel: string): void => {
            const nextEnabledPanel: string = nextEnabledPanelName(panel);
            applyVisibleOnOption(nextEnabledPanel, true);
        }

        const showNextPanelRegular = (panel: string): void => {
            const nextEnabledPanel: string = nextEnabledPanelName(panel);
            prepareOptions(nextEnabledPanel).then((): void => {
                if (panelHasSingleOption(nextEnabledPanel)) {
                    addSingleOptionToAutoSubmit(nextEnabledPanel);
                    onProceed(nextEnabledPanel, false, true);
                } else {
                    applyVisibleOnOption(nextEnabledPanel, true);
                    scrollToDataOffset(lastVisiblePanel());
                }
            });
        }

        const removeVehicleStepInStepper = (): void => {
            const accidentDataStepNumber: number = 2;
            const extraDetailsStepNumber: number = 4;
            const uploadFilesStepNumber: number = 5;
            const recipientDataStepNumber: number = 6;
            const yourContactDetailsStepNumber: number = 7;
            btaBase.dynamicStepper.applyEnabled([
                1,
                accidentDataStepNumber,
                extraDetailsStepNumber,
                uploadFilesStepNumber,
                recipientDataStepNumber,
                yourContactDetailsStepNumber,
            ]);
        }

        const optionIsPresent = (option: string): boolean => {
            return isSet(inputOptions[option]);
        }

        const fetchEnabledPanels = (initial: boolean = false): Promise<string[]> => {
            let result: Promise<string[]>;
            if (initial) {
                result = Promise.resolve([]);
            } else {
                const params: DynamicDictionary = visibilityRequestParams();
                PopupService.getInstance().show(new OnePopup().withType().loading);
                result = useAxios().get(Url.Ajax.claimsCascoEnabledPanels, {params})
                    .then((response: AxiosResponse<DynamicDictionary>) => {
                        if (isValidResponse(response)) {
                            return isSet(response.data.data.body.enabled) ? response.data.data.body.enabled : [];
                        } else {
                            btaBase.error.show(ErrorType.Error, 'fetchEnabledPanels', 'claims_casco_invalid_data');
                        }
                    })
                    .catch((reason: LimitedVariant) => {
                        btaBase.error.show(ErrorType.Error, 'fetchEnabledPanels', reason as DynamicDictionary);
                    })
                    .finally((): void => {
                        PopupService.getInstance().hide().then();
                    });
            }

            return result;
        }

        const initialFindPanelsData = (params: DynamicDictionary): void => {
            PopupService.getInstance().show(new OnePopup().withType().loading);
            useAxios().get(Url.Ajax.claimsCascoAccidentDataInitial, {params})
                .then((response: AxiosResponse<DynamicDictionary>): void => {
                    if (isValidBodyResponse(response)) {
                        buildInitialOptions(response.data.data.body);
                    } else {
                        resetToFirstPanel();
                    }
                })
                .catch((reason: LimitedVariant): void => {
                    resetToFirstPanel();
                    btaBase.error.show(ErrorType.Error, 'initialFindPanelsData', reason as DynamicDictionary);
                })
                .finally((): void => {
                    PopupService.getInstance().hide().then();
                });
        }

        const resetToFirstPanel = (): void => {
            canClearFormsAhead.value = true;
            onFormPanelInputChange(CascoClaimsPanelsNames.WhenEventHappened);
        }

        const proceedButtonIsDisabled = (panel: string, valueAsObject: boolean = false): boolean => {
            const isSelectDamageTypePanel: boolean = panel === CascoClaimsPanelsNames.SelectDamageType;
            const isPanelInvalid: boolean = isSelectDamageTypePanel ?
                !form.field(CascoClaimsPanelsNames.SelectDamageType).isValid :
                false;
            const isFormValueMissing: boolean = valueAsObject ?
                !formHasObjectValue(panel) :
                !formHasValue(panel);

            return isPanelInvalid || isFormValueMissing || isSearchInProgress.value;
        }

        const updateInitialPanelsVisibility = (): void => {
            for (const key in inputOptions) {
                if (!form.field(key).isEmpty()) {
                    applyPassedOnOption(key, true);
                    transformAndApplyValueOnOptions(key);
                    if (nextPanelFormIsNotEmpty(key)) {
                        onProceed(key, true);
                    }
                }
            }
        }

        const nextPanelFormIsNotEmpty = (panel: string): boolean => {
            return formHasValue(nextEnabledPanelName(panel));
        }

        const nextEnabledPanelName = (panel: string): string => {
            let result: string = '';
            let nextTargetPanel: boolean = false;
            Object.keys(inputOptions).forEach((key: string): void => {
                if (nextTargetPanel && inputOptions[key].enabled && !skippedPanelKey(key)) {
                    result = key;
                    nextTargetPanel = false;
                }
                if (key === panel) {
                    nextTargetPanel = true;
                }
            });
            if (hasAccidentCountryIso() && sameCountryIso() && hasSkippedRepairVehicleAbroadPanel()) {
                if (result === cascoClaimsPanelsNames.value.PleaseChooseRepairService) {
                    showNextPanel(panel);
                    initializeSkippedPanel();
                }
            }

            return result;
        }

        const skippedPanelKey = (key: string): boolean => {
            return skippedPanel.value === key;
        }

        const initializeSkippedPanel = (): void => {
            skippedPanel.value = '';
        }

        const blockOnStolenCar = (panel: string): Promise<void> => {
            let result: Promise<void> = Promise.resolve();
            if (panel === CascoClaimsPanelsNames.DamageSubtype) {
                if (formHasValue(CascoClaimsPanelsNames.DamageSubtype)) {
                    if (form.field(CascoClaimsPanelsNames.DamageSubtype).value.selected === 'STOLEN_CAR') {
                        openStolenCar();
                        result = Promise.reject();
                    }
                }
            }

            return result;
        }

        const applyResetOnOption = (option: string): void => {
            applyValueOnOption(option, '');
            applyOptionsOnOption(option, []);
            applyPassedOnOption(option, false)
            applyVisibleOnOption(option, false);
            form.field(option).clear().then();
        }

        const disableAllOptions = (): void => {
            Object.keys(inputOptions).forEach((field: string): void => {
                applyEnabledOnOption(field, false);
            });
        }

        const applyPassedOnOption = (option: string, passed: boolean): void => {
            inputOptions[option].passed = passed;
        }

        const hasBodyErrorResponse = (response: DynamicDictionary): boolean => {
            return isSet(response.objects) &&
                Array.isArray(response.objects) &&
                response.objects.length > 0 &&
                isSet(response.objects[0].error);
        }

        const hasRedirectAction = (panel: string): boolean => {
            return [
                CascoClaimsPanelsNames.CountryOfAccident,
                CascoClaimsPanelsNames.PleaseChooseRepairService,
                CascoClaimsPanelsNames.DescriptionOfAccident,
            ].includes(panel);
        }

        const previousFormHasValue = (field: string): boolean => {
            let result: string = '';
            let previousField: string = '';
            let found: boolean = false;
            Object.keys(inputOptions).forEach((currentField: string): void => {
                if (inputOptions[currentField].enabled) {
                    if (currentField === field && !found) {
                        result = previousField;
                        found = true;
                    }
                    previousField = currentField;
                }
            });

            return formHasValue(result);
        }

        const openStolenCar = (): void => {
            const scrollSpeed: number = 1;
            document.getElementsByTagName('html')[0].style.scrollBehavior = 'unset';
            $('html').animate({scrollTop: 0}, scrollSpeed);
            btaBase.dynamicStepper.show(false);
            showStolenCar.value = true;
        }

        const loadStolenCarText = (): void => {
            stolenCarText.value = translateForType(stolenCarText.value, Translations.getInstance().type);
            stolenCarWhatToDo.value = translateForType(stolenCarWhatToDo.value, Translations.getInstance().type);
        }

        const skipPanel = (panel: string): boolean => {
            let skip: boolean = false;
            switch (panel) {
                case CascoClaimsPanelsNames.DoYouWantToRepairVehicleAbroad:
                    if (hasAccidentCountryIso() && sameCountryIso() && inputOptions[panel].enabled) {
                        skip = true;
                        skippedPanel.value = panel;
                    }
                    break;
                case CascoClaimsPanelsNames.CountryOfAccident:
                    if (hasAccidentCountryIso() && !sameCountryIso()) {
                        skip = true;
                        skippedPanel.value = panel;
                    }
                    break;
                default:
                    break;
            }

            return skip;
        }

        const hasSkippedRepairVehicleAbroadPanel = (): boolean => {
            return skippedPanel.value === CascoClaimsPanelsNames.DoYouWantToRepairVehicleAbroad;
        }

        const sameCountryIso = (): boolean => {
            return branchCountryIso() === accidentCountryIso.value;
        }

        const hasAccidentCountryIso = (): boolean => {
            return accidentCountryIso.value !== '';
        }

        const branchCountryIso = (): string => {
            return new AppCountry().iso();
        }

        const accidentCountryData = (): void => {
            const accidentCountry: string =
                btaBase.userStorage.stepStorageData[CascoClaimsPanelsNames.WhereAccidentHappened]?.countryIso;
            accidentCountryIso.value = (accidentCountry) ? accidentCountry : '';
        }

        const addDamageSubtypeAsVisibilityRequestParam = (field: string): boolean => {
            let result: boolean = false;
            if (field === CascoClaimsPanelsNames.DamageSubtype &&
                panelHasSingleOption(CascoClaimsPanelsNames.DamageSubtype) &&
                inputOptions[CascoClaimsPanelsNames.DamageSubtype].value === '') {
                result = true;
            }

            return result;
        }

        const isAccidentReported = (): boolean => {
            return isSet(form.field(CascoClaimsPanelsNames.HowAccidentIsReported).value.selected) &&
                form.field(CascoClaimsPanelsNames.HowAccidentIsReported).value.selected
                    .includes(CascoClaimsMandatoryReport.Police);
        }

        const cascoClaimsPanelsNames: Ref<typeof CascoClaimsPanelsNames> = computed(() => {
            return CascoClaimsPanelsNames;
        });

        const servicesCount: Ref<string> = computed(() => {
            featuredServicesCount.value = SettingsService.getInstance().value('mapTopPlacesCount') as number;
            const replacements: DynamicDictionary = {
                '%count%': featuredServicesCount.value === 0 ? ' ' : featuredServicesCount.value
            };

            return translateForType('claims_casco_choose_accident_service_description', 'claims', replacements);
        });

        const translatedStolenCarText: Ref<string> = computed(() => {
            return stolenCarText.value.includes('claims_casco_') ? '' : stolenCarText.value;
        });

        const translatedStolenCarWhatToDo: Ref<string> = computed(() => {
            return stolenCarWhatToDo.value.includes('claims_casco_') ? '' : stolenCarWhatToDo.value;
        });

        const headerOffsetForScroll: Ref<number> = computed(() => {
            const smallOffset: number = 30;
            const bigOffset: number = 130;

            return btaBase.isVerticalMode.value ? smallOffset : bigOffset;
        });

        const visibilityRequestParams = (initial: boolean = false): DynamicDictionary => {
            const params: DynamicDictionary = {};
            Object.keys(inputOptions).forEach((field: string): void => {
                params[field] = inputOptions[field].value;
                if (addDamageSubtypeAsVisibilityRequestParam(field)) {
                    params[field] = {selected: inputOptions[field].options[0].value};
                }
            });
            if (!initial) {
                params.lastField = lastVisiblePanel();
            }

            return params;
        }

        const onFormPanelInputChange = (field: string): void => {
            clearAllValidators();
            if (canClearFormsAhead.value) {
                transformAndApplyValueOnOptions(field);
                clearFormsAhead(field);
                if (field === CascoClaimsPanelsNames.WhenEventHappened) {
                    onProceed(CascoClaimsPanelsNames.WhenEventHappened);
                }
            }
            if (canClearFormsAhead.value && field === CascoClaimsPanelsNames.HowAccidentIsReported) {
                howAccidentIsReportedOptionFetch(field);
                mandatoryReportInfoText.value = [];
            }
        }

        const calculateStepperVisibility = (): void => {
            btaBase.dynamicStepper.enableAll();
        }

        const baseSubmit = (): void => {
            stepsSubmitter.clearSubmitter();
            stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', btaBase.facility());
            Object.keys(inputOptions).forEach((field: string): void => {
                if (inputOptions[field].enabled && formHasValue(field)) {
                    stepsSubmitter.addSubmitCustomParam(field, inputOptions[field].value as DynamicDictionary);
                } else if (isSet(singleAutoSubmitOptions.value[field])) {
                    stepsSubmitter.addSubmitCustomParam(field, singleAutoSubmitOptions.value[field]);
                }
            });
            applySingleDamageSubtype();
            if (!form.field('repair-service-details').isEmpty()) {
                stepsSubmitter.addSubmitCustomParam('repair-service-details', form.field('repair-service-details').value)
            }
            stepsSubmitter.addSubmitCustomParam('dynamicStepper', btaBase.dynamicStepper.enableStates)
        }

        //INFO End of composable functions

        function onRedirect(panel: string): void {
            validatePanel(panel, false).then((): void => {
                prepareSubmit();
                switch (panel) {
                    case CascoClaimsPanelsNames.DescriptionOfAccident:
                        stepsSubmitter.proceedStep('', 0);
                        break;
                    default:
                }
            });
        }

        function applyExtraDetailsUrl(url: string): void {
            claimsCascoExtraDetailsUrl.value = url;
        }

        function titleTransformerCallback(item: InputOption): string {
            return item.name.split(TextSeparator)[0];
        }

        function descriptionTransformerCallback(item: InputOption): string {
            const objectNameSplit: string = item.name.split(TextSeparator)[1];
            const objectDescriptionData: string[] = objectNameSplit.split(' ');
            objectDescriptionData!.pop();
            return [
                objectDescriptionData.join(' '),
                OneDate.short((item.custom as DynamicDictionary).validTo),
            ].join(' ');
        }

        function onTodayClick(): void {
            form.field(CascoClaimsPanelsNames.WhenEventHappened).patch({
                    startDate: moment().toDate(),
                    endDate: ''
                }
            );
            onSelectDay();
        }

        function onYesterdayClick(): void {
            form.field(CascoClaimsPanelsNames.WhenEventHappened).patch(
                {
                    startDate: moment().subtract(1, 'days').toDate(),
                    endDate: ''
                }
            );
            onSelectDay();
        }

        function onSelectDay(): void {
            onFormPanelInputChange(CascoClaimsPanelsNames.WhenEventHappened);
            onProceed(CascoClaimsPanelsNames.WhenEventHappened, false, false);
        }

        function onFinderReceive(propertyObject: DynamicDictionary): void {
            if (!objectAlreadyStored(propertyObject.id)) {
                cascoObjectResponse.value.push(propertyObject);
            }
            onFormPanelInputChange(CascoClaimsPanelsNames.WhatVehicleWasInvolved);
        }

        function onInsuredReceiveError(): void {
            canClearFormsAhead.value = true;
            onFormPanelInputChange(CascoClaimsPanelsNames.WhatTimeDidItHappened);
        }

        function initialValuesCouldBeFetched(params: DynamicDictionary): boolean {
            return params['when-event-happened'] !== '';
        }

        function transformAndApplyValueOnOptions(field: string): void {
            let resultValue: LimitedVariant;
            const value: LimitedVariant = form.field(field).value;
            switch (field) {
                case CascoClaimsPanelsNames.WhenEventHappened:
                    resultValue = (moment(new Date((value as DateRange).startDate)).format('YYYY-MM-DD HH:mm:ss'));
                    break;

                default:
                    resultValue = value;
            }
            applyValueOnOption(field, resultValue);
        }

        function formHasValue(field: string): boolean {
            let result: boolean;
            switch (field) {
                case CascoClaimsPanelsNames.WhatVehicleWasInvolved:
                    result = !form.field(field).isEmpty() &&
                        form.field(field).value.object !== '';
                    break;

                case CascoClaimsPanelsNames.SelectDamageType:
                case CascoClaimsPanelsNames.DamageSubtype:
                    result = isSet(form.field(field).value.selected) && form.field(field).value.selected !== '';
                    break;

                case CascoClaimsPanelsNames.CountryOfAccident:
                    result = !form.field(CascoClaimsPanelsNames.CountryOfAccident).isEmpty() &&
                        form.field(CascoClaimsPanelsNames.RepairServiceDetails).value !== '';
                    break;

                case CascoClaimsPanelsNames.HowAccidentIsReported:
                    result = howAccidentIsReportedIsValid();
                    break;

                case CascoClaimsPanelsNames.ReportTheAccident:
                    result = true;
                    break;

                default:
                    result = !form.field(field).isEmpty();
            }

            return result;
        }

        function prepareOptions(field: string): Promise<void> {
            let result: Promise<void>;
            switch (field) {
                case CascoClaimsPanelsNames.WhatVehicleWasInvolved:
                    result = findInsuredObjects();
                    break;

                case CascoClaimsPanelsNames.SelectDamageType:
                    result = findDamageType();
                    break;

                case CascoClaimsPanelsNames.DamageSubtype:
                    result = fetchWhatHappened();
                    break;

                case CascoClaimsPanelsNames.ReportTheAccident:
                    result = fetchReportTheAccident();
                    break;

                case CascoClaimsPanelsNames.DescriptionOfAccident:
                    result = fetchDescribeAccident();
                    break;

                case CascoClaimsPanelsNames.HowAccidentIsReported:
                    result = fetchHowAccidentIsReported();
                    break;

                default:
                    result = buildOptions(field, []);
            }

            return result;
        }

        function touchAndRequireNecessaryField(field: string): void {
            resetFeedbackMessage();
            switch (field) {
                case CascoClaimsPanelsNames.WhatTimeDidItHappened:
                    form.field(field).addValidators({
                        isValidTime: () => {
                            const inputTime: string = form.field(CascoClaimsPanelsNames.WhatTimeDidItHappened).value;
                            let result: boolean = true;
                            if (isTodaySelected() && !isFutureTime() && isTimeInputEmpty(inputTime)) {
                                applyCustomFeedbackMessage('input_error_future_time');
                                result = false;
                            }
                            if (!isValidHours(inputTime) || !isValidMinutes(inputTime)) {
                                applyCustomFeedbackMessage('input_error_max_value');
                                result = false;
                            }

                            return result;
                        }
                    }).touch();
                    form.field(field).addValidators('time');
                    break;

                case CascoClaimsPanelsNames.SelectDamageType:
                case CascoClaimsPanelsNames.DamageSubtype:
                    form.field(field).addValidators({
                        notEmpty: () => {
                            return isSet(form.field(field).value.selected) &&
                                form.field(field).value.selected !== '';
                        }
                    }).touch();
                    break;

                case CascoClaimsPanelsNames.HowAccidentIsReported:
                    form.field(field).addValidators({
                        notEmpty: () => {
                            return howAccidentIsReportedIsValid();
                        }
                    }).touch();
                    break;

                case CascoClaimsPanelsNames.ReportTheAccident:
                    break;

                default:
                    form.field(field).addValidators({
                        notEmpty: () => {
                            return !form.field(field).isEmpty();
                        }
                    }).touch();
            }
        }

        function resetObjectsIfHasErrors(data: DynamicDictionary[]): void {
            if (data.length > 0 && isSet(data[0].error)) {
                onFormPanelInputChange(CascoClaimsPanelsNames.WhatVehicleWasInvolved);
            }
        }

        function howAccidentIsReportedIsValid(): boolean {
            let result: boolean = false;
            const value: DynamicDictionary = form.field(CascoClaimsPanelsNames.HowAccidentIsReported).value;
            const hasValue: boolean = isSet(value.selected) && value.selected !== '';
            if (hasValue) {
                result = value.selected.includes('other') ? value.customText.other !== '' : true;
            }

            return result;
        }

        function buildOptions(field: string, data: DynamicDictionary[]): Promise<void> {
            switch (field) {
                case CascoClaimsPanelsNames.WhatVehicleWasInvolved:
                    buildInsuredObjects(data);
                    break;

                case CascoClaimsPanelsNames.SelectDamageType:
                    buildDamageTypes(data);
                    break;

                case CascoClaimsPanelsNames.DamageSubtype:
                    buildWhatHappened(data);
                    break;

                case CascoClaimsPanelsNames.ReportTheAccident:
                    buildReportTheAccident(data);
                    break;

                case CascoClaimsPanelsNames.DescriptionOfAccident:
                    buildDescribeAccident(data);
                    break;

                case CascoClaimsPanelsNames.HowAccidentIsReported:
                    buildHowAccidentIsReported(data);
                    break;

                default:
                    buildStaticOptions();
                    applyPredefinedValues();
            }
            calculateStepperVisibility();

            return Promise.resolve();
        }

        function buildStaticOptions(): void {
            buildDidYouDamageThirdParty();
        }

        function applyPredefinedValues(): void {
            applyValueOnOption(CascoClaimsPanelsNames.ReportTheAccident, true);
        }

        function findInsuredObjects(): Promise<void> {
            isSearchInProgress.value = true;
            const params: Record<string, number | string> = {
                eventDate: value(CascoClaimsPanelsNames.WhenEventHappened) as string
            };
            return useAxios().get(Url.Ajax.claimsCascoObjects, {params})
                .then((response: AxiosResponse<DynamicDictionary>) => {
                    if (validResponse(response)) {
                        return buildOptions(CascoClaimsPanelsNames.WhatVehicleWasInvolved,
                            response.data.data.body.objects);
                    } else {
                        onInsuredReceiveError();
                    }
                })
                .catch((reason: LimitedVariant): void => {
                    onInsuredReceiveError();
                    btaBase.error.show(ErrorType.Error, 'accident_data::findInsuredObjects', reason as DynamicDictionary);
                })
                .finally((): void => {
                    isSearchInProgress.value = false;
                });
        }

        function setupForm(): void {
            Object.keys(inputOptions).forEach((field: string): void => {
                form.addField(new FormField(field));
            });
            additionalFormFields.value.forEach((field: string): void => {
                form.addField(new FormField(field));
            });
            form.setReady();
        }

        function buildInsuredObjects(data: DynamicDictionary[]): void {
            cascoObjectResponse.value = data;
            insuredObjects.value = [];
            if (data.length > 0 && !isSet(data[0].error)) {
                data.forEach((object: DynamicDictionary): void => {
                    insuredObjects.value.push(
                        (new InputOptionBuilder)
                            .setValue(object.id)
                            .setName(object.name)
                            .setCustom({
                                type: object.objectType,
                                policy: object.policyNumber,
                                insuredPersonIdentityNumber: object.insuredPersonIdentityNumber,
                                validFrom: object.validFrom,
                                validTo: object.validTo
                            })
                            .build()
                    )
                });
            }
        }

        function findDamageType(): Promise<void> {
            const params: DynamicDictionary = {
                risks: risks.value
            };
            PopupService.getInstance().show(new OnePopup().withType().loading);
            return useAxios().get(Url.Ajax.claimsCascoAvailableDamageTypes, {params})
                .then((response: AxiosResponse<DynamicDictionary>) => {
                    if (validResponse(response)) {
                        return buildOptions(CascoClaimsPanelsNames.SelectDamageType, response.data.data.body.risks);
                    } else {
                        btaBase.error.show(ErrorType.Error, 'findDamageType', 'claims_casco_invalid_data');
                    }
                })
                .catch((reason: LimitedVariant): void => {
                    btaBase.error.show(ErrorType.Error, 'findDamageType', reason as DynamicDictionary);
                })
                .finally((): void => {
                    PopupService.getInstance().hide();
                });
        }

        function buildDamageTypes(damageTypes: DynamicDictionary[]): void {
            const inputOptions: InputOption[] = [];
            damageTypes.forEach((object: DynamicDictionary): void => {
                inputOptions.push(
                    (new InputOptionBuilder)
                        .setCustom(damageTypeCustom(object))
                        .setValue(object.riskIc as string)
                        .setName(translateForType(object.riskIc as string, Translations.getInstance().type))
                        .build()
                )
            });
            applyOptionsOnOption(CascoClaimsPanelsNames.SelectDamageType, inputOptions);
        }

        function damageTypeCustom(risk: DynamicDictionary): OptionsSmartCustom {
            const riskIsCovered: boolean = risk.isFoundInPolicy;
            const selectionWarning: string = translate('risk_not_covered_by_the_selected_policy');
            const selectionIsValid: boolean = riskIsCovered;

            return new class implements OptionsSmartCustom {
                public type: OptionsSmartTypes = selectionIsValid ?
                    OptionsSmartTypes.Regular :
                    OptionsSmartTypes.Info;
                public initialValue: string = selectionWarning;
                public selectState: OptionsSmartCustomSelectState
                    = new class implements OptionsSmartCustomSelectState {
                    public borderColor?: OptionSmartColor | undefined
                        = selectionIsValid ? OptionSmartColor.Green : OptionSmartColor.Red;
                    public isInvalid: boolean = !selectionIsValid;
                };
            };
        }

        function fetchHowAccidentIsReported(): Promise<void> {
            const params: DynamicDictionary = damages();
            PopupService.getInstance().show(new OnePopup().withType().loading);
            return useAxios().get(Url.Ajax.claimsCascoReportedTo, {params})
                .then((response: AxiosResponse<DynamicDictionary>) => {
                    if (validResponse(response)) {
                        return buildOptions(CascoClaimsPanelsNames.HowAccidentIsReported, response.data.data.body);
                    } else {
                        btaBase.error.show(ErrorType.Error, 'fetchHowAccidentIsReported', 'claims_casco_invalid_data');
                    }
                })
                .catch((reason: LimitedVariant): void => {
                    btaBase.error.show(ErrorType.Error, 'fetchHowAccidentIsReported', reason as DynamicDictionary);
                })
                .finally((): void => {
                    PopupService.getInstance().hide();
                });
        }

        function buildHowAccidentIsReported(data: DynamicDictionary): void {
            const prefix: string = 'claims_casco_';
            const inputOptions: InputOption[] = [];
            let options: string[] = data.reportTo;
            if (options.includes(CascoClaimsMandatoryReport.Other)) {
                options = options.filter(option => option !== CascoClaimsMandatoryReport.Other);
                options.push(CascoClaimsMandatoryReport.Other);
            }
            options.forEach((text: string): void => {
                const value: string = String(text).toLowerCase();
                const custom: boolean = value === CascoClaimsMandatoryReport.Other;
                inputOptions.push(
                    (new InputOptionBuilder())
                        .setName(translateForType(String(prefix + text).toLowerCase(),
                            Translations.getInstance().type))
                        .setValue(value)
                        .setCustom(custom)
                        .build()
                );
            });
            applyOptionsOnOption(CascoClaimsPanelsNames.HowAccidentIsReported, inputOptions);
        }

        function buildDidYouDamageThirdParty(): void {
            const inputOptions: InputOption[] = [
                (new InputOptionBuilder)
                    .setValue('yes')
                    .setName(translate('btar_yes'))
                    .build(),
                (new InputOptionBuilder)
                    .setValue('no')
                    .setName(translate('btar_no'))
                    .build(),
            ];
            applyOptionsOnOption(CascoClaimsPanelsNames.DidYouDamageThirdPartyProperty, inputOptions);
        }

        function fetchWhatHappened(): Promise<void> {
            const params: DynamicDictionary = {
                risks: risks.value,
                damageType: form.field(CascoClaimsPanelsNames.SelectDamageType).value.selected
            };
            PopupService.getInstance().show(new OnePopup().withType().loading);
            return useAxios().get(Url.Ajax.claimsCascoWhatHappened, {params})
                .then((response: AxiosResponse<DynamicDictionary>) => {
                    if (validResponse(response)) {
                        return buildOptions(CascoClaimsPanelsNames.DamageSubtype, response.data.data.body);
                    } else {
                        btaBase.error.show(ErrorType.Error, 'fetchWhatHappened', 'claims_casco_invalid_data');
                    }
                })
                .catch((reason: LimitedVariant): void => {
                    btaBase.error.show(ErrorType.Error, 'fetchWhatHappened', reason as DynamicDictionary);
                })
                .finally((): void => {
                    PopupService.getInstance().hide();
                });
        }

        function buildWhatHappened(whatHappened: DynamicDictionary): void {
            const inputOptions: InputOption[] = [];
            whatHappened.happened.forEach((object: LimitedVariant): void => {
                inputOptions.push(
                    (new InputOptionBuilder)
                        .setValue(object as string)
                        .setName(translateForType(object as string, Translations.getInstance().type))
                        .build()
                )
            });
            applyOptionsOnOption(CascoClaimsPanelsNames.DamageSubtype, inputOptions);
        }

        function fetchReportTheAccident(): Promise<void> {
            const params: DynamicDictionary = damages();
            PopupService.getInstance().show(new OnePopup().withType().loading);
            return useAxios().get(Url.Ajax.claimsCascoReportTheAccident, {params})
                .then((response: AxiosResponse<DynamicDictionary>) => {
                    if (validResponse(response)) {
                        return buildOptions(CascoClaimsPanelsNames.ReportTheAccident, response.data.data.body);
                    } else {
                        btaBase.error.show(ErrorType.Error, 'fetchReportTheAccident', 'claims_casco_invalid_data');
                    }
                })
                .catch((reason: LimitedVariant): void => {
                    btaBase.error.show(ErrorType.Error, 'fetchReportTheAccident', reason as DynamicDictionary);
                })
                .finally((): void => {
                    PopupService.getInstance().hide();
                });
        }

        function buildReportTheAccident(data: DynamicDictionary): void {
            reportTheAccidentInfoText.value = data.report;
        }

        function fetchDescribeAccident(): Promise<void> {
            const params: DynamicDictionary = damages();
            PopupService.getInstance().show(new OnePopup().withType().loading);
            return useAxios().get(Url.Ajax.claimsCascoDescriptionOfAccident, {params})
                .then((response: AxiosResponse<DynamicDictionary>) => {
                    if (validResponse(response)) {
                        return buildOptions(CascoClaimsPanelsNames.DescriptionOfAccident, response.data.data.body);
                    } else {
                        btaBase.error.show(ErrorType.Error, 'fetchDescribeAccident', 'claims_casco_invalid_data');
                    }
                })
                .catch((reason: LimitedVariant) => {
                    btaBase.error.show(ErrorType.Error, 'fetchDescribeAccident', reason as DynamicDictionary);
                })
                .finally(() => {
                    PopupService.getInstance().hide();
                });
        }

        function damages(): DynamicDictionary {
            return {
                damageType: damageValue(CascoClaimsPanelsNames.SelectDamageType),
                damageSubType: damageValue(CascoClaimsPanelsNames.DamageSubtype) ??
                    damageValue(CascoClaimsPanelsNames.SelectDamageType)
            };
        }

        function damageValue(damagePanel: string): string {
            const damageType: DynamicDictionary = value(damagePanel) as DynamicDictionary;

            return isSet(damageType.selected) ?
                damageType.selected : singleAutoSubmitOptions.value[damagePanel]?.selected;
        }

        function buildDescribeAccident(data: DynamicDictionary): void {
            describeAccidentHtml.value = data.describe;
        }

        function objectAlreadyStored(objectId: string): boolean {
            return cascoObjectResponse.value.some((object: DynamicDictionary): boolean => object.id === objectId);
        }

        function resetFeedbackMessage(): void {
            customFeedbackMessage.value = '';
        }

        function isFutureTime(): boolean {
            return moment(form
                .field(CascoClaimsPanelsNames.WhatTimeDidItHappened).value, TimeFormat)
                .isSameOrBefore(moment(), 'minute');
        }

        function isTimeInputEmpty(time: string): boolean {
            const result: string[] = time.split(':');

            return time !== ':' && result[0] !== '';
        }

        function applyCustomFeedbackMessage(errorId: string): void {
            customFeedbackMessage.value = translateForType(errorId, 'errors');
        }

        function isTodaySelected(): boolean {
            return !moment(new Date(form.field(CascoClaimsPanelsNames.WhenEventHappened).value.startDate))
                .isBefore(moment(), 'day');
        }

        function isValidHours(time: string): boolean {
            const result: string[] = time.split(':');
            const maxHours: number = 23;
            const hours: number = parseInt(result[0], 10);

            return isNaN(hours) || (hours <= maxHours);
        }

        function isValidMinutes(time: string): boolean {
            const result: string[] = time.split(':');
            const maxMinutes: number = 59;
            const minutes: number = parseInt(result[1], 10);

            return isNaN(minutes) || (minutes <= maxMinutes);
        }

        function howAccidentIsReportedOptionFetch(field: string): void {
            disableOptions(field, isAccidentNotReported(field));
        }

        function disableOptions(field: string, isAccidentNotReported: boolean): void {
            const disableOption: boolean = isAccidentNotReported;
            inputOptions[field].options.forEach((option: DynamicDictionary): void => {
                if (option.value !== CascoClaimsMandatoryReport.NotReported) {
                    option.disabled = disableOption;
                }
            });
            if (isAccidentNotReported) {
                form.field(field).value.selected = CascoClaimsMandatoryReport.NotReported;
                form.field(field).value.customText.other = '';
            }
        }

        function isAccidentNotReported(field: string): boolean {
            return form.field(field).value.selected.includes(CascoClaimsMandatoryReport.NotReported);
        }

        function onSubmitStep(): void {
            btaBase.captcha.executeCaptcha(submit).then().catch((reason: string) => {
                Error.log(ErrorType.Error, 'onSubmitStep', reason, true);
            });
        }

        function submit(): void {
            prepareSubmit();
            stepsSubmitter.proceedStep('', 0);
        }

        function applyStepUrls(next: string, previous: string): void {
            stepsSubmitter.applyStepUrls(next, previous);
        }

        function prepareSubmit(): void {
            baseSubmit();
            const report: boolean = mandatoryReportInfoText.value.length === 0;
            stepsSubmitter.addSubmitCustomParam(CascoClaimsPanelsNames.ReportTheAccident, report);
        }

        function onAppReady(): void {
            accidentCountryData();
            updateInitialPanelsVisibility();
            const params: DynamicDictionary = visibilityRequestParams(true);
            if (initialValuesCouldBeFetched(params)) {
                initialFindPanelsData(params);
            } else {
                canClearFormsAhead.value = true;
            }
            loadStolenCarText();
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                customFeedbackMessage,
                inputOptions,
                cascoClaimsMandatoryReport,
                objectFetchUrl,
                calendarMinDate,
                calendarMaxDate,
                mandatoryReport,
                describeAccidentContent,
                feedbackText,
                selectDate,
                reportTheAccidentText,
                showStolenCar,
                translatedStolenCarText,
                translatedStolenCarWhatToDo,
                canClearFormsAhead,
                isSearchInProgress,
                stolenCarText,
                stolenCarWhatToDo,
                cascoClaimsPanelsNames,
                servicesCount,
                insuredObjects,
                descriptionTransformerCallback,
                titleTransformerCallback,
                onRedirect,
                applyExtraDetailsUrl,
                onTodayClick,
                onYesterdayClick,
                onSelectDay,
                onFinderReceive,
                onInsuredReceiveError,
                onFormPanelInputChange,
                transformAndApplyValueOnOptions,
                formHasValue,
                touchAndRequireNecessaryField,
                prepareOptions,
                buildInitialOptions,
                setupForm,
                applyStepUrls,
                onSubmitStep,
                submit,
                prepareSubmit,
                onAppReady,
                applyEnabledOnOption,
                applyVisibleOnOption,
                applyStolenCarInfo,
                proceedButtonIsVisible,
                onProceed,
                proceedDescriptionOfAccidentButtonIsDisabled,
                lastVisiblePanel,
                isVisiblePanel,
                clearFormsAhead,
                value,
                hasSkippedRepairVehicleAbroadPanel,
                proceedButtonIsDisabled,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();
        Translations.getInstance().addType('claims')
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.onAppReady();
            this.applyEnabledOnOption(CascoClaimsPanelsNames.WhenEventHappened, true);
            this.applyVisibleOnOption(CascoClaimsPanelsNames.WhenEventHappened, true);
            onAppIsPreparedAndReady.unsubscribe();
        });
        this.dynamicStepper.enableAll();
    }
});
</script>
