<script setup lang="ts">
import VueTimepicker from 'vue3-timepicker';
import {computed, ComputedRef, onMounted, PropType, ref, Ref, watch} from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import {useTranslate} from '@/Composables/Translate';

const props = defineProps({
    format: {type: String, default: 'HH:mm'},
    minTime: {type: Object, default: () => ({hours: 0, minutes: 0})},
    maxTime: {type: Object, default: () => ({hours: 23, minutes: 59})},
    hourRange: {type: Array, default: () => [[1, 24]]},
    minuteRange: {type: Array, default: () => [[0, 59]]},
    minutesStep: {type: Number, default: 1},
    formField: {type: Object as PropType<FormField<string>>, default: () => new FormField('')},
    dataStoreDisabled: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    label: {type: String, default: ''},
    required: {type: Boolean, default: false},
});

const emit = defineEmits(['change']);

const {translate} = useTranslate();

const tempSelected: Ref<string> = ref('');

const fieldId: ComputedRef<string> = computed(() => {
    return props.formField.name + '-dropdown';
});

const shouldValidate: ComputedRef<boolean> = computed(() => {
    return props.formField.isTouched && !props.formField.isEmpty();
});


watch(() => props.formField.value, (value: string, previousValue: string) => {
    if (value !== previousValue) {
        select(value);
    }
}, {deep: true});

onMounted((): void => {
    addTimeValidators();
    props.formField.onClear.subscribe((): void => {
        let timePickerClearButton: Element | null = document.querySelector('#' + props.formField.name + ' .clear-btn');
        if (timePickerClearButton) {
            timePickerClearButton.dispatchEvent(new Event('click'))
        }
    });
});

function timepickerChange(eventData: { data: any, displayTime: string }): void {
    tempSelected.value = eventData.displayTime;
}

function timepickerClose(): void {
    select(tempSelected.value);
    props.formField.touch();
}

function select(time: string): void {
    props.formField.patch(time);
    props.formField.validate();
    emit('change', props.formField.value);
}

function addTimeValidators(): void {
    addValidTimeValidator();
    addMinTimeValidator();
    addMaxTimeValidator();
}

function addValidTimeValidator(): void {
    props.formField.addValidators({
        validTime: () => {
            let returnValue = true;
            let time: number[] = props.formField.value.split(':');
            if (isNaN(time[0]) || isNaN(time[1])) {
                returnValue = false;
            }

            return shouldValidate.value ? returnValue : true;
        },
    });
}

function addMinTimeValidator(): void {
    props.formField.addValidators({
        'minTime': () => {
            let time: number[] = props.formField.value.split(':');
            let returnValue = true;
            if (time[0] === props.minTime.hours && time[1] < props.minTime.minutes) {
                returnValue = false;
            } else if (time[0] > props.minTime.hours) {
                returnValue = true;
            } else if (time[0] < props.minTime.hours) {
                returnValue = false;
            }

            return shouldValidate.value ? returnValue : true;
        },
    });
}

function addMaxTimeValidator(): void {
    props.formField.addValidators({
        'maxTime': () => {
            let time: number[] = props.formField.value.split(':');
            let returnValue = true;
            if (time[0] === props.maxTime.hours && time[1] > props.maxTime.minutes) {
                returnValue = false;
            } else if (time[0] < props.maxTime.hours) {
                returnValue = true;
            } else if (time[0] > props.maxTime.hours) {
                returnValue = false;
            }

            return shouldValidate.value ? returnValue : true;
        },
    });
}
</script>
<template>
    <div class="input time input-time-with-dropdown"
         :id="formField.name"
         :class="{...formField.classes(), 'disabled': disabled}"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : formField.value">
        <div v-if="label" class="label">
            <label :for="fieldId">{{ label }}<span v-if="required">*</span></label>
        </div>
        <div class="wrapper">
            <vue-timepicker
                :id="fieldId"
                ref="timepickerComponent"
                format="HH:mm"
                close-on-complete
                hide-disabled-items
                fixed-dropdown-button
                auto-scroll
                v-model="formField.value"
                :minutes-step="minutesStep"
                :hour-range="hourRange"
                :minute-range="minuteRange"
                @change="timepickerChange"
                @close="timepickerClose">
                <template v-slot:dropdownButton>
                    <img src="/images/bta-portals-28.png"
                         :alt="translate('component_input_time_with_dropdown_img_alt')"/>
                </template>
            </vue-timepicker>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.input-time {
    .wrapper {
        border: 1px solid var(--component-color-border-default);
        border-radius: 3px;
        width: 130px;
    }

    .wrapper:hover,
    .wrapper:focus {
        border-color: var(--component-color-border-focus);
    }
}
</style>
