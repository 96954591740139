import {nextTick} from 'vue';

export default class {
    private breakPointSlides: number = 812;
    private readonly slides: number[] = []
    private container: string = '.color-boxes .carousel';
    private initializedMarkerClass: string = '.slick-initialized';
    private slickPrev: string = '.slick-prev';
    private slickNext: string = '.slick-next';
    private slickControl: string = '.color-boxes .control .block, ' + this.slickPrev + ', ' + this.slickNext;
    private readonly arrowBack: string = '<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 1L1 7L7 13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
    private readonly arrowForward: string = '<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7 7L1 13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

    public constructor() {
        const lastSlide: number = 2;
        this.slides = [1, lastSlide];
    }

    public init(): void {
        const slickElementDom: any = $(this.container);
        const initialized: JQuery = $(this.initializedMarkerClass);
        if (slickElementDom && initialized.length === 0) {
            const slickDom: any = (slickElementDom).slick({
                infinite: false,
                slidesToShow: this.slides[1],
                slidesToScroll: this.slidesCount(this.slides[0], this.slides[1]),
                prevArrow: '<button type="button" class="slick-prev">' + this.arrowBack + '</button>',
                nextArrow: '<button type="button" class="slick-next">' + this.arrowForward + '</button>',
            });
            if (this.windowWidth() <= this.breakPointSlides && slickDom[0]?.slick.slideCount > 1) {
                slickElementDom.slick('slickAdd', '<div class="item clear"></div>');
            } else if (slickDom[0]?.slick.slideCount === 1) {
                slickElementDom.find('.slick-slide').addClass('slick-alone');
                slickElementDom.find('.slick-track').addClass('track-alone');
            }
            nextTick(() => {
                if (slickDom.length >= 1 && slickDom[0]?.slick.slideCount <= this.slides[1]) {
                    $(this.slickControl).hide();
                }
            });
        }
    }

    private slidesCount(breakpointSm: number, breakpointLg: number): number {
        if (this.windowWidth() <= this.breakPointSlides) {
            return breakpointSm;
        }

        return breakpointLg;
    }

    private windowWidth(): number {
        const widthElementDom: number | undefined = $(window).width();
        if (!widthElementDom || typeof widthElementDom === "undefined") {
            return 0;
        }

        return widthElementDom;
    }
}
