import {
    createRouter,
    createWebHistory, NavigationFailure,
    RouteLocationNormalized, RouteLocationNormalizedLoaded,
    Router as VueRouter,
    RouteRecordRaw
} from 'vue-router'
import {App, nextTick} from 'vue';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';

export default class Router {
    constructor(private app: App, private backendRoutes: BackendRoute[]) {
    }

    public create(): VueRouter {
        const router: VueRouter = createRouter({
            history: createWebHistory(),
            routes: this.routes(this.backendRoutes),
        });
        router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, failure:NavigationFailure | void): void => {
            nextTick((): void => {
                if (failure === undefined && to.meta?.title) {
                    document.title = to.meta.title as string;
                }
            }).then();
        });
        return router;
    }

    private routes(routes: BackendRoute[]): RouteRecordRaw[] {
        return routes.map((route: BackendRoute): RouteRecordRaw => {
            return {
                name: route.name,
                path: route.path,
                meta: {
                    title: route.title,
                    stepLabel: route.stepLabel,
                },
                component: this.app.component(route.component),
                children: [],
            }
        });
    }
}
