import {computed, reactive, ref, Ref, isRef, UnwrapNestedRefs} from 'vue';
import SpaFormStorageService from '@/services/SpaFormStorageService';
import ClaimsMtplObject from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplObjectInterface';
import ClaimsMtplFormFields from '@/Apps/ClaimsMtpl/Classes/ClaimsMtplFormFields';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import DamageTypes from '@/Apps/ClaimsMtpl/Enums/DamageTypesEnum';
import {useDefine} from '@/Composables/Define';
import ClaimsMtplClaimTypes from '@/Apps/ClaimsMtpl/Enums/ClaimsMtplClaimTypesEnum';
import ValidRoutes from '@/services/ValidRoutesService';

export default class ClaimsMtplService {
    public fields!: UnwrapNestedRefs<ClaimsMtplFormFields>;
    public initialStepUrl: string = '';
    public thankYouStepUrl: string = '';
    public claimTypes: string[] = [];
    public damageTypeFilterData: DynamicDictionary[] = [];
    public damageStepSubFlows: UnwrapNestedRefs<DynamicDictionary> = reactive({
        [DamageTypes.Vehicle]: {visible: false, result: null, editing: false},
        [DamageTypes.Belongings]: {visible: false, result: null, editing: false},
        [DamageTypes.Property]: {visible: false, result: null, editing: false},
        [DamageTypes.Person]: {visible: false, result: null, editing: false}
    });
    public damageStepLastVisiblePanel: Ref<string> = ref('');
    public vehicleRegistryData: Ref<DynamicDictionary | null> = ref(null);
    public insuredObjects: Ref<ClaimsMtplObject[]> = ref([]);
    public electronicStatement: Ref<DynamicDictionary | null> = ref(null);
    public additionalFormStorageFields: Ref<DynamicDictionary> = computed(() => {
        return {
            damageStepSubFlows: this.damageStepSubFlows,
            damageStepLastVisiblePanel: this.damageStepLastVisiblePanel.value,
            insuredObjects: this.insuredObjects.value,
            electronicStatement: this.electronicStatement.value,
        };
    });

    private static instance: ClaimsMtplService;
    private spaFormStorageService: SpaFormStorageService = SpaFormStorageService.getInstance();

    public static getInstance(): ClaimsMtplService {
        if (!ClaimsMtplService.instance) {
            ClaimsMtplService.instance = new ClaimsMtplService();
        }

        return ClaimsMtplService.instance;
    }

    public init(): void {
        const storageFields: DynamicDictionary = this.spaFormStorageService.formStorageFields();
        Object.keys(this.additionalFormStorageFields.value)
            .filter((field: string): boolean => storageFields[field])
            .forEach((field: string): void => {
                isRef(this[field as keyof ClaimsMtplService])
                    ? (this[field as keyof ClaimsMtplService] as Ref).value = storageFields[field]
                    : this[field as keyof ClaimsMtplService] = reactive(storageFields[field]);
                delete storageFields[field];
            });
        this.fields = storageFields;
        this.spaFormStorageService.applyDataProviderCallback((): DynamicDictionary => {
            return {
                ...this.fields,
                ...this.additionalFormStorageFields.value,
                ...ValidRoutes.getInstance().routes(),
                ...{insuredObjects: this.insuredObjects.value},
                ...{electronicStatement: this.electronicStatement.value},
            };
        });
        if (useDefine().isSet(storageFields.routes) && storageFields.routes.length > 0) {
            const claimsValidRoutes: ValidRoutes = ValidRoutes.getInstance();
            storageFields.routes.forEach((routeValidity: boolean, index: number): void => {
                claimsValidRoutes.applyRoute(index, routeValidity);
            });
        }
    }

    public clearFormsAhead(step: number): void {
        const fromFieldsList: string[] = [
            'whatWasDamaged',
            'whereAccidentHappened',
            'typeOfCompensation',
            'filesUploaded',
            'person',
            'contactEmail',
        ];
        const fromForm: string = fromFieldsList[step];
        let clear: boolean = false;
        Object.keys(new ClaimsMtplFormFields()).forEach((field: string): void => {
            if (fromForm == field) {
                clear = true;
            }
            if (clear) {
                if (field === 'whatWasDamaged') {
                    this.updateDamageStepData();
                }
                if (this.fields) {
                    (this.fields as DynamicDictionary)[field as keyof ClaimsMtplFormFields] = '';
                }
            }
        });
    }

    public isDamagePageVisible(): boolean {
        const foundElectronicallyField: DynamicDictionary = this.fields.weFoundElectronically!;

        return !(useDefine().isSet(foundElectronicallyField.selected) &&
            foundElectronicallyField.selected === 'yes');
    }

    public isExtraDetailsPageVisible(): boolean {
        return this.selectedDamageTypes().includes(DamageTypes.Vehicle) &&
            this.fields.typeOfClaim!.selected !== ClaimsMtplClaimTypes.Guilty;
    }

    public isIndemnityReceiverPageVisible(): boolean {
        return (this.isExtraDetailsPageVisible() && this.fields.typeOfCompensation!.selected !== 'service') ||
            this.selectedDamageTypes().length > 1;
    }

    public lockFormStorage(): void {
        this.spaFormStorageService.lockStorage();
    }

    public unlockFormStorage(): void {
        this.spaFormStorageService.unlockStorage();
    }

    private updateDamageStepData(): void {
        this.clearSubFlows();
        this.clearLastPanel();
    }

    private clearSubFlows(): void {
        Object.keys(this.damageStepSubFlows).forEach((subFlow: string): void => {
            this.damageStepSubFlows[subFlow].visible = false;
            this.damageStepSubFlows[subFlow].editing = false;
            this.damageStepSubFlows[subFlow].result = null;
        });
    }

    private clearLastPanel(): void {
        this.damageStepLastVisiblePanel.value = '';
    }

    private selectedDamageTypes(): string[] {
        const damageTypesField: DynamicDictionary = this.fields.whatWasDamaged!;

        return useDefine().isSet(damageTypesField.selected) ?
            damageTypesField.selected.split('@#@') :
            [];
    }
}
