import {
    Router,
    RouteLocationNormalizedLoaded,
    NavigationGuardNext,
    RouteLocationNormalized,
    RouteRecord,
    useRoute,
    useRouter,
} from 'vue-router';
import {nextTick} from 'vue';
import ValidRoutes from '@/services/ValidRoutesService';
import {useNavigate} from '@/Composables/Navigate';
import SpaFormStorageService from '@/services/SpaFormStorageService';

export default class GuardsService {
    private static instance: GuardsService;
    private route: RouteLocationNormalizedLoaded = useRoute();
    private router: Router = useRouter();
    private initialStepUrl: string = '';
    private spaFormStorageService: SpaFormStorageService = SpaFormStorageService.getInstance();

    public static getInstance(): GuardsService {
        if (!GuardsService.instance) {
            GuardsService.instance = new GuardsService();
            GuardsService.instance.prepareRoutes();
        }
        return GuardsService.instance;
    }

    public init(): void {
        this.guardRouteDirectAccess();
        this.router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext): void => {
            const routeIndex: number = this.routeIndexByName(to.name as string);
            let hasInvalidRoutes: boolean = false;
            GuardsService.instance.router.getRoutes().forEach((route: RouteRecord, index: number): void => {
                if (index < routeIndex && !ValidRoutes.getInstance().routeIsValid(index)) {
                    hasInvalidRoutes = true;
                }
            });
            if (hasInvalidRoutes) {
                next(false);
            } else {
                next();
            }
        });
        this.router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded): void => {
            window.scrollTo(0, 0);
        });
    }

    public applyStepValidity(step: number, isValid: boolean, clearAhead: boolean = false): void {
        ValidRoutes.getInstance().applyRoute(step, isValid);
        if (clearAhead) {
            GuardsService.instance.router.getRoutes().forEach((route: RouteRecord, index: number): void => {
                if (index > step) {
                    ValidRoutes.getInstance().applyRoute(index, false);
                }
            });
        }
    }

    public applyInitialStepUrl(initialStepUrl: string): void {
        this.initialStepUrl = initialStepUrl;
    }

    /**
     * Supposed to protect route from opening directly entering route path in address bar.
     * Planning to get rid of this method after migrating to vue3 - replacing with beforeRouteEnter event
     * @private
     */
    private guardRouteDirectAccess(): void {
        const routeIndex = this.routeIndexByName(this.route.name as string ?? '');
        if (routeIndex > 0 && !this.previousRouteValid(routeIndex)) {
            this.router.push(this.firstPreviousValidRouteUrl(routeIndex)).then();
        }
    }

    private prepareRoutes(): void {
        GuardsService.instance.router.getRoutes().forEach((value: RouteRecord, index: number): void => {
            ValidRoutes.getInstance().applyRoute(index, false);
        });
    }

    private routeIndexByName(routeName: string): number {
        let result: number = 0;
        GuardsService.instance.router.getRoutes().forEach((route: RouteRecord, index: number): void => {
            if (route.name === routeName) {
                result = index;
            }
        });

        return result;
    }

    private previousRouteValid(currentRouteIndex: number): boolean {
        return ValidRoutes.getInstance().routes().routes[currentRouteIndex - 1];
    }

    private firstPreviousValidRouteUrl(currentRouteIndex: number): string {
        const firstPreviousValidRouteIndex: number = ValidRoutes.getInstance().routes().routes
            .slice(0, currentRouteIndex)
            .lastIndexOf(true) + 1

        return GuardsService.instance.router.getRoutes()[firstPreviousValidRouteIndex].path ?? '';
    }
}
