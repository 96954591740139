import {Subject} from 'rxjs';
import 'unorm';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import SettingsService from '@/services/settings.service';

export default class Countries {
    private static instance: Countries;
    private countries: DynamicDictionary = {};
    private popularCountries: DynamicDictionary = {};
    public ready: boolean = false;
    public onExternalDataIsReady = new Subject<void>();

    private constructor() {
    }

    public static getInstance(): Countries {
        if (!Countries.instance) {
            Countries.instance = new Countries();
        }

        return Countries.instance;
    }

    public addCountries(
        countries: DynamicDictionary,
        popularCountries: DynamicDictionary,
    ): void {
        this.countries = countries;
        this.popularCountries = popularCountries;
        this.ready = true;
        this.onExternalDataIsReady.next();
    }


    public fetchCountries(): DynamicDictionary {
        return this.countries;
    }

    public fetchCountriesWithoutCurrentCountry(): DynamicDictionary {
        let result: DynamicDictionary = [];
        const countryCodeIndex: number = 1;
        const localeIso: string = SettingsService.getInstance().localeIso();
        Object.keys(this.countries).forEach((key: string) => {
            const valueParts: string[] = String(key).split(':');
            const countryCode: string = valueParts[countryCodeIndex];
            if (countryCode !== localeIso) {
                result[key] = this.countries[key];
            }
        });

        return result;
    }

    public fetchPopularCountries(): DynamicDictionary {
        return this.popularCountries;
    }

    public static normalizedSearchCountry(term: string): string {
        return term.toLowerCase().normalize('NFKD').replace(/[\u0300-\u036F]/g, '');
    }
}
