export default {
    mounted(element: HTMLElement): void {
        if (element.childElementCount === 0) {
            element.classList.add('hidden');
        }
    },
    updated(element: HTMLElement): void {
        element.childElementCount === 0
            ? element.classList.add('hidden')
            : element.classList.remove('hidden')
    }
};
