import FormField from '@/assets/libraries/form/form-field';
import Form from '@/assets/libraries/form/form';
import PolicyValidators from '@/pages/Travel/Policy/PolicyValidators';
import {InputOption} from '@/interfaces/InputOptionInterface';

export default class TravelCustomPolicy {
    private validators: PolicyValidators;
    private form: Form = new Form();
    public medicalExpensesInputOptions: InputOption[] = [];
    public luggageInputOptions: InputOption[] = [];
    public flightInterruptionInputOptions: InputOption[] = [];
    public extraCoverageInputOptions: InputOption[] = [];

    constructor() {
        this.validators = new PolicyValidators();
    }

    public init(form: Form): void {
        this.form = form;
    }

    public setupCustomPolicyForm(): void {
        this.form.addField(
            new FormField('customMedicalExpenses', '', this.validators.customMedicalExpensesValidator()));
        this.form.addField(new FormField('customLuggage', '', this.validators.customLuggageValidator()));
        this.form.addField(
            new FormField('flightInterruption', '', this.validators.flightInterruptionValidator()));
        this.form.addField(new FormField('extraCoverage', '', this.validators.extraCoverageValidator()));
    }
}
