export enum ClaimStatus {
    //TODO change to real values when possible
    Declined = 'declined',
    Registered = 'registered',
    InProgress = 'in_progress',
    Approved = 'approved',
    DocumentsAwaited = 'documents_awaited',
    Submitted = 'submitted',
    InClaimHandling = 'in_claim_handling',
    DecisionDone = 'decision_done',
    Closed = 'closed',
}
