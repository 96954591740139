<script lang="ts">
import {computed, defineComponent, ref, Ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import Url from '@/Enums/UrlEnum';
import FormField from '@/assets/libraries/form/form-field';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useTranslate} from '@/Composables/Translate';
import {Vehicle} from '@/interfaces/resources/vehicle.interface';
import PartnerVehicle from '@/Enums/PartnerVehicleEnum';
import SettingsService from '@/services/settings.service';
import {InputOption} from '@/interfaces/InputOptionInterface';
import InputRadioIcon from '@/Components/InputRadio/Enums/InputRadioIconEnum';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import FetchParams from '@/Components/MapWithPlaces/FetchParams';
import BreakPoints from '@/Enums/BreakPointsEnum';
import {useScroll} from '@/Composables/Scroll';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();


        const {translateForType} = useTranslate();


        const CurrentStep: number = 1;
        const Facility: string = 'my-facility';
        const TranslationType: string = 'repair-services';
        const fetchUrl: string = Url.Ajax.partnerServices;


        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const showQueriedServices: Ref<boolean> = ref(false);
        const mapsAreLoading: Ref<boolean> = ref(true);
        const vehicleTypes: Ref<string[]> = ref([]);


        const disableSubmitQuery: Ref<boolean> = computed(() => {
            const requiredFields: FormField[] = form.fields();

            return showVehicleAgeCustom.value ?
                requiredFields.some((field: FormField): boolean => field.isEmpty()) :
                requiredFields.some(
                    (field: FormField): boolean => field.isEmpty() &&
                        field.name !== 'vehicle-age-full'
                );
        });

        const showVehicleAgeCustom: Ref<boolean> = computed(() => {
            return form.field('vehicle-age').value ===
                PartnerVehicle.Age.AboveThreshold;
        });

        const showMapsLoader: Ref<boolean> = computed(() => {
            return mapsAreLoading.value;
        });

        const showQueryResults: Ref<boolean> = computed(() => {
            return showQueriedServices.value;
        });

        const showQueryContainer: Ref<boolean> = computed(() => {
            return !showQueryResults.value;
        });

        const showVehicleMake: Ref<boolean> = computed(() => {
            return form.field('vehicle-type').isNotEmpty();
        });

        const showVehicleAge: Ref<boolean> = computed(() => {
            return form.field('vehicle-make').isNotEmpty();
        });

        const showVehicleDamageType: Ref<boolean> = computed(() => {
            return form.field('vehicle-age').isNotEmpty();
        });

        const vehicleTypeOptions: Ref<InputOption[]> = computed(() => {
            const inputOptions: {
                name: string, value: string, icon: string
            }[] = [];
            vehicleTypes.value.forEach((type: string): void => {
                switch (type) {
                    case PartnerVehicle.Type.Light:
                        inputOptions.push(
                            {
                                name: translation('repair_services_vehicle_type_auto'),
                                value: PartnerVehicle.Type.Light,
                                icon: InputRadioIcon.Car
                            }
                        );
                        break;
                    case PartnerVehicle.Type.Cargo:
                        inputOptions.push(
                            {
                                name: translation('repair_services_vehicle_type_cargo'),
                                value: PartnerVehicle.Type.Cargo,
                                icon: InputRadioIcon.Truck
                            }
                        );
                        break;
                    case PartnerVehicle.Type.Motorcycle:
                        inputOptions.push(
                            {
                                name: translation('repair_services_vehicle_type_moto'),
                                value: PartnerVehicle.Type.Motorcycle,
                                icon: InputRadioIcon.Motorcycle
                            }
                        );
                        break;
                    default:
                }
            });

            return inputOptions.map((option: DynamicDictionary): InputOption => {
                return (new InputOptionBuilder)
                    .setValue(option.value)
                    .setName(option.name)
                    .setIcon(option.icon)
                    .build();
            });
        });

        const vehicleTypeIconPath: Ref<string> = computed(() => {
            let icon: string = '';
            switch (form.field('vehicle-type').value) {
                case PartnerVehicle.Type.Light:
                    icon = InputRadioIcon.Car;
                    break;
                case PartnerVehicle.Type.Cargo:
                    icon = InputRadioIcon.Truck;
                    break;
                case PartnerVehicle.Type.Motorcycle:
                    icon = InputRadioIcon.Motorcycle;
                    break;
                default:
            }

            return `images/one/components/input-radio/${icon}.svg`;
        });

        const vehicleAgeOptions: Ref<InputOption[]> = computed(() => {
            const vehicleAge: number = btaBase.settings.repairServiceVehicleAge();
            const inputOptions: { name: string, value: string }[] = [
                {
                    name: translation('repair_services_car_age_from', {'%count%': vehicleAge}),
                    value: PartnerVehicle.Age.BelowThreshold
                },
                {
                    name: translation('repair_services_car_age_to', {'%count%': vehicleAge}),
                    value: PartnerVehicle.Age.AboveThreshold
                },
            ];

            return inputOptions.map((option: DynamicDictionary): InputOption => {
                return (new InputOptionBuilder)
                    .setValue(option.value)
                    .setName(option.name)
                    .build();
            });
        });

        const vehicleAgeCustomOptions: Ref<InputOption[]> = computed(() => {
            const vehicleAgeOptions: string[] = btaBase.settings.repairServiceVehicleAgeOptions();

            return vehicleAgeOptions.map((option: string): InputOption => {
                return (new InputOptionBuilder)
                    .setValue(option)
                    .setName(option)
                    .build();
            });
        });

        const vehicleDamageTypeOptions: Ref<InputOption[]> = computed(() => {
            const inputOptions: { name: string, value: string }[] = [
                {
                    name: translation('repair_services_repair_type_glass'),
                    value: PartnerVehicle.DamageType.Glass
                },
                {
                    name: translation('repair_services_repair_type_other'),
                    value: PartnerVehicle.DamageType.Other
                },
            ];

            return inputOptions.map((option: DynamicDictionary): InputOption => {
                return (new InputOptionBuilder)
                    .setValue(option.value)
                    .setName(option.name)
                    .build();
            });
        });

        const vehicleMake: Ref<string> = computed(() => {
            return capitalized(form.field('vehicle-make').value.model);
        });

        const repairServicesFetchParams: Ref<FetchParams> = computed(() => {
            return {
                vehicleTypeWeb: form.field('vehicle-type').value,
                vehicleMake: vehicleMake.value,
                isOtherVehicle: isOtherVehicle(),
                vehicleAge: vehicleAge(),
                serviceType: form.field('vehicle-damage-type').value,
            } as FetchParams;
        });

        const mapSlotDamageType: Ref<string> = computed(() => {
            return form.field('vehicle-damage-type').value === PartnerVehicle.DamageType.Glass ?
                translation('repair_services_map_slot_glass_damage') :
                translation('repair_services_map_slot_other_damage');
        });

        function setupForm(): void {
            form.addField(new FormField('vehicle-type'));
            form.addField(new FormField('vehicle-make'));
            form.addField(new FormField('vehicle-age'));
            form.addField(new FormField('vehicle-age-full'));
            form.addField(new FormField('vehicle-damage-type'));
            form.setReady();
            formIsReady.value = true;
        }

        function translation(key: string, replacements?: DynamicDictionary): string {
            return translateForType(key, TranslationType, replacements);
        }

        function applyVehicleTypes(value: string): void {
            vehicleTypes.value = JSON.parse(value);
        }

        function onVehicleAgeChange(): void {
            form.field('vehicle-age-full')
                .clear()
                .then();
        }

        function onSubmitQueryClick(): void {
            showQueriedServices.value = true;
        }

        function onBackToQueryClick(): void {
            showQueriedServices.value = false;
            mapsAreLoading.value = true;
        }

        function onMapReady(): void {
            mapsAreLoading.value = false;
        }

        function isOtherVehicle(): boolean {
            return !OneBaseService.getInstance().vehicles
                .vehicleModels()
                .some((vehicle: Vehicle): boolean => vehicle
                    .model
                    .toLowerCase() === form
                    .field('vehicle-make').value.model
                    .toLowerCase());
        }

        function vehicleAge(): number {
            return form.field('vehicle-age').value === PartnerVehicle.Age.BelowThreshold ?
                SettingsService.getInstance().repairServiceVehicleAge() :
                vehicleAgeFromDropdown();
        }

        function vehicleAgeFromDropdown(): number {
            const ageFromDropdown: string = form.field('vehicle-age-full').value as string;

            return ageFromDropdown.includes('+') ?
                Number(ageFromDropdown.slice(0, (ageFromDropdown.length - 1))) :
                Number(ageFromDropdown);
        }

        function capitalized(value: string): string {
            return value
                .split(' ')
                .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        }

        function onSuggestionsListSelect(): void {
            //TODO: fix this after vue3 migration
            const width: number = btaBase.width.value || (btaBase as any).width;
            if (width < BreakPoints.Lg) {
                useScroll().scrollToDataOffset('map-with-places', true);
            }
        }


        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                fetchUrl,
                form,
                formIsReady,
                onSuggestionsListSelect,
                disableSubmitQuery,
                showVehicleAgeCustom,
                showMapsLoader,
                showQueryResults,
                showQueryContainer,
                showVehicleMake,
                showVehicleAge,
                showVehicleDamageType,
                vehicleTypeOptions,
                vehicleTypeIconPath,
                vehicleAgeOptions,
                vehicleAgeCustomOptions,
                vehicleDamageTypeOptions,
                vehicleMake,
                repairServicesFetchParams,
                mapSlotDamageType,
                setupForm,
                translation,
                applyVehicleTypes,
                onVehicleAgeChange,
                onSubmitQueryClick,
                onBackToQueryClick,
                vehicleAge,
                onMapReady,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setupForm();
    }
});
</script>
