export default class PolicyPeriodStrings {
    public static readonly OneYearPayment: string = 'oneYearPayment';
    public static readonly MonthlyPayment: string = 'monthlyPayment';
    public static readonly SubscriptionPayment: string = 'subscriptionPayment';
    public static readonly OneMonthPayment: string = 'oneMonthPayment';
    public static readonly TwoMonthsPayment: string = 'twoMonthPayment';
    public static readonly ThreeMonthsPayment: string = 'threeMonthsPayment';
    public static readonly FourMonthsPayment: string = 'fourMonthsPayment';
    public static readonly FiveMonthsPayment: string = 'fiveMonthsPayment';
    public static readonly SixMonthsPayment: string = 'sixMonthsPayment';
    public static readonly SevenMonthsPayment: string = 'sevenMonthsPayment';
    public static readonly EightMonthsPayment: string = 'eightMonthsPayment';
    public static readonly NineMonthsPayment: string = 'nineMonthsPayment';
    public static readonly TenMonthsPayment: string = 'tenMonthsPayment';
    public static readonly ElevenMonthsPayment: string = 'elevenMonthsPayment';

}
