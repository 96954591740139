import TwoWayModalContent from '@/Apps/TwoWayCommunication/Interfaces/TwoWayModalContentInterface';
import {Ref, ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Services/TwoWayCommunicationService';

export default class TwoWayModal {
    private static instance: TwoWayModal;
    private readonly twoWayModalContent?: TwoWayModalContent;
    private readonly twoWayCommunication: TwoWayCommunication = TwoWayCommunication.getInstance();

    public isVisible: Ref<boolean> = ref(false);

    private constructor() {
        const count: number = this.twoWayCommunication.unreadCommunicationsCount();
        const modalName: string = this.twoWayCommunication.name();
        this.twoWayModalContent = new class implements TwoWayModalContent {
            public name: string = modalName;
            public messagesCount: number = count;
            public proceedActionCallback: Function = (): void => {
            };
        }
    }

    public static getInstance(): TwoWayModal {
        if (!TwoWayModal.instance) {
            TwoWayModal.instance = new TwoWayModal();
        }

        return TwoWayModal.instance;
    }

    public applyProceedActionCallback(proceedActionCallback: Function): this {
        this.twoWayModalContent!.proceedActionCallback = proceedActionCallback;

        return this;
    }

    public content(): TwoWayModalContent {
        return this.twoWayModalContent as TwoWayModalContent;
    }

    public show(): void {
        this.isVisible.value = true;
        OneBaseService.getInstance().changeBodyVerticalScrollState();
    }

    public hide(): void {
        this.isVisible.value = false;
        OneBaseService.getInstance().changeBodyVerticalScrollState();
        this.twoWayModalContent!.proceedActionCallback();
    }
}