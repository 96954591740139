import {reactive, Ref, ref, UnwrapNestedRefs} from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import PersonState from '@/Enums/PersonStateEnum';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import BottomNotification from '@/services/bottom.notification.service';
import BottomNotificationLevel from '@/Enums/BottomNotificationLevelEnum';
import PopupBase from '@/assets/libraries/popups/popup.base';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PopupIcon from '@/Enums/PopupIconEnum';
import PopupService from '@/services/custom.popup.service';
import {Subscription} from 'rxjs';
import moment from 'moment';
import StatusIcon from '@/Components/StatusIcon/StatusIcon.enum';
import GridApi from '@/Components/SmartGrid/Interfaces/GridApiInterface';
import GridColumn from '@/Components/SmartGrid/Interfaces/GridColumnInterface';
import GridRowNode from '@/Components/SmartGrid/Interfaces/GridRowNodeInterface';
import GridLocalization from '@/Components/SmartGrid/Builders/GridLocalizationBuilder';
import GridContent from '@/Components/SmartGrid/Interfaces/GridContentInterface';
import ColumnsDefinitions from '@/Components/SmartGrid/Interfaces/ColumnsDefinitionsInterface';
import GridCallback from '@/Components/SmartGrid/Enums/GridCallbackEnum';
import CellRendererTemplate from '@/Components/SmartGrid/Interfaces/CellRendererTemplateInterface';
import LegalInsuredPerson from '@/pages/LegalPerson/Workbench/Interfaces/LegalInsuredPersonInterface';
import LegalPersonModalState from '@/pages/LegalPerson/Workbench/Enums/LegalPersonModalStateEnum';
import ValueObjectField from '@/Components/SmartGrid/Interfaces/ValueObjectFieldInterface';
import {useDefine} from '@/Composables/Define';
import {useFileDownload} from '@/Composables/FileDownload';
import Translations from '@/services/translations.service';
import AppCountry from '@/assets/libraries/app/app-country';

const {isSet} = useDefine();

export default class LegalPersons {
    public isRowSelectable: Function = this.isRowSelectableReceiver.bind(this);
    public rowClassesCallback: Function = this.rowClassesCallbackReceiver.bind(this);
    public gridEventCallback: Function = this.eventCallbackReceiver.bind(this);
    public onFilterChangeCallback: Function = this.onFilterChangeCallbackReceiver.bind(this);
    public gridApiCallback: Function = this.gridApiCallbackReceiver.bind(this);
    public gridFilterStatusCallback: Function = this.gridFilterStatusCallbackReceiver.bind(this);

    private readonly MaxRowsPerPage: number = 250;

    private context: DynamicDictionary = {};
    private insuredPersons: Ref<LegalInsuredPerson[]> = ref([]);
    private gridContent: UnwrapNestedRefs<GridContent> = reactive(new class implements GridContent {
        public columnDefs: ColumnsDefinitions[] = [];
        public rowData: DynamicDictionary[] = [];
    });
    private selectedRows: Ref<DynamicDictionary[]> = ref([]);
    private localGridSearchTerm: Ref<string> = ref('');
    private gridApi?: GridApi;
    private gridId: string = 'persons';
    private searchInProgress: Ref<boolean> = ref(false);

    public addContext(context: DynamicDictionary): void {
        this.context = context;
    }

    public applyPersons(persons: LegalInsuredPerson[]): void {
        this.insuredPersons.value = [];
        persons.forEach((person: LegalInsuredPerson): void => {
            person.icon = StatusIcon.InProgress;
            this.insuredPersons.value.push(person);
        });
    }

    public resetSelectedRows(): void {
        this.selectedRows.value = [];
    }

    public init(): void {
        this.gridContent.columnDefs = [
            {
                field: 'id',
                headerName: '',
                hide: true,
            },
            {
                field: 'selected',
                headerName: ' ',
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                showDisabledCheckboxes: true,
                maxWidth: 56,
                suppressSizeToFit: true,
            },
            {
                field: 'status',
                headerName: this.app.translated('status'),
                cellRendererParams: this.cellRendererTemplateBadge.bind(this),
                minWidth: 160,
                width: 240,
            },
            {
                field: 'firstName',
                headerName: this.app.translated('name')
            },
            {
                field: 'lastName',
                headerName: this.app.translated('surname')
            },
            {
                field: 'personCode',
                headerName: this.app.translated('identity_number')
            },
            {
                field: 'startDate',
                headerName: this.app.translated('start_date')
            },
            {
                field: 'endDate',
                headerName: this.app.translated('end_date'),
                cellClass: this.cellClassEndDate.bind(this),
            },
            {
                field: 'programName',
                cellClass: 'program-wrapper',
                headerName: this.app.translated('program'),
                cellRendererParams: this.cellRendererTemplateProgramName.bind(this),
                customTooltipRendererParams: this.cellTopPanelTooltipRenderer.bind(this),
            },
            {
                field: 'premium',
                headerName: this.app.translated('yearly_premium'),
                valueFormatter: this.premiumValueFormatter.bind(this),
                maxWidth: 120,
                minWidth: 100,
            },
            {
                field: 'actionsProcessing',
                headerName: ' ',
                cellClass: 'person-actions-wrapper',
                cellRendererParams: this.cellRendererTemplateActionsProcessing.bind(this),
                minWidth: 24,
                maxWidth: 44,
            },
            {
                field: 'actionsEnded',
                headerName: ' ',
                cellClass: 'person-actions-wrapper',
                cellRendererParams: this.cellRendererTemplateActionsEnded.bind(this),
                customTooltipRendererParams: this.cellActionTooltipRenderer.bind(this),
                minWidth: 24,
                maxWidth: 44,
            },
            {
                field: 'actionsEdit',
                headerName: ' ',
                cellClass: 'person-actions-wrapper',
                cellRendererParams: this.cellRendererTemplateActionsEdit.bind(this),
                minWidth: 24,
                maxWidth: 44,
            },
            {
                field: 'actionsDelete',
                headerName: ' ',
                cellClass: 'person-actions-wrapper',
                cellRendererParams: this.cellRendererTemplateActionsDelete.bind(this),
                minWidth: 24,
                maxWidth: 44,
            },
            {
                field: 'card',
                headerName: '',
                hide: true,
            },
        ];
    }

    public rebuildGrid(): void {
        this.gridContent.rowData = [];
        if (this.insuredPersons.value.length > 0) {
            this.insuredPersons.value.forEach((person: LegalInsuredPerson, index: number): void => {
                this.gridContent.rowData.push({
                    id: person.id,
                    selected: '',
                    status: String(person.status).toLowerCase(),
                    firstName: person.firstName,
                    lastName: person.lastName,
                    personCode: person.personCode,
                    startDate: moment(person.startDate).format(this.app.DateFormat),
                    endDate: moment(person.endDate).format(this.app.DateFormat),
                    programName: {
                        /* INFO:
                         *  use some IC generator if needed instead of 'id'
                         */
                        id: person.insuranceProgram.id,
                        name: person.insuranceProgram.name
                    },
                    premium: (person.premium as DynamicDictionary).amount,
                    actionsEnded: {
                        endDate: {
                            value: moment(person.endDate, this.app.DateFormats)
                                .format(this.app.DateFormat),
                            enabled: true,
                        },
                        relationIc: {
                            value: person.relationIc,
                            enabled: person.relationIc === PersonState.Relative,
                        },
                    },
                    card: person.cardNumber,
                    relationIc: person.relationIc,
                });
            });
        }
    }

    public onEditPersonClick(rowIndex: number): void {
        this.app.businessModal
            .assignPersonForEdit(this.insuredPersons.value[rowIndex])
            .applyModalState(LegalPersonModalState.EditPerson)
            .showModalPopup();
    }

    public onViewPersonClick(params: DynamicDictionary): void {
        const eventTarget: HTMLElement = params.event.target;
        const person: LegalInsuredPerson = this.insuredPersons.value[params.data.agGridRowRealIndex];
        const status: PersonState = person.status;
        const hasProcessing: boolean = isSet(person.processingRecords);
        const isRemoved: boolean = this.isRemovedPerson(person.endDate as string);
        const isRelative: boolean = person.relationIc === PersonState.Relative;
        if (eventTarget.tagName !== 'BUTTON') {
            if (!hasProcessing && !isRemoved && status === PersonState.Active && !isRelative) {
                this.onEditPersonClick(params.data.agGridRowRealIndex);
            } else {
                this.app.businessModal
                    .assignPersonForEdit(person)
                    .applyModalState(LegalPersonModalState.ViewPerson)
                    .showModalPopup();
            }
        }
    }

    public onDeletePersonClick(rowIndex: number): void {
        this.app.businessModal.assignPersonForEdit(this.insuredPersons.value[rowIndex]);
        this.app.showRemovePersonPopup();
    }

    public onProcessingPersonClick(rowIndex: number): void {
        const searchPerson: LegalInsuredPerson = this.insuredPersons.value[rowIndex];
        let showBaseProcessing: Boolean = false;
        this.app.processing.persons.forEach((person: LegalInsuredPerson): void => {
            if (person.personCode === searchPerson.personCode) {
                showBaseProcessing = true;
            }
        });
        if (showBaseProcessing) {
            this.app.processing.onShowDetailsClickReceiver();
        } else if (this.app.processing.personsManual.length > 0) {
            this.app.processing.onShowDetailsClickReceiverManual();
        }
    }

    public onExportPersons(): void {
        if (this.selectedRows.value.length > 0) {
            this.exportExcel(this.insuredPersonsBySelectedRows());
        } else {
            const confirmPopup: PopupBase = new OnePopup()
                .withType()
                .confirmPopup
                .withTitle(this.app.translated('are_you_sure_to_export_all_persons'))
                .withDescription(
                    this.app.translated('persons_count_to_export') + this.exportablePersonsCount()
                )
                .withIcon(PopupIcon.AlertOctagon);
            const confirmPopupSubscription: Subscription = confirmPopup.confirmSubject.subscribe((): void => {
                this.exportExcel(this.insuredPersons.value);
                confirmPopupSubscription.unsubscribe();
            })
            PopupService.getInstance().show(confirmPopup);
        }
    }

    public deletePersons(persons: LegalInsuredPerson[]): Promise<void> {
        const params: DynamicDictionary[] = persons.map((person: LegalInsuredPerson): DynamicDictionary => {
            return {
                id: person.id,
                personId: person.personId,
                endDate: person.endDate,
                agreement: person.insuranceProgram.agreement
            };
        });

        return this.app.axiosFetch(Url.Ajax.legalPersonDeletePersons, params, Method.Post)
            .then((response: DynamicDictionary): void => {
                if (response.data && response.data.errors) {
                    BottomNotification.getInstance()
                        .pushNotification(BottomNotificationLevel.Error,
                            this.app.localized('toastr_fail_specific_person_delete')
                        );
                } else {
                    BottomNotification.getInstance()
                        .pushNotification(BottomNotificationLevel.Success,
                            this.app.localized('toastr_success_person_delete')
                        );
                }
            })
            .catch((reason: DynamicDictionary): void => {
                BottomNotification.getInstance()
                    .pushNotification(BottomNotificationLevel.Error,
                        this.app.localized('toastr_global_fail_delete_person')
                    );
            });
    }

    public editPersons(persons: LegalInsuredPerson[]): Promise<void> {
        const params: DynamicDictionary = {
            persons: persons
        };

        return this.app.axiosFetch(Url.Ajax.legalPersonEditPersons, params, Method.Post)
            .then((response: DynamicDictionary) => {
                if (response.success.length > 0) {
                    BottomNotification.getInstance()
                        .pushNotification(BottomNotificationLevel.Success,
                            this.app.localized('toastr_success_person_post')
                        );
                }
                if (response.failed.length > 0) {
                    BottomNotification.getInstance()
                        .pushNotification(BottomNotificationLevel.Error,
                            this.app.localized('toastr_fail_specific_person_post')
                        );
                }
            })
            .catch((reason: DynamicDictionary) => {
                BottomNotification.getInstance()
                    .pushNotification(BottomNotificationLevel.Error,
                        this.app.localized('toastr_global_fail_delete_person')
                    );
            });
    }

    public eventCallbackReceiver(params: DynamicDictionary): void {
        const columnNameSelected: string = 'selected';
        const columnNameProgram: string = 'programName';
        const columnNameEdit: string = 'actionsEdit';
        const columnNameDelete: string = 'actionsDelete';
        const columnNameProcessing: string = 'actionsProcessing';
        const excludedPersonClickColumns: string[] = [columnNameSelected, columnNameProgram, columnNameEdit, columnNameDelete, columnNameProcessing];
        const node: GridRowNode = params.node as GridRowNode;
        const column: GridColumn | null = typeof params.column !== 'undefined' ? params.column as GridColumn : null;
        switch (params.type) {
            case GridCallback.RowSelected:
                this.selectedRows.value = params.api.getSelectedRows();
                break;
            case GridCallback.PaginationChanged:
                this.scrollToTitle();
                break;
            case GridCallback.CellClicked:
                if (column && !excludedPersonClickColumns.includes(column.colId)) {
                    this.onViewPersonClick(params);
                } else if (column && column.colId === columnNameSelected) {
                    node.setSelected(!node.selected);
                } else if (column && column.colId === columnNameEdit) {
                    this.onEditPersonClick(params.data.agGridRowRealIndex);
                } else if (column && column.colId === columnNameDelete) {
                    this.onDeletePersonClick(params.data.agGridRowRealIndex);
                } else if (column && column.colId === columnNameProcessing) {
                    this.onProcessingPersonClick(params.data.agGridRowRealIndex);
                }
                break;

            default:
        }
    }

    public deselectRows(persons: LegalInsuredPerson[]): void {
        const gridRows: DynamicDictionary[] | undefined = this.gridContent.rowData.filter((gridRow: DynamicDictionary) => {
            let result: boolean = false;
            persons.forEach((person: LegalInsuredPerson): void => {
                if (person.id === gridRow.id) {
                    result = true;
                }
            });

            return result;
        });
        const selectedNodes: GridRowNode[] = this.gridApi!.getSelectedNodes();
        selectedNodes.forEach((node: GridRowNode): void => {
            gridRows.forEach((row: DynamicDictionary): void => {
                if (row.id === node.data.id) {
                    node.setSelected(false);
                }
            });
        });
        if (selectedNodes.length === 0) {
            this.selectedRows.value = this.gridApi!.getSelectedRows();
        }
    }

    public onFilterChangeCallbackReceiver(term: string): void {
        this.gridApi!.deselectAll();
        this.localGridSearchTerm.value = term;
    }

    public gridApiCallbackReceiver(api: GridApi): void {
        this.gridApi = api;
    }

    public gridFilterStatusCallbackReceiver(filterInProgress: boolean): void {
        this.searchInProgress.value = filterInProgress;
    }

    public insuredPersonsBySelectedRows(onlyActive: boolean = false): LegalInsuredPerson[] {
        const result: LegalInsuredPerson[] = [];
        const editableStates: PersonState[] = [
            PersonState.Active,
            PersonState.Future,
            PersonState.Ended,
        ];
        this.selectedRows.value.forEach((row: DynamicDictionary): void => {
            const foundPerson: LegalInsuredPerson | undefined =
                this.insuredPersons.value.find((person: LegalInsuredPerson) => {
                    return (onlyActive && person.id === row.id && editableStates.includes(person.status)) ||
                        !onlyActive && person.id === row.id
                });
            if (foundPerson) {
                result.push(foundPerson);
            }
        });

        return result;
    }

    public insuredPersonObjectId(personCode: string): string {
        return String(this.insuredPersons.value
            .find((person: LegalInsuredPerson): boolean => person.personCode === personCode)!
            .id);
    }

    public insuredPersonId(personCode: string): string {
        return String(this.insuredPersons.value
            .find((person: LegalInsuredPerson): boolean => person.personCode === personCode)!
            .personId);
    }

    public get gridLocalizations(): DynamicDictionary {
        return GridLocalization.getInstance()
            .withOverride('to', this.app.localized('paginator_to'))
            .withOverride('of', this.app.localized('paginator_of'))
            .withOverride('page', this.app.localized('paginator_page'))
            .build();
    }

    public get searching(): boolean {
        return this.searchInProgress.value;
    }

    public get gridIdName(): string {
        return this.gridId;
    }

    public get registerEvents(): GridCallback[] {
        return [
            GridCallback.RowSelected,
            GridCallback.CellClicked,
        ];
    }

    public get persons(): LegalInsuredPerson[] {
        return this.insuredPersons.value;
    }

    public get gridContentData(): GridContent {
        return this.gridContent;
    }

    public get gridSearchTerm(): string {
        return this.localGridSearchTerm.value;
    }

    public get selectedRowsCount(): number {
        return this.selectedRows.value.length;
    }

    private get app(): DynamicDictionary {
        return this.context;
    }

    private scrollToTitle(): void {
        const target: JQuery = $('.tab-title');
        if (target.length > 0) {
            const offsetTop = target.offset();
            $('html,body').animate({
                scrollTop: offsetTop ? offsetTop.top : 0
            }, 0);
        }
    }

    private exportExcel(rows: LegalInsuredPerson[]): void {
        const params: DynamicDictionary = {
            rows: this.filteredRowsForExport(rows)
        };
        this.app.axiosFetch(Url.Ajax.legalPersonExportPersons, params, Method.Post)
            .then((response: DynamicDictionary) => {
                useFileDownload().downloadBase64File('xlsx', String(response), this.app.policyId);
            });
    }

    private filteredRowsForExport(rows: LegalInsuredPerson[]): DynamicDictionary {
        const result: DynamicDictionary[] = [];
        result.push({
            id: '',
            status: this.app.translated('status'),
            resident: this.app.translated('resident'),
            countryIc: this.app.translated('country_of_residence'),
            firstName: this.app.translated('name'),
            lastName: this.app.translated('surname'),
            email: this.app.translated('email'),
            personCode: this.app.translated('identity_number'),
            cardNumber: this.app.translated('card_number'),
            startDate: this.app.translated('start_date'),
            endDate: this.app.translated('end_date'),
            programName: this.app.translated('program'),
            premium: this.app.translated('yearly_premium'),
            ...!(new AppCountry()).isLT() && {
                relationIc: this.app.translated('relation_ic'),
            },
            ...(new AppCountry()).isLT() && {
                startingPremium: this.app.translated('starting_premium'),
                unusedPremium: this.app.translated('unused_premium'),
                finalPremium: this.app.translated('final_premium'),
                documentNumber: this.app.translated('document_number'),
                excludeDocumentNumber: this.app.translated('exclude_document_number'),
            }
        });
        let iterableIndex: number = 1;
        rows.forEach((person: LegalInsuredPerson, index: number): void => {
            if (this.isExportableStatus(person.status as PersonState)) {
                result.push({
                    id: iterableIndex++,
                    status: this.app.translated(String(person.status).toLowerCase()),
                    resident: person.resident ? 'Y' : 'N',
                    countryIc: person.countryIc ?? '-',
                    firstName: person.firstName,
                    lastName: person.lastName,
                    email: person.email,
                    personCode: person.personCode,
                    cardNumber: person.cardNumber,
                    startDate: moment(person.startDate).format(this.app.DateFormat),
                    endDate: moment(person.endDate).format(this.app.DateFormat),
                    programName: person.insuranceProgram.name,
                    premium: this.premiumValueFormatter({value: (person.premium as DynamicDictionary).amount}),
                    ...!new AppCountry().isLT() && {
                        relationIc: person.relationIc,
                    },
                    ...(new AppCountry().isLT() && {
                        startingPremium: person.startingPremium,
                        unusedPremium: person.unusedPremium,
                        finalPremium: person.premium?.amount,
                        documentNumber: person.documentNumber,
                        excludeDocumentNumber: person.excludeDocumentNumber,
                    })
                });
            }
        });

        return result;
    }

    private exportablePersonsCount(): number {
        let result: number = 0;
        this.insuredPersons.value.forEach((person: LegalInsuredPerson, index: number): void => {
            if (this.isExportableStatus(person.status as PersonState)) {
                result++;
            }
        });

        return result;
    }

    private isExportableStatus(status: PersonState): boolean {
        const exportableStatuses: PersonState[] = [
            PersonState.Future,
            PersonState.Active,
            PersonState.Ended,
        ];

        return exportableStatuses.includes(status);
    }

    private isProcessingStatus(status: PersonState): boolean {
        return [PersonState.New, PersonState.Error].includes(String(status).toUpperCase());
    }

    private isRowSelectableReceiver(params: DynamicDictionary): boolean {
        const status: string = (params.data as LegalInsuredPerson).status as string;

        return [PersonState.Future, PersonState.Active, PersonState.Ended]
            .includes(status.toUpperCase());
    }

    private rowClassesCallbackReceiver(params: DynamicDictionary): string {
        let result: string = '';
        const status: string = (params.data as LegalInsuredPerson).status as string;
        const endDate: string = (params.data as LegalInsuredPerson).endDate as string;
        const isRelative: boolean = (params.data as LegalInsuredPerson).relationIc === PersonState.Relative;
        if ((status.toUpperCase() === PersonState.Active
                && this.isFutureDate(endDate)
                && this.dateIsBeforeAgreementEnd(endDate))
            || status.toUpperCase() !== PersonState.Active
            || isRelative) {
            result = 'ag-grid-row-grey';
        }

        return result;
    }

    private premiumValueFormatter(params: DynamicDictionary): string {
        return params.value
            ? Number(params.value).toFixed(2) + ' ' + this.app.localized('btar_currency')
            : '-';
    }

    private cellRendererTemplateActionsEdit(params: DynamicDictionary): CellRendererTemplate {
        const rowIndex: number = params.data.agGridRowRealIndex;
        const person: LegalInsuredPerson = this.insuredPersons.value[rowIndex];
        const status: PersonState = person.status;
        const hasProcessing: boolean = isSet(person.processingRecords);
        const isRemoved: boolean = this.isRemovedPerson(person.endDate as string);
        const isRelative: boolean = person.relationIc === PersonState.Relative;
        const editIconHtml: string = '<button class="person-actions-edit"></button>';
        let iconsHtml: string = '';
        if (!hasProcessing && !isRemoved && status === PersonState.Active && !isRelative) {
            iconsHtml = editIconHtml;
        }
        const cellHtml: string = iconsHtml !== '' ?
            '<span class="person-actions">' + iconsHtml + '</span>' : '';

        return new class implements CellRendererTemplate {
            public template: string = cellHtml;
        }
    }

    private cellRendererTemplateActionsDelete(params: DynamicDictionary): CellRendererTemplate {
        const rowIndex: number = params.data.agGridRowRealIndex;
        const person: LegalInsuredPerson = this.insuredPersons.value[rowIndex];
        const status: PersonState = person.status;
        const hasProcessing: boolean = isSet(person.processingRecords);
        const isRemoved: boolean = this.isRemovedPerson(person.endDate as string);
        const isRelative: boolean = person.relationIc === PersonState.Relative;
        const deleteIconHtml: string = '<button class="person-actions-delete"></button>';
        let iconHtml: string = '';
        if (!hasProcessing && !isRemoved && status === PersonState.Active && !isRelative) {
            iconHtml = deleteIconHtml;
        }
        const cellHtml: string = iconHtml !== '' ?
            '<span class="person-actions">' + iconHtml + '</span>' : '';

        return new class implements CellRendererTemplate {
            public template: string = cellHtml;
        }
    }

    private cellRendererTemplateActionsProcessing(params: DynamicDictionary): CellRendererTemplate {
        const rowIndex: number = params.data.agGridRowRealIndex;
        const person: LegalInsuredPerson = this.insuredPersons.value[rowIndex];
        const hasProcessing: boolean = isSet(person.processingRecords);
        const isRemoved: boolean = this.isRemovedPerson(person.endDate as string);
        const openProcessingIconHtml: string = '<button class="person-actions-processing"></button>';
        let iconHtml: string = '';
        if (hasProcessing && !isRemoved) {
            iconHtml = openProcessingIconHtml;
        }
        const cellHtml: string = iconHtml !== '' ?
            '<span class="person-actions">' + iconHtml + '</span>' : '';

        return new class implements CellRendererTemplate {
            public template: string = cellHtml;
        }
    }

    private cellRendererTemplateActionsEnded(params: DynamicDictionary): CellRendererTemplate {
        const rowIndex: number = params.data.agGridRowRealIndex;
        const person: LegalInsuredPerson = this.insuredPersons.value[rowIndex];
        const status: PersonState = person.status;
        const openProcessingIconHtml: string = '<button class="person-actions-excluded" ' +
            '></button>';
        let iconHtml: string = '';
        if (status === PersonState.Active
            && this.isFutureDate(person.endDate!) && this.dateIsBeforeAgreementEnd(person.endDate!)
            || person.relationIc === PersonState.Relative) {
            iconHtml = openProcessingIconHtml;
        }
        const cellHtml: string = iconHtml !== '' ?
            '<span class="person-actions">' + iconHtml + '</span>' : '';

        return new class implements CellRendererTemplate {
            public template: string = cellHtml;
        }
    }

    private cellRendererTemplateBadge(params: DynamicDictionary): CellRendererTemplate {
        const rowIndex: number = params.data.agGridRowRealIndex;
        const status: PersonState = this.insuredPersons.value[rowIndex].status;
        const badgeClass: string = 'grid-status-badge-' +
            (this.isProcessingStatus(status) ? 'processing' : String(status).toLowerCase());
        const templateHtml: string = '<span class="grid-status-badge ' + badgeClass + '">@ ' +
            (this.isProcessingStatus(status) ?
                '<img width="12" height="12" src="images/one/components/processing/refresh.svg"></span>' :
                '</span>');
        const formattedValue: string = this.app.localized(
            this.isProcessingStatus(status) ? 'processing_badge' : String(params.value).toLowerCase(),
            Translations.getInstance().type);

        return new class implements CellRendererTemplate {
            public template: string = templateHtml;
            public formattedValue: LimitedVariant = formattedValue;
        }
    }

    private cellRendererTemplateProgramName(params: DynamicDictionary, data: DynamicDictionary): CellRendererTemplate {
        const templateHtml: string = '<span class="program-hover-area">@</span>';

        /*INFO:
         * use 'id' instead of 'name' if IC is needed
         */
        return new class implements CellRendererTemplate {
            public template: string = templateHtml;
            public valueObjectFieldName: string = 'name';
        }
    }

    private cellClassEndDate(params: DynamicDictionary): string {
        return String(params.data.status).toUpperCase() === PersonState.Active
        && this.isFutureDate(params.value)
        && this.dateIsBeforeAgreementEnd(params.value) ? 'text-color-red' : '';
    }

    private isFutureDate(date: string): boolean {
        return moment(date, this.app.DateFormats)
            .isAfter(moment());
    }

    private dateIsBeforeAgreementEnd(date: string): boolean {
        return moment(date, this.app.DateFormats)
            .isBefore(this.app.currentAgreementEndDate());
    }

    private cellTopPanelTooltipRenderer(): CellRendererTemplate {
        const templateHtml: string = '<div class="ag-one-tooltip-top-blue">@</div>';

        return new class implements CellRendererTemplate {
            public template: string = templateHtml;
            public valueObjectFieldName: string = 'name';
        }
    }

    private cellActionTooltipRenderer(): CellRendererTemplate {
        const tooltipTexts: DynamicDictionary = {
            endDate: this.app.translated('actions_ended_tooltip_text'),
            relative: this.app.translated('actions_ended_relative'),
        };

        return new class implements CellRendererTemplate {
            public template: string = '';
            public valueObjectFields: ValueObjectField[] = [
                {
                    name: 'endDate',
                    template: `<div class="ag-one-tooltip-top-blue">${tooltipTexts.endDate}</div>`,
                },
                {
                    name: 'relationIc',
                    template: `<div class="ag-one-tooltip-top-blue">${tooltipTexts.relative}</div>`,
                },
            ];
        }
    }

    private isRemovedPerson(date: string): boolean {
        const personEndDate: moment.Moment = moment(date);

        return !this.app.currentAgreementEndDate().isSame(personEndDate);
    }
}
