<script setup lang="ts">
import {onMounted, onBeforeMount, Ref, ref, computed, PropType, App, markRaw} from 'vue';
    import {ComponentPublicInstance} from '@vue/runtime-core';
    import FormField from '@/assets/libraries/form/form-field';
    import DefaultFieldset from '@/Components/FieldsetList/DefaultFieldset';
    import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
    import Field from '@/Components/FieldsetList/Interfaces/Field.interface';
    import User from '@/services/user.service';
    import FieldName from '@/Components/FieldsetList/Enums/FieldName.enum';
    import moment from 'moment';
    import {useTranslate} from '@/Composables/Translate';
    import {useScroll} from '@/Composables/Scroll';
    import SimpleError from '@/assets/libraries/popups/types/simple.error';
    import OnePopup from '@/assets/libraries/popups/one.popup';
    import PopupService from '@/services/custom.popup.service';
    import {useIntersectionObserver} from '@/Composables/IntersectionObserver';
    import EventBus from '@/services/event.bus.service';
    import Fieldset from '@/Components/FieldsetList/Interfaces/Fieldset.interface';
    import {useDebounce} from '@/Composables/Debounce';

    const props = defineProps({
        formField: {type: FormField, default: () => new FormField('')},
        proceedButtonText: {type: String, default: 'Confirm'},
        addButtonText: {type: String, default: 'Add'},
        removeButtonText: {type: String, default: 'Remove'},
        authUserText: {type: String, default: ''},
        fieldsetTitle: {type: String, default: ''},
        fieldsetLimitText: {type: String, default: 'Limit {count} exceeded'},
        dataStoreDisabled: {type: Boolean, default: false},
        fieldset: {type: Object as PropType<Fieldset>, default: () => DefaultFieldset.build()},
        fieldsetLimit: {type: Number, default: 0}
    });
    const emit = defineEmits(['confirm-subjects']);
    const {translate} = useTranslate();
    const {scrollToTop, scrollToView} = useScroll();
    const {debounce} = useDebounce();
    const {observe, unobserve, intersectionEvent, disjoinEvent} = useIntersectionObserver();
    const subjectIdPrefix: string = 'fieldsetSubject';
    const logicalPositionCenter: ScrollLogicalPosition = 'center';
    const logicalPositionEnd: ScrollLogicalPosition = 'end';
    const removeAnimationClass: string = 'removing';
    const defaultSubjectScrollOffset: number = 1;
    const defaultDebounceTime: number = 500;
    const subjectRemoveDebounce: Function = debounce(onSubjectRemoved, defaultDebounceTime);
    const authCheckbox: FormField = new FormField('auth-checkbox');
    const subjects: Ref<any[]> = ref([]);
    const showAuthenticatedCheckbox: Ref<boolean> = computed((): boolean => {
        return User.getInstance().isLogged() && props.authUserText !== '';
    });
    const componentRefs: Ref<App[]> = ref([]);

    onMounted((): void => {
        addDefaultFieldset();
    });

    onBeforeMount((): void => {
        applySubscriptions();
    });

    function applySubscriptions(): void {
        props.formField.onRestore.subscribe((): void => {
            restoreValues().then();
        });
        EventBus.getInstance().subscribe(disjoinEvent(), onSubjectNotIntersecting);
        EventBus.getInstance().subscribe(intersectionEvent(), onSubjectIntersecting);
    }

    function onSubjectNotIntersecting(entry: IntersectionObserverEntry): void {
        unobserve(entry.target.id).then((): void => {
            scrollToView('#' + entry.target.id, logicalPositionCenter);
        });
    }

    function onSubjectIntersecting(entry: IntersectionObserverEntry): void {
        unobserve(entry.target.id).then();
    }

    function restoreValues(): Promise<void> {
        return new Promise(resolve => {
            if (props.formField.isNotEmpty()) {
                subjects.value = [];
                const currentSubjects: DynamicDictionary[] = props.formField.value.subjects;
                const authState: boolean = props.formField.value.authCheckbox;
                currentSubjects.forEach((subject: DynamicDictionary, index: number): void => {
                    addSubject().then((): void => {
                        Object.keys(subject).forEach((key: string): void => {
                            (subjects.value[index][key].field as FormField).patch(subject[key]);
                        });
                    });
                });
                if (authState) {
                    authCheckbox.patch(authState);
                    lockFirstSubject();
                }
                resolve();
            }
        });
    }

    function addDefaultFieldset(): void {
        if (subjects.value.length === 0) {
            addSubject().then();
        }
    }

    function addSubject(): Promise<void> {
        return new Promise(resolve => {
            const subject: DynamicDictionary = {};
            props.fieldset.fields.forEach((field: Field): void => {
                const name: string = field.name as string;
                subject[name] = {
                    field: markRaw(new FormField(name + subjects.value.length, '', field.validator, field.sanitizer)),
                    component: field.component,
                    label: field.label,
                    placeholder: field.placeholder,
                    locked: false,
                };
            });
            subjects.value.push(subject);
            resolve();
        });
    }

    function removeSubject(index: number): Promise<void> {
        return new Promise(resolve => {
            subjects.value.splice(index, 1);
            resolve();
        })
    }

    function onAddSubjectClick(index: number): void {
        if (canAddSubject()) {
            addSubject().then((): void => {
                observe(subjectIdPrefix + (index + defaultSubjectScrollOffset)).then();
            });
        } else {
            showSubjectsLimitMessage();
        }
    }

    function showSubjectsLimitMessage(): void {
        const popup: SimpleError = new OnePopup()
            .withType()
            .simpleError
            .withDescription(props.fieldsetLimitText.replace('{count}', String(props.fieldsetLimit)));
        PopupService.getInstance().show(popup);
    }

    function canAddSubject(): boolean {
        return !fieldsetLimitReached();
    }

    function fieldsetLimitReached(): boolean {
        return props.fieldsetLimit !== 0 && subjects.value.length === props.fieldsetLimit;
    }

    function onRemoveSubjectClick(index: number): void {
        addRemoveAnimation(index).then((): void => {
            subjectRemoveDebounce(index);
        });
    }

    function addRemoveAnimation(index: number): Promise<void> {
        return new Promise(resolve => {
            $('#' + subjectIdPrefix + index).addClass(removeAnimationClass);
            resolve();
        });
    }

    function resetRemoveAnimation(): Promise<void> {
        return new Promise(resolve => {
            $('.subject').removeClass(removeAnimationClass);
            resolve();
        });
    }

    function onSubjectRemoved(index: number): void {
        removeSubject(index).then((): void => {
            patchFormFieldValue();
            const scrollIndex: number = index < subjects.value.length ? index : index - defaultSubjectScrollOffset;
            if (scrollIndex) {
                scrollToView('#' + subjectIdPrefix + scrollIndex, logicalPositionEnd);
            } else {
                scrollToTop();
            }
            resetRemoveAnimation().then();
        });
    }

    function onSubjectInput(): void {
        patchFormFieldValue();
    }

    function onSubjectsConfirmClick(): void {
        touchAndValidateAllFieldsets().then((): void => {
            if (!hasInvalidFields()) {
                emit('confirm-subjects', assembledSubjects());
            }
        });
    }

    function onAuthenticatedClick(value: boolean): void {
        if (value) {
            patchFirstSubject();
            lockFirstSubject();
        } else {
            unlockFirstSubject();
            clearFirstSubject();
        }
    }

    function patchFirstSubject(): void {
        subjects.value[0][FieldName.FirstName].field.patch(User.getInstance().current.firstname);
        subjects.value[0][FieldName.LastName].field.patch(User.getInstance().current.lastname);
    }

    function clearFirstSubject(): void {
        const firstSubject: DynamicDictionary = subjects.value[0];
        Object.keys(firstSubject).forEach((key: string): void => {
            (firstSubject[key].field as FormField).clear();
            componentRefs.value.forEach((ref: ComponentPublicInstance) => {
                if (ref.$data.form && firstSubject[key].field.name === ref.$vnode.key) {
                    ref.$data.form.clear();
                }
            });
        });
    }

    function lockFirstSubject(): void {
        const firstSubject: DynamicDictionary = subjects.value[0];
        Object.keys(firstSubject).forEach((key: string): void => {
            firstSubject[key].locked = true;
        });
    }

    function unlockFirstSubject(): void {
        const firstSubject: DynamicDictionary = subjects.value[0];
        Object.keys(firstSubject).forEach((key: string): void => {
            firstSubject[key].locked = false;
        });
    }

    function touchAndValidateAllFieldsets(): Promise<void> {
        return new Promise(resolve => {
            subjects.value.forEach((subject: DynamicDictionary): void => {
                Object.keys(subject).forEach((key: string): void => {
                    const field: FormField = subject[key].field;
                    field.touch().validate();
                });
            });
            resolve();
        });
    }

    function hasInvalidFields(): boolean {
        return subjects.value.map((subject: DynamicDictionary): boolean[] => {
            const result: boolean[] = [];
            Object.keys(subject).forEach((key: string): void => {
                result.push(subject[key].field.isNotEmpty());
            });

            return result;
        }).flat().includes(false);
    }

    function patchFormFieldValue(): void {
        props.formField.patch({
            subjects: assembledSubjects(),
            authCheckbox: authCheckbox.value,
        });
    }

    function assembledSubjects(): DynamicDictionary[] {
        return subjects.value.map((subject): DynamicDictionary => {
            const result: DynamicDictionary = {};
            Object.keys(subject).forEach((key): void => {
                if (subject[key].field.value instanceof Date) {
                    result[key] = moment(subject[key].field.value)
                        .utc(true).toDate();
                } else {
                    result[key] = subject[key].field.value;
                }
            });

            return result;
        });
    }

</script>

<template>
    <div class="fieldset-list"
         :id="formField.name"
         :class="{...formField.classes()}"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)">
        <div class="subject"
             v-for="(subject, index) in subjects"
             :id="subjectIdPrefix + index"
             :data-index="index"
             :key="index">
            <span class="fieldset-title" v-if="fieldsetTitle">{{ fieldsetTitle }}{{ ' #' + (index + 1) }}</span>
            <component
                    v-if="showAuthenticatedCheckbox && index === 0"
                    @click="onAuthenticatedClick"
                    :form-field="authCheckbox"
                    :data-store-disabled="true"
                    :is="'AppInputCheckbox'">
                {{ authUserText }}
            </component>
            <div class="fieldset" :class="fieldset.layout">
                <component
                        ref="componentRefs"
                        v-for="(attribute) in subject"
                        @input="onSubjectInput"
                        v-uppercase
                        :form-field="attribute.field"
                        :label="translate(attribute.label)"
                        :placeholder="translate(attribute.placeholder)"
                        :disabled="attribute.locked"
                        :key="attribute.field.name"
                        :data-store-disabled="true"
                        :disable-error-text="true"
                        :is="attribute.component">
                </component>
            </div>
            <div class="controls"
                 v-if="index === 0 ? subjects.length === 1 : true"
                 :class="{
                'space-between': index === 0,
                'flex-end': index === 0 && subjects.length > 1,
                'flex-start': index !== 0 && (index + 1) === (subjects.length - 1),
            }">
                <button class="button outside color-red"
                        v-if="index !== 0"
                        @click="onRemoveSubjectClick(index)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                stroke="#e30613" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 12H16" stroke="#e30613" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                    <span>{{ removeButtonText }}</span></button>
                <button class="button outside color-black"
                        v-if="(index + 1) === subjects.length"
                        @click="onAddSubjectClick(index)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                                d="M16 20V18C16 16.9391 15.5786 15.9217 14.8284 15.1716C14.0783 14.4214 13.0609 14 12 14H5C3.93913 14 2.92172 14.4214 2.17157 15.1716C1.42143 15.9217 1 16.9391 1 18V20"
                                stroke="#292C31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                                d="M8.5 11C10.7091 11 12.5 9.20914 12.5 7C12.5 4.79086 10.7091 3 8.5 3C6.29086 3 4.5 4.79086 4.5 7C4.5 9.20914 6.29086 11 8.5 11Z"
                                stroke="#292C31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M20 8V14M23 11H17" stroke="#292C31" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                    <span>{{ addButtonText }}</span></button>
                <button class="button red flip-svg"
                        v-if="(index + 1) === subjects.length"
                        @click="onSubjectsConfirmClick">
                    <span>{{ proceedButtonText }}</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 12H3" stroke="white" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M10 19L3 12L10 5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.fieldset-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: var(--size-normal);

  @include respond-above('sm') {
    width: 840px;
    padding: 0;
  }

  @keyframes addSubject {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes removeSubject {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .subject {
    display: flex;
    gap: 24px;
    background-color: var(--white);
    padding: var(--size-normal);
    border-radius: 16px;
    flex-direction: column;
    animation: addSubject 1s;

    &.removing {
      animation: removeSubject 1s;
    }

    .fieldset-title {
      font-size: var(--font-size-small);
    }

    .controls {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 12px;

      @include respond-above('sm') {
        flex-direction: row;
        gap: 0;
      }

      &.space-between {
        justify-content: space-between;
      }

      &.flex-end {
        justify-content: flex-end;
      }

      &.flex-start {
        justify-content: flex-start;
      }

      .button {
        width: initial;
        border-radius: 8px;
        padding: 0 var(--size-big);
        font-size: var(--font-size-nano);
        display: flex;
        gap: 12px;

        &.flip-svg {
          svg {
            transform: rotate(180deg);
          }
        }

        &.color-red {
          color: var(--brand-red);
        }

        &.color-black {
          color: var(--text-color-default);
        }

        &.outside {
          &::before {
            border-radius: 8px;
          }
        }
      }
    }

    .fieldset {
      display: grid;
      grid-template-columns: auto;
      gap: 24px;

      &.double-column {
        @include respond-above('sm') {
          grid-template-columns: auto auto;
        }
      }

      &.triple-column {
        @include respond-above('sm') {
          grid-template-columns: auto auto auto;
        }
      }

      .input {
        width: 100%;

        &.disabled {
          :deep(.wrapper) {
            input {
              -webkit-text-fill-color: var(--text-color-default);
            }
          }
        }

        :deep(.label) {
          color: var(--text-color-default);
        }
      }

      .input-date {
        width: 160px;

        :deep(.wrapper) {
          height: 52px;
          justify-content: left;
          padding-left: var(--size-small);
        }
      }
    }
  }
}
</style>
