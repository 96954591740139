import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useTranslate } from '@/Composables/Translate';
import PolicyPeriodStrings from '@/Enums/PolicyPeriodStringsEnum';


export default class AgreementPeriodMap {
    public static OneMonth: string = '1MON';
    public static TwoMonths: string = '2MON';
    public static ThreeMonths: string = '3MON';
    public static FourMonths: string = '4MON';
    public static FiveMonths: string = '5MON';
    public static SixMonths: string = '6MON';
    public static SevenMonths: string = '7MON';
    public static EightMonths: string = '8MON';
    public static NineMonths: string = '9MON';
    public static TenMonths: string = '10MON';
    public static ElevenMonths: string = '11MON';
    public static OneYear: string = '1YEAR';

    private readonly mapping: Map<string, DynamicDictionary> = new Map();

    public constructor() {
        this.mapping = new Map(Object.entries(AgreementPeriodMap.periodIcs()));
    }

    public monthsByIc(periodIc: string): number {
        let result: number = 0;
        const filtered: DynamicDictionary | undefined = this.mapping.get(periodIc);
        if (filtered) {
            result = filtered.months;
        }

        return result;
    }

    public monthsLabelByIc(periodIc: string): string {
        let result: string = '';
        const filtered: DynamicDictionary | undefined = this.mapping.get(periodIc);
        if (filtered) {
            result = filtered.monthsLabel;
        }

        return result;
    }

    public icByMonths(months: number): string {
        let result: string = '';
        const filtered: (string | DynamicDictionary)[] = [...this.mapping.entries()]
            .filter( value=> value[1].months === months)
            .flat();
        if (filtered.length > 0) {
            result = filtered[0] as string;
        }

        return result;
    }

    public monthsLabelByMonths(months: number): string {
        let result: string = '';
        const filtered: (string | DynamicDictionary)[] = [...this.mapping.entries()]
            .filter( value=> value[1].months === months)
            .flat();
        if (filtered.length > 0) {
            result = (filtered[1] as DynamicDictionary).monthsLabel;
        }

        return result;
    }

    public monthsLabelByPeriodString(periodString: string): string {
        let result: string = '';
        const filtered: (string | DynamicDictionary)[] = [...this.mapping.entries()]
            .filter( value=> value[1].periodString === periodString)
            .flat();
        if (filtered.length > 0) {
            result = (filtered[1] as DynamicDictionary).monthsLabel;
        }

        return result;
    }

    public static periodIcs(): DynamicDictionary {
        return {
            [AgreementPeriodMap.OneMonth]: {
                months: 1,
                monthsLabel: useTranslate().translate('btar_month'),
                periodString: PolicyPeriodStrings.OneMonthPayment,
            },
            [AgreementPeriodMap.TwoMonths]: {
                months: 2,
                monthsLabel: useTranslate().translate('btar_months'),
                periodString: PolicyPeriodStrings.TwoMonthsPayment,
            },
            [AgreementPeriodMap.ThreeMonths]: {
                months: 3,
                monthsLabel: useTranslate().translate('btar_months'),
                periodString: PolicyPeriodStrings.ThreeMonthsPayment,
            },
            [AgreementPeriodMap.FourMonths]: {
                months: 4,
                monthsLabel: useTranslate().translate('btar_months'),
                periodString: PolicyPeriodStrings.FourMonthsPayment,
            },
            [AgreementPeriodMap.FiveMonths]: {
                months: 5,
                monthsLabel: useTranslate().translate('btar_months_secondary'),
                periodString: PolicyPeriodStrings.FiveMonthsPayment,
            },
            [AgreementPeriodMap.SixMonths]: {
                months: 6,
                monthsLabel: useTranslate().translate('btar_months_secondary'),
                periodString: PolicyPeriodStrings.SixMonthsPayment,
            },
            [AgreementPeriodMap.SevenMonths]: {
                months: 7,
                monthsLabel: useTranslate().translate('btar_months_secondary'),
                periodString: PolicyPeriodStrings.SixMonthsPayment,
            },
            [AgreementPeriodMap.EightMonths]: {
                months: 8,
                monthsLabel: useTranslate().translate('btar_months_secondary'),
                periodString: PolicyPeriodStrings.SixMonthsPayment,
            },
            [AgreementPeriodMap.NineMonths]: {
                months: 9,
                monthsLabel: useTranslate().translate('btar_months_secondary'),
                periodString: PolicyPeriodStrings.NineMonthsPayment,
            },
            [AgreementPeriodMap.TenMonths]: {
                months: 10,
                monthsLabel: useTranslate().translate('btar_months_secondary'),
                periodString: PolicyPeriodStrings.TenMonthsPayment,
            },
            [AgreementPeriodMap.ElevenMonths]: {
                months: 11,
                monthsLabel: useTranslate().translate('btar_months_secondary'),
                periodString: PolicyPeriodStrings.ElevenMonthsPayment,
            },
            [AgreementPeriodMap.OneYear]: {
                months: 12,
                monthsLabel: useTranslate().translate('btar_months_secondary'),
                periodString: PolicyPeriodStrings.OneYearPayment,
            },
        };
    }
}
