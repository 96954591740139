import {getCurrentInstance, ComponentInternalInstance} from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export const useCore = (): CoreParams => {
    const forceUpdate = (): void => {
        const instance: ComponentInternalInstance | null = getCurrentInstance();
        if (instance) {
            (instance.proxy as DynamicDictionary).forceUpdate();
        }

    }

    const updateReactiveObject = (target: DynamicDictionary, source: DynamicDictionary): void => {
        for (const key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = source [key];
            }
        }
    }

    return {
        forceUpdate,
        updateReactiveObject,
    }
}

export interface CoreParams {
    forceUpdate: () => void;
    updateReactiveObject: (target: DynamicDictionary, source: DynamicDictionary) => void;
}
