import { defineStore, StoreDefinition } from 'pinia';
import { ref, Ref } from 'vue';
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router';

export const useSolarPanels = (): SolarPanelsStoreParams => {
    const store = (): StoreDefinition<string, SolarPanelsStore> => {
        return defineStore('solar-panels', (): {
            currentRoute: Ref<string>,
            updateRoute: Function,
            destroyRoute: Function,
        } => {
            const currentRoute: Ref<string> = ref(storedItem('solar-panels-route'));

            function updateRoute(): void {
                const route: RouteLocationNormalizedLoaded = useRoute();
                currentRoute.value = route.fullPath;
                localStorage.setItem('solar-panels-route', currentRoute.value);
            }

            function destroyRoute(): void {
                currentRoute.value = '';
                localStorage.removeItem('solar-panels-route');
            }

            return { currentRoute, updateRoute, destroyRoute };
        })
    }

    const storedItem = (name: string): string => {
        const stored: string | null = localStorage.getItem(name);

        return stored ? stored : '';
    }

    return {
        store,
    }
}

export interface SolarPanelsStore {
    currentRoute: string,
    updateRoute: Function,
    destroyRoute: Function,
}

export interface SolarPanelsStoreParams {
    store: () => StoreDefinition<string, SolarPanelsStore>;
}
