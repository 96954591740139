<script lang="ts">
import {computed, defineComponent, nextTick, ref, Ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import InsuredPerson from '@/interfaces/insured.person.interface';
import {useTranslate} from '@/Composables/Translate';
import RecipientValidators from '@/pages/ClaimsProperty/Recipient/RecipientValidators';
import Translations from '@/services/translations.service';
import {Subscription} from 'rxjs';
import {InputOption} from '@/interfaces/InputOptionInterface';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import ClaimsEnum from '@/Enums/ClaimsEnum';
import FormField from '@/assets/libraries/form/form-field';
import Validation from '@/services/validation.service';
import AppCountry from '@/assets/libraries/app/app-country';
import Sanitizer from '@/services/sanitizer.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Value from '@/assets/libraries/form/value';
import {useDefine} from '@/Composables/Define';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import {SanitizerCallback} from '@/Types/SanitizerCallbackType';
import UserCredentials from '@/interfaces/user.credentials.interface';
import moment from 'moment/moment';
import ExtractDataService from '@/services/extract.data.service';
import SubmitterUrls from '@/services/SubmitterUrls.service';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const {isSet} = useDefine();
        const {translateForType} = useTranslate();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 5;
        const Facility: string = 'one-claims-property';
        const validators: RecipientValidators = new RecipientValidators();

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const insuredPerson: Ref<InsuredPerson> = ref({} as InsuredPerson);


        const beneficiaryOptions: Ref<InputOption[]> = computed(() => {
            return [
                new InputOptionBuilder()
                    .setValue('userBeneficiary')
                    .setName(translation('claims_property_beneficiary_type_me'))
                    .build(),
                new InputOptionBuilder()
                    .setValue('otherBeneficiary')
                    .setName(translation('claims_property_beneficiary_type_other'))
                    .build(),
            ];
        });

        const personTypeOptions: Ref<InputOption[]> = computed(() => {
            return [
                new InputOptionBuilder()
                    .setValue('privateResident')
                    .setName(translation('claims_property_person_type_resident'))
                    .build(),
                new InputOptionBuilder()
                    .setValue('privateNonResident')
                    .setName(translation('claims_property_person_type_non_resident'))
                    .build(),
                new InputOptionBuilder()
                    .setValue('legal')
                    .setName(translation('claims_property_person_type_legal'))
                    .build(),
            ];
        });

        const currentUserIsInsuredPerson: Ref<boolean> = computed(() => {
            return btaBase.user.current.personCode === insuredPerson.value.identityNumber;
        });

        const currentUserIsPolicyHolder: Ref<boolean> = computed(() => {
            return btaBase.user.current.personCode === insuredPerson.value.policyHolder;
        });

        const showPrivatePersonFields: Ref<boolean> = computed(() => {
            return currentUserIsBeneficiary.value;
        });

        const showNonResidentFields: Ref<boolean> = computed(() => {
            return otherPersonIsBeneficiary.value && isNonResident.value;
        });

        const showLegalPersonFields: Ref<boolean> = computed(() => {
            return otherPersonIsBeneficiary.value && isLegalPerson.value;
        });

        const isPrivatePerson: Ref<boolean> = computed(() => {
            return form.field('personType').value === 'privateResident';
        });

        const isLegalPerson: Ref<boolean> = computed(() => {
            return form.field('personType').value === 'legal';
        });

        const isResident: Ref<boolean> = computed(() => {
            return !isNonResident.value;
        });

        const isNonResident: Ref<boolean> = computed(() => {
            return form.field('personType').value === 'privateNonResident';
        });

        const otherPersonIsBeneficiary: Ref<boolean> = computed(() => {
            return !currentUserIsBeneficiary.value;
        });

        const currentUserIsBeneficiary: Ref<boolean> = computed(() => {
            return form.field('beneficiaryType').value === 'userBeneficiary';
        });

        const lockUserFields: Ref<boolean> = computed(() => {
            return currentUserIsInsuredPerson.value && currentUserIsBeneficiary.value;
        });

        const showPrivateOtherFields: Ref<boolean> = computed(() => {
            return !currentUserIsBeneficiary.value && isPrivatePerson.value;
        });

        const showResidentBirthDate: Ref<boolean> = computed(() => {
            return policyHolderCodeIsAnonymous.value;
        });

        const policyHolderCodeIsAnonymous: Ref<boolean> = computed(() => {
            const holderCodeField: FormField = form.field('beneficiaryIdentityNumber');
            const holderCode: string = String(holderCodeField.value);

            return holderCodeField.isNotEmpty() && Validation.isValidPersonCodeWithoutDate(holderCode);
        });

        const identityNumberSanitizer: Ref<Function> = computed(() => {
            const useCountry: boolean = isNonResident.value
                && otherPersonIsBeneficiary.value
                && form.field('beneficiaryCountry').value.iso !== (new AppCountry()).iso();

            return useCountry ? Sanitizer.clearPersonCode : Sanitizer.cleanPersonCode
        });

        const toWhomReportedCustomText: Ref<DynamicDictionary> = computed(() => {
            const customText: DynamicDictionary = JSON.parse(btaBase.userStorage.stepStorageData.toWhomReportedCustom);
            const toWhomReported: string = btaBase.userStorage.stepStorageData.toWhomReported;
            return toWhomReported
                .split(':')
                .filter((value: string) => {
                    if (isSet(customText[value]) && !new Value(customText[value]).isEmpty()) {
                        return value;
                    }
                })
                .map((value: string): { [value: string]: string } => {
                    return {[value]: customText[value]}
                });
        });


        function setupForm(): void {
            Object.keys(btaBase.cmsFields).forEach((field: string): void => {
                form.addField(new FormField(btaBase.cmsFields[field].name));
            })
            form.setReady();
            formIsReady.value = true;
        }

        function onAppReady(): void {
            applyFormValidationAndSanitization();
            patchDefaultBeneficiaryTypes();
            patchBeneficiaryFields();
        }

        function translation(key: string): string {
            return translateForType(key, ClaimsEnum.TranslationType);
        }

        function applyInsuredPerson(value: string): void {
            insuredPerson.value = JSON.parse(value);
        }

        function onBeneficiaryTypeChange(): void {
            nextTick(() => {
                if (form.field('personType').isEmpty()) {
                    form.field('personType').patch(personTypeOptions.value[0].value);
                }
                if (currentUserIsBeneficiary.value) {
                    patchBeneficiaryFields();
                    form.field('personType').patch(personTypeOptions.value[0].value);
                } else {
                    const fieldNames: string[] = [
                        'beneficiaryFirstName',
                        'beneficiaryLastName',
                        'beneficiaryIdentityNumber',
                        'beneficiaryEmail',
                        'beneficiaryPhone',
                        'beneficiaryAccountNumber',
                    ];
                    fieldNames.forEach((fieldName: string): void => {
                        if (form.field(fieldName).isTouched) {
                            form.field(fieldName).clear().then(() => resetForm());
                        }
                    });
                    resetPhoneCountry();
                }
            });
        }

        function onPersonTypeChange(): void {
            resetForm();
            addCustomSanitizers();
        }

        function onSubmitStep(): void {
            resetForm();
            touchAndRequireNecessaryFields();
            form.validate().then((): void => {
                if (form.isValid()) {
                    if (nonResidentHasValidCountry()) {
                        btaBase.captcha.executeCaptcha(submit).then().catch((reason: string): void => {
                            btaBase.error.show(ErrorType.Error, 'onSubmitStep', reason);
                        });
                    } else {
                        showErrorForInvalidNonResidentCountry();
                    }
                }
            });
        }

        function submit(token: string): void {
            prepareSubmit(token);
            stepsSubmitter.submitMethod(Method.Post);
            stepsSubmitter.submitStep(Url.Ajax.claimsPropertyRecipient);
        }

        function resetPhoneCountry(): void {
            form.field('beneficiaryPhone').patch({
                code: new AppCountry().iso(),
                iso: new AppCountry().iso(),
                country: btaBase.settings.phoneCode(),
                phone: '',
            });
            form.field('beneficiaryPhone').markAsUntouched();
        }

        function applyFormValidationAndSanitization(): void {
            addCustomValidators();
            addDefaultSanitizers();
            addCustomSanitizers();
        }

        function addCustomSanitizers(): void {
            form.field('beneficiaryIdentityNumber').addSanitizer(identityNumberSanitizer.value as SanitizerCallback);
        }

        function addDefaultSanitizers(): void {
            form.field('beneficiaryFirstName').addSanitizer(Sanitizer.cleanName);
            form.field('beneficiaryLastName').addSanitizer(Sanitizer.cleanName);
            form.field('beneficiaryRegistrationNumber').addSanitizer(Sanitizer.cleanCompanyRegistrationNumber);
        }

        function addCustomValidators(): void {
            form.field('beneficiaryBirthDate').addValidators(validators.birthDateValidator());
            form.field('beneficiaryCompanyName').addValidators(validators.companyNameValidator());
            form.field('beneficiaryCompanyType').addValidators(validators.companyTypeValidator());
            form.field('beneficiaryEmail').addValidators(validators.emailValidator());
            form.field('beneficiaryFirstName').addValidators(validators.firstNameValidator());
            form.field('beneficiaryLastName').addValidators(validators.lastNameValidator());
            form.field('beneficiaryIdentityNumber').addValidators(validators.identityNumberValidator());
            form.field('beneficiaryPhone').addValidators(validators.phoneValidator());
            form.field('beneficiaryRegistrationNumber').addValidators(validators.registrationNumberValidator());
            form.field('beneficiaryCountry').addValidators(validators.countryValidator());
        }

        function nonResidentHasValidCountry(): boolean {
            return isNonResident.value && otherPersonIsBeneficiary.value ?
                form.field('beneficiaryCountry').value.iso !== (new AppCountry()).iso() : true;
        }

        function showErrorForInvalidNonResidentCountry(): void {
            btaBase.error.show(ErrorType.Error, Facility, 'cannot choose this country for non resident');
        }

        function patchDefaultBeneficiaryTypes(): void {
            if (form.field('beneficiaryType').isEmpty()) {
                switch (true) {
                    case insuredPerson.value.isLegal:
                        patchLegalType();
                        break;
                    case !insuredPerson.value.isLegal
                    && !(currentUserIsInsuredPerson.value || currentUserIsPolicyHolder.value):
                        patchPrivateType();
                        break;
                    case currentUserIsInsuredPerson.value || currentUserIsPolicyHolder.value:
                        patchDefaultType();
                        break;
                    default:
                        break;
                }
            }
        }

        function patchDefaultType(): void {
            form.field('beneficiaryType').patch('userBeneficiary');
        }

        function patchLegalType(): void {
            form.field('beneficiaryType').patch('otherBeneficiary');
            form.field('personType').patch('legal');
        }

        function patchPrivateType(): void {
            form.field('beneficiaryType').patch('otherBeneficiary');
            form.field('personType').patch('privateResident');
        }

        function patchBeneficiaryFields(): void {
            if (currentUserIsInsuredPerson.value && currentUserIsBeneficiary.value) {
                const user: UserCredentials = btaBase.user.current;
                form.field('beneficiaryFirstName').patch(user.firstname);
                form.field('beneficiaryLastName').patch(user.lastname);
                form.field('beneficiaryIdentityNumber').patch(user.personCode);
                form.field('beneficiaryEmail').patch(user.email);
                form.field('beneficiaryAccountNumber').patch(user.bank);
                form.field('beneficiaryPhone').patch({
                    code: user.code,
                    iso: user.code,
                    country: user.phoneCode,
                    phone: user.phone,
                });
            }
            if (!currentUserIsInsuredPerson.value && currentUserIsPolicyHolder.value) {
                form.field('beneficiaryFirstName').patch(insuredPerson.value.firstName!);
                form.field('beneficiaryLastName').patch(insuredPerson.value.lastName!);
                form.field('beneficiaryIdentityNumber').patch(insuredPerson.value.identityNumber!);
            }
            if (insuredPerson.value.isLegal) {
                if (form.field('beneficiaryCompanyName').isEmpty()) {
                    form.field('beneficiaryCompanyName').patch(insuredPerson.value.companyName!);
                }
                if (form.field('beneficiaryRegistrationNumber').isEmpty()) {
                    form.field('beneficiaryRegistrationNumber').patch(insuredPerson.value.registrationNumber!);
                }
            }
        }

        function resetForm(): void {
            form.fields().forEach((field: FormField): void => {
                field.clearValidators();
                field.markAsUntouched();
            })
        }

        function touchAndRequireNecessaryFields(): void {
            const newRequiredFields: string[] = requiredFields();
            newRequiredFields.forEach((field: string): void => {
                form.field(field).addValidators('required');
                form.field(field).touch();
            });
        }

        function requiredFields(): string[] {
            const fields: string[] = [
                btaBase.cmsFields.beneficiaryEmail.name,
                btaBase.cmsFields.beneficiaryPhone.name,
                btaBase.cmsFields.beneficiaryAccountNumber.name,
            ];
            if (showPrivatePersonFields.value || showPrivateOtherFields.value) {
                fields.push(
                    btaBase.cmsFields.beneficiaryFirstName.name,
                    btaBase.cmsFields.beneficiaryLastName.name,
                    btaBase.cmsFields.beneficiaryIdentityNumber.name
                );
                if (otherPersonIsBeneficiary.value && showResidentBirthDate.value) {
                    fields.push(btaBase.cmsFields.beneficiaryBirthDate.name);
                }
            }
            if (showNonResidentFields.value) {
                fields.push(
                    btaBase.cmsFields.beneficiaryFirstName.name,
                    btaBase.cmsFields.beneficiaryLastName.name,
                    btaBase.cmsFields.beneficiaryIdentityNumber.name,
                    btaBase.cmsFields.beneficiaryBirthDate.name,
                    btaBase.cmsFields.beneficiaryCountry.name
                );
            }
            if (showLegalPersonFields.value) {
                fields.push(
                    btaBase.cmsFields.beneficiaryCompanyName.name,
                    btaBase.cmsFields.beneficiaryCompanyType.name,
                    btaBase.cmsFields.beneficiaryRegistrationNumber.name
                );
            }

            return fields;
        }

        function prepareSubmit(token: string): void {
            stepsSubmitter.addSubmitParam('nextStep', btaBase.nextStep(), false);
            stepsSubmitter.addSubmitParam('facility', Facility, false);
            stepsSubmitter.addSubmitParam('applicant', applicant(), false);
            stepsSubmitter.addSubmitParam('beneficiary', beneficiary(), false);
            stepsSubmitter.addSubmitParam('claimType', 'property', false);
            stepsSubmitter.addSubmitParam('uploader', 'propertyClaim', false);
            stepsSubmitter.addSubmitParam('eventDate', btaBase.userStorage.stepStorageData.dateOfEvent, false);
            stepsSubmitter.addSubmitParam('caseDescription', caseDescription(), false);
            stepsSubmitter.addSubmitParam('eventDescription', eventDescription(), false);
            stepsSubmitter.addSubmitParam('objectAddress', objectAddress(), false);
            stepsSubmitter.addSubmitParam('addressCountryIso', addressCountryIso(), false);
            stepsSubmitter.addSubmitParam('policyNumber', policyNumber(), false);
            stepsSubmitter.addSubmitParam('riskReserveAmount', riskReserveAmount(), false);
            stepsSubmitter.addSubmitParam('acceptResponsibility', acceptResponsibilityText(), false);
            stepsSubmitter.addSubmitParam('riskIc', riskIc(), false);
            stepsSubmitter.addSubmitParam('subRiskId', subRiskId(), true);
            stepsSubmitter.addSubmitParam('propertyTypes', btaBase.userStorage.stepStorageData.propertyTypes, false);
            stepsSubmitter.addSubmitParam('propertyTypesWithoutIncludedRisks', btaBase.userStorage.stepStorageData.propertyTypesWithoutIncludedRisks, false);
            stepsSubmitter.addSubmitParam('selectedObject', btaBase.userStorage.stepStorageData.object, false);
            stepsSubmitter.addSubmitParam('filesUploaded', btaBase.userStorage.stepStorageData.filesUploaded, false);
            stepsSubmitter.addSubmitParam('caseType', caseType(), false);
        }

        function subRiskId(): string {
            return btaBase.userStorage.stepStorageData.riskRecord.id
        }

        function riskReserveAmount(): string {
            return btaBase.userStorage.stepStorageData.riskRecord.reserved_amount;
        }

        function riskIc(): string {
            return btaBase.userStorage.stepStorageData.riskRecord.risk;
        }

        function policyNumber(): string {
            return btaBase.userStorage.stepStorageData.object.policyNumber;
        }

        function addressCountryIso(): string {
            return hasDifferentAddress() ?
                btaBase.userStorage.stepStorageData.country.iso : (new AppCountry()).iso();
        }

        function objectAddress(): string {
            return hasDifferentAddress() ?
                btaBase.userStorage.stepStorageData.address : btaBase.userStorage.stepStorageData.object.name;
        }

        function hasDifferentAddress(): boolean {
            return btaBase.userStorage.stepStorageData.differentAddress;
        }

        function caseDescription(): string {
            const caseDescriptionFields: DynamicDictionary = {
                propertyTypes: btaBase.userStorage.stepStorageData.propertyTypes,
                riskCause: btaBase.userStorage.stepStorageData.riskRecord.cause,
                whatHappened: btaBase.userStorage.stepStorageData.riskRecord.what_happened,
                toWhomReported: btaBase.userStorage.stepStorageData.toWhomReported,
                describedEvent: btaBase.userStorage.stepStorageData.describedEvent,
            };
            if (!new Value(toWhomReportedCustomText.value).isEmpty()) {
                caseDescriptionFields.toWhomReportedCustom = toWhomReportedCustomText.value;
            }

            return JSON.stringify(caseDescriptionFields);
        }

        function eventDescription(): string {
            return JSON.stringify({
                riskCause: btaBase.userStorage.stepStorageData.riskRecord.cause,
                whatHappened: btaBase.userStorage.stepStorageData.riskRecord.what_happened,
            });
        }

        function acceptResponsibilityText(): string {
            return btaBase.userStorage.stepStorageData.acceptResponsibility || '';
        }

        function caseType(): string {
            return btaBase.userStorage.stepStorageData.caseType;
        }

        function applicant(): InsuredPerson {
            return {
                identityNumber: btaBase.user.current.personCode,
                personId: btaBase.user.current.personId,
                firstName: btaBase.user.current.firstname,
                lastName: btaBase.user.current.lastname,
                companyName: '',
                companyType: '',
                registrationNumber: '',
                isLegal: false,
                isResident: true,
                residenceCountryIso: new AppCountry().iso(),
                birthDate: btaBase.user.current.birthDate,
                email: btaBase.user.current.email,
                phoneCode: btaBase.user.current.phoneCode,
                phone: btaBase.user.current.phone,
                bankAccount: form.field('beneficiaryAccountNumber').value,
            }
        }

        function beneficiary(): InsuredPerson {
            return {
                identityNumber: form.field('beneficiaryIdentityNumber').value,
                firstName: form.field('beneficiaryFirstName').value,
                lastName: form.field('beneficiaryLastName').value,
                companyName: form.field('beneficiaryCompanyName').value,
                companyType: companyType(),
                registrationNumber: form.field('beneficiaryRegistrationNumber').value,
                isLegal: isLegalPerson.value,
                isResident: isResident.value,
                residenceCountryIso: residenceCountryIso(),
                birthDate: beneficiaryBirthDate(),
                email: form.field('beneficiaryEmail').value,
                phoneCode: form.field('beneficiaryPhone').value.country,
                phone: form.field('beneficiaryPhone').value.phone,
                bankAccount: form.field('beneficiaryAccountNumber').value,
            }
        }

        function beneficiaryBirthDate(): string {
            let birthDate: string = moment().format();
            if (currentUserIsBeneficiary.value) {
                birthDate = btaBase.user.current.birthDate;
            } else {
                if (!isLegalPerson.value) {
                    birthDate = isResident.value && !showResidentBirthDate.value ?
                        moment((new ExtractDataService())
                            .birthDateFromPersonCode(form.field('beneficiaryIdentityNumber').value))
                            .format()
                        : moment(form.field('beneficiaryBirthDate').value).format();
                }
            }

            return birthDate;
        }

        function companyType(): string {
            return form.field('beneficiaryCompanyType').isEmpty() ?
                '' : form.field('beneficiaryCompanyType').value.iso;
        }

        function residenceCountryIso(): string {
            return isResident.value ?
                (new AppCountry()).iso() : form.field('beneficiaryCountry').value.iso;
        }

        function applyStepUrls(next: string, previous: string): void {
            SubmitterUrls.getInstance().applyStepUrls(next, previous);
        }


        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                validators,
                setupForm,
                onAppReady,
                translation,
                applyInsuredPerson,
                onBeneficiaryTypeChange,
                onPersonTypeChange,
                onSubmitStep,
                submit,
                resetPhoneCountry,
                applyStepUrls,
                beneficiaryOptions,
                personTypeOptions,
                currentUserIsInsuredPerson,
                currentUserIsPolicyHolder,
                showPrivatePersonFields,
                showNonResidentFields,
                showLegalPersonFields,
                isPrivatePerson,
                isLegalPerson,
                isResident,
                isNonResident,
                otherPersonIsBeneficiary,
                currentUserIsBeneficiary,
                lockUserFields,
                showPrivateOtherFields,
                showResidentBirthDate,
                policyHolderCodeIsAnonymous,
                identityNumberSanitizer,
                toWhomReportedCustomText,
            }
        }
    }

    ,

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        Translations.getInstance().addType('claims');
        this.setupForm();
        this.validators.addContext(this);
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe(() => {
            this.onAppReady();
            onAppIsPreparedAndReady.unsubscribe();
        });
    }
});
</script>
