import {Subject} from 'rxjs';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import FormStorage from '@/interfaces/form.storage.interface';
import FormStorageElement from '@/interfaces/form.storage.element.interface';
import IdleTimer from '@/services/idle.timer.service';
import StorageCapsule from '@/interfaces/storage.capsule.interface';
import Url from '@/Enums/UrlEnum';
import {nextTick, reactive} from 'vue';
import VueModel from '@/services/vue.model.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import AppEnvironment from '@/assets/libraries/app/app-environment';
import OneBaseService from '@/services/OneBaseService';
import {useDefine} from '@/Composables/Define';
import {useAxios} from '@/Composables/Axios';
import PriceType from '@/Enums/PriceTypeEnum';
import {useModel} from '@/Composables/Model';


export default class UserStorage {
    private isSet = useDefine().isSet;


    public onStorageDataIsReady: Subject<void> = new Subject<void>();
    public onBeforeFormStorageDataIsRestored: Subject<number> = new Subject<number>();
    public onFormStorageDataIsReady: Subject<number> = new Subject<number>();
    public storageJson: string = '';
    public formUid: string = '';
    public formStorageJson: string = '';
    public userStoreUid: string = '';

    private readonly RejectReasonZeroFormElementsFound: string = 'Zero form elements found';
    private readonly RejectReasonZeroFormsFound: string = 'Zero forms found';
    private static instance: UserStorage;
    private formIds: string[] = [];
    private idleTimer: IdleTimer;
    private idleTimerDelay: number = 3;
    private idlePageReloadTimer: IdleTimer | null = null
    private formsCouldBeSaved: boolean = false;
    private dataRestored: boolean = false;
    private stepStorage: DynamicDictionary = {};
    private additionalStoragesData: DynamicDictionary = {};
    private defaultStorage: DynamicDictionary = {};
    private storage: StorageCapsule = new class implements StorageCapsule {
        public content: DynamicDictionary = reactive({});
        public contentAdditional: DynamicDictionary = reactive({});
    };
    private restoredElementsCount: number = 0;

    public constructor() {
        this.idleTimer = new IdleTimer(() => {
            if (!(new AppEnvironment()).isAcceptanceTest()) {
                this.saveForms().then().catch((reason: DynamicDictionary) => {
                    console.debug(reason);
                });
            }
        }, this.idleTimerDelay);
    }

    public static getInstance(): UserStorage {
        if (!UserStorage.instance) {
            UserStorage.instance = new UserStorage();
        }

        return UserStorage.instance;
    }

    public applyFormUid(uid: string): UserStorage {
        this.formUid = uid;

        return this;
    }

    public applyFormStoragesJson(json: string): UserStorage {
        this.formStorageJson = json;

        return this;
    }

    public applyStoragesJson(json: string): UserStorage {
        this.storageJson = json;

        return this;
    }

    public applyFormsSaveOnPageUnload(): UserStorage {
        if (!(new AppEnvironment()).isAcceptanceTest() && OneBaseService.getInstance().useStorage.value) {
            $(() => {
                window.addEventListener('beforeunload', () => {
                    this.saveForms().then().catch((reason: DynamicDictionary) => {
                        console.debug(reason);
                    });
                });
            });
        }

        return this;
    }

    public applyFormsAutoSaveAfterInterval(): UserStorage {
        if (!(new AppEnvironment()).isAcceptanceTest() && OneBaseService.getInstance().useStorage.value) {
            $(() => {
                const intervalMs: number = 300000;
                setInterval(() => {
                    this.saveForms()
                        .then()
                        .catch((reason: DynamicDictionary) => {
                            console.debug(reason);
                        });
                }, intervalMs);
            });
        }

        return this;
    }

    public saveFormsPromise(): Promise<void> {
        return this.findForms(true).then(() => {
            return this.collectFormsElements().then((elements: FormStorage[]) => {
                return this.sendFormsElements(elements);
            }).catch((reason: DynamicDictionary) => {
                Error.log(ErrorType.Info, 'collectFormsElements', reason);
            });
        }).catch((reason: DynamicDictionary) => {
            Error.log(ErrorType.Info, 'findForms', reason);
        });
    }

    public applyFormSaveOnIdle(): UserStorage {
        $(() => {
            const form: JQuery = $('[data-form-name]');
            if (form.length > 0) {
                if (OneBaseService.getInstance().useStorage.value) {
                    this.idleTimer.start();
                }
            }
        });

        return this;
    }

    public fetchFormStorage(): UserStorage {
        this.applyStorages();
        nextTick(() => {
            this.dataRestored = true
            this.formsCouldBeSaved = true;
            this.applyGlobalReloadTimerForCsrf();
        });

        return this;
    }

    public applyStorageData(value: DynamicDictionary): UserStorage {
        if (value) {
            this.storage.content = reactive(value);
        }

        return this;
    }

    public applyAdditionalOptionsStorage(value: DynamicDictionary): UserStorage {
        this.storage.contentAdditional = value ? value : {};

        return this;
    }

    public applyIsApprovalCaseNecessaryStorage(
        product: string,
        value?: DynamicDictionary
    ): UserStorage {
        const storageContent = this.storage.content;
        if (
            (value === undefined || value)
            && storageContent.attributes.vehicleProducts[product] !== undefined
        ) {
            storageContent.attributes.vehicleProducts[product]
                .isApprovalCaseNecessary = value;
            this.applyStorageData(storageContent);
        }

        return this;
    }

    public applyIsApprovalCaseNecessaryByPaymentPeriodStorage(
        product: string,
        value?: DynamicDictionary
    ): UserStorage {
        const storageContent = this.storage.content;
        if (
            (value === undefined || value)
            && storageContent.attributes.vehicleProducts[product] !== undefined
        ) {
            storageContent.attributes.vehicleProducts[product]
                .isApprovalCaseNecessaryByPaymentPeriod = value;
            this.applyStorageData(storageContent);
        }

        return this;
    }

    public addUserStoreUid(uid: string): void {
        this.userStoreUid = uid;
    }

    public priceType(): string {
        return OneBaseService.getInstance().user.isLogged() ?
            PriceType.AuthenticatedPrices : PriceType.GuestPrices;
    }

    public lockFormSave(locked: boolean): void {
        this.formsCouldBeSaved = !locked;
    }

    public saveForms(force: boolean = false): Promise<void> {
        let result: Promise<void>;
        if (!this.formsCouldBeSaved || (OneBaseService.getInstance().axiosIsPending.value && !force)) {
            const reason: string = 'Forms could not be saved: ' + (!this.formsCouldBeSaved ? 'Form save is locked' : 'axios still pending');
            result = Promise.reject(reason);
        } else {
            result = this.findForms(true).then(() => {
                this.collectFormsElements().then((elements: FormStorage[]) => {
                    this.sendFormsElements(elements).then();
                }).catch((reason: DynamicDictionary) => {
                    Error.log(ErrorType.Info, 'collectFormsElements', reason);
                });
            }).catch((reason: DynamicDictionary) => {
                Error.log(ErrorType.Info, 'findForms', reason);
            });
        }

        return result;
    }

    public transformedVueSafeString(vueSafe: string): string {
        return vueSafe.replace(/\|o\|/g, '{{')
            .replace(/\|c\|/g, '}}')
            .replace(/\|s\|/g, '\\"');
    }

    public get stepStorageData(): DynamicDictionary {
        return this.stepStorage;
    }

    public get defaultStorageData(): DynamicDictionary {
        return this.defaultStorage;
    }

    public get storageData(): DynamicDictionary {
        return this.storage.content;
    }

    public get additionalStorages(): DynamicDictionary {
        return this.additionalStoragesData;
    }

    public get storageAdditionalData(): DynamicDictionary {
        return this.storage.contentAdditional;
    }

    public get storageIsEmpty(): boolean {
        return Object.keys(this.storage.content).length === 0;
    }

    public get defaultStorageIsEmpty(): boolean {
        return Object.keys(this.defaultStorage).length === 0;
    }

    public get additionalOptionsStorageIsEmpty(): boolean {
        return Object.keys(this.storage.contentAdditional).length === 0;
    }

    public get isDataRestored(): boolean {
        return this.dataRestored;
    }

    public get uUid(): string {
        return this.userStoreUid;
    }

    private applyStorages(): void {
        this.applyStorage();
        if (!OneBaseService.getInstance().isSpa.value) {
            this.applyFormStorage();
        }
        nextTick(() => {
            OneBaseService.getInstance().appIsPreparedAndReady();
        });
    }

    private applyFormStorage(): void {
        try {
            const decodedFormStorageJson: DynamicDictionary = JSON.parse(this.formStorageJson);
            if (this.isSet(decodedFormStorageJson.data)) {
                this.restore(decodedFormStorageJson.data);
                this.formStorageJson = '';
                this.onFormStorageDataIsReady.next(this.restoredElementsCount);
            }
        } catch (e) {
            Error.log(ErrorType.Error, 'UserStorage::applyFormStorage', 'Can\'t parse form storage json');
        }
    }

    private applyStorage(): void {
        try {
            const decodedJson: DynamicDictionary = JSON.parse(this.storageJson);
            if (decodedJson.isSet) {
                if (this.isSet(decodedJson.storageData.content)) {
                    this.applyStorageData(decodedJson.storageData.content.data);
                } else if (this.isSet(decodedJson.storageData.item)) {
                    this.defaultStorage = decodedJson.storageData.item.data;
                }
                if (useDefine().objectMembersCount(decodedJson.stepStorageData) > 0) {
                    this.stepStorage = decodedJson.stepStorageData;
                }
                if (useDefine().isSet(decodedJson.additionalStoragesData) &&
                    useDefine().objectMembersCount(decodedJson.additionalStoragesData) > 0) {
                    this.additionalStoragesData = decodedJson.additionalStoragesData;
                }
                this.storageJson = '';
                this.onStorageDataIsReady.next();
            }
        } catch (e) {
            Error.log(ErrorType.Error, 'UserStorage::applyStorage', 'Can\'t parse storages json');
        }
    }

    private collectFormsElements(): Promise<FormStorage[]> {
        const result: FormStorage[] = [];
        for (let i: number = 0; i < this.formIds.length; i++) {
            const formId: string = this.formIds[i];
            const elements: FormStorageElement[] = this.elementsOfForm(formId);
            if (elements.length > 0) {
                if (this.isSet(formId) && formId !== '') {
                    result.push(new class implements FormStorage {
                            public formName: string = formId;
                            public formElements: FormStorageElement[] = elements;
                        }
                    );
                }
            }
        }
        let promise: Promise<FormStorage[]>;
        if (result.length > 0) {
            promise = Promise.resolve(result);
        } else {
            promise = Promise.reject(this.RejectReasonZeroFormElementsFound);
        }

        return promise;
    }

    private elementsOfForm(formId: string): FormStorageElement[] {
        const domElement: JQuery = $('[data-form-name="' + formId + '"]');
        let formElements: JQuery;
        const result: FormStorageElement[] = [];
        if (domElement.length > 0) {
            formElements = domElement.find('[data-store]')
                .not('[data-store-disabled="true"]')
                .filter((index: number, element: HTMLElement) => !!$(element).data('store'));
            formElements.each((index: number, dom: HTMLElement) => {
                const element: JQuery = $(dom);
                const fieldStoreId: string = element.attr('data-store')!;
                const modelValue: LimitedVariant = element.attr('data-store-value') ||
                    VueModel.modelValueByName(fieldStoreId, OneBaseService.getInstance()) ||
                    VueModel.modelValueByName(fieldStoreId, OneBaseService.getInstance().currentApp());
                result.push(new class implements FormStorageElement {
                        public fieldName: string = fieldStoreId;
                        public fieldValue: LimitedVariant = modelValue;
                    }
                );
            });
        }

        return result;
    }

    private findForms(forSave: boolean): Promise<void> {
        const dataType: string = forSave ? 'data-form-store-skip' : 'data-form-fetch-skip';
        this.formIds = [];
        const form: JQuery = $('[data-form-name]').not('[' + dataType + '="true"]');
        form.each((index: number, dom: HTMLElement) => {
            const formUid: string = $(dom).data('form-store');
            this.formIds.push(formUid);
        });
        let result: Promise<void>;
        if (this.formIds.length > 0) {
            result = Promise.resolve();
        } else {
            result = Promise.reject(this.RejectReasonZeroFormsFound);
        }

        return result;
    }

    private sendFormsElements(elements: FormStorage[]): Promise<void> {
        const stepFacility: string = OneBaseService.getInstance().facility();
        const params: DynamicDictionary = {
            uid: this.userStoreUid,
            step: OneBaseService.getInstance().currentStep(),
            facility: stepFacility,
            forms: elements
        };

        return useAxios().post(Url.Ajax.formStore, params).then(() => {
        }).catch((reason: DynamicDictionary) => {
            Error.log(ErrorType.Error, 'sendFormsElements', reason);
        });
    }

    private restore(value: DynamicDictionary): void {
        this.onBeforeFormStorageDataIsRestored.next();
        let fields: DynamicDictionary[] = [];
        if (this.isSet(value.fields)) {
            fields = value.fields;
            for (let i = 0; i < fields.length; i++) {
                const item: FormStorageElement = fields[i] as FormStorageElement;
                if (UserStorage.isBooleanString(item.fieldValue as string)) {
                    item.fieldValue = item.fieldValue === 'true';
                }
                if (UserStorage.isJson(item.fieldValue as string)) {
                    item.fieldValue = JSON.parse(item.fieldValue as string);
                }
                if (
                    this.isSet(OneBaseService.getInstance().currentApp()) &&
                    this.isSet(OneBaseService.getInstance().currentApp()?.form) &&
                    this.isSet(OneBaseService.getInstance().currentApp()?.form.exists(item.fieldName))
                ) {
                    OneBaseService.getInstance().currentApp()?.form.field(item.fieldName).patch(item.fieldValue, false);
                    OneBaseService.getInstance().currentApp()?.form.field(item.fieldName).markAsRestored();
                } else {
                    const context: DynamicDictionary | null = useModel().contextByMemberName(item.fieldName);
                    if (context === null) {
                        console.debug('UserStorage::restore() -> INFO: member not found: ' + item.fieldName);
                    }
                    if (this.isSet((context as DynamicDictionary)[item.fieldName].value) &&
                        this.isSet((context as DynamicDictionary)[item.fieldName].id)
                    ) {
                        (context as DynamicDictionary)[item.fieldName].value = item.fieldValue;
                    } else {
                        (context as DynamicDictionary)[item.fieldName] = item.fieldValue;
                    }
                }
            }
        }
        this.restoredElementsCount = fields.length;
    }

    private applyGlobalReloadTimerForCsrf(): void {
        if ($('[data-form-name]').length > 0) {
            const globalReloadTimer: number = OneBaseService.getInstance().settings.globalReloadTimerForCsrfDelay() - 1;
            this.idlePageReloadTimer = new IdleTimer(() => {
                window.location.reload();
            }, globalReloadTimer);
            this.idlePageReloadTimer.startWithNoUserInteractions();
        }
    }

    private static isBooleanString(value: string): boolean {
        return value === 'true' || value === 'false';
    }

    private static isJson(string: string): boolean {
        let result: boolean = true;
        try {
            JSON.parse(string);
        } catch (e) {
            result = false;
        }

        return result;
    }
}
