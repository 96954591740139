<script setup lang="ts">
import {PropType} from 'vue';
import {useTranslate} from '@/Composables/Translate';
import {useHtml} from '@/Composables/Html';
import {SolarPanels} from '@/Apps/SolarPanels/Interfaces/SolarPanelsInterface';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import IconSide from '@/Components/TextWithTip/IconSide.enum';
import {useFormatter} from '@/Composables/Formatter';
import {CoverageRisk} from '@/Apps/SolarPanels/Interfaces/CoverageRiskInterface';
import AppButtonWithCallback from '@/Components/ButtonWithCallback/ButtonWithCallback.vue';
import {usePrice} from '@/Composables/Price';
import AppTextWithTip from '@/Components/TextWithTip/TextWithTip.vue';

const props = defineProps({
    policies: {type: Array as PropType<SolarPanels[]>, default: () => []},
    mode: {type: String, default: ''},
    coveragePlanKey: {type: Number, default: 0},
});
const emit = defineEmits(['selected', 'blur', 'close']);

const TranslationType: string = 'solar_panels_whats_covered';

const {translateForType} = useTranslate();
const {sparsePrice} = useFormatter();

function singleMode(): boolean {
    return props.mode === 'single';
}

function localized(stringUid: string): string {
    return translateForType(stringUid, TranslationType);
}

function checkmarkIconAttributes(): ImageTagAttributes {
    return {
        class: 'item icon checkmark',
        src: 'images/one/checkmark-thin.svg',
        width: 24,
        height: 24,
    }
}

function crossIconAttributes(): ImageTagAttributes {
    return {
        class: 'item icon',
        src: 'images/one/cross-icon.svg',
        width: 14,
        height: 14,
    }
}

function iconContent(iconAttributes: ImageTagAttributes): string {
    return useHtml()
        .imgTag(iconAttributes);
}

function onSelectedClick(product: string): void {
    emit('selected', [product]);
}

function riskForPolicy(riskList: CoverageRisk[], riskId: string): CoverageRisk {
    return riskList.filter(risk => risk.id === riskId)[0];
}

function riskStatusIcon(singleMode: boolean, risk?: CoverageRisk): string {
    const icon: string =
        risk?.isIncluded ? `${iconContent(checkmarkIconAttributes())}`
            : `${iconContent(crossIconAttributes())}`;
    return singleMode ? icon : '';
}

function additonalTerritoryRisk(): CoverageRisk {
    return new class implements CoverageRisk {
        id: string = 'insurance_territory_at_address';
        isIncluded: boolean = true;
    }
}

function coveredRisks(risks: CoverageRisk[]): CoverageRisk[] {
    return risks.filter((risk: CoverageRisk) => risk.isWhatCovered);
}

function priceWithCents(price: number): string {
    return usePrice().formattedCurrency(price);
}
</script>

<template>
    <div class="covered-popup-multiple">
        <div v-if="!singleMode()" class="coverage-title-header">
            <div class="coverage-title">{{
                    translateForType('subtitle_solar_panels_insurance', 'solar_panels_insurance')
                }}
            </div>
        </div>
        <div v-if="singleMode()" class="coverage-title-header single">
            <div class="coverage-title">{{ localized('title_in_single_mode') }}</div>
        </div>
        <div v-if="!singleMode()" class="insurance-plans">
            <div class="plan" v-for="(plan) in props.policies">
                <p class="plan-title">{{ translateForType(plan.id.toLowerCase(), 'solar_panels_insurance') }}</p>
                <p class="price">
                    {{
                        translateForType('covered_price_from', 'components', {'%PRICE%': priceWithCents(plan.price)})
                    }} </p>
                <app-button-with-callback
                    :title="translateForType('covered_select', 'components')"
                    @button-callback-click="onSelectedClick(plan.id)">
                </app-button-with-callback>
            </div>
        </div>
        <div class="covered-information">
            <div class="coverage-text">
                <div class="title">{{ translateForType('insurance_coverage', 'components') }}</div>
                <div class="description">{{ localized('description') }}</div>
            </div>
            <div class="sep"></div>
            <div class="title blue-background">
                {{ localized('insured_objects_title') }}
            </div>
            <div class="total-sum-container">
                <div class="sub-title">
                    {{ localized('insurance_total_sum') }}
                </div>
                <div class="coverage-sum" v-if="singleMode()"
                     v-html="sparsePrice(coveragePlanKey, true) + '&nbsp;&euro;'"></div>
            </div>
            <div v-if="!singleMode()" class="coverage-amount-list">
                <div class="list-column" v-for="(policy) in props.policies">
                    <div class="item" v-for="(plan) in policy.coveragePlans">
                        <div class="coverage-sum" v-html="sparsePrice(plan.insuredSum, true)  + '&nbsp;&euro;'"></div>
                    </div>
                </div>
            </div>
            <div class="risks-list">
                <div :class="(singleMode() ? 'single ' : '') + 'item'" v-for="(risk) in coveredRisks(props.policies[0].risks)">
                    <app-text-with-tip class="risk-title"
                                       :info-icon-side="IconSide.Before"
                                       :title="translateForType(risk.id + '_MAIN', 'solar_panels_insurance')"
                                       :tip-description="translateForType(risk.id + '_DETAILED', TranslationType)"
                                       :value="riskStatusIcon(singleMode(), risk)">
                    </app-text-with-tip>
                    <div class="is-enabled-container" v-if="!singleMode()">
                        <div class="list-column" v-for="(policy) in  props.policies">
                            <div class="item">
                                <div v-if="riskForPolicy(policy.risks, risk.id as string).isIncluded"
                                     v-html="iconContent(checkmarkIconAttributes())"></div>
                                <div v-if="!riskForPolicy(policy.risks, risk.id as string).isIncluded"
                                     v-html="iconContent(crossIconAttributes())"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="(singleMode() ? 'single ' : '') + 'item'">
                    <app-text-with-tip class="risk-title"
                                       :info-icon-side="IconSide.Before"
                                       :title="translateForType('deductible', TranslationType)"
                                       :tip-description="translateForType('deductible' + '_DETAILED', TranslationType)"
                                       :value="singleMode() ? translateForType(props.policies[0].params.deductibleIc, TranslationType) : ''">
                    </app-text-with-tip>
                    <div class="is-enabled-container" v-if="!singleMode()">
                        <div class="list-column" v-for="(policy) in  props.policies">
                            <div class="item">
                                <div
                                    v-html="translateForType(policy.params.deductibleIc, TranslationType)"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="blue-background">
                    {{ localized('insurance_territory_title') }}
                </div>
                <app-text-with-tip class="custom-risk"
                                   :info-icon-side="IconSide.Before"
                                   :title="localized(additonalTerritoryRisk().id)"
                                   :tip-description="localized('insurance_territory_at_address_detailed')"
                                   :value="riskStatusIcon(true, additonalTerritoryRisk())">
                </app-text-with-tip>
                <div class="sep"></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.covered-popup-multiple {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: var(--size-big) !important;

    .insurance-plans {
        width: 100%;
        min-height: 235px;
        max-height: 235px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(95px, 1fr));
        align-items: flex-end;
        background: var(--background-light);
        padding: var(--size-small);
        gap: var(--size-nano);
        border-top-left-radius: var(--size-tiny);
        border-top-right-radius: var(--size-tiny);
        border-bottom: 1px solid var(--black-100);

        @include respond-above('sm') {
            padding: var(--size-big);
            min-height: 277px;
            gap: var(--size-big);
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        }

        .plan {
            max-height: 117px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-self: center;
            justify-content: center;
            align-items: center;

            @include respond-above('sm') {
                margin-bottom: 0;
            }

            .plan-title {
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                color: var(--text-color-default);
            }

            .price {
                font-weight: 500;
                color: var(--text-color-subtle);
                text-align: center;
                font-size: var(--text-color-tiny);
            }

            button {
                width: 100%;
                height: 100%;
                margin-top: var(--size-small);
                background: var(--red-700);
                color: var(--text-color-inverse);
                min-height: 52px;
            }
        }
    }

    .total-sum-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: var(--size-small);

        .coverage-sum {
            white-space: nowrap;
        }
    }

    .covered-information {
        display: grid;
        padding: 0 var(--size-small) var(--size-small);
        width: 100%;
        overflow-y: auto;

        @include respond-above('sm') {
            padding: var(--size-small) var(--size-small) 0 var(--size-big);
        }

        .coverage-text {
            .title {
                font-size: var(--size-normal);
                margin-bottom: var(--size-nano) !important;
                margin-top: var(--size-small) !important;
                font-weight: 700;
            }

            .description {
                text-align: left;
                margin-bottom: var(--size-small);
            }
        }

        .coverage-amount {
            .amount {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .title {
            margin-top: var(--size-small) !important;
        }

        .sub-title {
            color: var(--text-color-default);
            font-size: var(--font-size-tiny);
            font-style: normal;
            font-weight: 600;
            margin-top: var(--size-small) !important;
            margin-bottom: var(--size-small) !important;
        }

        .status {
            border-top: 1px solid var(--black-100);
            border-bottom: 1px solid var(--black-100);
            min-height: 46px;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            justify-content: center;
            align-items: stretch;

            .item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-right: 1px solid var(--black-100);
                padding: 10px;

                div {
                    .amount {
                        color: var(--text-color-subtle);
                    }

                    .icon {
                        .checkmark {
                            height: 11px;
                            width: 16px;
                        }
                    }
                }
            }

            .item:last-child {
                border-right: none;
            }
        }
    }

    .popup-title {
        position: absolute;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--black-100);
    }

    .coverage-title-header {
        position: absolute;
        max-width: 200px !important;
        top: 30px;
        left: 25px;
        font-size: var(--size-normal);
        font-weight: 700;

        &.single {
            padding: var(--size-small);
            position: static;
            background: var(--background-light);
            max-width: 100% !important;
            border-bottom: 1px solid var(--black-100);
            border-top-left-radius: var(--size-tiny);
            border-top-right-radius: var(--size-tiny);

            @include respond-above('sm') {
                padding: var(--size-big);
            }

            .coverage-title {
                max-width: 90%;
            }
        }

        @include respond-above('sm') {
            top: 50px;
            left: var(--size-big);
            max-width: 600px;
        }
    }

    .sep {
        height: 1px;
        background: var(--black-100);
    }

    .coverage-amount-list {
        display: flex;
        margin-bottom: var(--size-small) !important;
        border-top: 1px solid var(--black-100);
        border-bottom: 1px solid var(--black-100);

        .list-column {
            flex: 1 0 50%;
            padding: var(--size-nano);

            .item {
                text-align: center;
            }
        }

        .list-column:first-of-type {
            border-right: 1px solid var(--black-100);
        }
    }

    .risks-list {
        .item {
            margin-bottom: var(--size-tiny);

            &.single {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid var(--black-100);

                .risk-title {
                    margin-top: var(--size-tiny);
                    width: 100%;
                }
            }
        }


        .is-enabled-container {
            display: flex;
            margin-top: var(--size-tiny) !important;
            margin-bottom: var(--size-small) !important;
            border-top: 1px solid var(--black-100);
            border-bottom: 1px solid var(--black-100);


            &.single {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                border-top: 0 !important;
                border-bottom: 0 !important;

                .list-column {
                    padding: 0 !important;
                    padding-top: 13px !important;
                }

                .list-column:first-of-type {
                    border-right: 0 !important;
                }
            }

            .list-column:first-of-type {
                border-right: 1px solid var(--black-100);
            }

            .list-column {
                flex: 1 0 50%;
                padding: var(--size-nano);

                .item {
                    text-align: center;
                    margin-bottom: 0;
                }
            }
        }
    }

    :deep(.text-with-tip){
        .inline {
            .risk-title {
                font-weight: 500;
            }
        }

        .expanded-text {
            margin-top: var(--size-nano) !important;
        }
    }

    .blue-background {
        background-color: var(--teal-50);
        padding: var(--size-tiny) var(--size-small);
        font-size: 18px;
        font-weight: 700;
        border-radius: var(--size-femto);
    }

    .custom-risk {
        margin-top: var(--size-tiny) !important;
        margin-bottom: var(--size-tiny) !important;
    }
}
</style>
