<script lang="ts">
import {defineComponent} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import SubmissionFormHealth from '@/Modules/ClaimsSubmissionForm/SubmissionFormHealth.vue';
import VueModule from '@/Core/App/VueModule';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const claimsHealthSubmissionFormModule = new VueModule(SubmissionFormHealth).mount();

        return {
            ...btaBase,
            ...{
                claimsHealthSubmissionFormModule
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.claimsHealthSubmissionFormModule.create();
        this.claimsHealthSubmissionFormModule.beforeModuleMounted();
        this.claimsHealthSubmissionFormModule.moduleMounted();
    },
});
</script>