<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import { computed, ComputedRef, onMounted, PropType, ref, Ref, watch } from 'vue';

const emit = defineEmits(['change', 'click']);
const props = defineProps({
    formField: {
        type: Object as PropType<FormField<boolean>>,
        default: () => new FormField('')
    },
    dataStoreDisabled: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
});
const checkboxElement: Ref<HTMLInputElement | null> = ref(null);
const fieldId: ComputedRef<string> = computed(() => {
    return props.formField.name + '-checkbox';
});

watch(() => props.formField.value, () => {
    if (props.formField.isEmpty()) {
        props.formField.setValue(false);
    } else {
        checkboxElement.value!.checked = props.formField.value;
        emitChange();
    }
});

onMounted((): void => {
    if (props.formField.isEmpty()) {
        props.formField.setValue(false);
    }
    checkboxElement.value!.checked = props.formField.value;
});

function toggle(): void {
    props.formField.patch(checkboxElement.value!.checked);
    emitChange();
}

function onClick(): void {
    toggle();
    emitClick();
}

function emitChange(): void {
    emit('change', props.formField.value);
}

function emitClick(): void {
    emit('click', props.formField.value);
}
</script>
<template>
<span class="checkbox"
      :id="formField.name"
      :class="{...formField.classes(), 'disabled': disabled}"
      :data-store="dataStoreDisabled ? '' : formField.name"
      :data-store-value="dataStoreDisabled ? '' : `${formField.value}`">
    <input type="checkbox"
           ref="checkboxElement"
           :id="fieldId"
           :name="formField.name"
           :value="formField.value"
           :checked="formField.value"
           :disabled="disabled"
            @change="toggle()"
            @click="onClick()"
    >
    <label class="title"
           :for="fieldId">
        <slot></slot>
        <span v-if="required" class="asterisk">&#42;</span>
    </label>
    <slot name="app-tooltipster"></slot>
</span>
</template>
<style lang="scss" scoped>
.title:empty {
    display: none;
}

.checkbox {
    display: flex;
    flex-direction: row;
    scroll-margin-top: 6em;

    .title {
        span {
            :deep(p) {
                font-size: inherit;
                line-height: inherit;
                font-weight: inherit;
                color: inherit;
            }
        }
    }
}

.asterisk {
    font-size: var(--font-size-medium);
    color: var(--brand-red);
}
</style>
