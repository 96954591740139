import DrawerContent from '@/Components/drawer/interfaces/drawer.content.interface';
import PolicyDetailsContent from '@/Components/PolicyDetails/Interfaces/PolicyDetailsContentInterface';
import PolicyDetailsContentTabs from '@/Components/PolicyDetails/Interfaces/PolicyDetailsContentTabsInterface';
import PolicyDetailsContentTab from '@/Components/PolicyDetails/Interfaces/PolicyDetailsContentTabInterface';
import PolicyDetails from '@/Components/PolicyDetails/Services/PolicyDetailsService';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Agreement from '@/Components/PolicyBlock/Interfaces/AgreementInterface';
import Invoice from '@/Components/PolicyBlock/Interfaces/InvoiceInterface';
import AgreementTypes from '@/Components/PolicyBlock/Enums/AgreementTypesEnum';
import {ref, Ref} from 'vue';

export default class PolicyDetailsContentBuilder {
    private drawerContent: DrawerContent = new class implements DrawerContent {
        public component: string = 'AppPolicyDetails';
        public content: DynamicDictionary = new class implements PolicyDetailsContent {
            public type: string = '';
            public title: string = '';
            public description: string = '';
            public agreement!: Agreement;
            public invoices: Invoice[] = [];
            public tabs: PolicyDetailsContentTabs = new class implements PolicyDetailsContentTabs {
                public active: Ref<number> = ref(0);
                public elements: PolicyDetailsContentTab[] = [];
            };
        }
        public translationsType: string = '';
    }

    public withTranslationsType(translationsType: string): PolicyDetailsContentBuilder {
        this.drawerContent.translationsType = translationsType;

        return this;
    }

    public withAgreement(value: Agreement): PolicyDetailsContentBuilder {
        this.drawerContent.content.agreement = value;

        return this;
    }

    public withInvoices(value: Invoice[]): PolicyDetailsContentBuilder {
        this.drawerContent.content.invoices = value;

        return this;
    }

    public withType(type: AgreementTypes): PolicyDetailsContentBuilder {
        this.drawerContent.content.type = type;

        return this;
    }

    public withTitle(title: string): PolicyDetailsContentBuilder {
        this.drawerContent.content.title = title;

        return this;
    }

    public withDescriptionTitle(title: string): PolicyDetailsContentBuilder {
        this.drawerContent.content.description = title;

        return this;
    }

    public withTabs(tabs: PolicyDetailsContentTab[]): PolicyDetailsContentBuilder {
        this.drawerContent.content.tabs.elements = tabs;

        return this;
    }

    public withActiveTab(activeTabId: number): PolicyDetailsContentBuilder {
        this.drawerContent.content.tabs.active.value = activeTabId;

        return this;
    }

    public build(): DrawerContent {
        PolicyDetails.getInstance().addContent(this.drawerContent.content as PolicyDetailsContent);

        return this.drawerContent;
    }
}
