import {
    Router,
    RouteLocationNormalized,
    RouteLocationNormalizedLoaded,
    NavigationGuardNext,
    useRouter
} from 'vue-router';
import {useDefine} from '@/Composables/Define';
import Steps from '@/Apps/SolarPanels/Enums/Steps';
import Storage from '@/Apps/SolarPanels/Services/Storage';

const {isSet} = useDefine();

export default class StepsGuard {
    private static instance: StepsGuard;
    private router: Router = useRouter();
    private storage: Storage;

    private constructor(storage: Storage) {
        this.storage = storage;
    }

    public static getInstance(storage: Storage): StepsGuard {
        if (!StepsGuard.instance) {
            StepsGuard.instance = new StepsGuard(storage);
        }

        return StepsGuard.instance;
    }

    public init(): void {
        this.preventRouteDirectAccess();
        this.hideAdditionalModules();
        this.router.beforeEach((to: RouteLocationNormalized, _from: RouteLocationNormalizedLoaded, next: NavigationGuardNext): void => {
            this.storage.destroyRoute();
            let isInvalidRoute: boolean = false;
            switch (to.name) {
                case Steps.Coverage:
                case Steps.Address:
                    if (!isSet(this.storage.fields.selectedProgram)) {
                        isInvalidRoute = true;
                    }
                    break;
                case Steps.SummaryAndPayment:
                    if (!isSet(this.storage.fields.selectedProgram) || !isSet(this.storage.fields.address)) {
                        isInvalidRoute = true;
                    }
                    break;
                default:
            }
            isInvalidRoute ? next(false) : next();
        });
    }

    public preventRouteDirectAccess(): void {
        if (!isSet(this.storage.fields.selectedProgram) && this.router.currentRoute.value.name !== Steps.Insurance) {
            this.router.push({name: Steps.Insurance}).then();
            if (this.router.currentRoute.value.name === Steps.SummaryAndPayment && !isSet(this.storage.fields.address)) {
                this.router.push({name: Steps.Insurance}).then();
            }
        }
    }

    private hideAdditionalModules(): void {
        const isInitialStep: boolean = this.router.currentRoute.value.name === Steps.Insurance;
        const additionalModules: JQuery<HTMLElement> = $('.page-one-solar-panels').children('section');
        additionalModules.each((_index: number, module: HTMLElement): void => {
            isInitialStep
                ? $(module).show()
                : $(module).hide();
        });
    }
}
