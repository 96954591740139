<script setup lang="ts">

import FormField from '@/assets/libraries/form/form-field';
import {computed, ComputedRef, watch} from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useDefine} from '@/Composables/Define';
import {useGenerator} from '@/Composables/Generator';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import ActiveElement from '@/assets/libraries/Dom/ActiveElement';


const props = defineProps({
    formField: {type: FormField, default: () => new FormField('')},
    url: {type: String, default: ''},
    target: {type: String, default: '_blank'},
    mode: {type: String, default: 'button'},
    title: {type: String, default: 'default'},
    subtitle: {type: String, default: ''},
    icon: {type: String, default: ''},
    iconPosition: {type: String, default: ButtonIconPosition.Left},
    backgroundColor: {type: String, default: ButtonBackground.LightGrey},
    backgroundColorHover: {type: String, default: ButtonBackground.Red},
    textColor: {type: String, default: ButtonTextColor.Black},
    textColorHover: {type: String, default: ButtonTextColor.White},
    borderColor: {type: String, default: ButtonTextColor.Grey},
    iconColor: {type: String, default: ButtonIconColor.White},
    iconWidth: {type: Number, default: 24},
    iconHeight: {type: Number, default: 24},
    callback: {type: Function, default: () => {}},
    callbackParams: {
        type: [Object, String], default: () => {
            return {};
        }
    },
    dataStoreDisabled: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
});

const emit = defineEmits(['button-callback-click']);

const {isSet} = useDefine();
const {autoFieldId} = useGenerator();

const BackgroundColorPrefix: string = 'background-';
const BackgroundColorHoverPrefix: string = 'background-hover-';
const TextColorPrefix: string = 'text-';
const TextColorHoverPrefix: string = 'text-hover-';
const IconColorPrefix: string = 'icon-';
const BadgeModeIdentifier: string = 'badge';
const BadgeButtonModeIdentifier: string = 'badge-button';
const BorderColorPrefix: string = 'border-';

watch(() => props.formField.value, (newValue, oldValue) => {
    if (newValue !== oldValue && oldValue !== '') {
        emitButtonCallbackClick();
    }
});

function onClick(): void {
    if (props.formField.name !== '') {
        const currentValue: boolean = props.formField.value;
        props.formField.patch(!currentValue);
    }
    emitButtonCallbackClick();
}

function onMouseLeave(event: Event): void {
    if (ActiveElement.is(event.target)) {
        ActiveElement.blur();
    }
}

function emitButtonCallbackClick(): void {
    let result: DynamicDictionary = {};
    if (props.formField.name !== '') {
        result = {
            name: props.formField.name,
            value: props.formField.value
        }
    }
    isSet(props.callbackParams)
    {
        result.params = props.callbackParams;
    }
    emit('button-callback-click', result)
    props.callback(props.callbackParams);
}

function cssClasses(): object {
    return {
        [backgroundColorClass.value]: true,
        [backgroundColorHoverClass.value]: props.backgroundColorHover !== '',
        [textColorClass.value]: true,
        [textColorHoverClass.value]: props.textColorHover !== '',
        [BadgeModeIdentifier]: badgeModeEnabled.value,
        [iconColorClass.value]: !badgeModeEnabled.value,
        [borderColorClass.value]: props.backgroundColor !== '',
        ['triggered']: triggered.value,
    };
}

const formFieldName: ComputedRef<string> = computed(() => {
    return props.formField.name || autoFieldId()
});

const tagType: ComputedRef<string> = computed(() => {
    return props.url ? 'a' : 'button';
});

const targetType: ComputedRef<string> = computed(() => {
    return props.url ? props.target : '';
});

const iconSrc: ComputedRef<string> = computed(() => {
    return `images/one/components/button-with-callback/${props.icon}.svg`;
});

const badgeModeEnabled: ComputedRef<boolean> = computed(() => {
    return props.mode === BadgeModeIdentifier || props.mode === BadgeButtonModeIdentifier;
});

const displayIcon: ComputedRef<boolean> = computed(() => {
    return props.icon !== '' && !badgeModeEnabled.value && !props.disabled;
});

const backgroundColorClass: ComputedRef<string> = computed(() => {
    return BackgroundColorPrefix + props.backgroundColor;
});

const backgroundColorHoverClass: ComputedRef<string> = computed(() => {
    return BackgroundColorHoverPrefix + props.backgroundColorHover;
});

const textColorClass: ComputedRef<string> = computed(() => {
    return TextColorPrefix + props.textColor;
});

const textColorHoverClass: ComputedRef<string> = computed(() => {
    return TextColorHoverPrefix + props.textColorHover;
});

const iconColorClass: ComputedRef<string> = computed(() => {
    return IconColorPrefix + props.iconColor;
});

const borderColorClass: ComputedRef<string> = computed(() => {
    return BorderColorPrefix + props.borderColor;
});

const triggered: ComputedRef<boolean> = computed(() => {
    return props.formField.name !== '' && (
        props.mode !== BadgeButtonModeIdentifier && props.formField.value === true
    );
});
</script>

<template>
    <component class="button-with-callback"
               :is="tagType"
               :href="url"
               :target="targetType"
               :id="formFieldName"
               :class="{...cssClasses()}"
               :disabled="disabled"
               :data-store="dataStoreDisabled ? '' : formFieldName"
               :data-store-value="dataStoreDisabled ? '' : `${props.formField.value}`"
               @mouseleave="onMouseLeave($event)"
               @click="onClick">
        <img class="icon"
             alt=""
             v-if="displayIcon && iconPosition === 'left'"
             :width="props.iconWidth"
             :height="props.iconHeight"
             :src="iconSrc">
        <span v-html="title"></span>
        <span v-if="subtitle !== ''" class="subtitle" v-html="subtitle"></span>
        <img class="icon icon-right"
             alt=""
             v-if="displayIcon && iconPosition === 'right'"
             :width="props.iconWidth"
             :height="props.iconHeight"
             :src="iconSrc">
    </component>
</template>

<style lang="scss" scoped>
.button-with-callback {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 8px;
    display: inline-flex;
    flex-direction: row;
    font-family: var(--text-font-stack);
    font-size: var(--font-size-tiny);
    height: 40px;
    justify-content: center;
    overflow: visible;
    padding: 0 var(--size-tiny);
    position: relative;
    transition: background .3s, color .3s;
    white-space: normal;
    word-wrap: break-word;

    &.badge {
        border-radius: 100px;
        height: 32px;
        padding: var(--size-nano) 16px;
    }

    &.background-black {
        background-color: var(--black-950);
        border-color: transparent;
    }

    &.background-white {
        background-color: var(--white);
        border-color: var(--button-color-border-secondary-default);
    }

    &.background-grey {
        background-color: var(--black-500);
    }

    &.background-red {
        background-color: var(--button-color-background-primary-red-default);
    }

    &.background-orange {
        background-color: var(--orange-500);
    }

    &.background-light-grey {
        background-color: var(--black-50);
    }

    &.background-green {
        background-color: var(--brand-teal);
    }

    &.background-dark-green {
        background-color: var(--button-color-background-primary-green-default);
    }

    &.background-blue {
        background-color: var(--brand-blue);
    }

    &.background-transparent {
        background-color: transparent;
        border: 1px solid var(--component-color-border-default);
    }

    &.text-white {
        color: var(--white);
    }

    &.text-black {
        color: var(--button-color-text-secondary-default);
    }

    &.text-red {
        color: var(--brand-red);
    }

    &.text-green {
        color: var(--brand-teal);
    }

    &.text-blue {
        color: var(--brand-blue);
    }

    &.text-grey {
        color: var(--black-500);
    }

    &.text-link {
        color: var(--text-color-link);
    }

    &.icon-green {
        .icon {
            filter: var(--to-color-green);
        }
    }

    &.icon-red {
        .icon {
            filter: var(--to-color-red);
        }
    }

    &.icon-blue {
        .icon {
            filter: var(--to-color-blue);
        }
    }

    &.icon-grey {
        .icon {
            filter: var(--to-color-color-grey);
        }
    }

    &.icon-white {
        .icon {
            filter: var(--to-color-white);
        }
    }

    &.icon-link {
        .icon {
            filter: var(--to-color-link);
        }
    }

    &:focus-visible:not(.triggered) {
      outline: solid 2px var(--button-color-text-secondary-default);
      outline-offset: 2px;
    }

    &:hover {
        background-color: var(--button-color-background-primary-red-hover);
    }

    &.triggered {
        color: var(--text-color-default);
        background-color: var(--white);
        border-color: var(--black-500);
    }

    &:active {
        @include button-active;
    }

    &:disabled {
        color: var(--black-500);
        background: var(--button-color-background-primary-red-disabled);
        cursor: default;
        pointer-events: none;

        .icon {
            filter: var(--to-color-grey);
        }
    }

    .icon {
        margin-right: var(--size-tiny);
    }

    .icon-right {
        margin-right: 0;
        margin-left: 18px;
    }

    &.border {
        &-green {
            border: 1px solid var(--brand-teal);
        }

        &-red {
            border: 1px solid var(--brand-red);
        }

        &-blue {
            border: 1px solid var(--brand-blue);
        }

        &-orange {
            border: 1px solid var(--orange-500);
        }

        &-transparent {
            border: 1px solid transparent;
        }

        &-pale {
            border: 1px solid var(--button-color-border-secondary-default);
        }

        &-black {
            border: 1px solid var(--component-color-border-hover);
        }
    }

    &.triggered,
    &.triggered:focus,
    &:hover {
        &.background-hover-black {
            background-color: var(--black-950);
        }

        &.background-hover-white {
            background-color: var(--white);
            border-color: var(--black-500);

            &.border-transparent {
                border-color: transparent;
            }
        }

        &.background-hover-grey {
            background-color: var(--black-200);
        }

        &.background-hover-orange {
            background-color: var(--orange-600);
        }

        &.background-hover-light-grey {
            background-color: var(--black-300);
        }

        &.background-hover-green {
            background-color: var(--brand-teal);
        }

        &.background-hover-red {
            background-color: var(--button-color-background-primary-red-hover);
        }

        &.background-hover-dark-green {
            background-color: var(--button-color-background-primary-green-hover);
        }

        &.background-hover-blue {
            background-color: var(--brand-blue);
        }

        &.background-hover-transparent {
            background-color: transparent;
            border: 1px solid var(--component-color-border-hover);
        }

        &.text-hover-black {
            color: var(--text-color-default);
        }

        &.text-hover-red {
            color: var(--brand-red);
        }

        &.text-hover-green {
            color: var(--brand-teal);
        }

        &.text-hover-blue {
            color: var(--brand-blue);
        }

        &.text-hover-grey {
            color: var(--black-500);
        }

        &.text-hover-link {
            color: var(--text-color-link-hover);
        }

        &.icon-hover-green {
            .icon {
                filter: var(--to-color-green);
            }
        }

        &.icon-hover-red {
            .icon {
                filter: var(--to-color-red);
            }
        }

        &.icon-hover-blue {
            .icon {
                filter: var(--to-color-blue);
            }
        }

        &.icon-hover-grey {
            .icon {
                filter: var(--to-color-color-grey);
            }
        }

        &.icon-hover-white {
            .icon {
                filter: var(--to-color-white);
            }

            &:focus,
            &:hover {
                .icon {
                    filter: none;
                }
            }
        }

        &.background-white {
            border: 1px solid var(--component-color-border-hover);
        }
    }

    &:active {
        &.background-red {
            background-color: var(--button-color-background-primary-red-focus);
        }

        &.background-dark-green {
            background-color: var(--button-color-background-primary-green-focus);
        }

        &.background-white {
            outline: 1px solid var(--button-color-border-secondary-focus);
            border: 1px solid var(--button-color-border-secondary-focus);
        }
    }

    &.small {
        height: 32px;
        font-size: var(--font-size-pico);
        font-weight: 600;
        padding: 8px var(--size-nano);

        .icon {
            margin-left: var(--size-pico);
            height: var(--size-pico);
        }
    }

    &.big {
        height: 52px;
        padding: 17px var(--size-big);
        font-size: var(--font-size-tiny);
    }

    .subtitle {
        margin-left: auto;
        font-size: var(--size-nano);
        color: var(--text-color-subtle);
        font-weight: 600;
    }
}
</style>
