<script setup lang="ts">

import ResultMessage from '@/Components/ResultMessage/Services/ResultMessageService';
import ResultMessageType from '@/Components/ResultMessage/Enums/ResultMessageTypeEnum';

const resultMessage: ResultMessage = ResultMessage.getInstance();
</script>

<template>
    <div class="result-message">
        <div class="result-message-container"
             v-if="resultMessage.isVisible.value">
            <aside class="overlay"
                   :class="{'popup-mode':resultMessage.isModal()}"></aside>
            <div class="container">
                <img class="image"
                     v-if="resultMessage.content().type === ResultMessageType.Success"
                     src="images/one/success--icon--big.svg" alt="" width="60" height="60">
                <img class="image"
                     v-if="resultMessage.content().type === ResultMessageType.Warning"
                     src="images/one/warning--icon--big.svg" alt="" width="60" height="60">
                <img class="image"
                     v-if="resultMessage.content().type === ResultMessageType.Error"
                     src="images/one/error--icon--big.svg" alt="" width="60" height="60">
                <div class="title"
                     v-if="resultMessage.content().title !== ''">{{ resultMessage.content().title }}
                </div>
                <div class="description"
                     v-if="resultMessage.content().description !== ''"
                     v-html="resultMessage.content().description">
                </div>
                <div class="buttons">
                    <app-button-with-callback
                        v-for="(button, index) in resultMessage.content().buttons"
                        :key="index"
                        v-bind="button">
                    </app-button-with-callback>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.result-message {
    .result-message-container {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 4;
        display: flex;
        justify-content: center;
        align-items: center;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            background: var(--brand-black);
            opacity: 0.24;

            &.popup-mode {
                background-color: var(--background-light);
                opacity: 1;
            }

            @include respond-below('xs') {
                background-color: var(--background-light);
                opacity: 1;
            }
        }

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: auto;
            max-width: 600px;
            min-height: 400px;
            background-color: var(--background-light);
            position: relative;
            z-index: 1;
            padding: 60px;
            text-align: center;
            border-radius: var(--size-tiny);

            .image {
                margin-bottom: var(--size-normal);
            }

            .title {
                font-size: var(--font-size-medium);
                font-weight: 700;
                margin-bottom: var(--size-small);
            }

            .description {
                font-size: var(--font-size-tiny);
                color: var(--text-color-subtle);
                margin-bottom: var(--size-big);
                line-height: 22.4px;
            }

            .buttons {
                display: flex;
                gap: var(--size-small);
                flex-direction: column;

                .button-with-callback {
                    min-width: 286px;
                    min-height: 52px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                @include respond-below('sm') {
                    flex-direction: column;
                }
            }
        }
    }
}
</style>