<script lang="ts">
import {defineComponent} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import DataLayerFacilities from '@/Enums/DataLayerFacilitiesEnum';
import GetInTouchModule from '@/Modules/GetInTouch/GetInTouch.vue';
import VueModule from '@/Core/App/VueModule';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();
        const getInTouchModule = new VueModule(GetInTouchModule).mount();
        return {
            ...btaBase,
            ...{getInTouchModule}
        }
    },

    mounted() {
        this.applyApp(this);
        this.setFacility(DataLayerFacilities.BicycleInsurance)
        this.create();
        this.initBtaBase();
    },
});
</script>
