<script lang="ts">
import {computed, defineComponent, ref, Ref, nextTick} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import DateFormat from '@/Enums/DateFormatEnum';
import DataLayerType from '@/Enums/DataLayerTypeEnum';
import UserClaims from '@/interfaces/user.claims.interface';
import UserClaim from '@/interfaces/user.claim.interface';
import StringDictionary from '@/interfaces/string.dictionary.interface';
import UploadedFile from '@/interfaces/uploaded.file.interface';
import {Subscription} from 'rxjs';
import ModalType from '@/Enums/ModalTypeEnum';
import {OneDashboardInterface, useOneDashboard} from '@/pages/OneDashboard/Composables/OneDashboard';
import {useDefine} from '@/Composables/Define';
import {useTranslate} from '@/Composables/Translate';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import moment from 'moment/moment';
import OneDate from '@/assets/libraries/Date/OneDate';
import VueModel from '@/services/vue.model.service';
import VueEvent from '@/Classes/VueEventClass';
import UrlBuilder from '@/assets/libraries/url/url-builder';
import {SubmitParam} from '@/Types/SubmitParamType';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import {useAxios} from '@/Composables/Axios';
import Url from '@/Enums/UrlEnum';
import {AxiosResponse} from 'axios';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {useCore} from '@/Composables/Core';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const {isSet} = useDefine();
        const oneDashboard: OneDashboardInterface = useOneDashboard();

        const CurrentStep: number = 1;
        const Facility: string = 'dashboard';
        const skipMarker: string = 'DO_NOT_SHOW';
        const DateFormatter: string = 'DD.MM.YYYY';
        const dateFormat: string = DateFormat.Default.Short;
        const ClaimUploadFiles: string = DataLayerType.ClaimUploadFiles;
        const isUploadRestricted: boolean = false;


        const fileUploadOpened: Ref<boolean> = ref(false);
        const claims: Ref<UserClaims> = ref(new class implements UserClaims {
            public active: UserClaim[] = [];
            public resolved: UserClaim[] = [];
        });
        const claimsFetchIsInProgress: Ref<boolean> = ref(false);
        const claim: Ref<StringDictionary> = ref({});
        const claimDocuments: Ref<StringDictionary> = ref({});
        const correspondentTitle: Ref<string> = ref('');
        const healthClaimCaseNumber: Ref<string> = ref('');
        const showFilesUploadNotification: Ref<boolean> = ref(false);


        const uploadedFiles: Ref<UploadedFile[]> = ref([]);
        const filesUploader: Ref<HTMLDivElement | null> = ref(null);


        const showFilesUploadThankYou: Ref<boolean> = computed(() => {
            return showFilesUploadNotification.value;
        });

        const submitDocumentsButtonIsVisible: Ref<boolean> = computed(() => {
            return !fileUploadOpened.value;
        });

        const addFilesButtonIsDisabled: Ref<boolean> = computed(() => {
            let filesIsEmpty: boolean = true;
            let uploadInProgress: boolean = true;
            if (filesUploader.value) {
                filesIsEmpty = (filesUploader.value as DynamicDictionary).filesCount === 0;
                uploadInProgress = (filesUploader.value as DynamicDictionary).uploadInProgress;
            }

            return filesIsEmpty || uploadInProgress;
        });

        const fetchingLoaderIsVisible: Ref<boolean> = computed(() => {
            return claimsFetchIsInProgress.value;
        });

        const noClaimsIsVisible: Ref<boolean> = computed(() => {
            const activeCount: number = Array.isArray(claims.value.active) ?
                claims.value.active.length : useDefine().objectMembersCount(claims.value.active);
            const resolvedCount: number = Array.isArray(claims.value.resolved) ?
                claims.value.resolved.length : useDefine().objectMembersCount(claims.value.resolved);

            return activeCount === 0 && resolvedCount === 0 && !claimsFetchIsInProgress.value;
        });

        const activeClaimsCount: Ref<number> = computed(() => {
            return useDefine().objectMembersCount(claims.value.active);
        });

        const claimsTitle: Ref<string> = computed(() => {
            return useDefine().objectMembersCount(claims.value.active) > 1 ?
                useTranslate().translate('btar_my_claims_in_progress') : useTranslate().translate('btar_my_claim_in_progress');
        });

        const combinedClaims: Ref<UserClaim[]> = computed(() => {
            return claims.value.active
                .concat(claims.value.resolved)
                .sort((a: UserClaim, b: UserClaim): number =>
                    moment(a.ApplicationDate, DateFormatter) < moment(b.ApplicationDate, DateFormatter) ? 1 : -1);
        });

        const claimIsReady: Ref<boolean> = computed(() => {
            return isSet(claim.value.Uuid);
        });

        const eventDescriptionText: Ref<string> = computed(() => {
            return claim.value.EventDescription !== skipMarker ?
                claim.value.EventDescription : '';
        });

        const eventDate: Ref<string> = computed(() => {
            return OneDate.short(claim.value.EventDate);
        });

        const attachedFiles: Ref<UploadedFile[]> = computed(() => {
            return uploadedFiles.value;
        });


        function textBlockIsVisible(claim: UserClaim, block: string): boolean {
            return VueModel.modelValueByName(block, claim) !== '';
        }

        function coveredAmountIsVisible(claim: UserClaim): boolean {
            return isSet(claim.CoveredAmount) && claim.CoveredAmount !== '0';
        }

        function notificationTypeText(claim: UserClaim): string {
            return isSet(claim.NotificationTypeText) ?
                claim.NotificationTypeText : correspondentTitle.value;
        }

        function claimSubmissionDate(value: string): string {
            return OneDate.short(value);
        }

        function submitDocumentClick(event: VueEvent): void {
            showFilesUploader(fileUploadOpened.value = !fileUploadOpened.value);
        }

        function showFilesUploader(isVisible: boolean): void {
            const uploadBlock: JQuery = $('.file-upload');
            if (fileUploadOpened.value) {
                uploadBlock.stop(true, false).slideDown();
            } else {
                uploadBlock.stop(true, false).slideUp();
            }
        }

        function attachmentHref(item: DynamicDictionary): string {
            return new UrlBuilder()
                .withLanguage(useTranslate().language())
                .withUri('attachments/getAttachment')
                .withGetParams({fileId: item.id, filename: item.name})
                .build();
        }

        function submitDate(item: any): string {
            return btaBase.momentLocalized(item.created).format(dateFormat);
        }

        function showCompensation(): boolean {
            const approvedSumIsValid: boolean = claim.value.SumApproved !== '0' && claim.value.SumApproved !== '';
            let userIsBeneficiaryReceiver: boolean = false;
            if (isSet(claimDocuments.value.ReceiverRegCode)) {
                const clearLoggedUserPersonCode: string = String(btaBase.user.current.personCode).replace('-', '');
                const clearReceiverRegCode: string = String(claimDocuments.value.ReceiverRegCode).replace('-', '');
                userIsBeneficiaryReceiver = clearLoggedUserPersonCode === clearReceiverRegCode;
            }

            return approvedSumIsValid && userIsBeneficiaryReceiver;
        }

        function compensationDate(): string {
            let result: string = '';
            if (claim.value.EventDescription !== skipMarker) {
                result = OneDate.short(claim.value.EventDate);
            }

            return result;
        }

        function onClaimClick(newClaim: UserClaim): void {
            showFilesUploadNotification.value = false;
            fileUploadOpened.value = false;
            const params: SubmitParam = {
                claimId: newClaim.Uuid,
                claimNr: newClaim.ClaimCaseNo,
            };
            const headers: DynamicDictionary = {};
            btaBase.lockInput(true);
            PopupService.getInstance().show(new OnePopup().withType().loading);
            useAxios().post(Url.Ajax.oneDashboardGetClaim, params, {
                    headers: headers
                }
            ).then((value: AxiosResponse<DynamicDictionary>): void => {
                if (isSet(value.data.errors)) {
                    throw (value.data.errors.code);
                }
                claim.value = value.data.data.body.claimInfo;
                claimDocuments.value = value.data.data.body.claimDocuments;
                if (claim.value['PolicyType'] === '') {
                    claim.value['PolicyType'] = newClaim.PolicyType;
                }
                uploadedFiles.value = value.data.data.body.claimAttachments;
                PopupService.getInstance().hide().then(() => {
                    btaBase.showModal(ModalType.ClaimsOpened);
                    nextTick((): void => {
                        useCore().forceUpdate();
                    });
                    btaBase.lockInput(false);
                });
            }).catch((reason: DynamicDictionary): void => {
                btaBase.lockInput(false);
                btaBase.error.show(ErrorType.Error, 'onClaimClick', reason);
            });
        }

        function addAttachmentsToClaimCaseClick(event: VueEvent): void {
            const params: DynamicDictionary = {
                policyId: claim.value.PolicyId,
                policyNumber: claim.value.PolicyNumber,
                claimNr: claim.value.ClaimCaseNo,
                policyType: claim.value.PolicyType,
                claimId: claim.value.Uuid,
                uploader: claim.value.ClaimCaseNo,
            };
            const headers: DynamicDictionary = {};
            btaBase.lockInput(true);
            PopupService.getInstance().show(new OnePopup().withType().loading);
            useAxios().post(Url.Ajax.addOneAttachmentsToClaimCase, params, {
                    headers: headers
                }
            ).then((value: AxiosResponse): void => {
                uploadedFiles.value = value.data.data.body.attachments;
                showFilesUploader(fileUploadOpened.value = !fileUploadOpened.value);
                (filesUploader.value as DynamicDictionary).clearUploads();
                PopupService.getInstance().hide();
                showFilesUploadNotification.value = true;
            }).catch((reason: DynamicDictionary): void => {
                btaBase.error.show(ErrorType.Error, 'addAttachmentsToClaimCaseClick', reason);
            }).finally((): void => {
                btaBase.lockInput(false);
                btaBase.changeBodyVerticalScrollState();
            });
        }

        function applyHealthClaimCaseNumber(claimCaseNumber: string): void {
            healthClaimCaseNumber.value = claimCaseNumber;
        }

        function fetchClaims(): void {
            const headers: DynamicDictionary = {};
            claimsFetchIsInProgress.value = true;
            btaBase.lockInput(true);
            useAxios().post(Url.Ajax.oneDashboardGetClaims, {}, {
                    headers: headers
                }
            ).then((value: AxiosResponse): void => {
                claims.value = value.data.data.body;
                btaBase.lockInput(false);
                claimsFetchIsInProgress.value = false;
                if (healthClaimCaseNumber.value !== '') {
                    showHealthClaimSuccess();
                }
            }).catch((reason: DynamicDictionary): void => {
                btaBase.lockInput(false);
                claimsFetchIsInProgress.value = false;
                btaBase.error.show(ErrorType.Error, 'fetchClaims', reason);
            });
        }

        function showHealthClaimSuccess(): void {
            PopupService.getInstance().show(
                new OnePopup()
                    .withType()
                    .claimSuccess
                    .withTitle(useTranslate().translate('submit_health_claim_success_title'))
                    .withDescription(useTranslate().translate('submit_health_claim_type'))
                    .withSecondaryDescription(useTranslate()
                        .translate('submit_health_claim_case_number') + healthClaimCaseNumber.value)
            );
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                ClaimUploadFiles,
                isUploadRestricted,
                showFilesUploadNotification,
                fileUploadOpened,
                claims,
                claimsFetchIsInProgress,
                claim,
                claimDocuments,
                uploadedFiles,
                correspondentTitle,
                healthClaimCaseNumber,
                oneDashboard,
                filesUploader,
                textBlockIsVisible,
                coveredAmountIsVisible,
                notificationTypeText,
                claimSubmissionDate,
                submitDocumentClick,
                showFilesUploader,
                attachmentHref,
                submitDate,
                showCompensation,
                compensationDate,
                onClaimClick,
                addAttachmentsToClaimCaseClick,
                applyHealthClaimCaseNumber,
                fetchClaims,
                showFilesUploadThankYou,
                submitDocumentsButtonIsVisible,
                fetchingLoaderIsVisible,
                noClaimsIsVisible,
                activeClaimsCount,
                claimsTitle,
                combinedClaims,
                claimIsReady,
                eventDescriptionText,
                eventDate,
                attachedFiles,
                addFilesButtonIsDisabled,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.oneDashboard.buildNavigationBelt();
        const modalSubscription: Subscription = this.modal.onBeforeClose.subscribe((modalType: string): void => {
            if (modalType === ModalType.ClaimsOpened) {
                this.claim = {};
                this.claimDocuments = {};
                this.uploadedFiles = [];
                modalSubscription.unsubscribe();
            }
        });
        const externalDataSubscription: Subscription = this.onExternalDataIsReady.subscribe((modalType: void): void => {
            this.correspondentTitle = useTranslate().translate('btar_correspondent');
            externalDataSubscription.unsubscribe();
        });
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.fetchClaims();
            this.setOffersCount();
            onAppIsPreparedAndReady.unsubscribe();
        });
    }
});
</script>
