import {markRaw} from 'vue';
import Form from '@/assets/libraries/form/form';
import FormField from '@/assets/libraries/form/form-field';
import Translations from '@/services/translations.service';
import moment from 'moment';
import {useCurrency} from '@/Composables/Currency';
import ModalTab from '@/pages/LegalPerson/Workbench/Common/Tabs/ModalTab';
import LegalInsuredPerson from '@/pages/LegalPerson/Workbench/Interfaces/LegalInsuredPersonInterface';

export default class ViewPersonTab extends ModalTab {
    public form: Form = markRaw(new Form());
    public customClass: string = 'narrowed-centered';
    private static instance: ViewPersonTab;
    private person!: LegalInsuredPerson;

    public static getInstance(): ViewPersonTab {
        if (!ViewPersonTab.instance) {
            ViewPersonTab.instance = new ViewPersonTab();
        }

        return ViewPersonTab.instance;
    }

    public init(): void {
        this.setupForm().then((): void => {
            this.person = this.modal.personForEdit;
            this.patchValues();
            this.form.setReady();
        });
    }

    public destroy(): void {
        this.form.destroy();
    }

    private setupForm(): Promise<void> {
        return new Promise(resolve => {
            this.form.addField(new FormField('name'));
            this.form.addField(new FormField('surname'));
            this.form.addField(new FormField('identityNumber'));
            this.form.addField(new FormField('email'));
            this.form.addField(new FormField('dateOfBirth'));
            this.form.addField(new FormField('legalStatus'));
            this.form.addField(new FormField('policyStartDate'));
            this.form.addField(new FormField('policyEndDate'));
            this.form.addField(new FormField('insuranceProgram'));
            this.form.addField(new FormField('yearlyPremium'));
            this.form.addField(new FormField('countryIc'));
            resolve();
        });
    }

    private patchValues(): void {
        if (this.person) {
            this.form.field('name').patch(this.person.firstName);
            this.form.field('surname').patch(this.person.lastName);
            this.form.field('identityNumber').patch(this.person.personCode);
            this.form.field('email').patch(this.person.email);
            this.form.field('dateOfBirth').patch(moment(this.person.birthDate).format(this.modal.app.DateFormat));
            this.form.field('legalStatus').patch(this.legalStatus());
            this.form.field('policyStartDate').patch(moment(this.person.startDate).format(this.modal.app.DateFormat));
            this.form.field('policyEndDate').patch(moment(this.person.endDate).format(this.modal.app.DateFormat));
            this.form.field('insuranceProgram').patch(this.person.insuranceProgram.name);
            this.form.field('yearlyPremium').patch(this.person.premium!.amount! +
                ' ' + useCurrency().currency(this.person.premium!.currency!));
            if (this.person.countryIc) {
                this.form.field('countryIc').patch({ic: this.person.countryIc});
            }
        }
    }

    private legalStatus(): string {
        return this.person.resident ? this.translated('popup_column_resident') : this.translated('non_resident');
    }

    private translated(key: string): string {
        return Translations.getInstance().localized(key, 'legal');
    }
}
