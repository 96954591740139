<script lang="ts">
import {computed, defineComponent, ref, Ref, nextTick} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import PropertyClaimOption from '@/interfaces/property.claim.option.interface';
import PropertyClaimsPanelsNames from '@/Enums/PropertyClaimsPanelsNamesEnum';
import {Subscription} from 'rxjs';
import FormField from '@/assets/libraries/form/form-field';
import {useDefine} from '@/Composables/Define';
import {useStrings} from '@/Composables/Strings';
import {useTranslate} from '@/Composables/Translate';
import Translations from '@/services/translations.service';
import Url from '@/Enums/UrlEnum';
import {InputOption} from '@/interfaces/InputOptionInterface';
import {useTransforms} from '@/Composables/Transforms';
import AppCountry from '@/assets/libraries/app/app-country';
import {InsuredObjectRisk} from '@/interfaces/one_policy/insured.object.risk.interface';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import {useAxios} from '@/Composables/Axios';
import {AxiosResponse} from 'axios';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import Value from '@/assets/libraries/form/value';
import Coordinates = JQuery.Coordinates;
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import SubmitterUrls from '@/services/SubmitterUrls.service';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const {translateForType} = useTranslate();
        const {isSet, objectMembersCount} = useDefine();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 3;
        const Facility: string = 'one-claims-property';
        const LtSaikasObject: string = 'LT_SAIKAS'
        const SplitSeparator: string = ':';
        const StandardCaseType: string = 'STANDARD';
        const NotIncludedException: string = 'claims_property_not_reported';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const claimsPropertyObjects: Ref<Map<string, DynamicDictionary[]>> = ref(new Map());
        const panels: Ref<PropertyClaimOption[]> = ref([
            {
                name: PropertyClaimsPanelsNames.HappenedWith,
                options: [],
                passed: false,
            },
            {
                name: PropertyClaimsPanelsNames.AcceptResponsibility,
                options: [],
                passed: false,
            },
            {
                name: PropertyClaimsPanelsNames.WhatCauseEvent,
                options: [],
                passed: false,
            },
            {
                name: PropertyClaimsPanelsNames.WhatHappened,
                options: [],
                passed: false,
            },
            {
                name: PropertyClaimsPanelsNames.DescribeEvent,
                options: [],
                passed: false,
            },
            {
                name: PropertyClaimsPanelsNames.ToWhomReported,
                options: [],
                passed: false,
            },
        ]);
        const isVisibleInfoNote: Ref<boolean> = ref(false);
        const includeMapping: Ref<DynamicDictionary> = ref({});
        const isSelectedCivil: Ref<boolean> = ref(false);
        const describeEvent: Ref<string> = ref('');
        const fileUploadMessage: Ref<string> = ref('');


        const happenedWithProceedIsDisabled: Ref<boolean> = computed(() => {
            return form.field(PropertyClaimsPanelsNames.HappenedWith).isEmpty() ||
                (
                    isSet(form.field(PropertyClaimsPanelsNames.HappenedWith).value.selected) &&
                    form.field(PropertyClaimsPanelsNames.HappenedWith).value.selected === ''
                );
        });

        const acceptResponsibilityProceedIsDisabled: Ref<boolean> = computed(() => {
            let maxLengthIsInvalid: boolean = false;
            const noOption: string = 'no';
            const field: FormField = form.field(PropertyClaimsPanelsNames.AcceptResponsibility);
            const selectionIsEmpty: boolean = field.isEmpty() ||
                (isSet(field.value.selected) && field.value.selected === '');
            const checkCustomTextLength: boolean = field.value.selected === noOption;
            if (checkCustomTextLength) {
                maxLengthIsInvalid = field.value.customText[noOption].length <
                    btaBase.settings.claimsSettings().property.descriptionFieldMinLength;
            }

            return selectionIsEmpty || maxLengthIsInvalid;
        });

        const whatCauseEventProceedIsDisabled: Ref<boolean> = computed(() => {
            return form.field(PropertyClaimsPanelsNames.WhatCauseEvent).isEmpty() ||
                (
                    isSet(form.field(PropertyClaimsPanelsNames.WhatCauseEvent).value.selected) &&
                    form.field(PropertyClaimsPanelsNames.WhatCauseEvent).value.selected === ''
                );
        });

        const whatHappenedProceedIsDisabled: Ref<boolean> = computed(() => {
            return form.field(PropertyClaimsPanelsNames.WhatHappened).isEmpty() ||
                (
                    isSet(form.field(PropertyClaimsPanelsNames.WhatHappened).value.selected) &&
                    form.field(PropertyClaimsPanelsNames.WhatHappened).value.selected === ''
                );
        });

        const describeEventProceedIsDisabled: Ref<boolean> = computed(() => {
            const descriptionText: string = form.field(PropertyClaimsPanelsNames.DescribeEvent).value;
            const multibyteLength: number = useStrings().multibyteLength(descriptionText);
            const minLength: number = btaBase.settings.claimsSettings().property.descriptionFieldMinLength;
            const maxLength: number = btaBase.settings.claimsSettings().property.descriptionFieldMaxLength;
            const valueLengthOutOfBounds: boolean = multibyteLength < minLength || multibyteLength > maxLength;
            const valueHasInvalidText: boolean = !useStrings().isValidWordString(descriptionText);

            return [valueLengthOutOfBounds, valueHasInvalidText].some((value: boolean): boolean => value);
        });

        const toWhomReportedProceedIsDisabled: Ref<boolean> = computed(() => {
            const fieldOptionValue: string = 'claims_property_house_manager';
            const minCharacters: number = 3;
            const toWhomReportedField: FormField = form.field(PropertyClaimsPanelsNames.ToWhomReported);
            const selectedValues: string[] = toWhomReportedField.value?.selected || [];
            const customText: DynamicDictionary = toWhomReportedField.value?.customText || {};
            const isClaimsPropertyOtherSelected: boolean = selectedValues.includes('claims_property_other');
            const isClaimsPropertyHouseManagerSelected: boolean = selectedValues.includes(fieldOptionValue);
            const isOtherTextValid: boolean = customText.claims_property_other?.length >= minCharacters;
            const isManagerTextValid: boolean = isToWhomReportedElementHasCustomField(fieldOptionValue) ?
                customText.claims_property_house_manager?.length >= minCharacters : true;

            return toWhomReportedField.isEmpty() || !toWhomReportedField.isValid ||
                (isClaimsPropertyOtherSelected && !isOtherTextValid) ||
                (isClaimsPropertyHouseManagerSelected && !isManagerTextValid) ||
                (isClaimsPropertyOtherSelected && isClaimsPropertyHouseManagerSelected &&
                    (!isOtherTextValid || !isManagerTextValid));
        });

        const describeEventText: Ref<string> = computed(() => {
            return describeEvent.value !== '' ?
                useTranslate().translateForType(describeEvent.value, Translations.getInstance().type) : '';
        });

        const isVisibleClaimSeparatelyInfoNote: Ref<boolean> = computed(() => {
            return isVisibleInfoNote.value;
        });

        const propertyClaimsPanelsNames: Ref<typeof PropertyClaimsPanelsNames> = computed(() => {
            return PropertyClaimsPanelsNames;
        });

        const textareaLabel: Ref<string> = computed(() => {
            let result: string = '';
            happenedWithSelectedOptions.value.forEach((propertyObject: string): void => {
                const risks: DynamicDictionary = claimsPropertyObjects.value.get(propertyObject) || {};
                for (const o in risks) {
                    if (risks[o].risk === whatCauseEventSelectedOptions.value) {
                        if (risks[o].sub_risk === whatHappenedSelectedOption.value) {
                            result = useTranslate()
                                .hasLocalization(risks[o].describe_event_text, Translations.getInstance().type) ?
                                useTranslate().translateForType(risks[o].describe_event_text, Translations.getInstance().type) :
                                risks[o].describe_event_text;
                        }
                    }
                }
            });

            return result;
        });

        const hasWhatHappenedOptions: Ref<boolean> = computed(() => {
            return panelByName(PropertyClaimsPanelsNames.WhatHappened).options.length > 0;
        });

        const hasSingleWhatHappenedOption: Ref<boolean> = computed(() => {
            return panelByName(PropertyClaimsPanelsNames.WhatHappened).options.length === 1;
        });

        const hasWhatCauseEventOptions: Ref<boolean> = computed(() => {
            return panelByName(PropertyClaimsPanelsNames.WhatCauseEvent).options.length > 0;
        });

        const hasAcceptResponsibilityOptions: Ref<boolean> = computed(() => {
            return panelByName(PropertyClaimsPanelsNames.AcceptResponsibility).options.length > 0;
        });

        const hasSingleWhatCauseEventOption: Ref<boolean> = computed(() => {
            return panelByName(PropertyClaimsPanelsNames.WhatCauseEvent).options.length === 1;
        });

        const happenedWithSelectedOptions: Ref<string[]> = computed(() => {
            const selected: string = form.field(PropertyClaimsPanelsNames.HappenedWith).value.selected;

            return selected === '' ? [] : String(selected).split(SplitSeparator);
        });

        const whatCauseEventSelectedOptions: Ref<string> = computed(() => {
            return form.field(PropertyClaimsPanelsNames.WhatCauseEvent).value.selected;
        });

        const whatHappenedSelectedOption: Ref<string> = computed(() => {
            return form.field(PropertyClaimsPanelsNames.WhatHappened).value.selected;
        });

        const happenedWithUrl: Ref<string> = computed(() => {
            return isLtSaikas.value ? Url.Ajax.happenedWithOptionsLtSaikas : Url.Ajax.happenedWithOptions;
        });

        const isLtSaikas: Ref<boolean> = computed(() => {
            return isSet(propertyObject().objectType) && propertyObject().objectType === LtSaikasObject;
        });

        const acceptResponsibilityCustomText: Ref<string> = computed(() => {
            const field: FormField = form.field(PropertyClaimsPanelsNames.AcceptResponsibility);
            const question: string = useTranslate()
                .translateForType('claims_property_accept_responsibility', Translations.getInstance().type);
            const prefix: string = 'btar_' + field.value.selected;
            const customText: string = field.value.customText[field.value.selected];
            const result: string[] = [
                question,
                useTranslate().translate(prefix),
            ];
            if (customText) {
                result.push(customText);
            }

            return result.join(' - ');
        });


        function setupForm(): void {
            panels.value.forEach((panel: PropertyClaimOption): void => {
                form.addField(new FormField(panel.name));
            });
            form.setReady();
            formIsReady.value = true;
        }

        function onAppReady(): void {
            prepareHappenedWithOptions();
        }

        function onHappenedWithChange(): void {
            rebuildHappenedWithVisibility();
            clearFormsAhead(PropertyClaimsPanelsNames.HappenedWith);
            buildNextPanelOptions(PropertyClaimsPanelsNames.HappenedWith);
        }

        function onAcceptResponsibilityChange(): void {
            clearFormsAhead(PropertyClaimsPanelsNames.AcceptResponsibility);
            buildNextPanelOptions(PropertyClaimsPanelsNames.AcceptResponsibility);
        }

        function onWhatCauseEventChange(): void {
            clearFormsAhead(PropertyClaimsPanelsNames.WhatCauseEvent);
            buildNextPanelOptions(PropertyClaimsPanelsNames.WhatCauseEvent);
        }

        function onWhatHappenedChange(): void {
            clearFormsAhead(PropertyClaimsPanelsNames.WhatHappened);
            buildNextPanelOptions(PropertyClaimsPanelsNames.WhatHappened);
        }

        function onToWhomReportedChange(): void {
            const selectedValue: string = form.field(PropertyClaimsPanelsNames.ToWhomReported).value.selected || '';
            const isDisabled: boolean = selectedValue.includes(NotIncludedException);
            if (isDisabled) {
                form.field(PropertyClaimsPanelsNames.ToWhomReported).patch({
                    selected: NotIncludedException,
                    customText: {},
                });
            }
            applyDisabledStatusOnToWhomReported(isDisabled);
        }

        function onHappenedWithProceed(): void {
            panelByName(PropertyClaimsPanelsNames.HappenedWith).passed = true;
            if (!useAcceptResponsibility()) {
                whatCauseEventProceedCommon();
            }
            scrollToLastVisiblePanel();
        }

        function onAcceptResponsibilityProceed(): void {
            panelByName(PropertyClaimsPanelsNames.AcceptResponsibility).passed = true;
            whatCauseEventProceedCommon();
            scrollToLastVisiblePanel();
        }

        function whatCauseEventProceedCommon(): void {
            buildWhatCauseEventOptions();
            if (!hasWhatCauseEventOptions.value || hasSingleWhatCauseEventOption.value) {
                panelByName(PropertyClaimsPanelsNames.WhatCauseEvent).passed = true;
            }
            if (hasSingleWhatCauseEventOption.value) {
                form.field(PropertyClaimsPanelsNames.WhatCauseEvent).patch({
                    selected: panelByName(PropertyClaimsPanelsNames.WhatCauseEvent).options[0].value,
                    customText: {},
                });
                onWhatCauseEventProceed();
            }
        }

        function onWhatCauseEventProceed(): void {
            buildWhatHappenedOptions();
            panelByName(PropertyClaimsPanelsNames.WhatCauseEvent).passed = true;
            if (!hasWhatHappenedOptions.value || hasSingleWhatHappenedOption.value) {
                panelByName(PropertyClaimsPanelsNames.WhatHappened).passed = true;
            }
            if (hasSingleWhatHappenedOption.value) {
                form.field(PropertyClaimsPanelsNames.WhatHappened).patch({
                    selected: panelByName(PropertyClaimsPanelsNames.WhatHappened).options[0].value,
                    customText: {},
                });
                onWhatHappenedProceed();
            }
            scrollToLastVisiblePanel();
        }

        function onWhatHappenedProceed(): void {
            panelByName(PropertyClaimsPanelsNames.WhatHappened).passed = true;
            scrollToLastVisiblePanel();
        }

        function onDescribeEventProceed(): void {
            panelByName(PropertyClaimsPanelsNames.DescribeEvent).passed = true;
            buildNextPanelOptions(PropertyClaimsPanelsNames.DescribeEvent);
            scrollToLastVisiblePanel();
        }

        function applyIncludeRisksMapping(param: string): void {
            includeMapping.value = JSON.parse(useTransforms().transformedVueSafeString(param));
            Object.keys(includeMapping.value).forEach((key: string): void => {
                includeMapping.value[key] = {
                    mustBeIncluded: false,
                    mapping: String(includeMapping.value[key]).split(':')
                };
            });
        }

        function options(panelName: string): InputOption[] {
            return panelByName(panelName).options;
        }

        function onSubmitStep(): void {
            prepareSubmit();
            stepsSubmitter.proceedStep('', 0);
        }

        function isToWhomReportedElementHasCustomField(value: string): boolean {
            let result: boolean = false;
            const currentOptions: InputOption[] = options('to-whom-reported');
            currentOptions.forEach((option: InputOption): void => {
                if (option.value === value && option.custom === true) {
                    result = true;
                }
            });

            return result;
        }

        function isVisiblePanel(panelName: string): boolean {
            let result: boolean;
            let panel: PropertyClaimOption;
            switch (panelName) {
                case PropertyClaimsPanelsNames.HappenedWith:
                    panel = panelByName(PropertyClaimsPanelsNames.HappenedWith);
                    result = panel.options.length > 0;
                    break;
                case PropertyClaimsPanelsNames.AcceptResponsibility:
                    panel = panelByName(PropertyClaimsPanelsNames.HappenedWith);
                    result = panel.passed && hasAcceptResponsibilityOptions.value;
                    break;
                case PropertyClaimsPanelsNames.WhatCauseEvent:
                    panel = useAcceptResponsibility()
                        ? panelByName(PropertyClaimsPanelsNames.AcceptResponsibility)
                        : panelByName(PropertyClaimsPanelsNames.HappenedWith);
                    result = panel.passed && hasWhatCauseEventOptions.value && !hasSingleWhatCauseEventOption.value;
                    break;
                case PropertyClaimsPanelsNames.WhatHappened:
                    panel = panelByName(PropertyClaimsPanelsNames.WhatCauseEvent);
                    result = panel.passed && hasWhatHappenedOptions.value && !hasSingleWhatHappenedOption.value;
                    break;
                case PropertyClaimsPanelsNames.DescribeEvent:
                    panel = panelByName(PropertyClaimsPanelsNames.WhatHappened);
                    result = panel.passed;
                    break;
                case PropertyClaimsPanelsNames.ToWhomReported:
                    panel = panelByName(PropertyClaimsPanelsNames.DescribeEvent);
                    result = panel.passed;
                    break;
                default:
                    result = false;
            }

            return result;
        }

        function panelByName(panelName: string): PropertyClaimOption {
            return panels.value
                .filter((panel: PropertyClaimOption): boolean => panel.name === panelName)[0];
        }

        function useAcceptResponsibility(): boolean {
            return (!new AppCountry().isLT()) && isLiabilityRiskSelected();
        }

        function isLiabilityRiskSelected(): boolean {
            const field: FormField = form.field(PropertyClaimsPanelsNames.HappenedWith);
            const selectedRisks: string[] = (field.value.selected || '' as string).split(':');
            const liabilityRisks: string[] = btaBase.settings.civilLiabilityRisks();

            return liabilityRisks.filter((element: string): boolean => selectedRisks.includes(element)).length > 0;
        }

        function applyDisabledStatusOnToWhomReported(disabled: boolean): void {
            panelByName(PropertyClaimsPanelsNames.ToWhomReported).options.forEach((option: InputOption): void => {
                if (option.value !== NotIncludedException) {
                    option.disabled = disabled;
                }
            });
        }

        function updateInitialPanelsVisibility(): void {
            panels.value.forEach((panel: PropertyClaimOption): void => {
                buildOptions(panel.name);
                if (panel.options.length === 1) {
                    form.field(panel.name).patch({
                        selected: panel.options[0].value,
                        customText: {},
                    });
                }
                switch (panel.name) {
                    case PropertyClaimsPanelsNames.HappenedWith:
                    case PropertyClaimsPanelsNames.WhatCauseEvent:
                    case PropertyClaimsPanelsNames.WhatHappened:
                    case PropertyClaimsPanelsNames.ToWhomReported:
                        panel.passed = isSet(form.field(panel.name).value.selected)
                            && form.field(panel.name).value.selected !== '';
                        break;
                    case PropertyClaimsPanelsNames.AcceptResponsibility:
                        panel.passed = !acceptResponsibilityProceedIsDisabled.value;
                        break;
                    case PropertyClaimsPanelsNames.DescribeEvent:
                        panel.passed = !describeEventProceedIsDisabled.value;
                        break;
                    default:
                        break;
                }
            });
        }

        function prepareHappenedWithOptions(): void {
            fetchHappenedWithOptions(buildHappenedWithOptionsParams());
        }

        function buildHappenedWithOptionsParams(): DynamicDictionary {
            const params: DynamicDictionary = {};
            const propertyObjectRisks: DynamicDictionary = propertyObject();
            Object.keys(propertyObjectRisks.risks).forEach((key: string): void => {
                const risks: InsuredObjectRisk[] = propertyObjectRisks.risks[key];
                const objectRisks: string[] = [];
                risks.forEach((risk: InsuredObjectRisk): void => {
                    if (isAutoInclude(key)) {
                        includeMapping.value[key].mustBeIncluded = true;
                    }
                    objectRisks.push(risk.agrrisk);
                });
                params[key] = objectRisks;
            });

            return params;
        }

        function isAutoInclude(happenedWith: string): boolean {
            let result: boolean = false;
            Object.keys(includeMapping.value).forEach((key: string): void => {
                if (key === happenedWith) {
                    result = true;
                }
            });

            return result;
        }

        function propertyObject(): DynamicDictionary {
            const propertyObject: DynamicDictionary = objectMembersCount(btaBase.userStorage.stepStorageData.object) > 0
                ? btaBase.userStorage.stepStorageData.object
                : btaBase.userStorage.storageData[0];

            return propertyObject;
        }

        function fetchHappenedWithOptions(params: DynamicDictionary): void {
            PopupService.getInstance().show(new OnePopup().withType().loading);
            useAxios().get(happenedWithUrl.value, {params})
                .then((response: AxiosResponse<DynamicDictionary>): void => {
                    for (const o in response.data.data.body.items) {
                        if (objectMembersCount(response.data.data.body.items[o]) > 0) {
                            claimsPropertyObjects.value.set(o, response.data.data.body.items[o]);
                        }
                    }
                    buildHappenedWithOptions();
                    rebuildHappenedWithVisibility();
                    updateInitialPanelsVisibility();
                    scrollToLastVisiblePanel();
                })
                .catch((reason: LimitedVariant): void => {
                    btaBase.error.show(ErrorType.Error, 'event_details::fetchHappenedWithOptions',
                        reason as DynamicDictionary);
                })
                .finally((): void => {
                    PopupService.getInstance().hide();
                });
        }

        function buildNextPanelOptions(optionName: string): void {
            switch (optionName) {
                case PropertyClaimsPanelsNames.HappenedWith:
                    useAcceptResponsibility() ?
                        buildAcceptResponsibilityOptions() : buildWhatCauseEventOptions();
                    break;
                case PropertyClaimsPanelsNames.AcceptResponsibility:
                    buildWhatCauseEventOptions();
                    break;
                case PropertyClaimsPanelsNames.WhatCauseEvent:
                    buildWhatHappenedOptions();
                    break;
                case PropertyClaimsPanelsNames.DescribeEvent:
                    buildToWhomReported();
                    break;
                default:
            }
            buildDynamicTexts();
        }

        function buildOptions(optionName: string): void {
            switch (optionName) {
                case PropertyClaimsPanelsNames.HappenedWith:
                    buildHappenedWithOptions();
                    rebuildHappenedWithVisibility();
                    break;
                case PropertyClaimsPanelsNames.AcceptResponsibility:
                    buildAcceptResponsibilityOptions();
                    break;
                case PropertyClaimsPanelsNames.WhatCauseEvent:
                    buildWhatCauseEventOptions();
                    break;
                case PropertyClaimsPanelsNames.WhatHappened:
                    buildWhatHappenedOptions();
                    break;
                case PropertyClaimsPanelsNames.DescribeEvent:
                    buildDynamicTexts();
                    break;
                case PropertyClaimsPanelsNames.ToWhomReported:
                    buildToWhomReported();
                    break;
                default:
            }
        }

        function buildHappenedWithOptions(): void {
            panelByName(PropertyClaimsPanelsNames.HappenedWith).options = [];
            for (const key of claimsPropertyObjects.value.keys()) {
                const builder: InputOptionBuilder = (new InputOptionBuilder());
                builder
                    .setName(translateForType(key, Translations.getInstance().type))
                    .setValue(key);
                if (isHiddenAutoIncludeOption(key)) {
                    builder.setHidden(true)
                }
                panelByName(PropertyClaimsPanelsNames.HappenedWith).options.push(builder.build())
            }
        }

        function isHiddenAutoIncludeOption(option: string): boolean {
            let result: boolean = false;
            Object.keys(includeMapping.value).forEach((key: string): void => {
                if (option === key) {
                    result = true;
                }
            });

            return result;
        }

        function buildWhatCauseEventOptions(): void {
            panelByName(PropertyClaimsPanelsNames.WhatCauseEvent).options = [];
            const risks: DynamicDictionary = collectBuildWhatCauseEventOptions();
            risks.sort((itemA: DynamicDictionary, itemB: DynamicDictionary) => {
                let result: number = 0;
                if (parseInt(itemA.cause_ordering, 10) > parseInt(itemB.cause_ordering, 10)) {
                    result = 1;
                }
                if (itemA.cause_ordering < itemB.cause_ordering) {
                    result = -1;
                }

                return result;
            });
            risks.forEach((value: DynamicDictionary): void => {
                const item: string = value.cause;
                const caseType: string = value.case_type;
                if (item !== '' && hasNoRiskInOptionsList(PropertyClaimsPanelsNames.WhatCauseEvent, item)) {
                    panelByName(PropertyClaimsPanelsNames.WhatCauseEvent).options.push(
                        (new InputOptionBuilder())
                            .setName(translateForType(item, Translations.getInstance().type))
                            .setValue(item)
                            .setCustom(caseType === '' ? StandardCaseType : caseType)
                            .build()
                    );
                }
            });
        }

        function buildAcceptResponsibilityOptions(): void {
            if (!new AppCountry().isLT() && useAcceptResponsibility()) {
                const options: Record<string, boolean> = {
                    'claims_property_accept_responsibility_yes': false,
                    'claims_property_accept_responsibility_no': true,
                };
                panelByName(PropertyClaimsPanelsNames.AcceptResponsibility).options = [];
                for (const key in options) {
                    panelByName(PropertyClaimsPanelsNames.AcceptResponsibility).options.push(
                        (new InputOptionBuilder())
                            .setName(translateForType(key, Translations.getInstance().type))
                            .setValue(key.includes('yes') ? 'yes' : 'no')
                            .setCustom(options[key])
                            .build()
                    );
                }
            }
        }

        function collectBuildWhatCauseEventOptions(): DynamicDictionary[] {
            const result: DynamicDictionary[] = [];
            happenedWithSelectedOptions.value.forEach((propertyObject: string): void => {
                const risks: DynamicDictionary = claimsPropertyObjects.value.get(propertyObject) || {};
                for (const o in risks) {
                    const item: string = risks[o].cause;
                    if (item !== '' && hasNoRiskInOptionsList(PropertyClaimsPanelsNames.WhatCauseEvent, item)) {
                        result.push(risks[o]);
                    }
                }
            });

            return result;
        }

        function buildWhatHappenedOptions(): void {
            panelByName(PropertyClaimsPanelsNames.WhatHappened).options = [];
            const risks: DynamicDictionary = collectBuildWhatHappenedOptions();
            risks.sort((itemA: DynamicDictionary, itemB: DynamicDictionary) => {
                let result: number = 0;
                if (parseInt(itemA.what_happened_ordering, 10) > parseInt(itemB.what_happened_ordering, 10)) {
                    result = 1;
                }
                if (itemA.cause_ordering < itemB.cause_ordering) {
                    result = -1;
                }

                return result;
            });
            risks.forEach((value: DynamicDictionary): void => {
                if (hasNoRiskInOptionsList(PropertyClaimsPanelsNames.WhatHappened, value.what_happened)) {
                    panelByName(PropertyClaimsPanelsNames.WhatHappened).options.push(
                        (new InputOptionBuilder())
                            .setName(useTranslate().translateForType(value.what_happened, Translations.getInstance().type))
                            .setValue(value.what_happened)
                            .setCustom(value.case_type === '' ? StandardCaseType : value.case_type)
                            .build()
                    );
                }
            });
        }

        function collectBuildWhatHappenedOptions(): DynamicDictionary[] {
            const result: DynamicDictionary[] = [];
            happenedWithSelectedOptions.value.forEach((propertyObject: string) => {
                const risks: DynamicDictionary = claimsPropertyObjects.value.get(propertyObject) || {};
                for (const o in risks) {
                    const risk: DynamicDictionary = risks[o];
                    if (risk.cause === whatCauseEventSelectedOptions.value && risk.what_happened !== '') {
                        result.push(risk)
                    }
                }
            });

            return result;
        }

        function buildDynamicTexts(): void {
            const happenedWithFormValue: string = form.field(PropertyClaimsPanelsNames.HappenedWith).value.selected;
            const causeEvent: string = form.field(PropertyClaimsPanelsNames.WhatCauseEvent).value.selected;
            const whatHappened: string = form.field(PropertyClaimsPanelsNames.WhatHappened).value.selected || '';
            const happenedWithParts: string[] = String(happenedWithFormValue).split(':');
            let propertyObject: DynamicDictionary = {};
            happenedWithParts.forEach((property: string) => {
                const object: DynamicDictionary | undefined = claimsPropertyObjects.value.get(property);
                if (object) {
                    propertyObject = [propertyObject, object].reduce(deepMergedObjectWithUniqueKeys, {});
                }
            });
            describeEvent.value = '';
            if (propertyObject) {
                Object.keys(propertyObject).forEach((key: string): void => {
                    const object: DynamicDictionary = propertyObject[key];
                    if (object.cause === causeEvent && (object.what_happened === whatHappened || whatHappened === '')) {
                        describeEvent.value = object.describe_event_text;
                        fileUploadMessage.value = object.document_description;
                    }
                    if (new AppCountry().isLT() && isLiabilityRiskSelected()) {
                        describeEvent.value = object.describe_event_text;
                    }
                });
            }
        }

        function deepMergedObjectWithUniqueKeys(target: DynamicDictionary, source: DynamicDictionary): DynamicDictionary {
            Object.entries(source).forEach(([key, value]): void => {
                if (value && typeof value === 'object') {
                    deepMergedObjectWithUniqueKeys(target[value.id] = target[key] || {}, value);
                    return;
                }
                target[key] = value;
            });

            return target;
        }

        function buildToWhomReported(): void {
            const reports: Record<string, boolean> = {
                'claims_property_fire_department_or_police': false,
                'claims_property_house_owner': false,
                'claims_property_house_manager': !(new AppCountry()).isEE(),
                'claims_property_not_reported': false,
                'claims_property_other': true,
            }
            panelByName(PropertyClaimsPanelsNames.ToWhomReported).options = [];
            for (const key in reports) {
                panelByName(PropertyClaimsPanelsNames.ToWhomReported).options.push(
                    (new InputOptionBuilder())
                        .setName(useTranslate().translateForType(key, Translations.getInstance().type))
                        .setValue(key)
                        .setCustom(reports[key])
                        .build()
                );
            }
        }

        function hasNoRiskInOptionsList(optionsName: string, risk: string): boolean {
            return !panelByName(optionsName).options.some((element: InputOption) => {
                if (element.value === risk) {
                    return true;
                }
            });
        }

        function clearFormsAhead(fromField: string): void {
            let clear: boolean = false;
            panels.value.forEach((panel: PropertyClaimOption): void => {
                if (clear) {
                    form.field(panel.name).clear().then();
                    panel.options = [];
                    panel.passed = false;
                }
                if (panel.name === fromField) {
                    clear = true;
                    panel.passed = false;
                }
            });
        }

        function disablePropertyObjectByKey(key: string): void {
            panelByName(PropertyClaimsPanelsNames.HappenedWith).options.forEach((option: InputOption): void => {
                if (option.value === key) {
                    option.disabled = true;
                }
            });
        }

        function enableAllPropertyObjects(): void {
            panelByName(PropertyClaimsPanelsNames.HappenedWith).options.forEach((option: InputOption): void => {
                option.disabled = false;
            });
        }

        function applySelectedCivil(): void {
            const selected: string = form.field(PropertyClaimsPanelsNames.HappenedWith).value.selected;
            if (happenedWithSelectedOptions.value.length > 0) {
                const selectedClaimTogetherStatus: string = selectedPropertyObjectClaimTogetherStatus(selected);
                isSelectedCivil.value = selectedClaimTogetherStatus === 'N';
            }
        }

        function applyClaimTogetherStatusOnCheckboxes(): void {
            const selected: string = form.field(PropertyClaimsPanelsNames.HappenedWith).value.selected;
            if (happenedWithSelectedOptions.value.length > 0) {
                const selectedClaimTogetherStatus: string = selectedPropertyObjectClaimTogetherStatus(selected);
                for (const key of claimsPropertyObjects.value.keys()) {
                    const objectRisks: DynamicDictionary[] = claimsPropertyObjects.value.get(key) || [];
                    if (objectMembersCount(objectRisks) > 0 && selected && !selected.includes(key)) {
                        if (objectRisks[0].claim_together !== selectedClaimTogetherStatus) {
                            disablePropertyObjectByKey(key);
                            isVisibleInfoNote.value = true;
                        }
                    }
                }
            } else {
                enableAllPropertyObjects();
                isVisibleInfoNote.value = false;
            }
        }

        function applyAutoInclude(): void {
            const newHappenedWithSelectedItems: string[] = happenedWithSelectedItems();
            const newAutoIncludes: string[] = autoIncludes();
            const allIncludes: string[] = Object.keys(includeMapping.value);
            const itemsWithoutAutoIncluded: string[] = newHappenedWithSelectedItems.filter((item: string) => {
                return !allIncludes.includes(item);
            });
            if (!isSelectedCivil.value) {
                if (!(new Value(form.field(PropertyClaimsPanelsNames.HappenedWith).value).isEmpty())) {
                    form.field(PropertyClaimsPanelsNames.HappenedWith).value.selected =
                        itemsWithoutAutoIncluded.concat(newAutoIncludes).join(':');
                }
            }
        }

        function rebuildHappenedWithVisibility(): void {
            applySelectedCivil();
            applyAutoInclude();
            applyClaimTogetherStatusOnCheckboxes();
        }

        function happenedWithSelectedItems(): string[] {
            return String(form.field(PropertyClaimsPanelsNames.HappenedWith).value.selected).split(':');
        }

        function autoIncludes(): string[] {
            const result: string[] = [];
            const selectedProperties: string[] =
                String(form.field(PropertyClaimsPanelsNames.HappenedWith).value.selected).split(':');
            selectedProperties.forEach((item: string) => {
                const include: string = autoIncludeRiskForProperty(item);
                if (include !== '' && !result.includes(include)) {
                    result.push(include);
                }
            });

            return result;
        }

        function autoIncludeRiskForProperty(property: string): string {
            let result: string = '';
            Object.keys(includeMapping.value).forEach((key: string): void => {
                if (includeMapping.value[key].mustBeIncluded && includeMapping.value[key].mapping.includes(property)) {
                    result = key;
                }
            });

            return result;
        }

        function propertyTypesWithoutIncludedRisks(): string {
            const propertyTypes = happenedWithSelectedItems();
            return (propertyTypes.filter((item: string) => {
                return !autoIncludes().includes(item)
            })).join(SplitSeparator)
        }

        function selectedPropertyObjectClaimTogetherStatus(selected: string): string {
            let result: string = 'Y';
            for (const key of claimsPropertyObjects.value.keys()) {
                const objectRisks: DynamicDictionary[] = claimsPropertyObjects.value.get(key) || [];
                if (objectMembersCount(objectRisks) > 0 && selected && selected.includes(key)) {
                    result = objectRisks[0].claim_together;
                }
            }

            return result;
        }

        function scrollToLastVisiblePanel(): void {
            nextTick((): void => {
                let lastVisiblePanel: JQuery | undefined;
                panels.value.forEach((panel: PropertyClaimOption): void => {
                    const $panel: JQuery = $('[data-scroll="' + panel.name + '"]');
                    if ($panel.length > 0) {
                        lastVisiblePanel = $panel;
                    }
                });
                if (lastVisiblePanel !== undefined) {
                    const scrollSpeed: number = 350;
                    const headerAreaOffset: number = 65;
                    const offset: Coordinates | undefined = lastVisiblePanel.offset();
                    const verticalOffset: number = offset ? offset.top - headerAreaOffset : 0;
                    if (offset && verticalOffset > 0) {
                        $('html,body').animate({scrollTop: verticalOffset}, scrollSpeed);
                    }
                }
                onToWhomReportedChange();
            });
        }

        function applyStepUrls(next: string, previous: string): void {
            SubmitterUrls.getInstance().applyStepUrls(next, previous);
        }

        function prepareSubmit(): void {
            if (objectMembersCount(btaBase.userStorage.stepStorageData.object) === 0) {
                btaBase.userStorage.stepStorageData.object = btaBase.userStorage.storageData;
            }
            stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('propertyTypes',
                form.field(PropertyClaimsPanelsNames.HappenedWith).value.selected);
            stepsSubmitter.addSubmitCustomParam('propertyTypesWithoutIncludedRisks', propertyTypesWithoutIncludedRisks());
            stepsSubmitter.addSubmitCustomParam('risk', form.field(PropertyClaimsPanelsNames.WhatCauseEvent).value.selected);
            stepsSubmitter.addSubmitCustomParam('riskVariation',
                form.field(PropertyClaimsPanelsNames.WhatHappened).value.selected || '');
            stepsSubmitter.addSubmitCustomParam('riskVariation',
                form.field(PropertyClaimsPanelsNames.WhatHappened).value.selected || '');
            stepsSubmitter.addSubmitCustomParam('caseType', caseTypeIc());
            stepsSubmitter.addSubmitCustomParam('describedEvent', form.field(PropertyClaimsPanelsNames.DescribeEvent).value);
            stepsSubmitter.addSubmitCustomParam('toWhomReported',
                form.field(PropertyClaimsPanelsNames.ToWhomReported).value.selected);
            stepsSubmitter.addSubmitCustomParam('toWhomReportedCustom', toWhomReportedCustomText());
            stepsSubmitter.addSubmitCustomParam('riskRecord', riskRecord());
            stepsSubmitter.addSubmitCustomParam('fileUploadMessage', fileUploadMessage.value);
            if (useAcceptResponsibility()) {
                stepsSubmitter.addSubmitCustomParam('acceptResponsibility', acceptResponsibilityCustomText.value);
            }
        }

        function toWhomReportedCustomText(): string {
            const customText: DynamicDictionary = form.field(PropertyClaimsPanelsNames.ToWhomReported).value.customText;
            const customTextWithoutLineEndings: DynamicDictionary = Object.keys(form.field(PropertyClaimsPanelsNames.ToWhomReported).value.customText)
                .reduce((accumulator: DynamicDictionary, key: string): DynamicDictionary => {
                    accumulator[key] = customText[key].replaceAll('\n', ' ');

                    return accumulator;
                }, {});

            return JSON.stringify(customTextWithoutLineEndings);
        }

        function riskRecord(): DynamicDictionary {
            let result: DynamicDictionary = {};
            happenedWithSelectedOptions.value.forEach((propertyObject: string): void => {
                    const risks: DynamicDictionary = claimsPropertyObjects.value.get(propertyObject) || {};
                    for (const o in risks) {
                        if (risks[o].cause === whatCauseEventSelectedOptions.value &&
                            risks[o].what_happened === whatHappenedSelectedOption.value) {
                            result = risks[o];
                        }
                    }
                }
            );

            return result;
        }

        function caseTypeIc(): string {
            return hasWhatHappenedOptions.value ?
                filterForCaseType(PropertyClaimsPanelsNames.WhatHappened) :
                filterForCaseType(PropertyClaimsPanelsNames.WhatCauseEvent);
        }

        function filterForCaseType(panelName: string): string {
            let caseType: LimitedVariant | undefined = StandardCaseType;
            panelByName(panelName).options.forEach((optionChosen => {
                if (optionChosen.value === form.field(panelName).value.selected) {
                    caseType = optionChosen.custom;
                }
            }));

            return caseType;
        }


        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                LtSaikasObject,
                SplitSeparator,
                StandardCaseType,
                NotIncludedException,
                claimsPropertyObjects,
                panels,
                isVisibleInfoNote,
                includeMapping,
                isSelectedCivil,
                describeEvent,
                fileUploadMessage,
                setupForm,
                onAppReady,
                applyStepUrls,
                onHappenedWithChange,
                onAcceptResponsibilityChange,
                onWhatCauseEventChange,
                onWhatHappenedChange,
                onToWhomReportedChange,
                onHappenedWithProceed,
                onAcceptResponsibilityProceed,
                whatCauseEventProceedCommon,
                onWhatCauseEventProceed,
                onWhatHappenedProceed,
                onDescribeEventProceed,
                applyIncludeRisksMapping,
                options,
                onSubmitStep,
                isToWhomReportedElementHasCustomField,
                isVisiblePanel,
                panelByName,
                useAcceptResponsibility,
                buildOptions,
                isLiabilityRiskSelected,
                applyDisabledStatusOnToWhomReported,
                buildNextPanelOptions,
                updateInitialPanelsVisibility,
                prepareHappenedWithOptions,
                buildHappenedWithOptionsParams,
                isAutoInclude,
                propertyObject,
                fetchHappenedWithOptions,
                buildHappenedWithOptions,
                isHiddenAutoIncludeOption,
                buildAcceptResponsibilityOptions,
                collectBuildWhatCauseEventOptions,
                buildWhatHappenedOptions,
                collectBuildWhatHappenedOptions,
                buildDynamicTexts,
                deepMergedObjectWithUniqueKeys,
                buildToWhomReported,
                hasNoRiskInOptionsList,
                clearFormsAhead,
                disablePropertyObjectByKey,
                enableAllPropertyObjects,
                applySelectedCivil,
                applyClaimTogetherStatusOnCheckboxes,
                applyAutoInclude,
                rebuildHappenedWithVisibility,
                happenedWithSelectedItems,
                autoIncludes,
                autoIncludeRiskForProperty,
                selectedPropertyObjectClaimTogetherStatus,
                scrollToLastVisiblePanel,
                prepareSubmit,
                riskRecord,
                caseTypeIc,
                filterForCaseType,
                isLtSaikas,
                happenedWithProceedIsDisabled,
                acceptResponsibilityProceedIsDisabled,
                whatCauseEventProceedIsDisabled,
                whatHappenedProceedIsDisabled,
                describeEventProceedIsDisabled,
                toWhomReportedProceedIsDisabled,
                describeEventText,
                isVisibleClaimSeparatelyInfoNote,
                propertyClaimsPanelsNames,
                hasWhatHappenedOptions,
                textareaLabel,
                hasSingleWhatHappenedOption,
                hasWhatCauseEventOptions,
                hasAcceptResponsibilityOptions,
                hasSingleWhatCauseEventOption,
                happenedWithSelectedOptions,
                whatCauseEventSelectedOptions,
                whatHappenedSelectedOption,
                happenedWithUrl,
                acceptResponsibilityCustomText,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        Translations.getInstance().addType('claims');
        this.setupForm();
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.onAppReady();
            onAppIsPreparedAndReady.unsubscribe();
        });
    }
});
</script>
