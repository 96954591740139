<script lang="ts">
import { computed, defineComponent, ref, Ref, nextTick } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import AdditionalValidators from '@/pages/Property/Additional/AdditionalValidators';
import { Subscription } from 'rxjs';
import PropertyDataLayer from '@/pages/Property/PropertyDataLayer';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import { TranslateParams, useTranslate } from '@/Composables/Translate';
import Form from '@/assets/libraries/form/form';
import { DefineParams, useDefine } from '@/Composables/Define';
import moment from 'moment';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { StepsSubmitterParams, useStepsSubmitter } from '@/Composables/StepsSubmitter';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import UserCredentials from '@/interfaces/user.credentials.interface';
import AppCountry from '@/assets/libraries/app/app-country';
import PolicyOwners from '@/Enums/PolicyOwnersEnum';
import FormField from '@/assets/libraries/form/form-field';
import Sanitizer from '@/services/sanitizer.service';
import DateRange from '@/interfaces/date.range.interface';
import ExtractDataService from '@/services/extract.data.service';
import CssClass from '@/Enums/CssClassEnum';
import PropertyEnum from '@/Enums/PropertyEnum';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import OneBase from '@/interfaces/OneBaseInterface';
import { StringsParams, useStrings } from '@/Composables/Strings';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import { useUrl } from '@/Composables/Url';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();


        const {isSet}: DefineParams = useDefine();
        const {translateForType}: TranslateParams = useTranslate();
        const {capitalize}: StringsParams = useStrings();
        const stepsSubmitter: StepsSubmitterParams = useStepsSubmitter();


        const CurrentStep: number = 5;
        const RadioDefaultLength: number = 2;
        const dataLayer: PropertyDataLayer = new PropertyDataLayer();
        const validators: AdditionalValidators = new AdditionalValidators();


        const maxDate: Ref<Date | null> = ref(null);
        const form: Form = new Form();
        const defaultFields: string[] = [
            'FirstName',
            'LastName',
            'IdentityNumber',
            'Email',
            'Phone',
            'CompanyName',
            'CompanyType',
            'RegistrationNumber',
        ];

        const policyHolderTypeOptions: Ref<InputOption[]> = computed((): InputOption[] => {
            return [
                new InputOptionBuilder().setValue('privatePolicyHolder')
                    .setName(translation('one_property_private')).build(),
                new InputOptionBuilder().setValue('corporatePolicyHolder')
                    .setName(translation('one_property_corporate')).build(),
            ];
        });

        const insuredPersonTypeOptions: Ref<InputOption[]> = computed((): InputOption[] => {
            return [
                new InputOptionBuilder().setValue('privateInsuredPerson')
                    .setName(translation('one_property_private')).build(),
                new InputOptionBuilder().setValue('corporateInsuredPerson')
                    .setName(translation('one_property_corporate')).build(),
            ];
        });

        const beneficiaryTypeOptions: Ref<InputOption[]> = computed((): InputOption[] => {
            return [
                new InputOptionBuilder().setValue('bankBeneficiary')
                    .setName(translation('one_property_bank_option')).build(),
                new InputOptionBuilder().setValue('privateBeneficiary')
                    .setName(translation('one_property_private')).build(),
                new InputOptionBuilder().setValue('corporateBeneficiary')
                    .setName(translation('one_property_corporate')).build(),
            ];
        });

        const minPolicyDate: Ref<Date> = computed((): Date => {
            const minDate: moment.Moment = moment().add(1, 'days');

            return minDate.toDate();
        });

        const maxPolicyDate: Ref<Date> = computed((): Date => {
            return maxDate.value as Date;
        });

        const showInsuredPersonCheckbox: Ref<boolean> = computed((): boolean => {
            let result: boolean;
            if (btaBase.user.isLogged()) {
                result = form.field('insuredPersonType').value !== 'corporateInsuredPerson';
            } else {
                result =
                    form.field('policyHolderType').value === 'privatePolicyHolder' &&
                    form.field('insuredPersonType').value === 'privateInsuredPerson' ||
                    form.field('policyHolderType').value === 'corporatePolicyHolder' &&
                    form.field('insuredPersonType').value === 'corporateInsuredPerson';
            }

            return result;
        });

        const showBeneficiaryCheckbox: Ref<boolean> = computed((): boolean => {
            let result: boolean;
            if (btaBase.user.isLogged()) {
                result = form.field('beneficiaryType').value === 'privateBeneficiary';
            } else {
                result =
                    form.field('policyHolderType').value === 'privatePolicyHolder' &&
                    form.field('beneficiaryType').value === 'privateBeneficiary' ||
                    form.field('policyHolderType').value === 'corporatePolicyHolder' &&
                    form.field('beneficiaryType').value === 'corporateBeneficiary';
            }
            return result;
        });


        const policyHasAdditionalRisks: Ref<boolean> = computed((): boolean => {
            return isSet(btaBase.userStorage.stepStorageData.additionalPolicy);
        });

        function isInputDisabled(fieldName: string): boolean {
            const allowedFields: string[] = [
                'FirstName',
                'LastName',
                'IdentityNumber',
            ];
            let isDisabled!: boolean | undefined;
            allowedFields.forEach((field: string): void => {
                const fieldType: string = fieldName.replace(field, '');
                switch (fieldType) {
                    case 'insuredPerson':
                        isDisabled = form.field('policyHolderAsInsuredPerson').value;
                        break;
                    case 'beneficiary':
                        isDisabled = form.field('policyHolderAsBeneficiary').value;
                        break;
                    default:
                        break;
                }
            });

            return isDisabled === undefined ? false : isDisabled;
        }

        function radioClass(fieldName: string): string {
            return radioOptions()[fieldName] > RadioDefaultLength ?
                'radio-with-three' : 'radio-with-two';
        }

        function radioOptions(): DynamicDictionary {
            return {
                policyHolderType: policyHolderTypeOptions.value.length,
                insuredPersonType: insuredPersonTypeOptions.value.length,
                beneficiaryType: beneficiaryTypeOptions.value.length,
            }
        }

        function validateBeforeSubmit(): void {
            dataLayer.pushDataLayer(dataLayerParams());
            form.touch().then((): void => {
                form.validate().then((): void => {
                    if (form.isValid()) {
                        nextTick((): void => {
                            prepareSubmit();
                            if (policyDateHasChanged()) {
                                stepsSubmitter.addAjaxResponseCallbackBeforeStepsStorage(applyRecalculatedResponse);
                                stepsSubmitter.submitMethod(Method.Post);
                                stepsSubmitter.submitStep(Url.Ajax.propertyAdditionalInformation);
                            } else {
                                stepsSubmitter.proceedStep('', btaBase.nextStep());
                            }
                        });
                    } else {
                        const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
                        if (invalidElements.length > 0) {
                            invalidElements[0].scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                                inline: 'center'
                            });
                        }
                    }
                });
            });
        }

        function clearInvalidFields(): Promise<void> {
            return new Promise(resolve => {
                [
                    'beneficiaryPhone',
                    'beneficiaryEmail',
                ].forEach((fieldName: string): void => {
                    if (!form.field(fieldName).isValid) {
                        form.field(fieldName).clear().then((): void => {
                            form.validate();
                        });
                    }
                });
                resolve();
            });
        }

        function fillInsuredPersonFields(): void {
            form.field('policyHolderAsInsuredPerson').value ?
                patchInsuredPersonFields() : patchEmptyFields('insuredPerson');
        }

        function onRadioChange(value: string): void {
            switch (value) {
                case 'bankBeneficiary':
                    clearInvalidFields().then((): void => {
                        form.field('policyHolderAsBeneficiary').patch(false);
                    });
                    break;
                case 'corporateBeneficiary':
                    form.field('policyHolderAsBeneficiary').patch(false);
                    break;
                case 'privateInsuredPerson':
                case 'corporateInsuredPerson':
                    form.field('policyHolderAsInsuredPerson').patch(false);
                    break;
                case 'privatePolicyHolder':
                    if (form.field('policyHolderAsBeneficiary').value) {
                        form.field('beneficiaryType').patch('privateBeneficiary');
                    }
                    if (form.field('policyHolderAsInsuredPerson').value) {
                        form.field('insuredPersonType').patch('privateInsuredPerson');
                    }
                    break;
                case 'corporatePolicyHolder':
                    if (form.field('policyHolderAsBeneficiary').value) {
                        form.field('beneficiaryType').patch('corporateBeneficiary');
                    }
                    if (form.field('policyHolderAsInsuredPerson').value) {
                        form.field('insuredPersonType').patch('corporateInsuredPerson');
                    }
                    break;
                default:
                    break;
            }
        }

        function onFieldChange(fieldName: string): void {
            const fieldPrefix: string = 'policyHolder';
            const allowedFields: string[] = [
                fieldPrefix + 'FirstName',
                fieldPrefix + 'LastName',
                fieldPrefix + 'IdentityNumber',
                fieldPrefix + 'Email',
                fieldPrefix + 'Phone',
                fieldPrefix + 'CompanyName',
                fieldPrefix + 'RegistrationNumber',
            ];
            if (allowedFields.includes(fieldName)) {
                if (!btaBase.user.isLogged() && form.field('policyHolderAsBeneficiary').value) {
                    patchBeneficiaryFields();
                }
                if (!btaBase.user.isLogged() && form.field('policyHolderAsInsuredPerson').value) {
                    patchInsuredPersonFields();
                }
            }
        }

        function fillBeneficiaryFields(): void {
            form.field('policyHolderAsBeneficiary').value ?
                patchBeneficiaryFields() : patchEmptyFields('beneficiary');
        }

        function patchBeneficiaryFields(): void {
            if (form.field('beneficiaryType').value !== 'corporateBeneficiary') {
                form.field('beneficiaryType').patch('privateBeneficiary');
            }
            if (btaBase.user.isLogged()) {
                patchUserFields('beneficiary');
            } else {
                patchWithPolicyHolderFields('beneficiary');
            }
        }

        function patchUserFields(fieldPrefix: string): void {
            const currentUser: UserCredentials = btaBase.user.current;
            form.field(fieldPrefix + 'FirstName').patch(currentUser.firstname);
            form.field(fieldPrefix + 'LastName').patch(currentUser.lastname);
            form.field(fieldPrefix + 'IdentityNumber').patch(currentUser.personCode);
            form.field(fieldPrefix + 'Email').patch(currentUser.email);
            form.field(fieldPrefix + 'Phone').patch({
                code: currentUser.code,
                iso: currentUser.code,
                country: currentUser.phoneCode,
                phone: currentUser.phone,
            });
        }

        function patchWithPolicyHolderFields(fieldPrefix: string): void {
            defaultFields.forEach((fieldName: string): void => {
                if (fieldName === 'Phone') {
                    form.field(fieldPrefix + fieldName).patch({
                        code: form.field('policyHolder' + fieldName).value.code,
                        iso: form.field('policyHolder' + fieldName).value.code,
                        country: form.field('policyHolder' + fieldName).value.country,
                        phone: form.field('policyHolder' + fieldName).value.phone,
                    });
                } else {
                    form.field(fieldPrefix + fieldName)
                        .patch(form.field('policyHolder' + fieldName).value);
                }
            });
        }

        function patchEmptyFields(fieldPrefix: string): void {
            defaultFields.forEach((fieldName: string): void => {
                if (fieldName === 'Phone') {
                    form.field(fieldPrefix + fieldName).patch({
                        code: new AppCountry().iso(),
                        country: btaBase.settings.value('USER_PHONE_CODE'),
                        phone: '',
                    });
                } else {
                    form.field(fieldPrefix + fieldName).patch('');
                }
            });
        }

        function patchInsuredPersonFields(): void {
            if (btaBase.user.isLogged()) {
                patchUserFields('insuredPerson');
            } else {
                patchWithPolicyHolderFields('insuredPerson');
            }
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitParam('nextStep', btaBase.nextStep(), false);
            stepsSubmitter.addSubmitParam('facility', btaBase.facility(), false);
            addPolicyHolderAndInsuredPersonSubmitParams();
            addBeneficiarySubmitParams();
            addAdditionalSubmitParams();
            if (policyHasAdditionalRisks.value) {
                stepsSubmitter.addSubmitCustomParam('additionalPolicy',
                    JSON.stringify(btaBase.userStorage.stepStorageData.additionalPolicy));
            }
            stepsSubmitter.addSubmitCustomParam('dataLayerParams', dataLayerParams());
        }

        function dataLayerParams(): DynamicDictionary {
            return btaBase.userStorage.stepStorageData.dataLayerParams;
        }

        function addAdditionalSubmitParams(): void {
            stepsSubmitter.addSubmitCustomParam('policy[startDate]',
                moment(form.field('policyInformationDate').value.startDate).format('YYYY-MM-DD'));
            stepsSubmitter.addSubmitCustomParam('policy[checkUserRights]', 'true')
            stepsSubmitter.addSubmitCustomParam('policy[paymentType]', btaBase.userStorage.stepStorageData['payment[type]'])
            stepsSubmitter.addSubmitCustomParam('policy[additionalRisks]', btaBase.userStorage.stepStorageData['risks[additional]'])
            stepsSubmitter.addSubmitCustomParam('risksMapping', btaBase.userStorage.stepStorageData.risksMapping)
            stepsSubmitter.addSubmitCustomParam('propertyAddress', btaBase.userStorage.stepStorageData['propertyAddress'])
            stepsSubmitter.addSubmitCustomParam('propertyAddress[searchData]', btaBase.userStorage.stepStorageData['propertyAddress[searchData]'])
            stepsSubmitter.addSubmitCustomParam('propertyAddress[postalCode]', btaBase.userStorage.stepStorageData['propertyAddress[postalCode]'])
            stepsSubmitter.addSubmitCustomParam('property[propertyAddress]',
                btaBase.userStorage.stepStorageData.propertyAddress.addressName)
            stepsSubmitter.addSubmitCustomParam('property[propertyApartment]',
                btaBase.userStorage.stepStorageData.propertyAddress.apartment)
            stepsSubmitter.addSubmitCustomParam('property[detailedAddress]',
                btaBase.userStorage.stepStorageData.propertyAddress.detailed)
            stepsSubmitter.addSubmitCustomParam('property[propertyType]', btaBase.userStorage.stepStorageData['properties[0][type]'])
            stepsSubmitter.addSubmitCustomParam('property[permanentlyInhabited]', btaBase.userStorage.stepStorageData['properties[0][permanentlyInhabited]'])
            stepsSubmitter.addSubmitCustomParam('policy[insurancePlan]', btaBase.userStorage.stepStorageData.policyPlan)
            stepsSubmitter.addSubmitCustomParam('insuredPerson[policyHolderAsInsuredPerson]', form.field('policyHolderAsInsuredPerson').value)
            stepsSubmitter.addSubmitCustomParam('beneficiary[policyHolderAsBeneficiary]',
                form.field('policyHolderAsBeneficiary').value)
            stepsSubmitter.addSubmitCustomParam('policyOwners', Object.values(PolicyOwners));
            Object.keys(btaBase.userStorage.stepStorageData).forEach((key: string): void => {
                if (key.includes('properties')) {
                    stepsSubmitter.addSubmitCustomParam(key, btaBase.userStorage.stepStorageData[key]);
                }
            });
        }


        function setupForm(): void {
            Object.keys(btaBase.cmsFields).forEach((fieldName: string): void => {
                if (btaBase.user.isLogged() && fieldName.includes('policyHolder')) {
                    return;
                } else {
                    form.addField(new FormField(fieldName));
                }
            });
            setupCheckboxFields();
            form.setReady();
            validators.init(form);
        }

        function setupCheckboxFields(): void {
            form.addField(new FormField('policyHolderAsInsuredPerson', false));
            form.addField(new FormField('policyHolderAsBeneficiary', false));
        }

        function onAfterFormRestored(): void {
            if (btaBase.user.isLogged()) {
                if (userFieldsAreEmpty('insuredPerson')) {
                    form.field('policyHolderAsInsuredPerson').patch(true);
                    fillInsuredPersonFields();
                }
            }
            applyFormFieldValidatorsAndSanitizers();
            touchNonEmptyFields();
            offerToLogin();
        }

        function prepare(): void {
            setupForm();
            applyMaxDate('default');
            applyDefaultCmsValues();
        }

        function applyMaxDate(offset: string): void {
            const currentMaxDate: moment.Moment = moment().add(1, 'days');
            const defaultOffset: number = 2;
            const dateOffset: number = offset !== 'default'
                ? parseInt(offset, 10)
                : defaultOffset;
            currentMaxDate.add(dateOffset, 'months');
            maxDate.value = currentMaxDate.toDate();
        }

        function touchNonEmptyFields(): void {
            form.fields().forEach((field: FormField): void => {
                if (field.isNotEmpty()) {
                    field.touch();
                }
            });
        }

        function applyFormFieldValidatorsAndSanitizers(): void {
            form.fields().forEach((field: FormField): void => {
                switch (field.name) {
                    case 'policyHolderFirstName':
                    case 'policyHolderLastName':
                        field.addSanitizer(Sanitizer.cleanName);
                        field.addValidators(validators.policyHolderPrivateRequired(field.name));
                        break;
                    case 'policyHolderIdentityNumber':
                        field.addSanitizer(Sanitizer.clearPersonCode);
                        field.addValidators(validators.policyHolderIdentity());
                        break;
                    case 'policyHolderRegistrationNumber':
                        field.addSanitizer(Sanitizer.cleanCompanyRegistrationNumber);
                        field.addValidators(validators.policyHolderRegistration());
                        break;
                    case 'policyHolderCompanyName':
                        field.addValidators(validators.policyHolderCorporateRequired(field.name));
                        break;
                    case 'policyHolderCompanyType':
                        field.addValidators(validators.policyHolderCompanyType());
                        break;
                    case 'policyHolderEmail':
                        field.addValidators(validators.policyHolderPrivateRequired(field.name));
                        break;
                    case 'policyHolderPhone':
                        field.addValidators(validators.policyHolderPhone());
                        break;
                    case 'insuredPersonFirstName':
                    case 'insuredPersonLastName':
                        field.addSanitizer(Sanitizer.cleanName);
                        field.addValidators(validators.insuredPersonPrivateRequired(field.name));
                        break;
                    case 'insuredPersonIdentityNumber':
                        field.addSanitizer(Sanitizer.clearPersonCode);
                        field.addValidators(validators.insuredPersonIdentity());
                        break;
                    case 'insuredPersonRegistrationNumber':
                        field.addSanitizer(Sanitizer.cleanCompanyRegistrationNumber);
                        field.addValidators(validators.insuredPersonRegistration());
                        break;
                    case 'insuredPersonCompanyName':
                        field.addValidators(validators.insuredPersonCorporateRequired(field.name));
                        break;
                    case 'insuredPersonCompanyType':
                        field.addValidators(validators.insuredPersonCompanyType());
                        break;
                    case 'insuredPersonEmail':
                        field.addValidators(validators.insuredPersonPrivateRequired(field.name));
                        break;
                    case 'insuredPersonPhone':
                        field.addValidators(validators.policyHolderPhone());
                        break;
                    case 'beneficiaryFirstName':
                    case 'beneficiaryLastName':
                        field.addSanitizer(Sanitizer.cleanName);
                        field.addValidators(validators.beneficiaryPrivateRequired(field.name));
                        break;
                    case 'beneficiaryIdentityNumber':
                        field.addSanitizer(Sanitizer.clearPersonCode);
                        field.addValidators(validators.beneficiaryIdentity());
                        break;
                    case 'beneficiaryRegistrationNumber':
                        field.addSanitizer(Sanitizer.cleanCompanyRegistrationNumber);
                        field.addValidators(validators.beneficiaryRegistration());
                        break;
                    case 'beneficiaryCompanyName':
                        field.addValidators(validators.beneficiaryCorporateRequired(field.name));
                        break;
                    case 'beneficiaryCompanyType':
                        field.addValidators(validators.beneficiaryCompanyType());
                        break;
                    case 'beneficiaryEmail':
                        field.addValidators(validators.beneficiaryRequired('beneficiaryEmail'));
                        break;
                    case 'beneficiaryPhone':
                        field.addValidators(validators.beneficiaryPhone());
                        break;
                    case 'beneficiaryBank':
                        field.addValidators(validators.beneficiaryBank());
                        break;
                    default:
                        break;
                }
            });
        }

        function applyDefaultCmsValues(): void {
            form.field('policyHolderType').patch(btaBase.cmsFields.policyHolderType.value + 'PolicyHolder');
            form.field('insuredPersonType').patch(btaBase.cmsFields.insuredPersonType.value + 'InsuredPerson');
            form.field('beneficiaryType').patch(btaBase.cmsFields.beneficiaryType.value + 'Beneficiary');
            form.field('policyInformationDate').patch(defaultDate());
            applyMaxDate(btaBase.cmsFields.policyInformationDate.value);
        }

        function defaultDate(): DateRange {
            const dateFrom: moment.Moment = moment().add(1, 'days');

            return new class implements DateRange {
                public startDate: string = dateFrom.format();
                public endDate: string = dateFrom.format();
            };
        }

        function birthDate(identityNumber: string): string {
            let result: string = '';
            const extractedDate: Date | undefined = new ExtractDataService().birthDateFromPersonCode(identityNumber);
            if (extractedDate) {
                result = new ExtractDataService().dateWithCustomFormat(extractedDate);
            }

            return result;
        }

        function offerToLogin(): void {
            if (!btaBase.user.isLogged() && !btaBase.user.isGuestOnly && !userHasSelectGuestMode()) {
                PopupService.getInstance().show(new OnePopup().withType().login.withoutCloseButton());
                nextTick((): void => {
                    guestButtonVisibility();
                });
            }
        }

        function guestButtonVisibility(): void {
            $('.continue-as-guest-button').removeClass(CssClass.Hidden);
        }

        function userHasSelectGuestMode(): boolean {
            return useUrl().guestModeEnabled();
        }

        function translation(key: string): string {
            return translateForType(key, PropertyEnum.TranslationType);
        }

        function policyDateHasChanged(): boolean {
            const policyDateFromForm: Date = moment(form.field('policyInformationDate').value.startDate).toDate();
            const defaultPolicyDate: Date = moment(defaultDate().startDate).toDate();

            return !moment(policyDateFromForm).isSame(moment(defaultPolicyDate), 'day');
        }

        function name(fieldName: string): string {
            let personName: string = form.field(fieldName + 'FirstName').value +
                ' ' + form.field(fieldName + 'LastName').value;
            if (form.field(fieldName + 'Type').value.includes('corporate')) {
                personName = form.field(fieldName + 'CompanyName').value;
            }
            if (form.field(fieldName + 'Type').value.includes('bank')) {
                personName = form.field(fieldName + 'Bank').value.name;
            }

            return personName;
        }

        function phone(fieldName: string): string {
            return '+'
                + form.field(fieldName + 'Phone').value.country
                + form.field(fieldName + 'Phone').value.phone;
        }


        function isLegal(fieldName: string): string {
            const fieldWithUcFirst: string = capitalize(fieldName);
            const corporateField: string = 'corporate' + fieldWithUcFirst;
            const bankField: string = 'bank' + fieldWithUcFirst;
            const isCorporateOrBank: boolean =
                form.field(fieldName + 'Type').value === corporateField ||
                form.field(fieldName + 'Type').value === bankField

            return String(isCorporateOrBank);
        }

        function userBirthDateTransformed(): string {
            const birthDate: moment.Moment = moment(btaBase.user.current.birthDate, 'DD.MM.YYYY');

            return birthDate.format('Y-MM-DD');
        }

        function addPolicyHolderAndInsuredPersonSubmitParams(): void {
            const fieldPrefixes: string[] = [
                'policyHolder',
                'insuredPerson',
            ];
            fieldPrefixes.forEach((fieldPrefix: string): void => {
                if (btaBase.user.isLogged() && fieldPrefix === 'policyHolder') {
                    stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[identityNumber]', btaBase.user.current.personCode);
                    stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[birthDate]', userBirthDateTransformed());
                    stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[name]',
                        btaBase.user.current.firstname + ' ' + btaBase.user.current.lastname);
                    stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[isLegal]', 'false');
                    stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[email]', btaBase.user.current.email);
                    stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[phone]', btaBase.user.current.phone);
                    stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[phoneCode]', btaBase.user.current.phoneCode);
                } else {
                    if (form.field(fieldPrefix + 'Type').value === ('private' + capitalize(fieldPrefix))) {
                        stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[identityNumber]', form.field(fieldPrefix + 'IdentityNumber').value);
                        stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[birthDate]', birthDate(form.field(fieldPrefix + 'IdentityNumber').value));
                    } else {
                        stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[registrationNumber]', form.field(fieldPrefix + 'RegistrationNumber').value);
                        stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[companyType]', form.field(fieldPrefix + 'CompanyType').value.iso);
                    }
                    stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[name]', name(fieldPrefix))
                    stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[isLegal]', isLegal(fieldPrefix))
                    stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[email]', form.field(fieldPrefix + 'Email').value)
                    stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[phone]', phone(fieldPrefix))
                    stepsSubmitter.addSubmitCustomParam(fieldPrefix + '[phoneCode]', form.field(fieldPrefix + 'Phone').value.country);
                }
            })
        }

        function addBeneficiarySubmitParams(): void {
            if (form.field('beneficiaryType').value === 'bankBeneficiary') {
                stepsSubmitter.addSubmitCustomParam('beneficiary[registrationNumber]', form.field('beneficiaryBank').value.registrationNumber);
                stepsSubmitter.addSubmitCustomParam('beneficiary[companyType]', form.field('beneficiaryBank').value.typeIc);
            }
            if (form.field('beneficiaryType').value === 'privateBeneficiary') {
                stepsSubmitter.addSubmitCustomParam('beneficiary[identityNumber]', form.field('beneficiaryIdentityNumber').value);
                stepsSubmitter.addSubmitCustomParam('beneficiary[birthDate]', birthDate(form.field('beneficiaryIdentityNumber').value));
            }
            if (form.field('beneficiaryType').value === 'corporateBeneficiary') {
                stepsSubmitter.addSubmitCustomParam('beneficiary[registrationNumber]', form.field('beneficiaryRegistrationNumber').value);
                stepsSubmitter.addSubmitCustomParam('beneficiary[companyType]', form.field('beneficiaryCompanyType').value.iso);
            }
            if (form.field('beneficiaryType').value !== 'bankBeneficiary') {
                stepsSubmitter.addSubmitCustomParam('beneficiary[email]', form.field('beneficiaryEmail').value);
                stepsSubmitter.addSubmitCustomParam('beneficiary[phone]', phone('beneficiary'))
                stepsSubmitter.addSubmitCustomParam('beneficiary[phoneCode]', form.field('beneficiaryPhone').value.country);
            }
            stepsSubmitter.addSubmitCustomParam('beneficiary[isLegal]', isLegal('beneficiary'));
            stepsSubmitter.addSubmitCustomParam('beneficiary[name]', name('beneficiary'));
        }

        function applyRecalculatedResponse(value: DynamicDictionary): void {
            stepsSubmitter.addSubmitCustomParam('recalculatedResponse', JSON.stringify(value.body.data[0]));
        }

        function userFieldsAreEmpty(fieldType: string): boolean {
            return form.field(fieldType + 'FirstName').isEmpty() &&
                form.field(fieldType + 'LastName').isEmpty() &&
                form.field(fieldType + 'IdentityNumber').isEmpty() &&
                form.field(fieldType + 'Email').isEmpty() &&
                form.field(fieldType + 'Phone').isEmpty();
        }

        const applyStepUrls = (next: string, previous: string): void => {
            SubmitterUrls.getInstance().applyStepUrls(next, previous);
        }

        return {
            ...btaBase, ...{
                form,
                radioClass,
                isInputDisabled,
                fillInsuredPersonFields,
                policyHolderTypeOptions,
                insuredPersonTypeOptions,
                beneficiaryTypeOptions,
                minPolicyDate,
                maxPolicyDate,
                showInsuredPersonCheckbox,
                showBeneficiaryCheckbox,
                policyHasAdditionalRisks,
                CurrentStep,
                onAfterFormRestored,
                validateBeforeSubmit,
                onRadioChange,
                onFieldChange,
                prepare,
                fillBeneficiaryFields,
                patchBeneficiaryFields,
                applyStepUrls,
            }
        };
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility('one-property');
        this.setStorageUsage(true);
        this.prepare();
        const onAfterFormRestoredSubscription: Subscription =
            this.userStorage.onFormStorageDataIsReady.subscribe((): void => {
                this.onAfterFormRestored();
                onAfterFormRestoredSubscription.unsubscribe();
            });
    },
});
</script>
