<script setup lang="ts">
import { computed, onMounted, ref, Ref } from 'vue';
import User from '@/services/user.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import SettingsService from '@/services/settings.service';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import PopupBase from '@/assets/libraries/popups/popup.base';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PopupService from '@/services/custom.popup.service';
import { SubmitParam } from '@/Types/SubmitParamType';
import { AxiosResponse } from 'axios';
import { useTranslate } from '@/Composables/Translate';
import RequestService from '@/services/request.service';
import { useNavigate } from '@/Composables/Navigate';
import { Subscription } from 'rxjs';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import { useHtml } from '@/Composables/Html';

const props = defineProps({
    url: {type: String, default: ''},
    urlParams: {
        type: Object, default: () => {
        }
    },
});

const entityNameLength: number = 30;
const shortNameSuffix: string = '...';
let logoutHref: Ref<string> = ref('');
let businessDashboardHref: Ref<string> = ref('');
let myAccountHref: Ref<string> = ref('');
let dropdownIsVisible: Ref<boolean> = ref(false);
let entityName: Ref<string> = ref('');
let entityIcon: Ref<string> = ref('');

const settingsInstance: SettingsService = SettingsService.getInstance();
const userInstance: User = User.getInstance();
const baseInstance: OneBase = OneBaseService.getInstance();
const requestService: RequestService = RequestService.getInstance();

const {translate} = useTranslate();
const {navigate} = useNavigate();

const logoutUrl: Ref<string> = computed((): string => {
    return logoutHref.value;
});

const isDropdownVisible: Ref<boolean> = computed((): boolean => {
    return dropdownIsVisible.value;
});

const isHintVisible: Ref<boolean> = computed((): boolean => {
    return entityName.value.length > entityNameLength;
});

const dashboardUrl: Ref<string> = computed((): string => {
    return userInstance.current.selectedEntity
        ? businessDashboardHref.value
        : myAccountHref.value;
});

const shortEntityName: Ref<string> = computed((): string => {
    return entityName.value.length > entityNameLength
        ? entityName.value.substring(0, entityNameLength) + shortNameSuffix
        : entityName.value;
});

const entityReady: Ref<boolean> = computed((): boolean => {
    return entityName.value !== '';
});

onMounted((): void => {
    init();
});

function toggleDropdown(): void {
    if (userInstance.isLogged()) {
        dropdownIsVisible.value = !dropdownIsVisible.value;
    } else {
        PopupService.getInstance().show(new OnePopup().withType().login);
    }
}

function hideDropdown(): void {
    dropdownIsVisible.value = false;
}

function showSelectAccountPopup(): void {
    const selectAccountPopup: PopupBase = new OnePopup()
        .withType()
        .selectAccountPopup
        .withoutHeader()
        .withClickCallback(selectEntity)
        .withCloseButton()
    PopupService.getInstance().show(selectAccountPopup);
    hideDropdown();
}

function selectEntity(entity: DynamicDictionary): void {
    const params: SubmitParam = entity;
    Object.keys(props.urlParams).forEach((key: string): void => {
        params[key] = props.urlParams[key];
    });
    requestService.post({uri: props.url, content: params})
        .then((response: AxiosResponse): void => {
            if (responseHasRedirectUrl(response)) {
                navigate(response.data.data.body.redirectUrl);
            }
        })
        .catch((reason: DynamicDictionary): void => {
            Error.getInstance().show(ErrorType.Error, 'selectEntity', reason);
        });
}

function init(): void {
    applyValuesFromSettings();
    const userSubscription: Subscription = userInstance
        .onExternalDataIsReady.subscribe((): void => {
            if (isLegalRoute() && userInstance.current.selectedEntity) {
                entityName.value = userInstance.current.selectedEntity!.name;
                entityIcon.value = iconContent(legalIconAttributes());
            } else {
                entityName.value = userInstance.isLogged() ? userInstance.current.name : translate('btar_my_account');
                entityIcon.value = iconContent(privateIconAttributes());
            }
            if (userSubscription) {
                userSubscription.unsubscribe();
            }
        });
}

function isLegalRoute(): boolean {
    return baseInstance.legalPersonRoutes.routes
        .some((route: string): boolean => window.location.href.includes(route));
}

function applyValuesFromSettings(): void {
    logoutHref.value = settingsInstance.logoutUrl();
    businessDashboardHref.value = settingsInstance.businessDashboardUrl();
    myAccountHref.value = settingsInstance.myAccountUrl();
}

function responseHasRedirectUrl(response: AxiosResponse): boolean {
    return response.data.data.body.redirectUrl !== '';
}

function iconContent(iconAttributes: ImageTagAttributes): string {
    return useHtml().imgTag(iconAttributes);
}

function privateIconAttributes(): ImageTagAttributes {
    return {
        src: 'images/one/components/select-account-dropdown/private.svg',
        width: 24,
        height: 24,
    }
}

function legalIconAttributes(): ImageTagAttributes {
    return {
        src: 'images/one/components/select-account-dropdown/legal.svg',
        width: 24,
        height: 24,
    }
}
</script>

<template>
    <div class="select-account-dropdown" :class="{'with-hint':!isDropdownVisible}">
        <button id="select-account-opener"
                v-if="entityReady"
                class="wrapper"
                @click="toggleDropdown()"
                v-click-outside="hideDropdown">
            <span class="icon" v-html="entityIcon"></span>
            <span class="current-entity mobile-hidden" v-mask-analytics>{{ shortEntityName }}</span>
            <span class="icon dropdown mobile-hidden">
            <svg width="10" height="24" viewBox="0 -10 10 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 5L9 1" stroke="#9297A0" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
            </svg>
        </span>
        </button>
        <span class="hint-on-hover" v-if="isHintVisible">{{ entityName }}</span>
        <div class="dropdown-select"
             :class="{'slide-in': isDropdownVisible, 'slide-out': !isDropdownVisible}">
            <ul>
                <li class="dropdown-item">
                    <a class="dropdown-link" :href="dashboardUrl" @click="hideDropdown()">
                <span class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                            stroke="#5448C8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M22 22L21.5513 20.9231C20.3091 17.9419 17.3963 16 14.1667 16H9.83333C6.60373 16 3.69087 17.9419 2.44872 20.9231L2 22"
                            stroke="#5448C8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
                        <span class="text">{{ translate('select_account_dropdown_profile') }}</span>
                    </a>
                </li>
                <li class="dropdown-item">
                    <button class="dropdown-link" @click="showSelectAccountPopup()">
                <span class="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 1L3 5L7 9" stroke="#5448C8" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path
                                d="M21 11V9C21 7.93913 20.5786 6.92172 19.8284 6.17157C19.0783 5.42143 18.0609 5 17 5H3"
                                stroke="#5448C8"
                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17 23L21 19L17 15" stroke="#5448C8" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path
                                d="M3 13V15C3 16.0609 3.42143 17.0783 4.17157 17.8284C4.92172 18.5786 5.93913 19 7 19H21"
                                stroke="#5448C8"
                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                </span>
                        <span class="text">{{ translate('select_account_dropdown_switch') }}</span>
                    </button>
                </li>
                <li class="dropdown-item">
                    <a class="dropdown-link" :href="logoutUrl" @click="hideDropdown()">
                <span class="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
                                stroke="#5448C8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16 17L21 12L16 7" stroke="#5448C8" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M21 12H9" stroke="#5448C8" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                </span>
                        <span class="text">{{ translate('select_account_dropdown_logout') }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.select-account-dropdown {
    width: 100%;
    height: 24px;
    position: relative;

    .hint-on-hover {
        position: absolute;
        font-size: var(--font-size-femto);
        border: 1px solid transparent;
        border-radius: 12px;
        background-color: var(--brand-blue);
        display: none;
        bottom: -110%;
        right: 0;
        width: max-content;
        padding: 0 var(--size-nano);
        font-style: italic;
        color: var(--white);
    }

    &.with-hint {
        &:hover {
            .hint-on-hover {
                @include respond-above('sm') {
                    display: block;
                }
            }
        }
    }

    .wrapper {
        width: inherit;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .current-entity {
            margin: 0 11px 0 13px;
            font-size: var(--font-size-tiny);
            font-weight: 600;
        }

        .show-dropdown {
            height: inherit;
        }

        .mobile-hidden {
            display: none;

            @include respond-above('sm') {
                display: block;
            }
        }
    }

    @keyframes slide-in {
        0% {
            transform: translateY(250px);
        }

        100% {
            transform: translateY(0);
        }
    }

    @keyframes slide-out {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(250px);
        }
    }

    .slide-in {
        animation: slide-in .2s ease-in 0s 1 normal forwards;
        visibility: visible;
        opacity: 1;

        @include respond-above('sm') {
            display: block;
        }
    }

    .slide-out {
        animation: slide-out .2s ease-in 0s 1 normal forwards;
        visibility: hidden;
        opacity: 0;

        @include respond-above('sm') {
            display: none;
        }
    }

    .dropdown-select {
        position: fixed;
        right: 0;
        bottom: 0;
        width: inherit;
        transition: visibility .2s, opacity .2s linear;

        ul {
            position: initial;
            width: inherit;
            border: 1px solid var(--black-300);
            border-radius: 8px;
            background-color: var(--white);
            filter: drop-shadow(0 2px 5px var(--black-500));

            .dropdown-item {
                height: 64px;
                padding: 18px;
                font-size: var(--font-size-nano);

                .dropdown-link {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }

                .text {
                    margin-left: var(--size-nano);
                }

                &:first-child {
                    border-top-right-radius: 8px;
                    border-top-left-radius: 8px;
                }

                &:last-child {
                    border-bottom-right-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid var(--black-200);
                }

                &:hover {
                    background-color: var(--black-200);
                }
            }

            @include respond-above('sm') {
                position: absolute;
            }
        }

        @include respond-above('sm') {
            transition: none;
            position: relative;
            width: max-content;
            animation: none;
            margin-top: 30px;
        }
    }

    .icon {
        &:not(.dropdown) {
            svg {
                width: 20px;
                height: 20px;
            }
        }

        &.dropdown {
            width: 10px;
            height: 24px;
        }
    }

    @include respond-above('sm') {
        max-width: 183px;
        width: auto;
    }
}
</style>
