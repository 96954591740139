<script lang="ts">
import {computed, defineComponent, reactive, ref, Ref, watch, nextTick, UnwrapNestedRefs} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Translations from '@/services/translations.service';
import {useTranslate} from '@/Composables/Translate';
import {InputOption} from '@/interfaces/InputOptionInterface';
import {useDefine} from '@/Composables/Define';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import {AutoForms} from '@/pages/Auto/Composables/AutoForms';
import ReminderInformativeInterface from '@/interfaces/reminder.informative.interface';
import ReminderInformativeType from '@/interfaces/reminder.informative.type.interface';
import ReminderInformativeBlock from '@/interfaces/reminder.informative.block.interface';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {Auto} from '@/pages/Auto/Composables/Auto';
import {useStrings} from '@/Composables/Strings';
import AppCountry from '@/assets/libraries/app/app-country';
import VehicleProduct from '@/Enums/VehicleProductEnum';
import FormField from '@/assets/libraries/form/form-field';
import CssClass from '@/Enums/CssClassEnum';
import VueModel from '@/services/vue.model.service';
import AutoService from '@/pages/Auto/Services/AutoService';
import AutoFormsService from '@/pages/Auto/Services/AutoFormsService';
import PopupType from '@/Enums/PopupTypeEnum';
import PolicySums from '@/interfaces/policy.sums.interface';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import {PriceParams, usePrice} from '@/Composables/Price';
import VueEvent from '@/Classes/VueEventClass';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import AutoStorageService from '../Services/AutoStorageService';
import PopupBase from '@/assets/libraries/popups/popup.base';
import DataLayer from '@/services/data.layer.service';
import DataLayerType from '@/Enums/DataLayerTypeEnum';
import ErrorType from '@/Enums/ErrorTypeEnum';
import PolicyPeriodStrings from '@/Enums/PolicyPeriodStringsEnum';
import {AutoStorage} from '@/pages/Auto/Composables/AutoStorage';
import Error from '@/services/error.service';
import {AxiosResponse} from 'axios';
import Url from '@/Enums/UrlEnum';
import PaymentType from '@/Enums/PaymentTypeEnum';
import PolicyPeriods from '@/Enums/PolicyPeriodsEnum';
import Form from '@/assets/libraries/form/form';
import PolicySum from '@/interfaces/policy.sum.interface';
import {useNavigate} from '@/Composables/Navigate';
import Sanitizer from '@/services/sanitizer.service';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import UrlBuilder from '@/assets/libraries/url/url-builder';
import Validation from '@/services/validation.service';
import SpecificError from '@/Enums/SpecificErrorEnum';
import AgreementPeriodMap from '@/Apps/PayByLink/Classes/AgreementPeriodMap';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const auto: Auto = AutoService.getInstance();
        const autoStorage: AutoStorage = AutoStorageService.getInstance();
        const forms: AutoForms = AutoFormsService.getInstance();


        const {isSet, validResponse} = useDefine();
        const {translate, hasLocalization, language} = useTranslate();
        const {capitalize} = useStrings();
        const {formattedCurrency} = usePrice();
        const stepsSubmitter = useStepsSubmitter();
        const request: AxiosParams = useAxios();


        const CurrentStep: number = 2;
        const NextStep: number = 3;
        const Facility: string = 'auto-policy';
        const youngestDriverMin: number = 15;
        const youngestDriverMax: number = 99;


        const vehicleProduct: Ref<string> = ref('');
        const paymentOptionInputOptions: Ref<InputOption[]> = ref([]);
        const territoryInputOptions: Ref<InputOption[]> = ref([]);
        const policyPeriodInputOptions: Ref<InputOption[]> = ref([]);
        const drivingExperienceInputOptions: Ref<InputOption[]> = ref([]);
        const drivingExperienceLessThanThreeYearsInputOptions: Ref<InputOption[]> = ref([]);
        const paymentTypeInputOptions: Ref<InputOption[]> = ref([]);
        const NoAdditionalRisks: Ref<string> = ref('none');
        const activeReminderButtonMtpl: Ref<boolean> = ref(true);
        const activeReminderButtonBundle: Ref<boolean> = ref(true);
        const activeReminderButtonCasco: Ref<boolean> = ref(true);
        const selectedReminderId: Ref<string> = ref('');
        const selectedReminderDate: Ref<string> = ref('');
        const reminderDate: Ref<string> = ref('');
        const reminderMessage: Ref<string> = ref('');
        const selectedReminderInformativeId: Ref<string> = ref('');
        const remindInformativePopup: UnwrapNestedRefs<ReminderInformativeInterface> = reactive(
            new class implements ReminderInformativeInterface {
                public MTPL: ReminderInformativeType = new class implements ReminderInformativeType {
                    public monthly: ReminderInformativeBlock = new class implements ReminderInformativeBlock {
                        public title: string = 'btar_mtpl_reminder_info_monthly_title';
                        public description: string = 'btar_mtpl_reminder_info_monthly_description';
                        public list: string[] = [];
                    };
                    public fixed: ReminderInformativeBlock = new class implements ReminderInformativeBlock {
                        public title: string = 'btar_mtpl_reminder_info_fixed_title';
                        public description: string = 'btar_mtpl_reminder_info_fixed_description';
                        public list: string[] = [];
                    };
                };
                public CASCO: ReminderInformativeType = new class implements ReminderInformativeType {
                    public monthly: ReminderInformativeBlock = new class implements ReminderInformativeBlock {
                        public title: string = 'btar_casco_reminder_info_monthly_title';
                        public description: string = 'btar_casco_reminder_info_monthly_description';
                        public list: string[] = [];
                    };
                    public fixed: ReminderInformativeBlock = new class implements ReminderInformativeBlock {
                        public title: string = 'btar_casco_reminder_info_fixed_title';
                        public description: string = 'btar_casco_reminder_info_fixed_description';
                        public list: string[] = [];
                    };
                };
            });
        const vehicleProductAdditionalRisksMapping: Ref<DynamicDictionary> = ref([]);
        const footerFullSum: Ref<LimitedVariant> = ref(0);
        const footerDiscountSum: Ref<LimitedVariant> = ref(0);
        const reloadMtplSums: Ref<boolean> = ref(false);
        const fetchSumsBeforeNextStep: Ref<boolean> = ref(false);
        const oldDriverExperience: Ref<string> = ref('');
        const needToFetchOnBlur: Ref<boolean> = ref(false);
        const submitAfterFetch: Ref<boolean> = ref(false);
        const allowScrollToAdditionalItems: Ref<boolean> = ref(false);


        const vehicleProductLocalized: Ref<string> = computed(() => {
            return vehicleProductIsSelected.value ?
                translate('btar_product_' + String(vehicleProduct.value).toLowerCase()) :
                '';
        });

        const productReminderMessage: Ref<string> = computed(() => {
            const message: string = translate('btar_popup_leave_contacts');

            return message.split('%').join(String(selectedReminderId.value).toUpperCase());
        });

        const productReminderDate: Ref<string> = computed(() => {
            return selectedReminderDate.value;
        });

        const coveredInfoTextMtplWithRoadAssistance: Ref<string> = computed(() => {
            /*
             *  INFO: MTPL SPECIFIC: SUSPENDED: only specific hardcoded info text while MTPL has no items (for now)
             */
            let result: string = '';
            if (isSet(auto.coveredPopup.insuredRisksMTPL) &&
                isSet(auto.coveredPopup.insuredRisksMTPL.TE_R_ROAD_ASSISTANCE)
            ) {
                result = translate('btar_covered_info_block_mtpl_with_road_assistance');
            }

            return result;
        });

        const policyPeriodTitle: Ref<string> = computed(() => {
            return capitalize(translate('btar_policy_period_title'));
        });

        const footerFullSumFormatted: Ref<string> = computed(() => {
            return Number(footerFullSum.value).toFixed(2);
        });

        const showFooterDiscountBlock: Ref<boolean> = computed(() => {
            let show: boolean;
            if (footerFullSum.value && footerDiscountSum.value) {
                show = parseFloat(Number(footerFullSum.value).toFixed(2)) >
                    parseFloat(Number(footerDiscountSum.value).toFixed(2));
            } else {
                show = false;
            }

            return show;
        });

        const showPricePaymentPeriodText: Ref<boolean> = computed(() => {
            return !(new AppCountry()).isLV();
        });

        const footerDiscountSumFormatted: Ref<string> = computed(() => {
            return Number(footerDiscountSum.value).toFixed(2);
        });

        const footerSaveSumFormatted: Ref<string> = computed(() => {
            return Number(Number(footerFullSum.value) -
                Number(footerDiscountSum.value)).toFixed(2);
        });

        const reminderIsValid: Ref<boolean> = computed(() => {
            return forms.reminderForm.isValid();
        });

        const isOwnerPersonal: Ref<boolean> = computed(() => {
            return isSet(btaBase.userStorage.stepStorageData.ownerPersonCode);
        });

        const isOwnerLegalCode: Ref<boolean> = computed(() => {
            return isSet(btaBase.userStorage.stepStorageData.ownerRegistrationCode);
        });

        const vehicleProductIsSelected: Ref<boolean> = computed(() => {
            return vehicleProduct.value !== '';
        });

        const productIsBundle: Ref<boolean> = computed(() => {
            return vehicleProduct.value === VehicleProduct.Bundle;
        });

        const productIsMtpl: Ref<boolean> = computed(() => {
            return vehicleProduct.value === VehicleProduct.Mtpl;
        });

        const productIsCasco: Ref<boolean> = computed(() => {
            return vehicleProduct.value === VehicleProduct.Casco;
        });

        const paymentTypeIsMonthly: Ref<boolean> = computed(() => {
            const field: FormField = forms.form.field('paymentOption');
            let paymentType: string;
            if (isSubscription.value) {
                paymentType = 'monthly';
            } else {
                paymentType = btaBase.cmsFields.paymentOption.enabled ?
                    field.value : btaBase.cmsFields.paymentOption.value;
            }

            return paymentType === 'monthly';
        });

        const formIsValid: Ref<boolean> = computed(() => {
            return forms.form.isValid() && vehicleProduct.value !== '';
        });

        const isFixedPaymentType: Ref<boolean> = computed(() => {
            return !paymentTypeIsMonthly.value;
        });

        const isSubscription: Ref<boolean> = computed(() => {
            return forms.form.exists('paymentType') ?
                forms.form.field('paymentType').value === 'subscription' : false;
        });

        const isSubscriptionAvailable: Ref<boolean> = computed(() => {
            const products: any = btaBase.userStorage.storageData.attributes.vehicleProducts;
            const subscriptionNotAvailableForSomeProduct: boolean = Object.keys(products)
                .filter((key: string) => key !== VehicleProduct.Bundle)
                .filter((key: string) => !isSet(products[key].error))
                .filter((key: string) => !products[key].hasActiveSubscription)
                .some((key: string) => {
                    return !isSet(products[key].isSubscriptionAgreement);
                });

            return !subscriptionNotAvailableForSomeProduct;
        });

        const reminderClasses: Ref<string> = computed(() => {
            return reminderIsValid.value ? CssClass.Red : CssClass.Outside;
        });

        const controlsAreVisible: Ref<boolean> = computed(() => {
            return auto.storageIsReady.value && !bothProductsAreUnavailable() && !bothProductsAreLocked.value;
        });

        const coveredPopupFooterIsVisible: Ref<boolean> = computed(() => {
            return auto.coveredPopupIsVisible.value;
        });

        const youngestDriverValue: Ref<number> = computed(() => {
            return forms.form.field('youngestDriver').value;
        });

        const infoPopupForProduct: Ref<LimitedVariant> = computed(() => {
            return VueModel.modelValueByName(selectedReminderInformativeId.value, remindInformativePopup);
        });

        const pricePaymentPeriodText: Ref<string> = computed(() => {
            return paymentTypeIsMonthly.value ?
                translate('btar_month').toLowerCase() :
                translate('btar_year').toLowerCase();
        });

        const deductiblesAreVisible: Ref<boolean> = computed(() => {
            return isSet(btaBase.userStorage.storageData.attributes.vehicleDeductibles);
        });

        const insuranceOptionsAreVisible: Ref<boolean> = computed(() => {
            return isSet(btaBase.userStorage.storageData.attributes.cascoInsuranceOptionsIc);
        });

        const insuranceOptionsValue: Ref<string> = computed(() => {
            return btaBase.userStorage.storageData.attributes.cascoInsuranceOptionsIc.toLowerCase();
        });


        const drivingExperienceFieldIsVisible: Ref<boolean> = computed(() => {
            return btaBase.cmsFields.drivingExperience?.enabled &&
                !productIsMtpl.value &&
                isOwnerPersonal.value &&
                auto.storageVehicleProductExists(VehicleProduct.Casco);
        });

        const drivingExperienceLessThanThreeYearsFieldIsVisible: Ref<boolean> = computed(() => {
            return btaBase.cmsFields.drivingExperienceLessThanThreeYears.enabled &&
                forms.form.exists('drivingExperienceLessThanThreeYears') &&
                !productIsMtpl.value;
        });

        const fieldsPopupDriverAgeValueIsOutOfRange: Ref<boolean> = computed(() => {
            const driverAgeField: FormField = forms.fieldsPopupForm.field('driverAge');

            return !driverAgeField.isEmpty() && driverAgeField.hasError('outOfRange');
        });

        const fieldsPopupDriverAgeValue: Ref<boolean> = computed(() => {
            return forms.fieldsPopupForm.field('driverAge').value;
        });

        const fieldsPopupDrivingExperienceLessThanThreeYearsValue: Ref<number> = computed(() => {
            return forms.fieldsPopupForm.field('drivingExperienceLessThanThreeYears').value;
        });

        const fieldsPopupHeading: Ref<string> = computed(() => {
            let heading: string = '';
            if (fieldsPopupIsActiveLt.value) {
                heading = translate('youngest_driver_and_experience_popup_title');
            } else if (fieldsPopupIsActiveLv.value) {
                heading = translate('territory_and_driving_experience_popup_title');
            }

            return heading;
        });

        const fieldsPopupIsOpen: Ref<boolean> = computed(() => {
            return btaBase.popup.type === PopupType.VehicleInsuranceDriverFields;
        });

        const fieldsPopupIsActive: Ref<boolean> = computed(() => {
            return fieldsPopupIsActiveLt.value || fieldsPopupIsActiveLv.value;
        });

        const fieldsPopupIsActiveLt: Ref<boolean> = computed(() => {
            return (new AppCountry).isLT() &&
                youngestDriverFieldIsVisible &&
                drivingExperienceFieldIsVisible.value;
        });

        const fieldsPopupIsActiveLv: Ref<boolean> = computed(() => {
            return (new AppCountry).isLV() &&
                btaBase.cmsFields.territory.enabled &&
                drivingExperienceLessThanThreeYearsFieldIsVisible.value;
        });

        const fieldsPopupNoDrivingExperienceLessThanThreeYearsValue: Ref<boolean> = computed(() => {
            return typeof (fieldsPopupDrivingExperienceLessThanThreeYearsValue.value as any) !== 'boolean';
        });

        const fieldsPopupTerritoryValue: Ref<number> = computed(() => {
            return forms.fieldsPopupForm.field('territory').value;
        });

        const youngestDriverFieldIsVisible: Ref<boolean> = computed(() => {
            return btaBase.cmsFields.youngestDriver.enabled &&
                !productIsCasco.value &&
                isOwnerPersonal.value;
        });

        const sums: Ref<PolicySums> = computed(() => {
            return auto.productSums;
        });

        const bothProductsAreLocked: Ref<boolean> = computed(() => {
            return productIsLocked(VehicleProduct.Mtpl) &&
                productIsLocked(VehicleProduct.Casco);
        });

        const oneOfProductsIsLocked: Ref<boolean> = computed(() => {
            return productIsLocked(VehicleProduct.Mtpl) ||
                productIsLocked(VehicleProduct.Casco);
        });

        const bundleIsAvailable: Ref<boolean> = computed(() => {
            let result: boolean = (new AppCountry().isEE()) ? !isApprovalCaseNecessary() : true;
            if (result) {
                if (!btaBase.userStorage.storageIsEmpty) {
                    const vehicleProducts: any = btaBase.userStorage.storageData.attributes.vehicleProducts;
                    const mtpl: DynamicDictionary = vehicleProducts[VehicleProduct.Mtpl];
                    const casco: DynamicDictionary = vehicleProducts[VehicleProduct.Casco];
                    const anyProductHasAnError: boolean = useDefine().isSet(mtpl.error)
                        || useDefine().isSet(casco.error);
                    const reminderThreshold: number = btaBase.settings
                        .vehicleCanPurchaseIfPolicyHasLeftLessThanDays();
                    const anyProductHasReminder: boolean = parseInt(
                        mtpl.daysLeft || 0, 10
                    ) >= reminderThreshold || parseInt(
                        casco.daysLeft || 0, 10
                    ) >= reminderThreshold;
                    const anyProductHasAnActiveSubscription: boolean =
                        productHasActiveSubscription(mtpl) || productHasActiveSubscription(casco);
                    const anyProductHasNotAvailableSubscription: boolean = isSubscription.value ?
                        [mtpl.isSubscriptionAgreement, casco.isSubscriptionAgreement]
                            .some((value: string): boolean => value === 'N') :
                        false;
                    if (anyProductHasAnError
                        || anyProductHasReminder
                        || anyProductHasAnActiveSubscription
                        || anyProductHasNotAvailableSubscription
                    ) {
                        result = false;
                    }
                }
            }
            return result;
        });

        const hasDeductiblesOptions: Ref<boolean> = computed(() => {
            let hasDeductibleOptions: boolean = !isMtplProduct.value;
            if (hasDeductibleOptions && isSet(storageAttributes()?.vehicleProducts?.CASCO?.damageDeductiblePremiums)) {
                let deductiblePremiums: DynamicDictionary = storageAttributes().vehicleProducts.CASCO.damageDeductiblePremiums;
                hasDeductibleOptions = Object.keys(deductiblePremiums).length > 1;
            } else {
                hasDeductibleOptions = hasDeductibleOptions && isSet(storageAttributes().vehicleDeductibleOptions);
            }

            return hasDeductibleOptions;
        });

        const territoryCode: Ref<string> = computed(() => {
            const DefaultLvTerritory: string = 'EU';

            return isSet(btaBase.userStorage.storageData?.attributes?.territoryCode) ?
                btaBase.userStorage.storageData.attributes.territoryCode : DefaultLvTerritory;
        });

        const isMtplProduct: Ref<boolean> = computed(() => {
            return vehicleProduct.value === VehicleProduct.Mtpl;
        });

        const hasDefaultDeductiblesForOptions: Ref<boolean> = computed(() => {
            return autoStorage.defaultDeductibles(territoryCode.value) !== '';
        });

        watch(() => auto.mtplDiscountPercent.value, (newValue) => {
                if (newValue === auto.NoDiscountInDatabase) {
                    auto.mtplDiscountPercent.value = 0;
                    auto.fetchMtplDiscountFromJson.value = true;
                }
            },
            {
                immediate: true,
            });

        watch(() => auto.cascoDiscountPercent.value, (newValue) => {
                if (newValue === auto.NoDiscountInDatabase) {
                    auto.cascoDiscountPercent.value = 0;
                    auto.fetchMtplDiscountFromJson.value = true;
                }
            },
            {
                immediate: true,
            });

        watch(() => vehicleProduct.value, (newValue, oldValue) => {
                forms.form.field('vehicleProduct').patch(newValue);
                forms.form.validate().then();
            },
            {
                immediate: true,
            });

        watch(() => youngestDriverValue.value, (newValue, oldValue) => {
                if (fieldsPopupIsActiveLt.value &&
                    forms.form.exists('youngestDriver') &&
                    forms.form.field('youngestDriver').isTouched
                ) {
                    onYoungestDriverChange(newValue, oldValue as number);
                }
            },
            {
                immediate: true,
            });


        function applyReloadMtplSumsStatus(value: boolean): void {
            reloadMtplSums.value = value;
        }

        function applyFetchSumsStatus(value: boolean): void {
            fetchSumsBeforeNextStep.value = value;
        }

        function applyStepUrls(next: string, previous: string): void {
            stepsSubmitter.applyStepUrls(next, previous, '');
        }

        function discountBadgeIsVisible(product: string): boolean {
            const empty: number = 0;
            let result: boolean;
            switch (product) {
                case VehicleProduct.Bundle:
                    result = auto.bundlePercent() > empty;
                    break;
                default:
                    if (String(product).toUpperCase() === VehicleProduct.Mtpl) {
                        result = auto.mtplDiscountPercent.value > empty;
                    } else {
                        result = auto.cascoDiscountPercent.value > empty;
                    }
            }

            return result;
        }

        function discountPercentFormatted(product: string): string {
            let result: string = '';
            switch (product) {
                case VehicleProduct.Bundle:
                    result = String(Number(auto.bundlePercent()).toFixed());
                    break;
                default:
                    if (String(product).toUpperCase() === VehicleProduct.Mtpl) {
                        result = String(auto.mtplDiscountPercent.value);
                    } else {
                        result = String(auto.cascoDiscountPercent.value);
                    }
            }

            return [translate('btar_policy_discount_save'), result + '%'].join(' ');
        }

        function productSum(product: string): string {
            let sum: number;
            const priceName: string = product + (isSubscription.value ? 'SubscriptionPrice' : 'DiscountSum');
            if (btaBase.user.isLogged()) {
                sum = (sums.value.authenticated as DynamicDictionary)[priceName];
            } else {
                sum = (sums.value.guest as DynamicDictionary)[priceName];
            }

            return formattedCurrency(sum);
        }

        function bundleProductSum(product: string): string {
            let sum: number;
            if (btaBase.user.isLogged()) {
                sum = VueModel.modelValueByName(product + 'BundlePrice', sums.value.authenticated) as number;
            } else {
                sum = VueModel.modelValueByName(product + 'BundlePrice', sums.value.guest) as number;
            }

            return formattedCurrency(sum);
        }

        function paymentButton(event: VueEvent): void {
            updatePolicyPeriodByPaymentType();
            applyFinalSums();
        }

        function territoryButton(event: VueEvent): void {
            if (!bothProductsAreUnavailable() && !forms.form.isInputLocked()) {
                PopupService.getInstance().show(new OnePopup().withType().loading);
                forms.form.lockInput();
                fetchSumsForOwner()
                    .then(() => PopupService.getInstance().hide())
                    .finally(() => forms.form.unlockInput());
            }
        }

        function policyPeriodButton(event: VueEvent): void {
            applyFinalSums();
            btaBase.userStorage.saveFormsPromise().then();
        }

        function paymentTypeButton(): void {
            updatePolicyPeriodByPaymentType();
            applyFinalSums();
        }

        function onDriverExperienceChange(currentValue: string): void {
            if (!bothProductsAreUnavailable() && !forms.form.isInputLocked() &&
                oldDriverExperience.value !== currentValue
            ) {
                oldDriverExperience.value = currentValue;
                PopupService.getInstance().show(new OnePopup().withType().loading);
                fetchSumsForOwner().then((): void => {
                    PopupService.getInstance().hide();
                }).finally((): void => {
                    forms.form.unlockInput();
                    needToFetchOnBlur.value = false;
                    if (submitAfterFetch.value) {
                        submitAfterFetch.value = false;
                        submit();
                    }
                });
            }
        }

        function onYoungestDriverChange(currentValue: number, valueBefore: number | string): void {
            if (!bothProductsAreUnavailable() && !forms.form.isInputLocked() &&
                currentValue !== valueBefore && valueBefore !== ''
            ) {
                youngestDriverChange();
            }
        }

        function youngestDriverChange(): void {
            PopupService.getInstance().show(new OnePopup().withType().loading);
            forms.form.lockInput();
            fetchSumsForOwner().then((): void => {
                PopupService.getInstance().hide();
            }).finally((): void => {
                forms.form.unlockInput();
                needToFetchOnBlur.value = false;
                if (submitAfterFetch.value) {
                    submitAfterFetch.value = false;
                    submit();
                }
            });
        }

        function onYoungestDriverInput(value: number, oldValue: number): void {
            if (String(value) !== String(oldValue)) {
                needToFetchOnBlur.value = true;
            }
        }

        function onYoungestDriverError(): void {
            submitAfterFetch.value = false;
            needToFetchOnBlur.value = false;
        }

        function vehicleProductItemButton(event: VueEvent): void {
            vehicleProduct.value = event.params.id;
            if (vehicleProduct.value === VehicleProduct.Bundle) {
                if (productHasRisks(VehicleProduct.Mtpl) || productHasRisks(VehicleProduct.Casco)) {
                    scrollToPoliciesListEnd();
                }
            } else {
                if (productHasRisks(vehicleProduct.value)) {
                    scrollToPoliciesListEnd();
                }
            }
            makePolicyPeriodInputOptions();
            applyFinalSums();
            btaBase.userStorage.saveFormsPromise().then();
            forms.form.touch();
        }

        function vehicleProductCoveredButton(event: VueEvent): void {
            auto.coveredPopup.contentID = event.params.id;
            prepareCoveredPopup(event.params.id, true);
            PopupService.getInstance().show(new OnePopup().withType().covered);
            nextTick((): void => {
                btaBase.initPopupTooltips();
            });
        }

        function additionalFooterCoveredButton(event: VueEvent): void {
            auto.coveredPopup.contentID = vehicleProduct.value;
            prepareCoveredPopup(vehicleProduct.value, true);
            PopupService.getInstance().show(new OnePopup().withType().covered);
            nextTick((): void => {
                btaBase.initPopupTooltips();
            });
        }

        function coveredProductTabButtonClick(product: string): void {
            auto.coveredPopup.contentID = product;
        }

        function vehicleProductButtonClasses(vehicleProductId: string): string {
            let result: string = CssClass.Red;
            if (vehicleProduct.value === vehicleProductId) {
                result = CssClass.Hidden;
            } else {
                if (vehicleProductIsSelected.value) {
                    result = CssClass.Outside;
                }
            }

            return result;
        }

        function policyItemClasses(vehicleProductId: string): string {
            let result: string = '';
            if ((paymentTypeIsMonthly.value && btaBase.settings.value('LOCALE_ISO') !== 'EE') ||
                isSubscription.value) {
                result = CssClass.WithoutDescription;
                if (isSubscription.value
                    && vehicleProductId !== VehicleProduct.Bundle
                    && !productHasSubscription(vehicleProductId)
                ) {
                    result = CssClass.Hidden;
                }
            }
            if (vehicleProduct.value === vehicleProductId) {
                result += ' ' + CssClass.Active;
            }
            if (vehicleProductId === VehicleProduct.Bundle && !bundleIsAvailable.value) {
                result = CssClass.Hidden;
            }
            return result;
        }

        function reminderItemButton(event: VueEvent): void {
            selectedReminderId.value = String(event.params.id).toUpperCase();
            selectedReminderDate.value = event.params.reminderDate;
            let allowReminder: string = '';
            switch (String(event.params.id)) {
                case 'Mtpl':
                    allowReminder = String(activeReminderButtonMtpl.value);
                    break;
                case 'Casco':
                    allowReminder = String(activeReminderButtonCasco.value);
                    break;
                case 'Bundle':
                    allowReminder = String(activeReminderButtonBundle.value);
                    break;
                default:
            }
            if (allowReminder === 'true') {
                btaBase.showPopup(PopupType.Remind);
            }
        }

        function prepareReminderInfoPopup(): void {
            remindInformativePopup.MTPL.fixed.list = reminderInfoListById('btar_mtpl_reminder_info_fixed_list');
            remindInformativePopup.MTPL.monthly.list = reminderInfoListById('btar_mtpl_reminder_info_monthly_list');
            remindInformativePopup.CASCO.fixed.list = reminderInfoListById('btar_casco_reminder_info_fixed_list');
            remindInformativePopup.CASCO.monthly.list = reminderInfoListById('btar_casco_reminder_info_monthly_list');
        }

        function reminderInfoListById(id: string): string[] {
            let result: string[] = [];
            if (hasLocalization(id)) {
                const elements: string = translate(id);
                if (elements !== '') {
                    result = elements.split('#');
                }
            }

            return result;
        }

        function infoPopup(event: VueEvent): void {
            prepareReminderInfoPopup();
            selectedReminderInformativeId.value = String(event.params.id).toUpperCase();
            btaBase.showPopup(PopupType.CoveredInformative);
        }

        function additionalFooterSubmit(event: VueEvent): void {
            if (requireMandatoryLogin()) {
                btaBase.popup.applyTextDescription(useTranslate().translate('vehicle_purchase_restriction_auth_popup'))
                PopupService.getInstance().show(new OnePopup().withType().login);
            } else {
                submit();
            }
        }

        function productPriceForSelectedPeriod(vehicleProduct: string): number {
            const period: any = forms.form.field('policyPeriod').value;
            const prices: any = autoStorage.storageDataPrices(vehicleProduct, defaultEuDeductible());
            let productPrice: number = 0;
            if (prices) {
                if (isSubscription.value) {
                    productPrice = prices.subscriptionPayment;
                } else {
                    productPrice = isFixedPaymentType.value ?
                        prices.fixedPeriodPayment[period] : prices.monthlyPayment;
                }
            }

            return productPrice;
        }

        function calculatePrices(): void {
            auto.calculatePrices();
            updateFooterSums();
            updateFinalSums();
        }

        function additionalContainerClasses(product: string): string {
            let result: string = '';
            if (vehicleProductIsSelected.value) {
                result = vehicleProduct.value === product || vehicleProduct.value === VehicleProduct.Bundle
                    ? '' : CssClass.Hidden;
            } else {
                result = CssClass.Hidden;
            }

            return result;
        }

        function bundleRiskIsVisible(additionalOptionId: string): boolean {
            let result: boolean = false;
            const risk: DynamicDictionary = mappedRisk(additionalOptionId);
            if (useDefine().isSet(risk.risk_ic)) {
                result = risk.bundle === '1';
            }

            return result;
        }

        function closeFieldsPopup(): void {
            if (fieldsPopupIsOpen.value) {
                btaBase.popup.hidePopup();
            }
        }

        function confirmFieldsPopup(): void {
            if (fieldsPopupIsActiveLt.value) {
                const driverAgeFormField: FormField = forms.form.field('youngestDriver');
                if (driverAgeFormField
                    && driverAgeFormField.value !== fieldsPopupDriverAgeValue.value
                ) {
                    driverAgeFormField.patch(fieldsPopupDriverAgeValue.value);
                }
                const drivingExperienceFormField: FormField = forms.form.field('drivingExperience');
                const drivingExperienceValue: string = forms.fieldsPopupForm
                    .field('drivingExperience').value;
                if (drivingExperienceFormField
                    && drivingExperienceFormField.value !== drivingExperienceValue
                ) {
                    drivingExperienceFormField.value = drivingExperienceValue;
                }
            } else if (fieldsPopupIsActiveLv.value) {
                const drivingExperienceLessThanThreeYearsFormField: FormField =
                    forms.form.field('drivingExperienceLessThanThreeYears');
                if (typeof drivingExperienceLessThanThreeYearsFormField !== 'undefined'
                    && drivingExperienceLessThanThreeYearsFormField.value !==
                    fieldsPopupDrivingExperienceLessThanThreeYearsValue.value
                ) {
                    drivingExperienceLessThanThreeYearsFormField.value =
                        fieldsPopupDrivingExperienceLessThanThreeYearsValue.value;
                }
                const territoryFormField: FormField = forms.form.field('territory');
                if (territoryFormField
                    && territoryFormField.value !== fieldsPopupTerritoryValue.value
                ) {
                    territoryFormField.value = fieldsPopupTerritoryValue.value;
                }
            }
            closeFieldsPopup();
        }

        function openFieldsPopup(): void {
            if (fieldsPopupIsActiveLt.value || fieldsPopupIsActiveLv.value) {
                btaBase.popup.showPopup(PopupType.VehicleInsuranceDriverFields);
            }
        }

        function applyDiscount(product: string, value: number): void {
            switch (product) {
                case 'mtpl':
                    auto.mtplDiscountPercent.value = value;
                    break;
                case 'casco':
                    auto.cascoDiscountPercent.value = value;
                    break;
                default:
            }
        }

        function applyDiscountOnBundleProduct(product: string, value: number): void {
            switch (product) {
                case 'mtpl':
                    auto.mtplDiscountPercent.value = value + 1;
                    break;
                case 'casco':
                    auto.cascoDiscountPercent.value = value + 1;
                    break;
                default:
            }
        }

        function productHasSubscription(product: string): boolean {
            const vehicleProducts: DynamicDictionary = btaBase.userStorage.storageData.attributes.vehicleProducts;

            return vehicleProducts[product].isSubscriptionAgreement === 'Y';
        }

        function isVehicleProductUnavailable(vehicleProduct: string): boolean {
            let result: boolean = false;
            if (!btaBase.userStorage.storageIsEmpty) {
                const vehicleProducts: DynamicDictionary = btaBase.userStorage.storageData.attributes.vehicleProducts;
                const product: DynamicDictionary = vehicleProducts[vehicleProduct];
                if (vehicleProduct !== VehicleProduct.Bundle) {
                    if (!btaBase.userStorage.storageIsEmpty) {
                        result = useDefine().isSet(product.error);
                    }
                }
                if (vehicleProduct === VehicleProduct.Casco &&
                    (new AppCountry().isEE()) &&
                    isApprovalCaseNecessary()) {
                    product.error = 'vehicle_product_can_not_be_issued_online';
                    result = true;
                }
            }

            return result;
        }

        function vehicleProductErrorDetails(vehicleProduct: string): string {
            let errorDescription: string = '';
            if (vehicleProduct !== VehicleProduct.Bundle) {
                if (!btaBase.userStorage.storageIsEmpty) {
                    const vehicleProducts: DynamicDictionary = btaBase.userStorage.storageData.attributes.vehicleProducts;
                    const product: DynamicDictionary = vehicleProducts[vehicleProduct];
                    errorDescription = product.error;
                }
            }

            return translate(errorDescription);
        }

        function isProductBundle(id: string): boolean {
            return id === VehicleProduct.Bundle;
        }

        function fullProductSum(product: string): string {
            let sum: number;
            const priceName: string = product.toLowerCase() + (isSubscription.value ?
                ((new AppCountry()).isLV() ? 'SubscriptionFullPrice' : 'SubscriptionPrice') : 'FullSum');
            if (btaBase.user.isLogged()) {
                sum = VueModel.modelValueByName(priceName, auto.productSums.authenticated) as number;
            } else {
                sum = VueModel.modelValueByName(priceName, auto.productSums.guest) as number;
            }

            return formattedCurrency(sum);
        }

        function productIsLocked(product: string): boolean {
            let isLocked: boolean = false;
            if (!btaBase.userStorage.storageIsEmpty &&
                auto.storageVehicleProductExists(product)
            ) {
                const storedProducts: DynamicDictionary[] = btaBase.userStorage.storageData
                    .attributes.vehicleProducts;
                const storedProduct: DynamicDictionary = storedProducts[
                    product as keyof typeof storedProducts
                    ] as DynamicDictionary;
                isLocked = !storedProduct.hasActiveSubscription && (
                    !useDefine().isSet(storedProduct.daysLeft)
                    || storedProduct.daysLeft === '0'
                ) && !countrySpecificRestrictions(product);
            }

            return isLocked;
        }

        function countrySpecificRestrictions(product: string): boolean {
            let result: boolean = false;
            if (new AppCountry().isEE() && product === VehicleProduct.Mtpl && btaBase.userStorage.stepStorageData.special) {
                result = true;
            }

            return result;
        }

        function productHasRisks(product: string): boolean {
            let risksCount: number = 0;
            const vehicleProduct: DynamicDictionary = btaBase.userStorage.storageData.attributes.vehicleProducts[product];
            if (useDefine().isSet(vehicleProduct.risks) && useDefine().isSet(vehicleProduct.risks.notSelected)) {
                for (const o in vehicleProduct.risks.notSelected) {
                    risksCount++;
                }
            }

            return risksCount > 0;
        }

        function submit(): void {
            if (needToFetchOnBlur.value) {
                submitAfterFetch.value = true;
            } else if (!forms.form.isInputLocked()) {
                if (formIsValid.value) {
                    const fetchSumsPromise: Promise<void> = (fetchSumsBeforeNextStep.value) ?
                        fetchSumWithAdditionalOptions() :
                        new Promise((resolve: (value: PromiseLike<void> | void) => void) => resolve());
                    const loadingType: PopupBase = (fetchSumsBeforeNextStep.value) ?
                        new OnePopup().withType().loading : new OnePopup().withType().loadingWait;
                    PopupService.getInstance().show(loadingType);
                    fetchSumsPromise.then(() => {
                        forms.form.lockInput();
                        return btaBase.userStorage.saveFormsPromise().then((): void => {
                            pushDataLayer();
                            nextTick((): void => {
                                if (btaBase.settings.metaApiEnabled()) {
                                    sendMetaEvent();
                                }
                                submitStep2();
                            });
                        })
                    }).catch((reason: DynamicDictionary): void => {
                        PopupService.getInstance().hide();
                        forms.form.unlockInput();
                        btaBase.error.show(ErrorType.Error, 'saveFormsPromise', reason);
                    });
                } else {
                    forms.form.unlockInput();
                    PopupService.getInstance().hide();
                    Error.log(ErrorType.Error, 'formIsValid', 'form validation id fail');
                }
            }
        }

        function pushDataLayer(): void {
            const territoryCode: string = btaBase.userStorage.stepStorageData.territoryCode;
            const dataLayerInstance: DataLayer = DataLayer.getInstance()
                .useCheckout()
                .addActionParam('step', CurrentStep)
                .addActionParam('option', DataLayerType.ProductTypeVehicle);
            let products: string[] = [vehicleProduct.value];
            if (productIsBundle.value) {
                products = [VehicleProduct.Mtpl, VehicleProduct.Casco];
            }
            products.forEach((product: string) => {
                const productName: string = String(product).toLowerCase();
                const productPrice: string = productIsBundle.value ?
                    bundleProductSum(productName) :
                    productSum(productName);
                dataLayerInstance
                    .newProduct()
                    .addProductParam('name', DataLayerType.AutoInsurance)
                    .addProductParam('id', DataLayerType.AutoProductFakeId)
                    .addProductParam('price', productPrice)
                    .addProductParam('category', DataLayerType.AutoInsurance)
                    .addProductParam('quantity', 1)
                    .addProductParam('variant', product)
                    .addProductParam('coupon', '')
                    .addProductParam('dimension2',
                        transformedPeriod() + DataLayerType.AutoProductPolicyPeriodPostfix
                    )
                    .addProductParam('dimension8', territoryCode)
                    .addProductParam('dimension10', dataLayerTransformedPaymentCount())
                    .addProductParam('dimension11', productIsBundle.value ?
                        DataLayerType.AutoProductBundle :
                        DataLayerType.AutoProductStandalone);
            });
            dataLayerInstance.buildAndPush();
        }

        function needToPatchPolicyPeriod(): boolean {
            return forms.form.field('policyPeriod').isEmpty();
        }

        function needToPatchVehicleProduct(): boolean {
            return !forms.form.field('vehicleProduct').isEmpty();
        }

        function updatePolicyPeriodByPaymentType(): void {
            const field: FormField = forms.form.field('policyPeriod');
            if (isSubscription.value) {
                field.patch(PolicyPeriodStrings.SubscriptionPayment);
            } else {
                if (isFixedPaymentType.value) {
                    const prices: any = selectedVehicleProductPrices();
                    if (!useDefine().isSet(prices)
                        || !useDefine().isSet(prices.fixedPeriodPayment)
                        || !useDefine().isSet(prices.fixedPeriodPayment[field.value])
                    ) {
                        field.patch(PolicyPeriodStrings.OneYearPayment);
                    } else {
                        if (vehicleProduct.value === VehicleProduct.Mtpl) {
                            field.patch(PolicyPeriodStrings.OneYearPayment);
                        }
                    }
                } else {
                    field.patch(PolicyPeriodStrings.MonthlyPayment);
                }
            }
        }

        function applyReminderUser(): void {
            if (btaBase.user.isLogged()) {
                forms.reminderForm.field('reminderName')
                    .patch(btaBase.user.current.firstname + ' ' + btaBase.user.current.lastname);
                forms.reminderForm.field('reminderEmail').patch(btaBase.user.current.email);
                forms.reminderForm.field('reminderPhone').patch({
                    phone: btaBase.user.current.phone,
                    country: btaBase.user.current.phoneCode
                });
            }
        }

        /**INFO:
         * Check if a product has an active subscription and it can be purchased
         *
         * From a comment on ONE-4681:
         *
         * > If there is no `daysLeft` and `canIssueOn` attributes for MTPL then
         * > `hasActiveSubscription` should be handled as `false` hence making the
         * > product available for purchase.
         *
         * @link https://jira.bta.lv/browse/ONE-4681
         */
        function productHasActiveSubscription(product: DynamicDictionary): boolean {
            const hasActiveSubscription: boolean = isSet(
                product.hasActiveSubscription
            ) && product.hasActiveSubscription as boolean;

            return product.id === VehicleProduct.Mtpl.toLowerCase() ?
                hasActiveSubscription &&
                isSet(product.daysLeft) &&
                isSet(product.canIssueOn) :
                hasActiveSubscription;
        }

        function applyVehicleProductAdditionalRisksMapping(risks: any[]): void {
            vehicleProductAdditionalRisksMapping.value = risks;
        }

        function transformedMtplPeriod(): string {
            const selectedPeriod: string = transformedPeriod();

            return selectedPeriod === '12' ? '1YEAR' : selectedPeriod + 'MON';
        }

        function mappedRisk(riskId: string): any {
            let result: any = {};
            vehicleProductAdditionalRisksMapping.value.forEach((risk: any): void => {
                if (risk.risk_ic === riskId) {
                    if (risk.mtpl_period !== '' && risk.mtpl === '1') {
                        const period: string = transformedMtplPeriod();
                        if (risk.mtpl_period === period) {
                            result = risk;
                        }
                    } else {
                        result = risk;
                    }
                }
            });

            return result;
        }

        function isVisibleRisk(riskIc: string, mtplPeriod: string): boolean {
            let result: boolean = false;
            if (vehicleProductIsSelected.value) {
                const risk: any = mappedRisk(riskIc);
                const period: string = transformedMtplPeriod();
                if (useDefine().isSet(risk.risk_ic)) {
                    if (mtplPeriod !== '') {
                        result = risk.mtpl_period === mtplPeriod;
                    } else {
                        result = true;
                    }
                }
            }

            return result;
        }

        function prepareCoveredPopup(productId: string, allRisks: boolean): void {
            const storage: DynamicDictionary = btaBase.userStorage.storageData;
            auto.coveredPopup.contentID = productId === VehicleProduct.Bundle ? VehicleProduct.Casco : productId;
            auto.coveredPopup.default.setValue(auto.coveredPopup.contentID);
            auto.coveredPopup.title = useTranslate().translate('btar_what_covered');
            auto.coveredPopup.descriptionMtpl = useTranslate().translate('btar_what_covered_description_mtpl');
            auto.coveredPopup.descriptionCasco = useTranslate().translate('btar_what_covered_description_casco');
            if (useDefine().isSet(storage.attributes.vehicleDeductibles)) {
                const vehicleDeductibles: DynamicDictionary = storage.attributes.vehicleDeductibles;
                const damageDeductible: string = vehicleDeductibles.modifiedDamageDeductibleIc
                    ? vehicleDeductibles.modifiedDamageDeductibleIc
                    : vehicleDamageDeductible();
                const price: PriceParams = usePrice();
                if (damageDeductible) {
                    if (damageDeductible !== vehicleDeductibles.totalLossDeductibleIc) {
                        auto.coveredPopup.deductibles.btar_damage_deductible =
                            price.currencyEuroOrPercentPostfix(damageDeductible)
                    } else {
                        auto.coveredPopup.deductibles.btar_vehicle_damage_total_loss_deductible =
                            price.currencyEuroOrPercentPostfix(damageDeductible);
                    }
                }
                if (vehicleDeductibles.theftAndTotalLossDeductibleIc) {
                    auto.coveredPopup.deductibles.theft_and_total_loss_deductible =
                        price.currencyEuroOrPercentPostfix(
                            vehicleDeductibles.theftAndTotalLossDeductibleIc
                        );
                } else {
                    if (vehicleDeductibles.theftDeductibleIc) {
                        auto.coveredPopup.deductibles.btar_theft_deductible =
                            price.currencyEuroOrPercentPostfix(
                                vehicleDeductibles.theftDeductibleIc
                            );
                    }
                    if (vehicleDeductibles.totalLossDeductibleIc &&
                        vehicleDeductibles.damageDeductibleIc !== vehicleDeductibles
                            .totalLossDeductibleIc
                    ) {
                        auto.coveredPopup.deductibles.btar_total_loss_deductible =
                            price.currencyEuroOrPercentPostfix(
                                vehicleDeductibles.totalLossDeductibleIc
                            );
                    }
                }
                if (vehicleDeductibles.glassDeductibleIc) {
                    auto.coveredPopup.deductibles.btar_glass_deductible =
                        price.currencyEuroOrPercentPostfix(vehicleDeductibles.glassDeductibleIc);
                }
                if (vehicleDeductibles.deductibleIc) {
                    auto.coveredPopup.deductibles.btar_deductible =
                        price.currencyEuroOrPercentPostfix(vehicleDeductibles.deductibleIc);
                }
            }
            const applyFromProducts: string[] = [VehicleProduct.Mtpl, VehicleProduct.Casco];
            auto.coveredPopup.insuredRisksMTPL = {};
            auto.coveredPopup.insuredRisksCASCO = {};
            for (let i: number = 0; i < applyFromProducts.length; i++) {
                auto.appendBasicRisksToCoveredPopup(applyFromProducts[i]);
            }
        }

        function vehicleDamageDeductible(): string {
            const storage: DynamicDictionary = btaBase.userStorage.storageData;
            const vehicleDeductibles: DynamicDictionary = storage.attributes.vehicleDeductibles;
            const country: AppCountry = new AppCountry();
            let result: string = vehicleDeductibles.damageDeductibleIc;
            if (hasDeductiblesOptions.value &&
                country.isLV() &&
                hasDefaultDeductiblesForOptions.value) {
                result = autoStorage.defaultDeductibles(territoryCode.value);
            }

            return result;
        }

        function storageAttributes(): DynamicDictionary {
            const storage: DynamicDictionary = btaBase.userStorage.storageData;

            return isSet(storage.attributes) ? storage.attributes : {};
        }

        function productHasRisk(product: string, risk: string): boolean {
            let result: boolean = false;
            const vehicleProduct: any = btaBase.userStorage.storageData.attributes.vehicleProducts[product];
            if (isSet(vehicleProduct.risks) &&
                (isSet(vehicleProduct.risks.isSelected ||
                    isSet(vehicleProduct.risks.notSelected)))
            ) {
                result = useDefine().arrayContains(vehicleProduct.risks.isSelected, risk)
                    || useDefine().arrayContains(vehicleProduct.risks.notSelected, risk);
            }

            return result;
        }

        function applyFromThisProducts(): string[] {
            let result: string[] = [vehicleProduct.value];
            if (productIsBundle.value) {
                result = [VehicleProduct.Mtpl, VehicleProduct.Casco];
            }

            return result;
        }

        function selectedVehicleProductPrices(): any {
            let result: any;
            if (!auto.storageVehicleProductExists(vehicleProduct.value)) {
                result = false;
            } else {
                result = autoStorage.storageDataPrices(vehicleProduct.value, defaultEuDeductible());
            }

            return result;
        }

        function scrollToPoliciesListEnd(): void {
            nextTick((): void => {
                const additionalItems: JQuery = $('.additional-list')
                    .not('.hidden')
                    .children('.additional-item')
                    .not('.included-state');
                if (additionalItems.length > 0) {
                    const targetItem: JQuery = $(additionalItems.get(0) as HTMLElement);
                    const policyListOffset: JQuery.Coordinates | undefined = targetItem.offset();
                    if (policyListOffset && allowScrollToAdditionalItems.value) {
                        allowScrollToAdditionalItems.value = false;
                        const easingSpeed: number = 500;
                        $('html,body').animate({scrollTop: policyListOffset.top}, easingSpeed);
                    }
                }
            });
        }

        function queryRemind(): void {
            if (forms.form.isInputLocked() || !reminderIsValid.value) {
                return;
            }
            forms.form.lockInput();
            btaBase.closePopup();
            PopupService.getInstance().show(new OnePopup().withType().loading);
            btaBase.userStorage.lockFormSave(true);
            const params: any = assembleReminder(selectedReminderId.value);
            request.post(Url.Ajax.remind,
                params
            ).then((value: AxiosResponse<any>): void => {
                btaBase.userStorage.lockFormSave(false);
                if (validResponse(value) && value.data.data.status === 'OK') {
                    switch (capitalize(selectedReminderId.value)) {
                        case 'Mtpl':
                            activeReminderButtonMtpl.value = false;
                            break;
                        case 'Casco':
                            activeReminderButtonCasco.value = false;
                            break;
                        case 'Bundle':
                            activeReminderButtonBundle.value = false;
                            break;
                        default:
                    }
                    btaBase.userStorage.saveForms();
                    forms.form.unlockInput();
                    PopupService.getInstance().hide();
                } else {
                    forms.form.unlockInput();
                    btaBase.error.show(ErrorType.Error, 'queryRemind', value);
                }
            }).catch((reason: any): void => {
                forms.form.unlockInput();
                btaBase.userStorage.lockFormSave(false);
                btaBase.error.show(ErrorType.Error, 'reminder', reason);
            });
        }

        function assembleReminder(vehicleProduct: string): any {
            return {
                data: {
                    id: vehicleProduct,
                    type: 'reminder',
                    attributes: {
                        plateNumber: btaBase.userStorage.storageData.id,
                        lang: language(),
                        name: forms.reminderForm.field('reminderName').value,
                        email: forms.reminderForm.field('reminderEmail').value,
                        phone: forms.reminderForm.field('reminderPhone').value.phone || '',
                        phoneCode: forms.reminderForm.field('reminderPhone').value.country || '',
                        policyStartDate: reminderFirstPossibleStartingDate(vehicleProduct),
                        marketing: forms.reminderForm.field('reminderAgreeToReceiveMarketing').value ? 'true' : 'false'
                    }
                }
            };
        }

        function reminderFirstPossibleStartingDate(vehicleProduct: string): string {
            return btaBase.userStorage.storageData.attributes.vehicleProducts[vehicleProduct].policyStartDate;
        }

        function transformedPaymentCount(): string {
            return paymentTypeIsMonthly.value ? PaymentType.Monthly : PaymentType.Fixed;
        }

        function transformedPeriod(): string {
            let result: string = PolicyPeriods.OneYearPayment;
            if (isFixedPaymentType.value) {
                result = PolicyPeriods.memberValueByName(String(policyPeriodString()));
            }

            return result;
        }

        function monthsLabel(policyPeriodString: string): string {
            return new AppCountry().isLV() ? new AgreementPeriodMap().monthsLabelByPeriodString(policyPeriodString)
                : translate('btar_month');
        }

        function applyFinalSums(): void {
            if (!bothProductsAreUnavailable()) {
                calculatePrices();
            }
        }

        function fetchSumsForOwner(): Promise<void> {
            const params: DynamicDictionary = prepareFormParams(forms.form);
            return auto.fetchSumsForOwner(params).then((): void => {
                calculatePrices();
            });
        }

        function fetchSumWithAdditionalOptions(): Promise<void> {
            if (productIsMtpl.value && !reloadMtplSums.value) {
                calculatePrices();
                return new Promise((resolve: (value: PromiseLike<void> | void) => void) => resolve());
            }
            const params: DynamicDictionary = prepareFormParams(forms.form);
            auto.vehicleProduct.value = vehicleProduct.value;
            return auto.fetchSumWithAdditionalOptions(params, reloadMtplSums.value).then((): void => {
                calculatePrices();
            });
        }

        function prepareFormParams(form: Form): any {
            const params: DynamicDictionary = {};
            if (form.exists('territory')) {
                params['territory'] = form.field('territory').value;
            }
            if (form.exists('youngestDriver')) {
                params['youngestDriverAge'] = form.field('youngestDriver').value;
            }
            if (form.exists('drivingExperience')) {
                params['drivingExperience'] = form.field('drivingExperience').value;
            }
            if (form.exists('drivingExperienceLessThanThreeYears')) {
                params['experienceLessThanThreeYears'] = form.field('drivingExperienceLessThanThreeYears').value
                    ? 'Y' : 'N';
            }
            if (form.exists('youngestDriver')) {
                params['youngestDriver'] = form.field('youngestDriver').value;
            }
            if (form.exists('drivingExperience')) {
                params['drivingExperience'] = form.field('drivingExperience').value;
            }
            if (new AppCountry().isLT()) {
                params['privacyPolicy'] = btaBase.userStorage.stepStorageData.privacyPolicy;
            }

            return params;
        }

        function bothProductsAreUnavailable(): boolean {
            return !auto.storageVehicleProductExists(VehicleProduct.Mtpl)
                && !auto.storageVehicleProductExists(VehicleProduct.Casco);
        }

        function updateFooterSums(): void {
            const sumType: any = btaBase.user.isLogged() ?
                sums.value.authenticated : sums.value.guest;
            if (isSubscription.value) {
                const productSubscriptionFullPrice: string = vehicleProduct.value.toLowerCase() + 'SubscriptionFullPrice';
                const productSubscriptionPrice: string = vehicleProduct.value.toLowerCase() + 'SubscriptionPrice';
                footerFullSum.value = VueModel.modelValueByName(productSubscriptionFullPrice, sumType);
                footerDiscountSum.value = VueModel.modelValueByName(productSubscriptionPrice, sumType);
            } else {
                footerFullSum.value = VueModel.modelValueByName(vehicleProduct.value.toLowerCase() + 'FullSum', sumType);
                footerDiscountSum.value = VueModel.modelValueByName(
                    vehicleProduct.value.toLowerCase() + 'DiscountSum', sumType);
            }
        }

        function updateFinalSums(): void {
            sums.value.guest.finalFullSum = finalFullSumWithApprovalCase(false);
            sums.value.guest.finalDiscountSum = finalDiscountSumWithApprovalCase(false);
            sums.value.authenticated.finalFullSum = finalFullSumWithApprovalCase(true);
            sums.value.authenticated.finalDiscountSum = finalDiscountSumWithApprovalCase(true);
        }

        function finalFullSumWithApprovalCase(logged: boolean): number {
            const sumType: DynamicDictionary = logged ?
                sums.value.authenticated : sums.value.guest;
            let result: number =
                VueModel.modelValueByName(vehicleProduct.value.toLowerCase() + 'FullSum', sumType) as number;
            if (isBundleWithApprovalCase()) {
                result = sumType.mtplFullSum;
            }

            return result;
        }

        function finalDiscountSumWithApprovalCase(logged: boolean): number {
            const sumType: PolicySum = logged ?
                sums.value.authenticated : sums.value.guest;
            const policyPeriod: any = forms.form.field('policyPeriod').value;
            let result: LimitedVariant = VueModel.modelValueByName(
                vehicleProduct.value.toLowerCase() + 'DiscountSum', sumType);
            if (isBundleWithApprovalCase()) {
                const prices: any = autoStorage.storageDataPricesByUserType(VehicleProduct.Bundle, logged, defaultEuDeductible());
                if (prices) {
                    result = isFixedPaymentType.value ?
                        prices.mtpl.fixedPeriodPayment[policyPeriod] :
                        prices.mtpl.monthlyPayment;
                } else {
                    result = 0;
                }
            }

            return Number(result);
        }

        function submitStep2(): Promise<void> {
            const params: any = assembleStep2();
            return useAxios().post(Url.Ajax.stepsStore, params).then((value: any): void => {
                if (validResponse(value) && value.data.data.status === 'OK') {
                    useNavigate().navigate(stepsSubmitter.nextStep());
                } else {
                    forms.form.unlockInput();
                    btaBase.error.show(ErrorType.Error, 'submitStep2', value);
                }
            }).catch((reason: any): void => {
                forms.form.unlockInput();
                btaBase.error.show(ErrorType.Error, 'submitStep2', reason);
            });
        }

        function isBundleWithApprovalCase(): boolean {
            return vehicleProduct.value === VehicleProduct.Bundle && isApprovalCaseNecessary();
        }

        function isApprovalCaseNecessary(): boolean {
            let isApprovalCaseNecessary: string[] = [];
            if (isSet(btaBase.userStorage.storageData.attributes)) {
                const cascoProductFromStorage: any = btaBase.userStorage.storageData.attributes.vehicleProducts.CASCO;
                if (cascoProductFromStorage.isApprovalCaseNecessary) {
                    isApprovalCaseNecessary = [cascoProductFromStorage.isApprovalCaseNecessary];
                }
                if (cascoProductFromStorage.isApprovalCaseNecessaryByPaymentPeriod) {
                    isApprovalCaseNecessary = Object.values(cascoProductFromStorage.isApprovalCaseNecessaryByPaymentPeriod);
                }
            }

            return isApprovalCaseNecessary.includes('Y') || isApprovalCaseNecessary.includes('N/A');
        }

        function policyPeriodString(): string {
            return vehicleProduct.value === VehicleProduct.Casco ?
                PolicyPeriodStrings.OneYearPayment : forms.form.field('policyPeriod').value;
        }

        function assembleStep2(): DynamicDictionary {
            auto.coveredPopup.contentID = vehicleProduct.value;
            prepareCoveredPopup(vehicleProduct.value, true);
            const stepStorage: any = btaBase.userStorage.stepStorageData;
            const territoryCode: unknown = forms.form.exists('territory') ?
                forms.form.field('territory').value : null;
            const youngestDriver: unknown = forms.form.exists('youngestDriver')
                ? forms.form.field('youngestDriver').value
                : null;
            const drivingExperience: boolean = forms.form.exists('drivingExperience')
                ? forms.form.field('drivingExperience').value : null;
            const drivingExperienceLessThanThreeYears: unknown = forms.form.exists('drivingExperienceLessThanThreeYears')
                ? forms.form.field('drivingExperienceLessThanThreeYears').value : null;
            const json: DynamicDictionary = {};
            json.data = {
                vehicleProduct: vehicleProduct.value,
                policyPeriod: transformedPeriod(),
                policyPeriodString: policyPeriodString(),
                paymentType: transformedPaymentCount(),
                isSubscription: isSubscription.value,
                territoryCode: territoryCode,
                youngestDriver: youngestDriver,
                drivingExperience: drivingExperience,
                drivingExperienceLessThanThreeYears: drivingExperienceLessThanThreeYears,
                ...(stepStorage.ownerDrivingLicenceDate
                    ? {ownerDrivingLicenceDate: stepStorage.ownerDrivingLicenceDate}
                    : {}),
                ...(stepStorage.noDriversLicence ? {noDriversLicence: stepStorage.noDriversLicence} : {}),
                ...(stepStorage.ownerRegistrationCode ? {ownerRegistrationCode: stepStorage.ownerRegistrationCode} : {}),
                ...(stepStorage.ownerPersonCode ? {ownerPersonCode: stepStorage.ownerPersonCode} : {}),
                ...(stepStorage.vehicleRegCertificateNumber
                    ? {vehicleRegCertificateNumber: stepStorage.vehicleRegCertificateNumber}
                    : {}),
                ...(stepStorage.disabilityCertificateNumber
                    ? {disabilityCertificateNumber: stepStorage.disabilityCertificateNumber}
                    : {}),
                ...(stepStorage.referralCode ? {referralCode: stepStorage.referralCode} : {}),
                coveredPopup: auto.coveredPopup,
                sums: sums.value,
                cachedRequests: auto.cachedRequests.value,
            };

            return {
                data: {
                    uid: btaBase.userStorage.uUid,
                    json: JSON.stringify(json),
                    facility: btaBase.facility(),
                    step: NextStep,
                }
            };
        }

        function drivingExperienceValidators(): Record<string, unknown> {
            return {
                requiredForCascoAndBundle: () => {
                    return productIsMtpl.value || !forms.form.field('drivingExperience').isEmpty()
                }
            };
        }

        function setupForm(): void {
            btaBase.setStep(CurrentStep);
            const storage: any = btaBase.userStorage.storageData.attributes || {};
            if (btaBase.cmsFields.paymentType.enabled && isSubscriptionAvailable.value) {
                forms.form.addField(new FormField('paymentType', btaBase.cmsFields.paymentType.value));
            }
            if (btaBase.cmsFields.paymentOption.enabled) {
                forms.form.addField(new FormField('paymentOption', btaBase.cmsFields.paymentOption.value));
            }
            if (btaBase.cmsFields.territory.enabled) {
                const value: any = storage && storage.territoryCode
                    ? storage.territoryCode
                    : btaBase.cmsFields.territory.value;
                forms.form.addField(new FormField('territory', value));
            }
            if (new AppCountry().isEE()) {
                forms.form.addField(new FormField('territory', btaBase.settings.cascoTerritoryIc()));
            }
            if (btaBase.cmsFields.youngestDriver.enabled && isOwnerPersonal.value) {
                forms.form.addField(
                    new FormField('youngestDriver', youngestDriverInitialValue(), youngestDriverValidators()));
            }
            if (btaBase.cmsFields.drivingExperience.enabled && isOwnerPersonal.value) {
                const value: any = storage && storage.driverExperience ?
                    storage.driverExperience : btaBase.cmsFields.drivingExperience.value;
                forms.form.addField(new FormField('drivingExperience', value, drivingExperienceValidators()));
                oldDriverExperience.value = value;
            }
            if (btaBase.cmsFields.drivingExperienceLessThanThreeYears.enabled && storage.experienceLessThanThreeYears) {
                const value: any = storage && storage.experienceLessThanThreeYears
                    ? storage.experienceLessThanThreeYears === 'Y'
                    : btaBase.cmsFields.drivingExperienceLessThanThreeYears.value;
                forms.form.addField(new FormField('drivingExperienceLessThanThreeYears', value));
            }
            forms.form.addField(new FormField('policyPeriod', ''));
            forms.form.addField(new FormField('vehicleProduct', ''));
            forms.form.setReady();
            forms.formsAreReady.value = true;
        }

        function setupFieldsPopupForm(): void {
            if (forms.form.exists('youngestDriver')
                && forms.form.exists('drivingExperience')
            ) {
                forms.fieldsPopupForm.addField(new FormField(
                    'driverAge',
                    forms.form.field('youngestDriver').value,
                    fieldsPopupDriverAgeValidators()
                ));
                forms.fieldsPopupForm.addField(new FormField(
                    'drivingExperience',
                    forms.form.field('drivingExperience').value
                ));
            } else if (forms.form.exists('drivingExperienceLessThanThreeYears')
                && forms.form.exists('territory')
            ) {
                forms.fieldsPopupForm.addField(new FormField(
                    'drivingExperienceLessThanThreeYears',
                    forms.form.field('drivingExperienceLessThanThreeYears').value
                ));
                forms.fieldsPopupForm.addField(new FormField(
                    'territory',
                    forms.form.field('territory').value
                ));
            }
            forms.fieldsPopupForm.setReady();
        }

        function youngestDriverInitialValue(): number {
            const storage: any = btaBase.userStorage.storageData.attributes || {};
            const result: string = storage && storage.youngestDriverAge !== undefined ?
                storage.youngestDriverAge : btaBase.cmsFields.youngestDriver.value;

            return parseInt(result, 10);
        }

        function youngestDriverValidators(): Record<string, unknown> {
            return {
                requiredForMtplAndBundle: () => {
                    const validYoungestDriver: boolean = !forms.form.field('youngestDriver').isEmpty()
                        && inRange(
                            parseInt(forms.form.field('youngestDriver').value, 10),
                            youngestDriverMin,
                            youngestDriverMax
                        );

                    return productIsCasco.value || validYoungestDriver;
                }
            };
        }

        function inRange(value: number, min: number, max: number): boolean {
            return value >= min && value <= max;
        }

        function setupReminderForm(): void {
            forms.reminderForm.addField(new FormField('reminderName', '', 'required', Sanitizer.cleanName));
            forms.reminderForm.addField(new FormField('reminderEmail', '', 'required'));
            forms.reminderForm.addField(new FormField('reminderPhone'));
            forms.reminderForm.addField(new FormField('reminderAgreeToReceiveMarketing', false));
            forms.reminderForm.setReady();
        }

        function makePaymentOptionInputOptions(): void {
            paymentOptionInputOptions.value = btaBase.cmsFields.paymentOption.items.map((option: any) => {
                return (new InputOptionBuilder()).setValue(option.value).setName(option.name).build();
            });
        }

        function makeTerritoryInputOptions(): void {
            territoryInputOptions.value = btaBase.cmsFields.territory.items.map((option: any) => {
                return (new InputOptionBuilder()).setValue(option.value).setName(option.name).build();
            });
        }

        function makePolicyPeriodInputOptions(): void {
            const countryFilterType: string = vehicleProduct.value || VehicleProduct.Mtpl;
            const prices: any = autoStorage.storageDataPrices(countryFilterType, defaultEuDeductible());
            policyPeriodInputOptions.value = [];
            if (prices && prices.fixedPeriodPayment) {
                policyPeriodInputOptions.value = Object.keys(prices.fixedPeriodPayment).map((period: string) => {
                    return {name: PolicyPeriods.memberValueByName(period), value: period};
                }).sort((a: { name: string }, b: { name: string }) => {
                    return parseInt(a.name, 10) - parseInt(b.name, 10);
                }).map((periodMapped: { name: string; value: string }) => {
                    return (new InputOptionBuilder())
                        .setValue(periodMapped.value)
                        .setName(periodMapped.name)
                        .build();
                });
            }
        }

        function makeDrivingExperienceInputOptions(): void {
            drivingExperienceInputOptions.value = btaBase.cmsFields.drivingExperience.items.map((option: any) => {
                return (new InputOptionBuilder()).setValue(option.value).setName(option.name).build();
            });
        }

        function makeDrivingExperienceLessThanThreeYearsInputOptions(): void {
            drivingExperienceLessThanThreeYearsInputOptions.value =
                btaBase.cmsFields.drivingExperienceLessThanThreeYears.items
                    .map((option: any) => {
                        return (new InputOptionBuilder()).setValue(option.value).setName(option.name).build();
                    });
        }

        function makePaymentTypeInputOptions(): void {
            paymentTypeInputOptions.value = btaBase.cmsFields.paymentType.items.map((option: any) => {
                return (new InputOptionBuilder()).setValue(option.value).setName(option.name).build();
            });
        }

        function initApplicationSteps(): void {
            const titleElement = $('div.application-step-subtitle0').first();
            let resultText: string = '';
            if (titleElement) {
                resultText = applicationFirstStepDynamicTitle();
            }
            if (resultText.length > 0) {
                titleElement.text(resultText);
            }
        }

        function applicationFirstStepDynamicTitle(): string {
            const storage: any = btaBase.userStorage.storageData;
            const attributes = storage.attributes || {};
            let title: string = '';
            const make = attributes.vehicleMake;
            const modelModification = attributes.modelModification;
            const technicalCertificateNumber = attributes.technicalCertificateNumber;
            if (make) {
                title += make;
            }
            if (modelModification) {
                title += ' ' + modelModification;
            }
            if (technicalCertificateNumber) {
                title += ' / ' + technicalCertificateNumber;
            }
            return title;
        }

        function dataLayerTransformedPaymentCount(): string {
            let result: string = '';
            if (isSubscription.value) {
                result = DataLayerType.AutoProductSubscription;
            } else {
                result = paymentTypeIsMonthly.value ?
                    DataLayerType.AutoProductMonthly : DataLayerType.AutoProductOnce;
            }
            return result;
        }

        function fieldsPopupDriverAgeValidators(): object {
            return {
                outOfRange: () => {
                    return inRange(
                        parseInt(forms.fieldsPopupForm.field('driverAge').value, 10),
                        youngestDriverMin,
                        youngestDriverMax
                    );
                }
            };
        }

        function requireMandatoryLogin(): boolean {
            let result: boolean = false;
            if (new AppCountry().isLT() && btaBase.user.isGuest()) {
                result = !isCorporateVehicle() && isPersonNotOwnerCase();
            }

            return result;
        }

        function checkAuthenticatedUserAndReroute(): void {
            if (new AppCountry().isLT()) {
                if (!isCorporateVehicle() && btaBase.user.isLogged()) {
                    const authUserPersonCode: string = btaBase.user.current.personCode;
                    const vehicleOwnerPersonCode: string = btaBase.userStorage.stepStorageData.ownerPersonCode;
                    if (authUserPersonCode !== vehicleOwnerPersonCode) {
                        const url: string = new UrlBuilder()
                            .withUri(stepsSubmitter.previousStep())
                            .withGetParams({
                                purchaseRestricted: 'true',
                            })
                            .build();
                        PopupService.getInstance().show(new OnePopup().withType().loading);
                        useNavigate().navigate(url);
                    }
                }
            }
        }

        function isCorporateVehicle(): boolean {
            let result: boolean = false;
            if (isSet(btaBase.userStorage.stepStorageData.ownerRegistrationCode)) {
                const ownerRegistrationCode: string = btaBase.userStorage.stepStorageData.ownerRegistrationCode;
                result = Validation.isValidCompanyRegistrationNumber(ownerRegistrationCode);
            }

            return result;
        }

        function isPersonNotOwnerCase(): boolean {
            const attributes: DynamicDictionary = btaBase.userStorage.storageData.attributes;

            return isSet(attributes.showMessage) && attributes.showMessage === SpecificError.VehicleNotOwned;
        }

        function fetchYoungestDriverIfNeeded(): void {
            if (btaBase.cmsFields.youngestDriver.enabled && isOwnerPersonal.value) {
                const newYoungestDriverInitialValue: number = youngestDriverInitialValue();
                const newYoungestDriverRestoreValue: number = forms.form.field('youngestDriver').value;
                if (newYoungestDriverInitialValue !== newYoungestDriverRestoreValue) {
                    submitAfterFetch.value = false;
                    youngestDriverChange();
                }
            }
        }

        function sendMetaEvent(): void {
            request.post(Url.Ajax.addToCartEvent, {
                productCategory: 'AutoInsurance',
                productName: vehicleProduct.value,
                productIds: vehicleProduct.value,
                policyPrice: selectedProductPrice(vehicleProduct.value),
            }).then();
        }

        function selectedProductPrice(productId: string): string {
            let result: string = '';
            const autoSum = btaBase.user.isLogged() ?
                auto.productSums.authenticated :
                auto.productSums.guest;
            switch (productId) {
                case VehicleProduct.Casco:
                    result = isSubscription.value ?
                        autoSum.cascoSubscriptionPrice.toString() :
                        autoSum.cascoDiscountSum.toString();
                    break;
                case VehicleProduct.Mtpl:
                    result = isSubscription.value ?
                        autoSum.mtplSubscriptionPrice.toString() :
                        autoSum.mtplDiscountSum.toString();
                    break;
                case VehicleProduct.Bundle:
                    result = isSubscription.value ?
                        autoSum.bundleSubscriptionPrice.toString() :
                        autoSum.bundleDiscountSum.toString();
                    break;
                default:
            }
            return result.toString();
        }

        function updateContentID(): void {
            auto.coveredPopup.contentID = auto.coveredPopup.default.value;
        }

        function onAfterFormRestored(): void {
            makePaymentOptionInputOptions();
            makeTerritoryInputOptions();
            makePolicyPeriodInputOptions();
            if (needToPatchPolicyPeriod()) {
                updatePolicyPeriodByPaymentType();
            }
            if (needToPatchVehicleProduct()) {
                vehicleProduct.value = forms.form.field('vehicleProduct').value;
            }
            initApplicationSteps();
            nextTick((): void => {
                applyReminderUser();
                applyFinalSums();
                allowScrollToAdditionalItems.value = true;
                needToFetchOnBlur.value = false;
                fetchYoungestDriverIfNeeded();
                checkAuthenticatedUserAndReroute();
            });
        }

        function onUserStorageReady(): void {
            makeDrivingExperienceInputOptions();
            makeDrivingExperienceLessThanThreeYearsInputOptions();
            makePaymentTypeInputOptions();
            setupForm();
            setupFieldsPopupForm();
            setupReminderForm();
            applyReminderUser();
        }

        function assocArrayLength(param: DynamicDictionary): number {
            return useDefine().assocArrayLength(param);
        }

        return {
            ...btaBase,
            ...forms,
            ...auto,
            ...{
                CurrentStep,
                Facility,
                vehicleProduct,
                paymentOptionInputOptions,
                territoryInputOptions,
                policyPeriodInputOptions,
                drivingExperienceInputOptions,
                drivingExperienceLessThanThreeYearsInputOptions,
                paymentTypeInputOptions,
                NoAdditionalRisks,
                activeReminderButtonMtpl,
                activeReminderButtonBundle,
                activeReminderButtonCasco,
                selectedReminderId,
                selectedReminderDate,
                reminderDate,
                reminderMessage,
                selectedReminderInformativeId,
                remindInformativePopup,
                vehicleProductAdditionalRisksMapping,
                footerFullSum,
                footerDiscountSum,
                reloadMtplSums,
                fetchSumsBeforeNextStep,
                oldDriverExperience,
                needToFetchOnBlur,
                submitAfterFetch,
                allowScrollToAdditionalItems,
                vehicleProductLocalized,
                productReminderMessage,
                productReminderDate,
                coveredInfoTextMtplWithRoadAssistance,
                policyPeriodTitle,
                footerFullSumFormatted,
                showFooterDiscountBlock,
                showPricePaymentPeriodText,
                footerDiscountSumFormatted,
                footerSaveSumFormatted,
                reminderIsValid,
                isOwnerPersonal,
                isOwnerLegalCode,
                vehicleProductIsSelected,
                productIsBundle,
                productIsMtpl,
                productIsCasco,
                paymentTypeIsMonthly,
                formIsValid,
                isFixedPaymentType,
                isSubscription,
                isSubscriptionAvailable,
                reminderClasses,
                controlsAreVisible,
                coveredPopupFooterIsVisible,
                youngestDriverMin,
                youngestDriverMax,
                youngestDriverValue,
                infoPopupForProduct,
                pricePaymentPeriodText,
                deductiblesAreVisible,
                insuranceOptionsAreVisible,
                insuranceOptionsValue,
                drivingExperienceFieldIsVisible,
                drivingExperienceLessThanThreeYearsFieldIsVisible,
                fieldsPopupDriverAgeValueIsOutOfRange,
                fieldsPopupDriverAgeValue,
                fieldsPopupDrivingExperienceLessThanThreeYearsValue,
                fieldsPopupHeading,
                fieldsPopupIsOpen,
                fieldsPopupIsActive,
                fieldsPopupIsActiveLt,
                fieldsPopupIsActiveLv,
                fieldsPopupNoDrivingExperienceLessThanThreeYearsValue,
                fieldsPopupTerritoryValue,
                youngestDriverFieldIsVisible,
                sums,
                bothProductsAreLocked,
                oneOfProductsIsLocked,
                bundleIsAvailable,
                setupForm,
                onAfterFormRestored,
                onUserStorageReady,
                applyReloadMtplSumsStatus,
                applyFetchSumsStatus,
                applyStepUrls,
                discountBadgeIsVisible,
                discountPercentFormatted,
                productSum,
                bundleProductSum,
                paymentButton,
                policyPeriodButton,
                paymentTypeButton,
                onYoungestDriverChange,
                youngestDriverChange,
                onYoungestDriverInput,
                onYoungestDriverError,
                onDriverExperienceChange,
                vehicleProductItemButton,
                vehicleProductCoveredButton,
                additionalFooterCoveredButton,
                coveredProductTabButtonClick,
                vehicleProductButtonClasses,
                policyItemClasses,
                reminderItemButton,
                territoryButton,
                prepareReminderInfoPopup,
                infoPopup,
                additionalFooterSubmit,
                productPriceForSelectedPeriod,
                calculatePrices,
                additionalContainerClasses,
                bundleRiskIsVisible,
                closeFieldsPopup,
                confirmFieldsPopup,
                openFieldsPopup,
                applyDiscount,
                applyDiscountOnBundleProduct,
                productHasSubscription,
                isVehicleProductUnavailable,
                vehicleProductErrorDetails,
                isProductBundle,
                fullProductSum,
                productIsLocked,
                countrySpecificRestrictions,
                productHasRisks,
                needToPatchPolicyPeriod,
                needToPatchVehicleProduct,
                updatePolicyPeriodByPaymentType,
                applyReminderUser,
                productHasActiveSubscription,
                applyVehicleProductAdditionalRisksMapping,
                transformedMtplPeriod,
                mappedRisk,
                isVisibleRisk,
                prepareCoveredPopup,
                productHasRisk,
                applyFromThisProducts,
                selectedVehicleProductPrices,
                scrollToPoliciesListEnd,
                queryRemind,
                updateContentID,
                assocArrayLength,
                transformedPeriod,
                fetchSumsForOwner,
                monthsLabel,
                policyPeriodString,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        Translations.getInstance().addType('auto-policy');
        const onStorageReadySubscription =
            this.userStorage.onStorageDataIsReady.subscribe((): void => {
                this.onUserStorageReady();
                onStorageReadySubscription.unsubscribe();
            });
        const onAfterFormRestoredSubscription =
            this.userStorage.onFormStorageDataIsReady.subscribe(() => {
                this.onAfterFormRestored();
                onAfterFormRestoredSubscription.unsubscribe();
            });
    }
});

const defaultEuDeductible = ((): number => {
    const DefaultTerritory: string = 'EU';

    return Number(AutoStorageService.getInstance().defaultDeductibles(DefaultTerritory));
});
</script>

