<script setup lang="ts">
import ClaimsMtplService from '@/Apps/ClaimsMtpl/Services/ClaimsMtplService';
import Form from '@/assets/libraries/form/form';
import {computed, onMounted, reactive, Ref, UnwrapNestedRefs} from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import {useTranslate} from '@/Composables/Translate';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import {InputOption} from '@/interfaces/InputOptionInterface';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ClaimsMtplOptions from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplOptionsInterface';
import PropertyDamageNames from '@/Apps/ClaimsMtpl/Enums/Damage/PropertyDamageNames';
import DamageTypes from '@/Apps/ClaimsMtpl/Enums/DamageTypesEnum';
import {SubFlowEvent} from '@/Apps/ClaimsMtpl/Interfaces/SubFlowEvent';
import Translations from '@/services/translations.service';
import ClaimsMtplClaimTypes from '@/Apps/ClaimsMtpl/Enums/ClaimsMtplClaimTypes';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Value from '@/assets/libraries/form/value';
import {useDefine} from '@/Composables/Define';
import ClaimsMtplFormFields from '@/Apps/ClaimsMtpl/Classes/ClaimsMtplFormFields';

const props = defineProps({
    dataScroll: {type: String},
});
const emit = defineEmits<{
    (event: 'completed', subFlowEvent: SubFlowEvent): void
    (event: 'change'): void
}>();

const {translate, translateForType} = useTranslate();
const {isSet} = useDefine();

const claimsMtplService: ClaimsMtplService = ClaimsMtplService.getInstance();

const form: Form = new Form();
const inputOptions: UnwrapNestedRefs<ClaimsMtplOptions> = reactive({});

const propertyOwnerIsNotApplicant: Ref<boolean> = computed(() => {
    return form.field(PropertyDamageNames.OwnerIsApplicant).value === 'no';
});

const isGuiltyFlow: Ref<boolean> = computed(() => {
    return claimsMtplService.fields.typeOfClaim?.selected === ClaimsMtplClaimTypes.Guilty
});

function preparePanels(): void {
    for (let key in PropertyDamageNames) {
        inputOptions[key] = new class ClaimsMtplOptions {
            public enabled: boolean = true;
            public passed: boolean = false;
            public visible: boolean = false;
            public value: LimitedVariant = '';
            public options: InputOption[] = [];
        }
    }
}

function buildPropertyOwnerIsApplicantOptions(): void {
    inputOptions.OwnerIsApplicant.options = [
        (new InputOptionBuilder).setName(translate('btar_yes')).setValue('yes').build(),
        (new InputOptionBuilder).setName(translate('btar_no')).setValue('no').build(),
    ];
}

function proceedButton(): ButtonWithCallbackParams {
    return {
        title: form.field(PropertyDamageNames.Location).isValid ? localized('proceed') : localized('select_map_or_enter_address_to_proceed'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
}

function setupForm(): void {
    form.addField(new FormField(PropertyDamageNames.Location, '', 'required'));
    form.addField(new FormField(PropertyDamageNames.OwnerDetails));
    form.addField(new FormField(PropertyDamageNames.OwnerIsApplicant));
    form.setReady();
}

function restoreValues(): void {
    const storedValues: DynamicDictionary = claimsMtplService.fields;
    Object.keys(PropertyDamageNames).forEach((field: string) => {
        const formKey: string = PropertyDamageNames[field as keyof PropertyDamageNames];
        const serviceKey: string = 'property' + formKey.charAt(0).toUpperCase() + formKey.slice(1);
        if (isSet(storedValues[serviceKey]) && new Value(storedValues[serviceKey]).isNotEmpty()) {
            form.field(formKey).setValue(storedValues[serviceKey]);
        }
    });
}

function storeFormToService(): void {
    Object.keys(PropertyDamageNames).forEach((field: string) => {
        const formKey: string = PropertyDamageNames[field as keyof PropertyDamageNames];
        const serviceKey: string = 'property' + formKey.charAt(0).toUpperCase() + formKey.slice(1);
        claimsMtplService.fields[serviceKey as keyof ClaimsMtplFormFields] = form.field(formKey).value;
    });
}

function localized(stringUid: string): string {
    return translateForType(stringUid, Translations.getInstance().type);
}

function completedEvent(): SubFlowEvent {
    return {
        [DamageTypes.Property]: {
            description: form.field(PropertyDamageNames.Location).value.address
        }
    }
}

function onChange(): void {
    emit('change');
    storeFormToService();
}

preparePanels();
buildPropertyOwnerIsApplicantOptions();

onMounted(() => {
    setupForm();
    restoreValues();
});
defineExpose({
    claimsMtplService,
});
</script>

<template>
    <div class="container">
        <app-custom-form
            v-if="form.isReady()"
            :form="form"
            :data-scroll="dataScroll"
            @change="onChange()"
            class="form">
            <div class="whiteboard-panel whiteboard-panel-margin">
                <label>{{ localized('property_damage_title') }}</label>
                <div class="whiteboard">
                    <h4 class="title">{{ localized('property_details_title') }}</h4>
                    <app-map-with-address
                        :label="localized('property_details_location_label')"
                        :custom-search-placeholder="localized('property_details_location_placeholder')"
                        :form-field="form.field(PropertyDamageNames.Location)">
                    </app-map-with-address>
                    <template v-if="!isGuiltyFlow">
                        <app-input-radio
                            :type="'radio'"
                            :label="localized('property_details_owner_is_applicant_label')"
                            :form-field="form.field(PropertyDamageNames.OwnerIsApplicant)"
                            :options="inputOptions.OwnerIsApplicant.options">
                        </app-input-radio>
                        <template v-if="propertyOwnerIsNotApplicant">
                            <h4 class="title">{{ localized('property_details_owner_details_title') }}</h4>
                            <app-input-text
                                :style="'width: 100%'"
                                :placeholder="localized('property_details_owner_details_placeholder')"
                                :form-field="form.field(PropertyDamageNames.OwnerDetails)">
                            </app-input-text>
                        </template>
                    </template>
                    <app-button-with-callback class="button"
                                              v-bind="proceedButton()"
                                              @button-callback-click="emit('completed', completedEvent())"
                                              :disabled="!form.field(PropertyDamageNames.Location).isValid">
                    </app-button-with-callback>
                </div>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;

    .input:not(:last-child) {
        margin-bottom: 24px;
    }

    .button {
        padding: 0 var(--size-medium);

        .icon-right {
            margin-left: var(--size-nano);
        }
    }

    .button-with-callback {
        height: 52px;
    }

    .whiteboard.whiteboard-margin h4:first-of-type {
        margin-bottom: var(--size-big);
    }

    :deep(#ownerDetails, .input-radio[data-type="ownerIsApplicant"]) {
        margin-bottom: 0;
    }

    :deep(.input-radio) {
        margin-top: var(--size-big);

        .buttons {
            width: min-content;

            button.type-radio.size-large {
                padding-left: 36px;
                padding-right: var(--size-big);

                &.active::before {
                    border-color: var(--system-color-success-dark);
                    background-color: var(--white);
                    border-width: 8px;
                }
            }
        }

        + h4.title {
            margin-top: var(--size-big);
        }
    }

    :deep(.map-with-address .search-container .google-search-suggestions .geo svg .pin) {
        fill: var(--black-500);
    }
}
</style>
