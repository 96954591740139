<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import {Ref, ref, computed, watch, onMounted, reactive, PropType} from 'vue';
import CompanyType from '@/interfaces/company.type.interface';
import OptionValue from '@/interfaces/option.value.interface';
import CompanyTypeListItem from '@/interfaces/company.type.options.list.item.interface';
import OptionWithToken from '@/interfaces/option.with.token.interface';
import axios, {CancelTokenSource} from 'axios';
import VueEvent from '@/Classes/VueEventClass';
import Url from '@/Enums/UrlEnum';
import CompanyTypes from '@/interfaces/company.types.interface';
import Translations from '@/services/translations.service';
import {useFormatter} from '@/Composables/Formatter';
import {InputOption} from '@/interfaces/InputOptionInterface';
import AppPopup from '@/Components/Popup/Popup.vue';

const props = defineProps({
    placeholder: {type: String, default: () => ''},
    formField: {type: Object as PropType<FormField<CompanyType>>, default: () => new FormField('')},
    label: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    dataStoreDisabled: {type: Boolean, default: false},
    disableErrorText: {type: Boolean, default: false},
    supportTextMessage: {type: String, default: ''},
    popupLabel: {type: String, default: ''},
    showResidentValues: {type: Boolean, default: true},
});

const emit = defineEmits(['change', 'input', 'close', 'click']);
const translations: Translations = Translations.getInstance();
const {formattedUrl} = useFormatter();
const MaxDefaultElements: number = 5;
const companyType: OptionValue = reactive({
    id: ':void:',
    value: '',
});
const axiosToken: OptionWithToken = new class implements OptionWithToken {
    public options: InputOption[] = [];
    public cancelToken: CancelTokenSource | null = null;
    public fetchIsInProgress: boolean = false;
};
const isVisibleDropdown: Ref<boolean> = computed(() => {
    return typedValue.value !== '' || inputInFocus.value
});
const companyTypeData: Ref<CompanyType> = computed(() => {
    return {
        iso: companyType.id,
        name: companyType.value
    }
})

let companyTypes: Ref<CompanyType[]> = ref([]);
let companyTypesResident!: CompanyType[];
let companyTypesNonResident!: CompanyType[];
let visible: Ref<boolean> = ref(false);
let typedValue: Ref<string> = ref('');
let options: Ref<CompanyTypeListItem[]> = ref([]);
let inputInFocus: Ref<boolean> = ref(false);
let index: number = 0;
let selectorPlaceholder: Ref<string> = ref('');

watch(() => companyType.id, () => {
        props.formField?.setValue(companyTypeData.value);
        props.formField.validate();
        emitChange();
    }
);

watch(() => props.showResidentValues, () => {
        updateCompanyTypesForDropdown();
        resetFormFieldValue();
        emitChange();
    }
);

onMounted((): void => {
    init();
    selectorPlaceholder.value = props.placeholder === ''
        ? selectorPlaceholder.value = translations.localized('input_company_type_placeholder')
        : selectorPlaceholder.value = props.placeholder;
    if (!props.formField.isEmpty()) {
        companyType.id = props.formField.value.iso;
        companyType.value = props.formField?.value.name;
    }
    props.formField.onPatch.subscribe((): void => {
        companyType.id = props.formField.value.iso;
        companyType.value = props.formField.value.name;
    });
});

function showListSearch(event: VueEvent): void {
    if (!props.disabled) {
        prepareOptionsList(event, true);
    }
}

function isVisibleItem(current: CompanyTypeListItem): boolean {
    let result: boolean = false;
    if (typedValue.value) {
        result = current.name.toLowerCase().includes(typedValue.value.toLowerCase());
    } else if (inputInFocus.value) {
        result = current.index < MaxDefaultElements;
    }

    return result;
}

function onFocus(event: VueEvent): void {
    inputInFocus.value = true;
}

function onBlur(event: VueEvent): void {
    setTimeout(() => {
        inputInFocus.value = false;
    }, 500);
}

function onOptionSelectClick(event: VueEvent): void {
    companyType.id = event.data('id');
    companyType.value = event.data('value');
    close();
}

function closePopup(): void {
    close();
}

function init(): void {
    if (companyTypes.value.length === 0) {
        fetchCompanyTypes();
    }
}

function fetchCompanyTypes(): void {
    if (axiosToken.fetchIsInProgress) {
        axiosToken.cancelToken!.cancel();
    }
    axiosToken.fetchIsInProgress = true;
    axiosToken.cancelToken = axios.CancelToken.source();
    axios.get(formattedUrl(Url.Ajax.fetchCompanyTypes),
        {cancelToken: axiosToken.cancelToken.token}
    ).then((value: any) => {
        const companyTypes: CompanyTypes = JSON.parse(value.data.data.body.types);
        applyCompanyTypes(companyTypes);
        axiosToken.fetchIsInProgress = false;
    }).catch((reason: any) => {
        axiosToken.fetchIsInProgress = false;
    })
}

function applyCompanyTypes(types: CompanyTypes): void {
    companyTypesResident = types.resident;
    companyTypesNonResident = types.nonResident;
    companyTypes.value = props.showResidentValues ? companyTypesResident : companyTypesNonResident;
}

function prepareOptionsList(event: VueEvent, withSearch: boolean = false): void {
    clear();
    for (let o in companyTypes.value) {
        addItem(o, companyTypes.value[o].name);
    }
    typedValue.value = '';
    visible.value = true;
}

function clear(): void {
    options.value = [];
    index = 0;
}

function addItem(companyTypeIso: string, companyTypeName: string): void {
    const itemIndex: number = index++;
    options.value.push(
        new class implements CompanyTypeListItem {
            public iso: string = companyTypeIso;
            public name: string = companyTypeName;
            public index: number = itemIndex;
        }
    );
}

function close(): void {
    visible.value = false;
    emit('close');
}

function updateCompanyTypesForDropdown(): void {
    companyTypes.value = props.showResidentValues
        ? companyTypesResident
        : companyTypesNonResident;
}

function resetFormFieldValue(): void {
    props.formField.clear();
}

function emitChange(): void {
    props.formField.touch();
    props.formField.sanitize();
    props.formField.validate();
    emit('change', props.formField.value);
}
</script>

<template>
    <div class="input input-company-type"
         :id="formField.name"
         :class="formField.classes()"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)">
        <div v-if="label" class="label informative">
            <p v-html="label"></p>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper select">
            <div class="current button cursor-pointer"
                 :class="{'disabled' : props.disabled}"
                 :id="formField.name + '-showListSearch'"
                 @click="showListSearch(new VueEvent($event))">
            <span v-if="companyType.value === ''"
                  v-html="selectorPlaceholder"
                  class="text placeholder text-icon"></span>
                <span v-if="companyType.value !== ''"
                      v-html="companyType.value"
                      class="text text-icon"></span>
                <span class="icon arrow-icon">
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 1L7 7L1 1" stroke="#E30613" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                </svg>
            </span>
            </div>
        </div>
        <div class="popups">
            <app-popup v-if="visible" class="simple list-search" @close="closePopup">
                <div class="title" v-html="popupLabel || label"></div>
                <div class="elements">
                    <div class="field">
                        <div class="added"></div>
                        <div class="search">
                            <div class="icon">
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.48">
                                        <path d="M14 14L20 20" stroke="#9297A0" stroke-width="2"></path>
                                        <circle cx="8.5" cy="8.5" r="7.5" stroke="#9297A0"
                                                stroke-width="2"></circle>
                                    </g>
                                </svg>
                            </div>
                            <input class="text"
                                   :id="formField.name + '-typedValue'"
                                   v-model="typedValue"
                                   @focus="onFocus(new VueEvent($event))"
                                   @blur="onBlur(new VueEvent($event))"
                                   :placeholder="placeholder"
                                   :autocomplete="'disabled'"/>
                        </div>
                        <div class="dropdown"
                             :class="{hidden:!isVisibleDropdown}">
                            <button class="country-item"
                                    v-for="(item, index) in options"
                                    :key="index"
                                    :id="formField.name + '-dropdown-' + item.name"
                                    :class="{hidden:!isVisibleItem(item)}"
                                    :data-value="item.name"
                                    :data-id="item.iso"
                                    @click="onOptionSelectClick(new VueEvent($event))">
                                <span class="text">{{ item.name }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </app-popup>
        </div>
    </div>

</template>

<style lang="scss" scoped>
.input-company-type {
    scroll-margin-top: 4em;

    .wrapper {
        .disabled {
            background: var(--component-color-background-disabled);
        }
    }

    p {
        color: inherit;
    }

    .text {
        color: var(--text-color-default);
    }

    .placeholder {
        opacity: 0.56;
        color: var(--text-color-subtlest);
    }

    &.invalid:not(.untouched) {
        .current {
            border-color: var(--brand-red);
        }
    }

    .label.informative {
        display: flex;
        margin-bottom: 0;
        min-height: 31px;
        align-items: flex-start;

        p {
            line-height: 14px;
        }
    }

    .popups .single-popup.list-search > .wrapper .elements .field .search .text {
        background-color: var(--component-color-background-base);
    }
}
</style>
