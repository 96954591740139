<script lang="ts">
import {defineComponent} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import ClaimsTransportSubmissionFormModule from '@/Modules/ClaimsSubmissionForm/SubmissionFormTransport.vue';
import VueModule from '@/Core/App/VueModule';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const claimsTransportSubmissionFormModule = new VueModule(ClaimsTransportSubmissionFormModule).mount();

        return {
            ...btaBase,
            ...{
                claimsTransportSubmissionFormModule
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.claimsTransportSubmissionFormModule.create();
        this.claimsTransportSubmissionFormModule.beforeModuleMounted();
        this.claimsTransportSubmissionFormModule.moduleMounted();
    },
});
</script>