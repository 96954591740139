import {reactive, UnwrapNestedRefs} from 'vue';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import TwoWaySupportType from '@/Apps/TwoWayCommunication/Enums/TwoWaySupportTypeEnum';
import TwoWayCommunicationsBlock from '@/Apps/TwoWayCommunication/Interfaces/TwoWayCommunicationsBlockInterface';
import TwoWaySingleCommunication from '@/Apps/TwoWayCommunication/Interfaces/TwoWaySingleCommunicationInterface';
import Claim from '@/Components/ClaimBlock/Interfaces/ClaimInterface';
import TwoWayStorageFields from '@/Apps/TwoWayCommunication/Interfaces/TwoWayStorageFieldsInterface';
import SpaUserStorage from '@/services/SpaUserStorageService';


export default class TwoWayCommunication {
    public storageFields: UnwrapNestedRefs<TwoWayStorageFields> = reactive(
        new class implements TwoWayStorageFields {
            public supportType: string = TwoWaySupportType.None;
            public claimCaseSupport: string = TwoWaySupportType.None;
            public selectedClaim: string = '';
            public claimsList: Claim[] = [];
            public communicationId: string = '';
            public isReply: boolean = false;
            public isAddNewMessage: boolean = false;

        });
    private static instance: TwoWayCommunication;
    private allCommunications: TwoWayCommunicationsBlock[] = [];
    private modalName: string = '';
    private communicationsInitialUrl: string = '';
    private transferStateService: TransferStateService = TransferStateService.getInstance();


    private constructor() {
    }

    public static getInstance(): TwoWayCommunication {
        if (!TwoWayCommunication.instance) {
            TwoWayCommunication.instance = new TwoWayCommunication();
            TwoWayCommunication.instance.buildState();
        }

        return TwoWayCommunication.instance;
    }

    public communications(): TwoWayCommunicationsBlock[] {
        return this.allCommunications;
    }

    public communicationsById(communicationId: string): TwoWaySingleCommunication[] {
        let result: TwoWaySingleCommunication[] = [];
        const communication: TwoWayCommunicationsBlock[] =
            this.allCommunications.filter((communication: TwoWayCommunicationsBlock): boolean => {
                return communication.id === communicationId;
            });
        if (communication.length > 0 && communication[0].communications.length > 0) {
            result = communication[0].communications;
        }

        return result;
    }

    public currentSelectedClaim(): Claim | null {
        let result: Claim | null = null;
        if (String(this.storageFields.claimsList) !== '') {
            this.storageFields.claimsList.forEach((claim: Claim): void => {
                if (claim.claimId === this.storageFields.selectedClaim) {
                    result = claim;
                }
            });
        }

        return result;
    }

    public unreadCommunicationsCount(): number {
        return this.allCommunications.filter((communication: TwoWayCommunicationsBlock) => {
            return communication.unread;
        }).length;
    }

    public name(): string {
        return this.modalName;
    }

    public communicationsUrl(): string {
        return this.communicationsInitialUrl;
    }

    public actionsAfterRouteChanged(from: string, to: string): void {
        if (from === 'communication-new' && to === 'communication-single') {
            this.storageFields.supportType = TwoWaySupportType.None;
            this.storageFields.claimCaseSupport = TwoWaySupportType.None;
            this.storageFields.isReply = false;
            this.storageFields.isAddNewMessage = false;
        }
        if (from === 'communication-new' && to === 'communication-single') {
            this.storageFields.supportType = TwoWaySupportType.None;
        }
        SpaUserStorage.getInstance().saveUpdatedStorage();
    }

    private buildState(): void {
        this.allCommunications = this.transferStateService.get('communications') || [];
        this.modalName = this.transferStateService.get('communicationsUserName') || '';
        this.communicationsInitialUrl = this.transferStateService.get('communicationsInitialUrl') || '';
    }
}