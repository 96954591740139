<script setup lang="ts">
    import Sanitizer from '@/services/sanitizer.service';
    import {computed, ComputedRef, onMounted, PropType, watch} from 'vue';
    import { default as AppCountryLibrary } from '@/assets/libraries/app/app-country';
    import Validation from '@/services/validation.service';
    import FormField from '@/assets/libraries/form/form-field';
    import {useInputErrorMessage} from '@/Composables/InputErrorMessage';
    import Form from '@/assets/libraries/form/form';
    import SettingsService from '@/services/settings.service';
    import PhoneField from '@/interfaces/phone.field.interface';
    import Translations from '@/services/translations.service';
    import AppInputCountry from '@/Components/InputCountry/InputCountry.vue';

    const props = defineProps({
      placeholder: {type: String, default: () => ''},
      formField: {type: Object as PropType<FormField<PhoneField>>, default: () => new FormField('')},
      label: {type: String, default: ''},
      disabled: {type: Boolean, default: false},
      dataStoreDisabled: {type: Boolean, default: false},
      feedbackMessage: {type: String, default: ''},
      supportTextMessage: {type: String, default: ''},
      countryShowCustomPopup: {type: Boolean, default: true},
      disableErrorText: {type: Boolean, default: false},
      mobileModeEnabled: {type: Boolean, default: false},
      required: {type: Boolean, default: false},
    });

    const emit = defineEmits(['change', 'close']);

    const form: Form = new Form();
    const settings: SettingsService = SettingsService.getInstance();
    const translations: Translations = Translations.getInstance();
    const appCountry: AppCountryLibrary = new AppCountryLibrary();
    const {infoMessageIsVisible, infoMessage} = useInputErrorMessage(props.formField, props.disableErrorText, {
      supportTextMessage: props.supportTextMessage,
      feedbackMessage: props.feedbackMessage
    });
    const isValidPhoneNumber: ComputedRef<boolean> = computed(() => {
      let isValid: boolean = !props.formField.isTouched;
      if (form.field('phone').value !== '') {
        const country: any = form.field('country').value;
        isValid = props.mobileModeEnabled
            ? Validation.isValidMobilePhone(form.field('phone').value, country.iso)
            : Validation.isValidPhone(form.field('phone').value, country.iso);
      }

      return isValid;
    });
    const isEmptyValue: ComputedRef<boolean> = computed(() => {
        return props.formField.isEmpty();
    });

    watch(() => props.formField.name, () => {
      init();
    });

    watch(() => props.formField.value, () => {
      props.formField.validate();
    });

    watch(() => props.formField.isRestored, () => {
      applyValuesToChildFields();
    });

    onMounted((): void => {
      setupForm();
      init();
    });

    function fieldId(): string {
      return props.formField.name + '-phone';
    }

    function popupLabel(): string {
      return translations.localized('btar_phone_country');
    }

    function onCountryClose(): void {
      emit('close');
    }

    function onCountryChange(): void {
      form.sanitize();
      form.validate().then(() => {
        applyValues();
      });
    }

    function onPhoneChange(): void {
      form.sanitize();
      form.validate().then(() => {
        applyValues();
        props.formField.touch();
      });
    }

    function init(): void {
      props.formField.addValidators({
        childrenAreValid: () => form.isValid()
      });
      applyValuesToChildFields();
      props.formField.onClear.subscribe(() => {
            applyValuesToChildFields();
          }
      );
      props.formField.onPatch.subscribe(() => {
        applyValuesToChildFields();
      });
    }

    function setupForm(): void {
      form.addField(new FormField('country'));
      form.addField(new FormField('phone', '', {
        isValidPhoneNumber: () => isValidPhoneNumber.value
      }, Sanitizer.cleanPhone));
      form.addField(new FormField('fixed'));
      form.addField(new FormField('mobile'));
      form.setReady();
    }

    function applyValuesToChildFields(): void {
      const value = props.formField.value || {};
      if (!isEmptyValue.value) {
      form.field('country').patch(
          {iso: value.iso, phoneCode: value.country}
      );
    }
    form.field('phone').patch(value.phone || '');
    form.field('fixed').patch(mobileAndFixedPhone().fixed || '');
    form.field('mobile').patch(mobileAndFixedPhone().mobile || '');
    form.sanitize();
    form.validate();
    }

    function applyValues(): void {
      const fieldValue: PhoneField = !form.field('phone').isEmpty()
          ? {
            code: form.field('country').value.iso,
            country: form.field('country').value.phoneCode,
            phone: form.field('phone').value,
            fixed: mobileAndFixedPhone().fixed,
            mobile: mobileAndFixedPhone().mobile,
          }
          : defaultValue();
      props.formField.setValue(fieldValue)
      props.formField.validate();
    }

    function defaultValue(): PhoneField {
      return {
        code: '',
        country: '',
        phone: '',
        fixed: '',
        mobile: '',
      };
    }

    function mobileAndFixedPhone(): { mobile: string, fixed: string } {
      const country: string = appCountry.iso();
      const phone: string = props.formField.value.phone;
      let mobile: string = phone;
      let fixed: string = '';
      if (settings.value('USER_PHONE_CODE').toString() === props.formField.value.country
          && Validation.isValidFixedPhone(phone, country)
          && !Validation.isValidMobilePhone(phone, country)) {
        fixed = phone;
        mobile = '';
      }

      return {mobile: mobile, fixed: fixed};
    }
</script>

<template>
  <div class="input input-phone-with-country with-flag"
       v-if="form.isReady()"
       :id="formField.name"
       :class="formField.classes()"
       :data-store="dataStoreDisabled ? '' : formField.name"
       :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)">
    <div class="label" v-if="label">
      <label :for="fieldId()">
        {{ label }}<span v-if="props.required" class="asterisk">&#42;</span>
      </label>
      <slot name="app-tooltipster"></slot>
    </div>
    <div class="wrapper">
      <app-input-country
          class="phone-code-selector number"
          :popup-label="popupLabel()"
          :form-field="form.field('country')"
          :disabled="disabled"
          :data-store-disabled="true"
          :show-value="'phone-code'"
          :show-custom-popup="countryShowCustomPopup"
          :mobile-mode-enabled="mobileModeEnabled"
          @close="onCountryClose"
          @change="onCountryChange"
      ></app-input-country>
      <input
          type="text"
          v-model="form.field('phone').value"
          :disabled="disabled"
          :id="fieldId()"
          :autocomplete="'tel-national'"
          :placeholder="placeholder"
          @keyup="onPhoneChange"
          @change="onPhoneChange"
          @blur="onPhoneChange">
      <div v-if="infoMessageIsVisible()" class="feedback" v-html="infoMessage()"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input-phone-with-country {
  scroll-margin-top: 4em;

  .asterisk {
    font-size: var(--font-size-medium);
    color: var(--brand-red);
  }

  :deep(.input) {
    width: auto !important;
    margin-top: 0 !important;
    left: 0;
    top: 0;

    &::before {
      top: 0;
      bottom: 0;
    }
  }

  input::placeholder {
     font-size: var(--font-size-nano);

      @include respond-above('sm') {
          font-size: var(--font-size-tiny);
      }
  }

  > .wrapper {
    > .phone-code-selector {
      z-index: inherit;

      :deep(.button) {
        border: none;
        background: none;
        padding: 0 0 0 var(--size-small);
        margin: 0;

        .arrow-icon {
          display: none;
        }

        .flag {
          img {
            vertical-align: initial;
          }
        }
      }
    }
  }
}

.feedback {
  position: absolute;
  top: 105%;
  width: 100%;
  color: var(--brand-red);
  font-size: var(--font-size-pico);
}
</style>
