<script setup lang="ts">
import {onMounted, PropType, watch} from 'vue';
import FormField from '@/assets/libraries/form/form-field';

const props = defineProps({
    labelForTrue: {type: String, default: ''},
    labelForFalse: {type: String, default: ''},
    formField: {type: Object as PropType<FormField<boolean>>, default: () => new FormField('')},
    disabled: {type: Boolean, default: false},
    dataStoreDisabled: {type: Boolean, default: false},
});

const emit = defineEmits(['change']);

watch(() => props.formField.value, (value: boolean, valueBefore: boolean) => {
    if (value !== valueBefore) {
        emit('change', value);
    }
});

onMounted(() => {
    if (props.formField.isEmpty()) {
        props.formField.setValue(false);
    }
});

function toggle(): void {
    if (!props.disabled) {
        props.formField.setValue(!props.formField.value);
    }
}

function switchOn(): void {
    if (!props.disabled) {
        props.formField.setValue(true);
    }
}

function switchOff(): void {
    if (!props.disabled) {
        props.formField.setValue(false);
    }
}
</script>

<template>
<span class="input input-switch"
      :id="formField.name"
      :class="{...formField.classes(), 'disabled': disabled}"
      :data-store="dataStoreDisabled ? '' : formField.name"
      :data-store-value="dataStoreDisabled ? '' : `${formField.value}`">
    <label class="left-side-label"
           :class="{'selected': !formField.value}"
           :id="formField.name + '-switchOff'"
           @click="switchOff()">
        <template>{{ labelForFalse }}</template>
    </label>
    <div class="switch-container">
        <div class="switch-control"
             :class="{'switched-on': formField.value}"
             :id="formField.name + '-toggle'"
             @click="toggle()">
            <div class="control"></div>
        </div>
    </div>
    <label class="right-side-label"
           :class="{'selected': formField.value}"
           :id="formField.name + '-switchOn'"
           @click="switchOn()">
         <template>{{ labelForTrue }}</template>
    </label>
</span>
</template>

<style lang="scss" scoped>
.input-switch {
    $control-size: 16px;
    $border-width: 4px;
    $control-container-width: 44px;
    $control-container-height: calc(#{$control-size} + (#{$border-width} * 2));
    $background-color: var(--brand-teal);
    $control-color: var(--white);
    $switch-transition: all .3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;

    &.off {
        .switch-control {
            background-color: var(--black-200) !important;
            border-color: transparent !important;
        }
    }

    .left-side-label {
        margin-right: var(--size-small);
    }

    .right-side-label {
        margin-left: var(--size-small);
    }

    .left-side-label,
    .right-side-label {
        color: var(--black-500);
        font-size: var(--font-size-tiny);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        max-width: 90px;

        @include respond-above('sm') {
            width: auto;
            max-width: calc((100% - #{$control-container-width}) / 2);
        }

        &.selected {
            color: var(--text-color-default);
        }

        &:empty {
            display: none;
        }
    }

    .switch-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .switch-control {
            width: $control-container-width;
            height: $control-container-height;
            border: $border-width solid $background-color;
            background-color: $background-color;
            border-radius: $control-container-height;
            transition: $switch-transition;
            cursor: pointer;

            .control {
                height: $control-size;
                width: $control-size;
                border-radius: 50%;
                background: $control-color;
                transition: $switch-transition;
            }

            &.switched-on {
                .control {
                    transform: translateX(calc(#{$control-container-width} - (#{$control-size} + (#{$border-width} * 2))));
                }
            }
        }
    }
}
</style>
