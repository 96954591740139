<script lang="ts">
import {computed, defineComponent, ref, Ref, nextTick} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import {useTranslate} from '@/Composables/Translate';
import {useDefine} from '@/Composables/Define';
import {AxiosResponse} from 'axios';
import {InsuredObjectRisk} from '@/interfaces/one_policy/insured.object.risk.interface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import ContentLoaderIconColor from '@/Enums/ContentLoaderIconColorEnum';
import {Subscription} from 'rxjs';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import FormField from '@/assets/libraries/form/form-field';
import Value from '@/assets/libraries/form/value';
import AppCountry from '@/assets/libraries/app/app-country';
import {useStrings} from '@/Composables/Strings';
import {InputOption} from '@/interfaces/InputOptionInterface';
import OptionsSmartCustom from '@/Components/OptionsSmartList/Interfaces/OptionsSmartCustomInterface';
import OptionsSmartTypes from '@/Components/OptionsSmartList/Enums/OptionsSmartTypesEnum';
import OptionSmartColor from '@/Components/OptionsSmartList/Enums/OptionSmartColorEnum';
import InputDateWithCalendarBinds
    from '@/Components/OptionsSmartList/Components/interfaces/InputDateWithCalendarBindsInterface';
import Tooltipster from '@/interfaces/tooltipster.interface';
import OptionsSmartCustomSelectState
    from '@/Components/OptionsSmartList/Interfaces/OptionsSmartCustomSelectStateInterface';
import AccidentTypeEnum from '@/Enums/ClaimsAccidentAccidentTypeEnum';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import moment from 'moment';
import Url from '@/Enums/UrlEnum';
import SelectorItem from '@/Components/PopupSelector/SelectorItem';
import OneDate from '@/assets/libraries/Date/OneDate';
import {useAxios} from '@/Composables/Axios';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const stepsSubmitter = useStepsSubmitter();
        const {translate, translateForType} = useTranslate();
        const {isSet} = useDefine();

        const CurrentStep: number = 3;
        const Facility: string = 'one-claims-accident';
        const TranslationType: string = 'claims_accident';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);

        const FormFields: string[] = [
            'describe',
            'disability-group',
            'start-date',
            'autopsy',
            'policy-called',
            'injury-type',
            'expenses',
        ];

        const accidentType: Ref<
            'injuries'
            | 'disability'
            | 'liability'
            | 'critical_illness'
            | 'diseases'
            | 'death'
            | 'other'
            | 'submit'> = ref('injuries');
        const currentSection: Ref<string> = ref('');
        const riskIc: Ref<string> = ref('');
        const risksOfSelectedPolicy:  Ref<InsuredObjectRisk[]> = ref([]);
        const policyValidFrom: Ref<string> = ref('');
        const policyValidTo: Ref<string> = ref('');
        const accidentDescriptions: Ref<string> = ref('');

        const accidentsTypes: Ref<InjuryType[]> = ref([]);
        const loading: Ref<boolean> = ref(true);
        const AdditionalInformation: Ref<{
            injuries: DynamicDictionary;
            submit: DynamicDictionary;
            disability: DynamicDictionary;
            liability: DynamicDictionary;
            critical_illness: DynamicDictionary;
            diseases: DynamicDictionary;
            death: DynamicDictionary;
            other: DynamicDictionary;
        }> = ref({
            injuries: {
                name: 'injuries',
                type: {
                    name: 'injury-type',
                    show: false,
                    button: false,
                },
                describe: {
                    name: 'describe',
                    show: false,
                    buttton: false,
                },
                expenses: {
                    name: 'expenses',
                    show: false,
                    buttton: false,
                },
            },
            submit: {
                name: 'submit',
            },
            disability: {
                name: 'disability',
                describe: {
                    name: 'describe',
                    show: false,
                    buttton: false,
                },
                disabilityGroup: {
                    name: 'disability-group',
                    show: false,
                    buttton: false,
                }
            },
            liability: {
                name: 'liability',
                describe: {
                    name: 'describe',
                    show: false,
                    buttton: false,
                }
            },
            critical_illness: {
                name: 'critical_illness',
                describe: {
                    name: 'describe',
                    show: false,
                    buttton: false,
                },
                startDate: {
                    name: 'start-date',
                    show: false,
                    buttton: false,
                },
            },
            diseases: {
                name: 'diseases',
                describe: {
                    name: 'describe',
                    show: false,
                    buttton: false,
                },
                startDate: {
                    name: 'start-date',
                    show: false,
                    buttton: false,
                },
            },
            death: {
                name: 'death',
                describe: {
                    name: 'describe',
                    show: false,
                    buttton: false,
                },
                autopsy: {
                    name: 'autopsy',
                    show: false,
                    buttton: false,
                },
                policy: {
                    name: 'policy-called',
                    show: false,
                    buttton: false,
                },
            },
            other: {
                name: 'other',
                describe: {
                    name: 'describe',
                    show: false,
                    buttton: false,
                }
            },
        });
        const ContentLoaderColor: string = ContentLoaderIconColor.Blue;

        const injuriesTypes: Ref<InputOption[]> = computed(() => {
            const result: InputOption[] = [];
            const types: string[] = injuriesByType(AdditionalInformation.value.injuries.name);
            types.forEach((injury: string): void => {
                let customField: OptionsSmartCustom;
                if (isTextFieldForInjury(injury)) {
                    customField = new class implements OptionsSmartCustom {
                        public type: OptionsSmartTypes = OptionsSmartTypes.Text;
                        public placeholder: string = translateForType('placeholder_injury_type_' + injury, TranslationType);
                        public required: boolean = true;
                        public selectState: OptionsSmartCustomSelectState
                            = new class implements OptionsSmartCustomSelectState {
                            public borderColor?: OptionSmartColor = OptionSmartColor.Green;
                        };
                    };
                } else {
                    customField = new class implements OptionsSmartCustom {
                        public type: OptionsSmartTypes = OptionsSmartTypes.Regular;
                        public selectState: OptionsSmartCustomSelectState = new class implements OptionsSmartCustomSelectState {
                            public borderColor?: OptionSmartColor = OptionSmartColor.Green;
                        };
                    };
                }
                result.push(new class implements InputOption {
                    public name: string = translateForType('injury_type_' + injury, TranslationType);
                    public value: string = injury;
                    public custom: OptionsSmartCustom = customField;
                    public tooltip: Tooltipster = new class implements Tooltipster {
                        public description: string = translateForType('tooltip_description_' + injury, TranslationType);
                        public title: string = translateForType('tooltip_title_' + injury, TranslationType);
                    };
                });
            });

            return result;
        })

        const expensesTypes: Ref<InputOption[]> = computed(() => {
            const result: InputOption[] = [];
            availableExpenses().forEach((injury: InjuryType): void => {
                const fieldName: string = injury.event_description_key;
                const riskIc: string = injury.expenses_risk_ic;
                const expenseValue: Expense = expense(riskIc);
                let customField: OptionsSmartCustom;
                if (isTextFieldForExpense(injury.injury_type.toLowerCase(), riskIc)) {
                    let initialValue: string = '';
                    if (isSet(form.field(AdditionalInformation.value.injuries.expenses.name).value.extra)) {
                        initialValue = form.field(AdditionalInformation.value.injuries.expenses.name)
                            .value.extra[riskIc];
                    }
                    customField = new class implements OptionsSmartCustom {
                        public type: OptionsSmartTypes = OptionsSmartTypes.Sum;
                        public limitType: string = expenseValue.type;
                        public limit: number = expenseValue.limit;
                        public placeholder: string = translateForType('placeholder_expenses_' + riskIc, TranslationType);
                        public initialValue: string = initialValue;
                        public checkboxColor: string = OptionSmartColor.Green;
                    };
                } else if (isDateFieldForExpense(
                    injury.injury_type.toLowerCase(), riskIc)) {
                    const calendarFormat: string = new AppCountry().isLT() ? 'YYYY.MM.DD' : 'DD.MM.YYYY';
                    const calendarMinDateValue: Date = calendarMinDate.value;
                    const calendarMaxDateValue: Date = calendarMaxDate.value;
                    customField = new class implements OptionsSmartCustom {
                        public type: OptionsSmartTypes = OptionsSmartTypes.Calendar;
                        public placeholder: string = 'placeholder_' + riskIc;
                        public required: boolean = true;
                        public binds: InputDateWithCalendarBinds = {
                            isRange: true,
                            placeholder: translateForType('select_select_event_date', TranslationType),
                            numberOfCalendars: 1,
                            numberOfDays: 364,
                            format: calendarFormat,
                            minDate: calendarMinDate.value,
                            maxDate: calendarMaxDate.value,
                            startDate: calendarMaxDate.value,
                            returnRaw: false,
                            forcedRange: true
                        }
                        public checkboxColor: string = OptionSmartColor.Green;
                    }
                }

                result.push(new class implements InputOption {
                    public name: string = translateForType(injury.expenses, TranslationType);
                    public value: string = riskIc;
                    public custom: OptionsSmartCustom = customField;
                    public required: boolean = true;
                    public tooltip: Tooltipster = new class implements Tooltipster {
                        public description: string = translateForType('tooltip_description_expenses_' + riskIc,
                            TranslationType);
                        public title: string = translateForType('tooltip_title_expenses_' + riskIc, TranslationType);
                    };
                });
            });

            return result;
        });

        const isExpensesFieldsAvailable: Ref<boolean> = computed(() => {
            return availableExpenses().length > 0;
        });

        const isDisabilityFieldsAvailable: Ref<boolean> = computed(() => {
            return disabilityGroupOptions.value.length > 0;
        });

        const disabilityGroupOptions: Ref<InputOption[]> = computed(() => {
            const disabilityGroups: InjuryType[] = accidentsTypes.value.filter((injury: InjuryType): boolean => {
                return injury.accident_type === AccidentTypeEnum.Disability &&
                    injury.subrisk !== '';
            });

            return disabilityGroups.map((group: InjuryType): InputOption => {
                return (new InputOptionBuilder)
                    .setName(translateForType(group.subrisk, TranslationType))
                    .setValue(group.subrisk_ic)
                    .build();
            });
        });

        const autopsyOptions: Ref<InputOption[]> = computed(() => {
            return [
                (new InputOptionBuilder)
                    .setName(translate('btar_yes'))
                    .setValue(true)
                    .build(),
                (new InputOptionBuilder)
                    .setName(translate('btar_no'))
                    .setValue(false)
                    .build(),
            ];
        });

        const policyCalledOptions: Ref<InputOption[]> = computed(() => {
            return [
                (new InputOptionBuilder)
                    .setName(translate('btar_yes'))
                    .setValue(true)
                    .build(),
                (new InputOptionBuilder)
                    .setName(translate('btar_no'))
                    .setValue(false)
                    .build(),
            ];
        });

        const showDescribeField: Ref<boolean> = computed(() => {
            return AdditionalInformation.value.injuries.describe.show ||
                AdditionalInformation.value.disability.describe.show ||
                AdditionalInformation.value.liability.describe.show ||
                AdditionalInformation.value.critical_illness.describe.show ||
                AdditionalInformation.value.diseases.describe.show ||
                AdditionalInformation.value.death.describe.show ||
                AdditionalInformation.value.other.describe.show;
        });

        const showDescribeButton: Ref<boolean> = computed(() => {
            const accidentTypes: string[] = [
                AdditionalInformation.value.injuries.name,
                AdditionalInformation.value.disability.name,
                AdditionalInformation.value.liability.name,
                AdditionalInformation.value.critical_illness.name,
                AdditionalInformation.value.diseases.name,
                AdditionalInformation.value.death.name,
                AdditionalInformation.value.other.name];

            return (accidentTypes.includes(accidentType.value) &&
                    AdditionalInformation.value[accidentType.value].describe.button) ||
                !isExpensesFieldsAvailable.value;
        });

        const descriptionDescribeText: Ref<string> = computed(() => {
            return translateForType(accidentDescriptions.value, TranslationType);
        });

        const calendarMinDate: Ref<Date> = computed(() => {
            return moment(policyValidFrom.value).toDate();
        });

        const calendarMaxDate: Ref<Date> = computed(() => {
            return moment().toDate() > moment(policyValidTo.value).toDate() ?
                moment(policyValidTo.value).toDate() :
                moment().toDate();
        });

        function canProceed(section: string = currentSection.value): boolean {
            let result: boolean = false;
            const isTextField: boolean = isTextFieldForInjury(selectedInjury());
            const notMandratoryTextField: boolean = notMandatoryTextFieldForInjury(selectedInjury());
            switch (accidentType.value) {
                case AdditionalInformation.value.injuries.name:
                    switch (section) {
                        case AdditionalInformation.value.injuries.type.name:
                            if (isTextField && notMandratoryTextField) {
                                result = true;
                            } else {
                                result = form.field(section).value.selected !== '' &&
                                    (!isTextField || (isTextField && injuryCustomValue(section) !== ''));
                            }
                            break;
                        case AdditionalInformation.value.injuries.describe.name:
                            result = !describeEventProceedIsDisabled(form.field(section).value) &&
                                canProceed(AdditionalInformation.value.injuries.type.name);
                            break;
                        case AdditionalInformation.value.injuries.expenses.name:
                            if (isExpensesFieldsAvailable.value) {
                                const localResults: boolean[] = [];
                                result = true;
                                availableExpenses().forEach((injury: InjuryType): void => {
                                    if (selectedExpenses().includes(injury.expenses_risk_ic)) {
                                        const field: FormField = form.field(
                                            AdditionalInformation.value.injuries.expenses.name);
                                        const fieldExtra: string | DynamicDictionary = field.value
                                            .extra[injury.expenses_risk_ic] || '';
                                        const expenseValue: Expense = expense(injury.expenses_risk_ic);
                                        switch (injury.expenses_subfield_type) {
                                            case 'Text':
                                                localResults.push(field.value.selected !== '' &&
                                                    Number(fieldExtra) > 0 &&
                                                    Number(fieldExtra) <= expenseValue.limit);
                                                break;
                                            case 'Date':
                                                localResults.push(field.value.selected !== '' &&
                                                    new Value(fieldExtra).isNotEmpty());
                                                break;
                                            default:
                                                break;
                                        }
                                        result = !localResults.includes(false);
                                    }
                                });
                            }
                            break;
                        default:
                    }
                    break;
                case AdditionalInformation.value.disability.name:
                    switch (section) {
                        case AdditionalInformation.value.disability.describe.name:
                            result = !describeEventProceedIsDisabled(form.field(section).value);
                            break;
                        case AdditionalInformation.value.disability.disabilityGroup.name:
                            result = form.field(section).value.selected !== '' &&
                                canProceed(AdditionalInformation.value.disability.describe.name);
                            break;
                        default:
                    }
                    break;
                case AdditionalInformation.value.liability.name:
                    result = !describeEventProceedIsDisabled(form.field(section).value);
                    break;
                case AdditionalInformation.value.critical_illness.name:
                    switch (section) {
                        case AdditionalInformation.value.critical_illness.describe.name:
                            result = !describeEventProceedIsDisabled(form.field(section).value);
                            break;
                        case AdditionalInformation.value.critical_illness.startDate.name:
                            result = isSet(form.field(section).value.startDate) &&
                                canProceed(AdditionalInformation.value.critical_illness.describe.name);
                            break;
                        default:
                    }
                    break;
                case AdditionalInformation.value.diseases.name:
                    switch (section) {
                        case AdditionalInformation.value.diseases.describe.name:
                            result = !describeEventProceedIsDisabled(form.field(section).value);
                            break;
                        case AdditionalInformation.value.diseases.startDate.name:
                            result = isSet(form.field(section).value.startDate) &&
                                canProceed(AdditionalInformation.value.diseases.describe.name);
                            break;
                        default:
                    }
                    break;
                case AdditionalInformation.value.death.name:
                    switch (section) {
                        case AdditionalInformation.value.death.describe.name:
                            result = !describeEventProceedIsDisabled(form.field(section).value);
                            break;
                        case AdditionalInformation.value.death.autopsy.name:
                            result = form.field(section).value.selected !== '' &&
                                canProceed(AdditionalInformation.value.death.describe.name);
                            if (!new AppCountry().isEE()) {
                                result = result && isSet(form.field(section).value.selected)
                            }
                            break;
                        case AdditionalInformation.value.death.policy.name:
                            result = form.field(section).value.selected !== '' &&
                                canProceed(AdditionalInformation.value.death.autopsy.name) &&
                                isSet(form.field(section).value.selected);
                            break;
                        default:
                    }
                    break;
                case AdditionalInformation.value.other.name:
                    result = !describeEventProceedIsDisabled(form.field(section).value);
                    break;
                default:
            }

            return result;
        }

        function describeEventProceedIsDisabled(descriptionText: string): boolean {
            const multibyteLength: number = useStrings().multibyteLength(descriptionText);
            const minLength: number = btaBase.settings.claimsSettings().accident.descriptionFieldMinLength;
            const maxLength: number = btaBase.settings.claimsSettings().accident.descriptionFieldMaxLength;
            const valueLengthOutOfBounds: boolean = multibyteLength < minLength || multibyteLength > maxLength;
            const valueHasInvalidText: boolean = !useStrings().isValidWordString(descriptionText);

            return [valueLengthOutOfBounds, valueHasInvalidText].some((value: boolean): boolean => value);
        }

        function proceed(allowSubmit: boolean = true): void {
            let submit: boolean = false;
            if (!form.field('describe').isEmpty()) {
                form.field('describe').touch();
            }
            switch (accidentType.value) {
                case AdditionalInformation.value.injuries.name:
                    switch (currentSection.value) {
                        case AdditionalInformation.value.injuries.type.name:
                            currentSection.value = AdditionalInformation.value.injuries.describe.name;
                            AdditionalInformation.value.injuries.describe.show = true;
                            AdditionalInformation.value.injuries.describe.button = true;
                            AdditionalInformation.value.injuries.type.button = false;
                            break;
                        case AdditionalInformation.value.injuries.describe.name:
                            if (isExpensesFieldsAvailable.value) {
                                currentSection.value = AdditionalInformation.value.injuries.expenses.name;
                                AdditionalInformation.value.injuries.expenses.show = true;
                                AdditionalInformation.value.injuries.expenses.button = true;
                                AdditionalInformation.value.injuries.describe.button = false;
                            } else {
                                submit = true;
                            }
                            break;
                        case AdditionalInformation.value.injuries.expenses.name:
                            submit = true;
                            break;
                        default:
                    }
                    break;
                case AdditionalInformation.value.disability.name:
                    switch (currentSection.value) {
                        case AdditionalInformation.value.disability.describe.name:
                            if (isDisabilityFieldsAvailable.value) {
                                currentSection.value = AdditionalInformation.value.disability.disabilityGroup.name;
                                AdditionalInformation.value.disability.disabilityGroup.show = true;
                                AdditionalInformation.value.disability.describe.button = false;
                            } else {
                                submit = true;
                            }
                            break;
                        case AdditionalInformation.value.disability.disabilityGroup.name:
                            submit = true;
                            break;
                        default:
                    }
                    break;
                case AdditionalInformation.value.liability.name:
                    submit = true;
                    break;
                case AdditionalInformation.value.critical_illness.name:
                    switch (currentSection.value) {
                        case AdditionalInformation.value.critical_illness.describe.name:
                            currentSection.value = AdditionalInformation.value.critical_illness.startDate.name;
                            AdditionalInformation.value.critical_illness.startDate.show = true;
                            AdditionalInformation.value.critical_illness.describe.button = false;
                            break;
                        case AdditionalInformation.value.critical_illness.startDate.name:
                            submit = true;
                            break;
                        default:
                    }
                    break;
                case AdditionalInformation.value.diseases.name:
                    switch (currentSection.value) {
                        case AdditionalInformation.value.diseases.describe.name:
                            currentSection.value = AdditionalInformation.value.diseases.startDate.name;
                            AdditionalInformation.value.diseases.startDate.show = true;
                            AdditionalInformation.value.diseases.describe.button = false;
                            break;
                        case AdditionalInformation.value.diseases.startDate.name:
                            submit = true;
                            break;
                        default:
                    }
                    break;
                case AdditionalInformation.value.death.name:
                    switch (currentSection.value) {
                        case AdditionalInformation.value.death.describe.name:
                            switch (new AppCountry().iso()) {
                                case 'LT': {
                                    currentSection.value = AdditionalInformation.value.death.describe.name;
                                    AdditionalInformation.value.death.describe.show = true;
                                    AdditionalInformation.value.death.describe.button = true;
                                    submit = true;
                                    break;
                                }
                                case 'LV': {
                                    currentSection.value = AdditionalInformation.value.death.autopsy.name;
                                    AdditionalInformation.value.death.autopsy.show = true;
                                    AdditionalInformation.value.death.describe.button = false;
                                    AdditionalInformation.value.death.autopsy.button = true;
                                    break;
                                }
                                case 'EE': {
                                    currentSection.value = AdditionalInformation.value.death.policy.name;
                                    AdditionalInformation.value.death.policy.show = true;
                                    AdditionalInformation.value.death.policy.button = true;
                                    AdditionalInformation.value.death.autopsy.button = false;
                                    break;
                                }
                                default:
                            }
                            break;
                        case AdditionalInformation.value.death.autopsy.name:
                            currentSection.value = AdditionalInformation.value.death.policy.name;
                            AdditionalInformation.value.death.policy.show = true;
                            AdditionalInformation.value.death.policy.button = true;
                            AdditionalInformation.value.death.autopsy.button = false;
                            break;
                        case AdditionalInformation.value.death.policy.name:
                            submit = true;
                            break;
                        default:
                    }
                    break;
                case AdditionalInformation.value.other.name:
                    submit = true;
                    break;
                default:
            }
            if (submit && allowSubmit) {
                onSubmitStep();
            } else {
                nextTick(() => scrollToPart(currentSection.value));
            }
        }

        function setupForm(): void {
            FormFields.forEach((field: string): void => {
                form.addField(new FormField(field));
            });
            form.field('describe').addValidators({
                describeEventValid: (value: string) => {
                    return !describeEventProceedIsDisabled(value);
                }
            });
            form.setReady();
        }

        function showPanelsOnAppStart(): void {
            Object.keys(AdditionalInformation.value[accidentType.value])
                .filter((sectionKey: string): boolean => sectionKey !== 'name')
                .forEach((): void => {
                    if (canProceed(currentSection.value)) {
                        proceed(false);
                    }
                });
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', stepsSubmitter.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('caseDescription', form.field('describe').value as SelectorItem[])
            stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
            switch (accidentType.value) {
                case AdditionalInformation.value.disability.name:
                    stepsSubmitter.addSubmitCustomParam('disabilityGroup', disabilityGroup());
                    break;
                case AdditionalInformation.value.critical_illness.name:
                    stepsSubmitter.addSubmitCustomParam('criticalIllness', criticalIllness());
                    break;
                case AdditionalInformation.value.diseases.name:
                    stepsSubmitter.addSubmitCustomParam('disease', diseases());
                    break;
                case AdditionalInformation.value.death.name:
                    stepsSubmitter.addSubmitCustomParam('autopsyCarried', autopsyCarried());
                    stepsSubmitter.addSubmitCustomParam('reportedToPolice', reportedToPolice());
                    break;
                case AdditionalInformation.value.injuries.name:
                    stepsSubmitter.addSubmitCustomParam('injuryType', form.field('injury-type').value);
                    stepsSubmitter.addSubmitCustomParam('expenses', form.field('expenses').value);
                    break;
                default:
                    break;
            }
        }

        function scrollToPart(part: string): void {
            scrollToElement(
                document.querySelector(`[data-scroll="${part}"]`) as HTMLElement
            );
        }

        function scrollToElement(element: HTMLElement): void {
            if (element !== null) {
                window.scroll({
                    behavior: 'smooth',
                    top: element.offsetTop + parseInt(window.getComputedStyle(
                        document.querySelector('.module.steps') as Element
                    ).marginBottom, 10) + parseInt(window.getComputedStyle(
                        document.querySelector('.additional-information .wrapper') as Element
                    ).marginTop, 10)
                });
            }
        }

        function autopsyCarried(): DynamicDictionary {
            return {
                name: 'caption_autopsy',
                value: form.field('autopsy').value.selected === 'true' ? 'btar_yes' : 'btar_no'
            };
        }

        function reportedToPolice(): DynamicDictionary {
            return {
                name: 'caption_policy_called',
                value: form.field('policy-called').value.selected === 'true' ? 'btar_yes' : 'btar_no'
            };
        }

        function criticalIllness(): DynamicDictionary {
            const calendarFormat: string = new AppCountry().isLT() ? 'YYYY.MM.DD' : 'DD.MM.YYYY';

            return {
                name: 'caption_illness_start_date',
                value: OneDate.custom(new Date(form.field('start-date').value.startDate), calendarFormat)
            };
        }

        function diseases(): DynamicDictionary {
            const calendarFormat: string = new AppCountry().isLT() ? 'YYYY.MM.DD' : 'DD.MM.YYYY';

            return {
                name: 'caption_diseases_start_date',
                value: OneDate.custom(new Date(form.field('start-date').value.startDate), calendarFormat)
            };
        }

        function disabilityGroup(): DynamicDictionary {
            const selectedSubrisk: string = form.field('disability-group').value.selected;
            const foundType: InjuryType | undefined = accidentsTypes.value.find((type: InjuryType) =>
                type.accident_type === AccidentTypeEnum.Disability && type.subrisk_ic === selectedSubrisk
            );

            return {
                name: 'disability_group',
                subriskName: foundType?.subrisk ?? '',
                value: selectedSubrisk
            };
        }

        function onSubmitStep(): void {
            resetForm();
            form.validate().then(() => {
                if (form.isValid()) {
                    prepareSubmit();
                    stepsSubmitter.proceedStep('', btaBase.nextStep());
                }
            });
        }

        function injuriesTypeChange(after: { selected: string }, before: { selected: string }): void {
            if (before.selected !== '' && before.selected !== after.selected) {
                if (riskIc.value) {
                    fetchAccidentDescriptions().then();
                }
                form.field('describe').clear();
                form.field('expenses').clear();
                currentSection.value = AdditionalInformation.value.injuries.type.name;
                AdditionalInformation.value.injuries.type.show = true;
                AdditionalInformation.value.injuries.type.button = true;
                AdditionalInformation.value.injuries.describe.show = false;
                AdditionalInformation.value.injuries.expenses.show = false;
            }
        }

        async function fetchAccidentTypes(): Promise<void> {
            return useAxios().get(Url.Ajax.accidentType)
                .then((response: AxiosResponse): void => {
                    accidentsTypes.value = response.data.data.body.accidentTypes;
                })
                .catch((reason: string | DynamicDictionary): void => {
                    btaBase.error.show(
                        ErrorType.Error,
                        'Accident claims additional information',
                        reason
                    );
                })
                .finally((): void => {
                    loading.value = false;
                });
        }

        async function fetchAccidentDescriptions(): Promise<void> {
            const params: Record<string, number | string> = {
                riskIc: riskIc.value,
                injuryType: accidentType.value === AdditionalInformation.value.injuries.name ?
                    selectedInjury() :
                    '',
            };
            return useAxios().get(Url.AccidentClaims.accidentDescriptions, {params})
                .then((response: AxiosResponse) => {
                    accidentDescriptions.value = response.data.data.body.accidentDescriptions as string;
                })
                .catch((reason: string | DynamicDictionary) => {
                    btaBase.error.show(
                        ErrorType.Error,
                        'Accident claims accident descriptions fetch',
                        reason
                    );
                });
        }

        async function setupStepData(): Promise<void> {
            const decodedJson: DynamicDictionary = btaBase.userStorage.stepStorageData;
            riskIc.value = decodedJson.riskIc;
            risksOfSelectedPolicy.value = decodedJson.risksOfSelectedPolicy;
            accidentType.value = decodedJson.type.toLowerCase();
            const fetchAccidentDescription: Promise<void> =
                accidentType.value !== AdditionalInformation.value.injuries.name ?
                    fetchAccidentDescriptions() :
                    new Promise(resolve => resolve());
            policyValidFrom.value = decodedJson.policyObject.custom.validFrom;
            policyValidTo.value = decodedJson.policyObject.custom.validTo;
            return Promise.all([
                fetchAccidentDescription,
                fetchAccidentTypes().then(),
                fetchAccidentDescriptions(),
            ]).then(() => {
                setupAdditionalInformation();
            });
        }

        function setupAdditionalInformation(): void {
            switch (accidentType.value) {
                case AdditionalInformation.value.injuries.name:
                    currentSection.value = AdditionalInformation.value.injuries.type.name;
                    AdditionalInformation.value.injuries.type.show = true;
                    AdditionalInformation.value.injuries.type.button = true;
                    break;
                case AdditionalInformation.value.disability.name:
                    currentSection.value = AdditionalInformation.value.disability.describe.name;
                    AdditionalInformation.value.disability.describe.show = true;
                    AdditionalInformation.value.disability.describe.button = true;
                    break;
                case AdditionalInformation.value.liability.name:
                    currentSection.value = AdditionalInformation.value.liability.describe.name;
                    AdditionalInformation.value.liability.describe.show = true;
                    AdditionalInformation.value.liability.describe.button = true;
                    break;
                case AdditionalInformation.value.critical_illness.name:
                    currentSection.value = AdditionalInformation.value.critical_illness.describe.name;
                    AdditionalInformation.value.critical_illness.describe.show = true;
                    AdditionalInformation.value.critical_illness.describe.button = true;
                    break;
                case AdditionalInformation.value.diseases.name:
                    currentSection.value = AdditionalInformation.value.diseases.describe.name;
                    AdditionalInformation.value.diseases.describe.show = true;
                    AdditionalInformation.value.diseases.describe.button = true;
                    break;
                case AdditionalInformation.value.death.name:
                    currentSection.value = AdditionalInformation.value.death.describe.name;
                    AdditionalInformation.value.death.describe.show = true;
                    AdditionalInformation.value.death.describe.button = true;
                    break;
                case AdditionalInformation.value.other.name:
                    currentSection.value = AdditionalInformation.value.other.describe.name;
                    AdditionalInformation.value.other.describe.show = true;
                    AdditionalInformation.value.other.describe.button = true;
                    break;
                default:
                    break;
            }
        }

        function policyRisks(): string[] {
            return [...new Set(risksOfSelectedPolicy.value.map((item: InsuredObjectRisk) => item.agrrisk))];
        }

        function availableExpenses(): InjuryType[] {
            return selectedInjuries().filter((injury: InjuryType) => {
                return policyRisks().includes(injury.expenses_risk_ic) &&
                    injury.expenses_risk_ic !== '' &&
                    injury.expenses_risk_ic !== injury.accident_type_risk_ic &&
                    injury.accident_type_risk_ic === riskIc.value;
            });
        }

        function accidentsByType(type: string): InjuryType[] {
            return accidentsTypes.value.filter((injury: InjuryType): boolean => {
                return injury.accident_type === type.toUpperCase() &&
                    policyRisks().includes(injury.accident_type_risk_ic)
            });
        }

        function injuriesByType(type: string): string[] {
            return [...new Set(accidentsByType(type).map(
                (item: InjuryType) => item.injury_type.toLowerCase()
            ))];
        }

        function injuryByType(type: string): InjuryType[] {
            const accidentType: string = AdditionalInformation.value.injuries.name;

            return accidentsByType(accidentType)
                .filter((injury: InjuryType) => {
                    return injury.injury_type.toLowerCase() === type;
                });
        }

        function selectedInjuries(): InjuryType[] {
            const accidentType: string = AdditionalInformation.value.injuries.type.name;
            const injuryType: string = form.field(accidentType).value.selected;

            return injuryByType(injuryType);
        }

        function selectedInjury(): string {
            const injuryType: string = AdditionalInformation.value.injuries.type.name;

            return form.field(injuryType).value.selected;
        }

        function selectedExpenses(): string[] {
            const fieldValue: string = form.field(
                AdditionalInformation.value.injuries.expenses.name
            ).value.selected || '';

            return fieldValue.split('@#@');
        }

        function expense(riskIc: string): Expense {
            let limit: number = 0;
            let type: string = '';
            risksOfSelectedPolicy.value.find((risk: InsuredObjectRisk): void => {
                if (risk.agrrisk === riskIc) {
                    if (isSet(risk.caselimit) && Number(risk.caselimit!) !== 0) {
                        type = 'caselimit';
                        limit = Number(risk.caselimit);
                    } else {
                        type = 'limit';
                        limit = Number(risk.limit);
                    }
                }
            });

            return (new class implements Expense {
                public type: string = type;
                public limit: number = limit;
            });
        }

        function injuryCustomValue(section: string): string {
            return isSet(form.field(section).value.extra) ?
                form.field(section).value.extra[selectedInjury()] || '' :
                '';
        }

        function isTextFieldForInjury(type: string): boolean {
            return injuryByType(type).some((injury: InjuryType): boolean => injury.injury_subfield_type === 'Text' ||
                injury.injury_subfield_type === 'Any');
        }

        function notMandatoryTextFieldForInjury(type: string): boolean {
            return injuryByType(type).some((injury: InjuryType): boolean =>
                injury.injury_subfield_type === 'Any');
        }

        function isTextFieldForExpense(type: string, risk: string): boolean {
            return injuryByType(type).some((injury: InjuryType) =>
                injury.expenses_subfield_type === 'Text' &&
                injury.expenses_risk_ic === risk);
        }

        function isDateFieldForExpense(type: string, risk: string): boolean {
            return injuryByType(type).some((injury: InjuryType) =>
                injury.expenses_subfield_type === 'Date' &&
                injury.expenses_risk_ic === risk);
        }

        function resetForm(): void {
            form.fields().forEach((field: FormField): void => {
                field.clearValidators();
            })
        }

        function applyStepUrls(next: string, previous: string): void {
            stepsSubmitter.applyStepUrls(next, previous);
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                accidentType,
                currentSection,
                riskIc,
                risksOfSelectedPolicy,
                policyValidFrom,
                policyValidTo,
                accidentDescriptions,
                accidentsTypes,
                loading,
                AdditionalInformation,
                ContentLoaderColor,
                injuriesTypes,
                expensesTypes,
                isExpensesFieldsAvailable,
                isDisabilityFieldsAvailable,
                disabilityGroupOptions,
                autopsyOptions,
                policyCalledOptions,
                showDescribeField,
                showDescribeButton,
                descriptionDescribeText,
                calendarMinDate,
                calendarMaxDate,
                setupForm,
                applyStepUrls,
                onSubmitStep,
                prepareSubmit,
                canProceed,
                describeEventProceedIsDisabled,
                proceed,
                showPanelsOnAppStart,
                scrollToPart,
                scrollToElement,
                autopsyCarried,
                reportedToPolice,
                criticalIllness,
                diseases,
                disabilityGroup,
                injuriesTypeChange,
                fetchAccidentTypes,
                fetchAccidentDescriptions,
                setupStepData,
                setupAdditionalInformation,
                policyRisks,
                availableExpenses,
                accidentsByType,
                injuriesByType,
                injuryByType,
                selectedInjuries,
                selectedInjury,
                selectedExpenses,
                expense,
                injuryCustomValue,
                isTextFieldForInjury,
                notMandatoryTextFieldForInjury,
                isTextFieldForExpense,
                isDateFieldForExpense,
                resetForm
            }
        }
    },

    mounted() {
        PopupService.getInstance().show(new OnePopup().withType().loading);
        this.applyApp(this);
        this.create();
        this.initBtaBase();
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();
        this.dynamicStepper.enableAll();
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.setupStepData().then((): void => {
                this.showPanelsOnAppStart();
                PopupService.getInstance().hide();
            });
            onAppIsPreparedAndReady.unsubscribe();
        });
    }
});

interface InjuryType {
    accident_type: string;
    accident_type_risk_ic: string;
    injury_type: string;
    injury_subfield_type: string;
    event_type: string;
    event_description_key: string;
    expenses: string;
    expenses_risk_ic: string;
    subrisk: string;
    subrisk_ic: string;
    expenses_subfield_type: string;
    document_description_key: string;
}

interface Expense {
    type: string;
    limit: number;
}
</script>
