<script lang="ts">
import { defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import { useTranslate } from '@/Composables/Translate';
import { useNavigate } from "@/Composables/Navigate";
import { Subscription } from 'rxjs';
import MovablePropertySteps from '@/pages/ItemsAndBelongings/Classes/MovablePropertySteps';
import UserStorage from '@/services/user.storage.service';
import Device from '@/pages/ItemsAndBelongings/Interfaces/DeviceInterface';
import MovablePropertyDeviceOption from '@/Components/OptionsFieldList/Interfaces/MovablePropertyDeviceOption';
import MovablePropertyDeviceOptionBuilder
    from '@/Components/OptionsFieldList/Builders/MovablePropertyDeviceOptionBuilder';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import FormField from '@/assets/libraries/form/form-field';
import { useHtml } from '@/Composables/Html';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import { CoverageRisk } from '@/interfaces/resources/MovableProperties/CoverageRiskInterface';
import { useStrings } from '@/Composables/Strings';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const {translate, translateForType, hasLocalization} = useTranslate();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 4;
        const Facility: string = 'items-and-belongings';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const deviceFields: Ref<Device[]> = ref([]);
        const deviceOptions: Ref<MovablePropertyDeviceOption[]> = ref([]);

        const applyStepUrls = (next: string, previous: string): void => {
            SubmitterUrls.getInstance().applyStepUrls(next, previous);
        }

        function applyDeviceFields(value: Device[]): void {
            deviceFields.value = value;
        }

        function setupForm(): void {
            form.addField(new FormField('device-options'));
            form.setReady();
            formIsReady.value = true;
            stepsSubmitter.addForm(form);
        }

        function buildDeviceOptions(): void {
            deviceOptions.value = [];
            deviceFields.value.forEach((device: Device): void => {
                let optionBuilder: MovablePropertyDeviceOptionBuilder = new MovablePropertyDeviceOptionBuilder()
                    .withDevice(device)
                    .withState(currentOptionState(device.optionIc))
                    .withOptionField()
                    .withFieldHint(optionHint(device.optionIc))
                    .withFieldLabel(optionLabel(device.optionIc))
                    .withFieldPlaceholder(optionPlaceholder(device.optionIc));
                if (addRestoredValue()) {
                    optionBuilder.withInitialValue(currentOptionInitialFieldValue(device.optionIc))
                }
                deviceOptions.value.push(optionBuilder.build());
            });
        }

        function currentOptionInitialFieldValue(deviceIc: string): string {
            return form.field('device-options').value[deviceIc]
                ? form.field('device-options').value[deviceIc].value
                : '';
        }

        function addRestoredValue(): boolean {
            const optionsField: FormField = form.field('device-options');

            return optionsField.isRestored && !optionsField.isTouched;
        }

        function optionLabel(deviceIc: string): string {
            const hintTranslationKey: string = deviceIc + '_LABEL';

            return hasLocalization(hintTranslationKey, 'items_and_belongings')
                ? translateForType(hintTranslationKey, 'items_and_belongings')
                : '';
        }

        function optionPlaceholder(deviceIc: string): string {
            const hintTranslationKey: string = deviceIc + '_PLACEHOLDER';

            return hasLocalization(hintTranslationKey, 'items_and_belongings')
                ? translateForType(hintTranslationKey, 'items_and_belongings')
                : '';
        }

        function optionHint(deviceIc: string): string {
            const hintTranslationKey: string = deviceIc + '_HINT';

            return hasLocalization(hintTranslationKey, 'items_and_belongings')
                ? useHtml().imgTag(cautionIconAttributes())
                + translateForType(hintTranslationKey, 'items_and_belongings')
                : '';
        }

        function cautionIconAttributes(): ImageTagAttributes {
            return {
                class: '',
                src: 'images/one/caution.svg',
                width: 16,
                height: 16,
            }
        }

        function currentSelectedOption(): MovablePropertyDeviceOption | undefined {
            return deviceOptions.value
                .find((option: MovablePropertyDeviceOption): boolean => option.enabled.value)
        }

        function currentOptionState(deviceIc: string): boolean {
            return form.field('device-options').value[deviceIc]
                ? form.field('device-options').value[deviceIc].state
                : false;
        }

        function onAppReady(): void {
            btaBase.dynamicStepper.applyEnabled(MovablePropertySteps.byProductId(selectedProductId()));
            buildDeviceOptions();
        }

        function onBeforeFormRestore(): void {
            setupForm();
        }

        function onBackClick(): void {
            useNavigate().navigate(SubmitterUrls.getInstance().previousStep());
        }

        function onSubmitStep(): void {
            stepsSubmitter.proceedStep('', btaBase.nextStep());
        }

        function onDeviceOptionToggle(optionEmit: DynamicDictionary): void {
            form.field('device-options').patch(optionEmit);
            buildDeviceOptions();
        }

        function onDeviceOptionInput(listEmit: DynamicDictionary): void {
            form.field('device-options').patch(listEmit);
        }

        function onContinueClick(): void {
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            prepareSubmit();
            stepsSubmitter.proceedStep('', 0);
        }

        function selectedProductId(): string {
            return UserStorage.getInstance().stepStorageData.productId ?? '';
        }

        function selectedCoverageId(): number {
            return Number(UserStorage.getInstance().stepStorageData.coverageId) ?? 0;
        }

        function selectedRisks(): CoverageRisk[] {
            return UserStorage.getInstance().stepStorageData.selectedRisks ?? [];
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('selectedRisks', selectedRisks());
            stepsSubmitter.addSubmitCustomParam('productId', selectedProductId());
            stepsSubmitter.addSubmitCustomParam('coverageId', selectedCoverageId());
            stepsSubmitter.addSubmitCustomParam('selectedDevice', currentSelectedOption()!.device);
            stepsSubmitter.addSubmitCustomParam('deviceInfo', deviceInfo());
            stepsSubmitter.addSubmitCustomParam('deviceField', useStrings()
                .transformedToSnakeCase(currentSelectedOption()!.field.name));
        }

        function deviceInfo(): string {
            const deviceIc: string = currentSelectedOption()!.device.optionIc;

            return form.field('device-options').value[deviceIc].value;
        }

        function continueButtonParams(): ButtonWithCallbackParams {
            return {
                title: translate('btar_continue'),
                textColor: ButtonTextColor.White,
                backgroundColor: ButtonBackground.Red,
                icon: ButtonIcon.LongArrowRight,
                iconColor: ButtonIconColor.White,
                iconPosition: ButtonIconPosition.Right,
            };
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                onSubmitStep,
                onBackClick,
                applyStepUrls,
                setupForm,
                onBeforeFormRestore,
                onAppReady,
                applyDeviceFields,
                deviceFields,
                deviceOptions,
                onDeviceOptionToggle,
                onDeviceOptionInput,
                continueButtonParams,
                onContinueClick,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();

        const onAppIsPreparedAndReady: Subscription =
            this.onAppIsPreparedAndReady.subscribe((): void => {
                this.onAppReady();
                onAppIsPreparedAndReady.unsubscribe();
            });
    }
});
</script>
