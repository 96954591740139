import AppModule from '@/Components/Module/Module.vue';
import CustomForm from '@/Components/CustomForm/CustomForm.vue';
import AppInputText from '@/Components/InputText/InputText.vue';
import AppInputTextAjax from '@/Components/InputTextAjax/InputTextAjax.vue';
import InputSelect from '@/Components/InputSelect/InputSelect.vue';
import InputSelectionPanels from '@/Components/InputSelectionPanels/InputSelectionPanels.vue';
import InputDateWithDropdown from '@/Components/InputDateWithDropdown/InputDateWithDropdown.vue';
import InputRadio from '@/Components/InputRadio/InputRadio.vue';
import AppInputCheckbox from '@/Components/InputCheckbox/InputCheckbox.vue';
import InputNumber from '@/Components/InputNumber/InputNumber.vue';
import AppInputCounter from '@/Components/InputCounter/InputCounter.vue';
import InputMultiCounter from '@/Components/InputMultiCounter/InputMultiCounter.vue';
import AppTooltipster from '@/Components/Tooltipster/Tooltipster.vue';
import InputCountry from '@/Components/InputCountry/InputCountry.vue';
import MultiCountry from '@/Components/MultiCountry/MultiCountry.vue';
import PhoneWithCountry from '@/Components/PhoneWithCountry/PhoneWithCountry.vue';
import AppStepperBelt from '@/Components/StepperBelt/StepperBelt.vue';
import InputPlateNumber from '@/Components/InputPlateNumber/InputPlateNumber.vue';
import AppInputTextarea from '@/Components/InputTextarea/InputTextarea.vue';
import AppInputTime from '@/Components/InputTime/InputTime.vue';
import AppInputTimeWithDropdown from '@/Components/InputTimeWithDropdown/InputTimeWithDropdown.vue';
import AppFilesUploader from '@/Components/FilesUploader/FilesUploader.vue';
import AppExpandableItem from '@/Components/ExpandableItem/ExpandableItem.vue';
import AppExpandableAccordion from '@/Components/ExpandableAccordion/ExpandableAccordion.vue';
import AppContentLoader from '@/Components/ContentLoader/ContentLoader.vue';
import AppPopup from '@/Components/Popup/Popup.vue';
import AppModal from '@/Components/Modal/Modal.vue';
import AppImageInfo from '@/Components/ImageInfo/ImageInfo.vue';
import AppPaginator from '@/Components/Paginator/Paginator.vue';
import AppFilesArchive from '@/Components/FilesArchive/FilesArchive.vue';
import AppAddressFinder from '@/Components/AddressFinder/AddressFinder.vue';
import AppMobileDetect from 'mobile-device-detect';
import CountryRegions from '@/Components/CountryRegions/CountryRegions.vue';
import CountryRegionsMulti from '@/Components/CountryRegionsMulti/CountryRegionsMulti.vue';
import InputCompanyType from '@/Components/InputCompanyType/InputCompanyType.vue';
import InputSwitch from '@/Components/InputSwitch/InputSwitch.vue';
import SecurityDeviceConsent from '@/Components/SecurityDeviceConsent/SecurityDeviceConsent.vue';
import InputRadioSwitch from '@/Components/InputRadioSwitch/InputRadioSwitch.vue';
import InputMultiCheckbox from '@/Components/InputMultiCheckbox/InputMultiCheckbox.vue';
import LoadMoreList from '@/Components/LoadMoreList/LoadMoreList.vue';
import InputBankInstitution from '@/Components/InputBankInstitution/InputBankInstitution.vue';
import AppItemsButton from '@/Components/ItemsButton/ItemsButton.vue';
import InputEmail from '@/Components/InputEmail/InputEmail.vue';
import InsuredObjectsBlocks from '@/Components/InsuredObjectsBlocks/InsuredObjectsBlocks.vue';
import AppOptionsList from '@/Components/OptionsList/OptionsList.vue';
import AppMapWithPlaces from '@/Components/MapWithPlaces/MapWithPlaces.vue';
import AppMapWithAddress from '@/Components/MapWithAddress/MapWithAddress.vue';
import AppMapControls from '@/Components/MapControls/MapControls.vue';
import AppItemsListWithIcons from '@/Components/ItemsListWithIcons/ItemsListWithIcons.vue';
import AppModalOverlayMessage from '@/Components/ModalOverlayMessage/ModalOverlayMessage.vue';
import AppInformationalTextBlock from '@/Components/InformationalTextBlock/InformationalTextBlock.vue';
import AppPopupFilter from '@/Components/PopupFilter/PopupFilter.vue';
import AppOverlayButton from '@/Components/OverlayButton/OverlayButton.vue';
import InputPostCode from '@/Components/InputPostCode/InputPostCode.vue';
import AppButtonWithCallback from '@/Components/ButtonWithCallback/ButtonWithCallback.vue';
import AppProcessing from '@/Components/Processing/Processing.vue';
import AppIconWithLabel from '@/Components/IconWithLabel/IconWithLabel.vue';
import AppLegalModal from '@/Components/LegalModal/LegalModal.vue';
import PersonListWithSearch from '@/Components/PersonListWithSearch/PersonListWithSearch.vue';
import AppInputSmartRadio from '@/Components/InputSmartRadio/InputSmartRadio.vue';
import AppButtonsBeltWithDigits from '@/Components/ButtonsBeltWithDigits/ButtonsBeltWithDigits.vue';
import InputBankAccount from '@/Components/InputBankAccount/InputBankAccount.vue';
import AppInputSearch from '@/Components/InputSearch/InputSearch.vue';
import SelectAccountDropdown from '@/Components/SelectAccountDropdown/SelectAccountDropdown.vue';
import AppInputDocumentUpload from '@/Components/InputDocumentUpload/InputDocumentUpload.vue';
import AppGrid from '@/Components/SmartGrid/SmartGrid.vue';
import AppDrawer from '@/Components/Drawer/Drawer.vue';
import AppPolicyDetails from '@/Components/PolicyDetails/PolicyDetails.vue';
import AppPolicyDetailsObjects from '@/Components/PolicyDetailsObjects/PolicyDetailsObjects.vue';
import AppPolicyDetailsOverview from '@/Components/PolicyDetailsOverview/PolicyDetailsOverview.vue';
import AppPolicyDetailsInvoices from '@/Components/PolicyDetailsInvoices/PolicyDetailsInvoices.vue';
import AppPolicyDetailsPaymentMethod from '@/Components/PolicyDetailsPaymentMethod/PolicyDetailsPaymentMethod.vue';
import AppPolicyDetailsPersons from '@/Components/PolicyDetailsPersons/PolicyDetailsPersons.vue';
import AppCreditCardStatusWidget from '@/Components/CreditCardStatusWidget/CreditCardStatusWidget.vue';
import AppStatusIcon from '@/Components/StatusIcon/StatusIcon.vue';
import InputDate from '@/Components/InputDate/InputDate.vue';
import AppInsuredRisks from '@/Components/InsuredRisks/InsuredRisks.vue';
import AppPolicyLimits from '@/Components/PolicyLimits/PolicyLimits.vue';
import AppOptionsSmartList from '@/Components/OptionsSmartList/OptionsSmartList.vue';
import AppRemotePaginator from '@/Components/RemotePaginator/RemotePaginator.vue';
import AppFieldsetList from '@/Components/FieldsetList/FieldsetList.vue';
import AppPopupSelector from '@/Components/PopupSelector/PopupSelector.vue';
import AppHumanBodyParts from '@/Components/HumanBodyParts/HumanBodyParts.vue';
import InputDateWithCalendar from '@/Components/InputDateWithCalendar/InputDateWithCalendar.vue';
import AppProductBlock from '@/Components/ProductBlock/ProductBlock.vue';
import ProductBlockInnerOneDayPolicy from '@/Components/ProductBlock/Components/ProductBlockInnerOneDayPolicy.vue';
import ProductBlockInnerMovableProperty
    from '@/Components/ProductBlock/Components/ProductBlockInnerMovableProperty.vue';
import ProductBlockInnerBicyclePolicy from '@/Components/ProductBlock/Components/ProductBlockInnerBicyclePolicy.vue';
import InputCreditCardDate from '@/Components/InputCreditCardDate/InputCreditCardDate.vue';
import InputCreditCardNumber from '@/Components/InputCreditCardNumber/InputCreditCardNumber.vue';
import CreditCardDetails from '@/Components/CreditCardDetails/CreditCardDetails.vue';
import HealthCardsList from '@/Components/HealthCardsList/HealthCardsList.vue';
import CreditCardUpdate from '@/Components/CreditCardUpdate/CreditCardUpdate.vue';
import InputVehicleMake from '@/Components/InputVehicleMake/InputVehicleMake.vue';
import CreditCardWidget from '@/Components/CreditCardWidget/CreditCardWidget.vue';
import AppPolicyBlock from '@/Components/PolicyBlock/PolicyBlock.vue';
import PaymentWidget from '@/Components/PaymentWidget/PaymentWidget.vue';
import PreviewHeader from '@/Components/PreviewHeader/PreviewHeader.vue';
import Expandable from '@/Components/Expandable/Expandable.vue';
import ConsentsList from '@/Components/ConsentsList/ConsentsList.vue';
import AdditionalOptionsList from '@/Components/AdditionalOptionsList/AdditionalOptionsList.vue';
import MovablePropertyOption from '@/Components/AdditionalOptionsList/Components/MovablePropertyOption.vue';
import MtplOfferOption from '@/Components/AdditionalOptionsList/Components/MtplOfferOption.vue';
import TravelOptionRentalCarSecurity
    from '@/Components/AdditionalOptionsList/Components/TravelOptionRentalCarSecurity.vue';
import InsurancePlanWidget from '@/Components/InsurancePlanWidget/InsurancePlanWidget.vue';
import OptionsFieldList from '@/Components/OptionsFieldList/OptionsFieldList.vue';
import MovablePropertyDeviceOption from '@/Components/OptionsFieldList/Components/MovablePropertyDeviceOption.vue';
import AppCoveredPopupSingle from '@/Components/CoveredPopup/CoveredPopupSingle.vue';
import AppCoveredPopupMultiple from '@/Components/CoveredPopup/CoveredPopupMultiple.vue';
import AppCoveredPopupBicycleSingle from '@/Components/CoveredPopup/CoveredPopupBicycleSingle.vue';
import AppCoveredPopupBicycleMulti from '@/Components/CoveredPopup/CoveredPopupBicycleMulti.vue';
import TextWithTip from '@/Components/TextWithTip/TextWithTip.vue';
import InputSearchVehicleMake from '@/Components/InputSearchVehicleMake/InputSearchVehicleMake.vue';
import InputRadioOverlayed from '@/Components/InputRadioOverlayed/InputRadioOverlayed.vue';
import OneDayMini from '@/Components/ProductBlock/Components/MiniPlans/OneDayMini.vue';
import BicycleMini from '@/Components/ProductBlock/Components/MiniPlans/BicycleMini.vue';
import MovablePropertyMini from '@/Components/ProductBlock/Components/MiniPlans/MovablePropertyMini.vue';
import InputCount from '@/Components/InputCount/InputCount.vue';
import SingleAppSteps from '@/Components/SingleAppSteps/SingleAppSteps.vue';
import PaymentMethods from '@/Components/PaymentMethods/PaymentMethods.vue';
import LanguageSwitcher from '@/Components/LanguageSwitcher/LanguageSwitcher.vue';
import ObjectList from '@/Components/ObjectList/ObjectList.vue';
import AppInputIdentityNumber from '@/Components/InputIdentityNumber/InputIdentityNumber.vue';
import AppPopupOpener from '@/Components/PopupOpener/PopupOpener.vue';
import Badge from '@/Components/Badge/Badge.vue';
import NextBestAction from '@/Components/NextBestAction/NextBestAction.vue';
import InfoWithActions from '@/Components/InfoWithActions/InfoWithActions.vue';
import ContactUs from '@/Components/ContactUs/ContactUs.vue';
import TwoWayModalScreen from '@/Apps/TwoWayCommunication/Components/TwoWayModalScreen.vue';
import ProgressSteps from '@/Components/ProgressSteps/ProgressSteps.vue';
import UserMessageCard from '@/Components/MessageCard/UserMessageCard.vue';
import Message from '@/Components/Message/Message.vue';
import ClaimBlock from '@/Components/ClaimBlock/ClaimBlock.vue';
import CommunicationNotificator from '@/Components/CommunicationNotificator/CommunicationNotificator.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import NewMessage from '@/Components/NewMessage/NewMessage.vue';
import ClaimDetails from '@/Components/ClaimDetails/ClaimDetails.vue';
import ClaimOverview from '@/Components/ClaimOverview/ClaimOverview.vue';
import ClaimDocuments from '@/Components/ClaimDocuments/ClaimDocuments.vue';
import ResultMessage from '@/Components/ResultMessage/ResultMessage.vue';
import CompactFileUploader from '@/Components/CompactFileUploader/CompactFileUploader.vue';

export default {
    AppModule: AppModule,
    AppCustomForm: CustomForm,
    AppInputText: AppInputText,
    AppInputTextAjax: AppInputTextAjax,
    AppInputSelect: InputSelect,
    AppInputSelectionPanels: InputSelectionPanels,
    AppInputDateWithDropdown: InputDateWithDropdown,
    AppInputDateWithCalendar: InputDateWithCalendar,
    AppInputRadio: InputRadio,
    AppInputCheckbox: AppInputCheckbox,
    AppInputSwitch: InputSwitch,
    AppInputRadioSwitch: InputRadioSwitch,
    AppInputNumber: InputNumber,
    AppInputCounter: AppInputCounter,
    AppInputMultiCounter: InputMultiCounter,
    AppTooltipster: AppTooltipster,
    AppInputCountry: InputCountry,
    AppMultiCountry: MultiCountry,
    AppPhoneWithCountry: PhoneWithCountry,
    AppStepperBelt: AppStepperBelt,
    AppInputPlateNumber: InputPlateNumber,
    AppInputTextarea: AppInputTextarea,
    AppInputTime: AppInputTime,
    AppInputTimeWithDropdown: AppInputTimeWithDropdown,
    AppFilesUploader: AppFilesUploader,
    AppExpandableItem: AppExpandableItem,
    AppExpandableAccordion: AppExpandableAccordion,
    AppContentLoader: AppContentLoader,
    AppPopup: AppPopup,
    AppModal: AppModal,
    AppModalOverlayMessage: AppModalOverlayMessage,
    AppImageInfo: AppImageInfo,
    AppPaginator: AppPaginator,
    AppFilesArchive: AppFilesArchive,
    AppAddressFinder: AppAddressFinder,
    isMobile: AppMobileDetect,
    AppCountryRegions: CountryRegions,
    AppCountryRegionsMulti: CountryRegionsMulti,
    AppHealthCardsList: HealthCardsList,
    AppInputCompanyType: InputCompanyType,
    AppSecurityDeviceConsent: SecurityDeviceConsent,
    AppInputMultiCheckbox: InputMultiCheckbox,
    AppLoadMoreList: LoadMoreList,
    AppInputBankInstitution: InputBankInstitution,
    AppItemsButton: AppItemsButton,
    AppInputCreditCardNumber: InputCreditCardNumber,
    AppInputCreditCardDate: InputCreditCardDate,
    AppCreditCardDetails: CreditCardDetails,
    AppCreditCardUpdate: CreditCardUpdate,
    AppInputEmail: InputEmail,
    AppInsuredObjectsBlocks: InsuredObjectsBlocks,
    AppOptionsList: AppOptionsList,
    AppMapWithPlaces: AppMapWithPlaces,
    AppMapWithAddress: AppMapWithAddress,
    AppMapControls: AppMapControls,
    AppItemsListWithIcons: AppItemsListWithIcons,
    AppInformationalTextBlock: AppInformationalTextBlock,
    AppPopupFilter: AppPopupFilter,
    AppOverlayButton: AppOverlayButton,
    AppInputPostCode: InputPostCode,
    AppButtonWithCallback: AppButtonWithCallback,
    AppProcessing: AppProcessing,
    AppIconWithLabel: AppIconWithLabel,
    AppLegalModal: AppLegalModal,
    AppPersonListWithSearch: PersonListWithSearch,
    AppInputSmartRadio: AppInputSmartRadio,
    AppButtonsBeltWithDigits: AppButtonsBeltWithDigits,
    AppInputBankAccount: InputBankAccount,
    AppInputSearch: AppInputSearch,
    AppSelectAccountDropdown: SelectAccountDropdown,
    AppInputDocumentUpload: AppInputDocumentUpload,
    AppPaymentWidget: PaymentWidget,
    AppGrid: AppGrid,
    AppDrawer: AppDrawer,
    AppPolicyDetails: AppPolicyDetails,
    AppPolicyDetailsOverview: AppPolicyDetailsOverview,
    AppPolicyDetailsInvoices: AppPolicyDetailsInvoices,
    AppPolicyDetailsObjects: AppPolicyDetailsObjects,
    AppPolicyDetailsPaymentMethod: AppPolicyDetailsPaymentMethod,
    AppPolicyDetailsPersons: AppPolicyDetailsPersons,
    AppInputVehicleMake: InputVehicleMake,
    AppCreditCardWidget: CreditCardWidget,
    AppCreditCardStatusWidget: AppCreditCardStatusWidget,
    AppStatusIcon: AppStatusIcon,
    AppInputDate: InputDate,
    AppInsuredRisks: AppInsuredRisks,
    AppPolicyLimits: AppPolicyLimits,
    AppOptionsSmartList: AppOptionsSmartList,
    AppRemotePaginator: AppRemotePaginator,
    AppFieldsetList: AppFieldsetList,
    AppPopupSelector: AppPopupSelector,
    AppHumanBodyParts: AppHumanBodyParts,
    AppProductBlock: AppProductBlock,
    AppProductBlockInnerOneDayPolicy: ProductBlockInnerOneDayPolicy,
    AppProductBlockInnerMovableProperty: ProductBlockInnerMovableProperty,
    AppProductBlockInnerBicyclePolicy: ProductBlockInnerBicyclePolicy,
    AppPolicyBlock: AppPolicyBlock,
    AppPreviewHeader: PreviewHeader,
    AppExpandable: Expandable,
    AppConsentsList: ConsentsList,
    AppAdditionalOptionsList: AdditionalOptionsList,
    AppMovablePropertyOption: MovablePropertyOption,
    AppMtplOfferOption: MtplOfferOption,
    AppTravelOptionRentalCarSecurity: TravelOptionRentalCarSecurity,
    AppInsurancePlanWidget: InsurancePlanWidget,
    AppCoveredPopupSingle: AppCoveredPopupSingle,
    AppCoveredPopupMultiple: AppCoveredPopupMultiple,
    AppCoveredPopupBicycleSingle: AppCoveredPopupBicycleSingle,
    AppCoveredPopupBicycleMulti: AppCoveredPopupBicycleMulti,
    AppOptionsFieldList: OptionsFieldList,
    AppMovablePropertyDeviceOption: MovablePropertyDeviceOption,
    AppTextWithTip: TextWithTip,
    AppInputSearchVehicleMake: InputSearchVehicleMake,
    AppInputRadioOverlayed: InputRadioOverlayed,
    AppOneDayMini: OneDayMini,
    AppBicycleMini: BicycleMini,
    AppMovablePropertyMini: MovablePropertyMini,
    AppInputCount: InputCount,
    AppSingleAppSteps: SingleAppSteps,
    AppPaymentMethods: PaymentMethods,
    AppLanguageSwitcher: LanguageSwitcher,
    AppObjectList: ObjectList,
    AppInputIdentityNumber: AppInputIdentityNumber,
    AppPopupOpener: AppPopupOpener,
    AppBadge: Badge,
    AppNextBestAction: NextBestAction,
    AppInfoWithActions: InfoWithActions,
    AppContactUs: ContactUs,
    AppTwoWayModalScreen: TwoWayModalScreen,
    AppProgressSteps: ProgressSteps,
    AppUserMessageCard: UserMessageCard,
    AppMessage: Message,
    AppNewMessage: NewMessage,
    AppClaimBlock: ClaimBlock,
    AppCommunicationNotificator: CommunicationNotificator,
    AppClaimDetails: ClaimDetails,
    AppClaimOverview: ClaimOverview,
    AppClaimDocuments: ClaimDocuments,
    AppResultMessage: ResultMessage,
    AppCompactFileUploader: CompactFileUploader,
} as Components;
