<script setup lang="ts">

import {computed, ComputedRef, onMounted, PropType, ref, Ref, watch, nextTick} from 'vue';
import InputTagIcon from '@/Components/InputText/InputTagIcon';
import FormField from '@/assets/libraries/form/form-field';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useInputErrorMessage} from '@/Composables/InputErrorMessage';

const props = defineProps({
    formField: {
        type: Object as PropType<FormField<string>>,
        default: () => new FormField('')
    },
    sanitizer: {type: Function, default: () => null},
    validators: {type: Object, default: () => null},
    maxLength: {type: Number, default: 9999},
    insideLabel: {type: String, default: ''},
    leftIcon: {type: Object as PropType<InputTagIcon>, default: () => null},
    rightIcon: {type: Object as PropType<InputTagIcon>, default: () =>  null},
    disabled: {type: Boolean, default: false},
    hidden: {type: Boolean, default: false},
    hintLabel: {type: String, default: ''},
    locked: {type: Boolean, default: false},
    isLockable: {type: Boolean, default: false},
    tabFocusesTooltipAfterField: {type: Boolean, default: false},
    labelUnder: {type: Boolean, default: false},
    onlyUpperCase: {type: Boolean, default: false},
    label: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    ariaLabel: {type: String, default: ''},
    autocomplete: {type: String, default: ''},
    dataStoreDisabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    supportTextMessage: {type: String, default: ''},
    disableErrorText: {type: Boolean, default: false},
    feedbackMessage: {type: String, default: ''},
});

let isLocked: Ref<boolean> = ref(false);

const emit = defineEmits([
    'keyup', 'keypress', 'blur', 'change', 'lock-change', 'left-icon-click', 'right-icon-click', 'input',
]);

onMounted(() => {
    isLocked.value = props.locked;
    if (props.sanitizer && props.sanitizer() !== null) {
        props.formField.addSanitizer(props.sanitizer);
    }
    if (props.validators) {
        props.formField.addValidators(props.validators);
    }
    if (props.formField.value) {
        props.formField.touch();
    }
    props.formField.sanitize().validate().then();
    nextTick(() => {
        emit('lock-change', props.isLockable ? isLocked.value : false);
    });
})

function onInput(): void {
    props.formField.setValue(props.formField.value);
    props.formField.sanitize();
    format();
    emit('input', props.formField.value);
}

function onKeyUp(): void {
    props.formField.setValue(props.formField.value);
    props.formField.touch();
    props.formField.validate();
    emit('keyup', props.formField.value);
}

function onKeyPress(): void {
    props.formField.setValue(props.formField.value);
    props.formField.touch();
    props.formField.validate();
    emit('keypress', props.formField.value);
}

function onChange(): void {
    props.formField.setValue(props.formField.value);
    props.formField.touch();
    props.formField.validate();
    emit('change', props.formField.value);
}

function onBlur(): void {
    props.formField.setValue(props.formField.value);
    props.formField.touch();
    props.formField.validate();
    emit('blur', props.formField.value);
}

function enable(): void {
    isLocked.value = false;
    props.formField.clear();
    props.formField.touch();
    props.formField.validate();
    emit('keypress', props.formField.value);
}

function lock(): void {
    isLocked.value = true;
}

function iconSrc(name: string): string {
    return name !== '' ? `images/one/components/input-text/${name}_black.svg` : '';
}

function emitLockChange() {
    return props.isLockable ? isLocked.value : false;
}

function onLeftIconClick(): void {
    emit('left-icon-click');
}

function onRightIconClick(): void {
    emit('right-icon-click');
}

function addLabelUnderInput(): boolean {
    return (props.labelUnder && !props.tabFocusesTooltipAfterField)
        || (!props.labelUnder && props.tabFocusesTooltipAfterField);
}

function format(): void {
    if (props.onlyUpperCase) {
        (props as DynamicDictionary).formField.value = props.formField.value.toUpperCase();
    }
}

const isDisabled: Ref<boolean> = computed(() => {
    return props.isLockable ? isLocked.value : props.disabled;
});

const inputErrors = computed(() => useInputErrorMessage(props.formField, props.disableErrorText, {
    supportTextMessage: supportTextMessage.value,
    feedbackMessage: feedBackMessage.value
}));

const feedBackMessage: ComputedRef<string> = computed(() => props.feedbackMessage);
const supportTextMessage: ComputedRef<string> = computed(() => props.supportTextMessage);

const fieldId: ComputedRef<string> = computed(() => props.formField.name + '-text');

watch(() => props.formField.value, (newValue, oldValue) => {
    if (!props.isLockable) {
    emit('change', newValue, oldValue);
    }
});

watch(() => props.locked, (newValue, oldValue) => {
    isLocked.value = newValue;
});

watch(() => isLocked.value, (newValue, oldValue) => {
    emit('lock-change', newValue);
});

defineExpose({
    lock,
});
</script>

<template>
    <div class="input input-text"
        :id="formField.name"
        :class="{
            ...formField.classes(),
            disabled: disabled || isLocked,
            hidden: props.hidden,
            'tab-focuses-tooltip-after-field': tabFocusesTooltipAfterField,
        }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : formField.value"
    >
        <div class="label informative" v-if="label && !addLabelUnderInput()">
            <label :for="fieldId">
                {{ label }}<span v-if="required" class="asterisk">&#42;</span>
            </label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper"
             :class="{'padding-left': leftIcon, 'padding-right': rightIcon}">
            <button v-if="leftIcon" class="inside-icon left"
                    :class="leftIcon.cssClass"
                    @click="onLeftIconClick">
                <img class="icon" alt="" width="24" height="24"
                     :src="iconSrc(leftIcon.name)">
            </button>
            <input type="text"
                   ref="input"
                   v-model="formField.value"
                   :name="formField.name"
                   :id="fieldId"
                   :disabled="isDisabled"
                   :hidden="props.hidden"
                   :class="{'with-inside-label':insideLabel !== ''}"
                   :placeholder="placeholder"
                   :maxlength="maxLength"
                   :autocomplete="autocomplete"
                   :aria-label="ariaLabel && !label ? ariaLabel : undefined"
                   :data-type="'inputField'"
                   @input="onInput"
                   @change="onChange"
                   @keyup="onKeyUp"
                   @keypress="onKeyPress"
                   @blur="onBlur"
            />
            <div v-if="insideLabel" class="inside-label" v-html="insideLabel"></div>
            <div v-if="hintLabel" class="hint-label" v-html="hintLabel"></div>
            <div v-if="inputErrors.infoMessageIsVisible()" v-html="inputErrors.infoMessage()" class="feedback"></div>
            <div class="enabler"
                 v-if="isLocked && isLockable">
                <svg @click="enable"
                     :id="formField.name + '-enable'"
                     width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.48">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M14.7071 1.29289C14.3166 0.902369 13.6834 0.902369 13.2929 1.29289L2.29289 12.2929C2.10536 12.4804 2 12.7348 2 13V17C2 17.5523 2.44772 18 3 18H7C7.26522 18 7.51957 17.8946 7.70711 17.7071L18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289L14.7071 1.29289ZM4 16V13.4142L14 3.41421L16.5858 6L6.58579 16H4Z"
                              fill="#9297A0"/>
                        <path
                            d="M3 21C2.44772 21 2 21.4477 2 22C2 22.5523 2.44772 23 3 23H21C21.5523 23 22 22.5523 22 22C22 21.4477 21.5523 21 21 21H3Z"
                            fill="#9297A0"/>
                    </g>
                </svg>
            </div>
            <button class="inside-icon right"
                    v-if="rightIcon"
                    :class="rightIcon.cssClass"
                    @click="onRightIconClick">
                <img class="icon" alt="" width="24" height="24"
                     :src="iconSrc(rightIcon.name)">
            </button>
        </div>
        <div class="label informative" v-if="label && addLabelUnderInput()">
            <label :for="fieldId">
                {{ label }}<span v-if="required" class="asterisk">&#42;</span>
            </label>
            <slot name="app-tooltipster"></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-text {
    scroll-margin-top: 4em;

    &.hidden {
        display: none;
    }

    &.tab-focuses-tooltip-after-field {
        flex-direction: column-reverse;
    }

    .inside-label {
        height: 52px;
        width: 60px;
        justify-content: center;
        align-items: center;
        position: absolute;
        display: flex;
        right: 0;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: var(--black-200);
        }
    }

    .wrapper {
        &.padding-left {
            input {
                padding-left: 49px;
            }
        }

        &.padding-right {
            input {
                padding-right: 44px;
            }
        }

        .enabler {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;

            svg {
                width: 20px;
                height: 20px;

                &:hover {
                    path {
                        fill: var(--text-color-default);
                    }
                }
            }
        }
    }
}

input.disabled {
    color: var(--component-color-text-disabled);
    border-color: transparent;
}

.with-inside-label {
    padding: 0 58px 0 20px;
}

.hint-label {
    color: var(--black-500);
    font-size: var(--font-size-pico);
    font-style: italic;
    position: absolute;
    bottom: -24px;
    left: 6px;
}

.inside-icon {
    position: absolute;
    height: 100%;

    &.left {
        left: 16px;
    }

    &.right {
        right: 16px;
    }

    &.black-to-grey {
        filter: var(--to-color-color-grey);

        &:hover {
            filter: none;
        }
    }

    &.black-to-green {
        filter: var(--to-color-green);

        &:hover {
            filter: none;
        }
    }

    &.black-to-red {
        filter: var(--to-color-red);

        &:hover {
            filter: none;
        }
    }

    &.input-reset {
        filter: var(--to-color-color-grey);
        width: 16px;
        padding-top: 6px;

        &:hover {
            filter: none;
        }
    }
}
</style>
