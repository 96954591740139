<script lang="ts">
import {
    computed,
    defineComponent,
    reactive,
    ref,
    Ref,
    watch,
    nextTick,
    UnwrapNestedRefs,
} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import {PriceParams, usePrice} from '@/Composables/Price';
import CoveredPopupProperty from '@/pages/Property/CoveredPopupProperty';
import PropertyDataLayer from '@/pages/Property/PropertyDataLayer';
import {Subscription} from 'rxjs';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Method from '@/Enums/MethodEnum';
import CssClass from '@/Enums/CssClassEnum';
import VueEvent from '@/Classes/VueEventClass';
import PropertyRiskData from '@/interfaces/property.risk.data.interface';
import PropertyProductTerms from '@/interfaces/property.product.terms.interface';
import {DefineParams, useDefine} from '@/Composables/Define';
import PropertyEnum from '@/Enums/PropertyEnum';
import {TranslateParams, useTranslate} from '@/Composables/Translate';
import PropertyRiskDataPrice from '@/interfaces/property.risk.data.price.interface';
import PropertyRisk from '@/interfaces/property.risk.interface';
import {StepsSubmitterParams, useStepsSubmitter} from '@/Composables/StepsSubmitter';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import FormField from '@/assets/libraries/form/form-field';
import Form from '@/assets/libraries/form/form';
import PropertyPrices from '@/interfaces/property.prices.interface';
import Url from '@/Enums/UrlEnum';
import {InputOption} from '@/interfaces/InputOptionInterface';
import PropertyPaymentType from '@/Enums/PropertyPaymentTypeEnum';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import PropertyTransformation from '@/services/property.transformation.service';
import UserState from '@/Enums/UserStateEnum';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PropertyRiskType from '@/Enums/PropertyRiskTypeEnum';
import OneBase from '@/interfaces/OneBaseInterface';
import {useTransforms} from '@/Composables/Transforms';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();


        const {price, sparse}: PriceParams = usePrice();
        const {isSet}: DefineParams = useDefine();
        const {translate, translateForType, hasLocalization}: TranslateParams = useTranslate();
        const stepsSubmitter: StepsSubmitterParams = useStepsSubmitter();
        const request: AxiosParams = useAxios();


        const LastProduct: number = 2;
        const CurrentStep: number = 3;
        const coveredPopup: CoveredPopupProperty = new CoveredPopupProperty();
        const dataLayer: PropertyDataLayer = new PropertyDataLayer();
        let onWindowResizeSubscription!: Subscription;
        let onAfterFormRestoredSubscription!: Subscription;


        let policyPlan: Ref<string> = ref('');
        let visibleMobilePlan: Ref<string> = ref('');
        const form: Form = new Form();
        const selectedProductWithAdditionalRisks: UnwrapNestedRefs<DynamicDictionary> = reactive({});
        const selectedPropertyOptions: Ref<string[]> = ref([]);
        let badges: Ref<DynamicDictionary[]> = ref([]);


        const policyPlanLocalized: Ref<string> = computed((): string => {
            return translation('one_property_policy_' + policyPlan.value);
        });

        const paymentOptionInputOptions: Ref<InputOption[]> = computed((): InputOption[] => {
            return [
                (new InputOptionBuilder()).setValue(PropertyPaymentType.Monthly)
                    .setName(translation('one_property_policy_monthly')).build(),
                (new InputOptionBuilder()).setValue(PropertyPaymentType.Annual)
                    .setName(translation('one_property_policy_annual')).build(),
            ];
        });

        const miniPolicyPaymentType: Ref<string> = computed((): string => {
            return form.field('paymentOption').value === 'monthly' ?
                translate('btar_month').toLowerCase() : '';
        });

        const additionalContainerClasses: Ref<string> = computed((): string => {
            return propertyProductIsSelected.value ? '' : CssClass.Hidden;
        });

        const propertyProductIsSelected: Ref<boolean> = computed((): boolean => {
            return policyPlan.value !== '';
        });

        const selectedProductPrice: Ref<string> = computed((): string => {
            let result: string = '0';
            if (policyPlan.value !== '') {
                result = sparsePrice(productPrice(policyPlan.value).priceWithDiscount);
            }

            return result;
        });

        const selectedProductOriginalPrice: Ref<string> = computed((): string => {
            let result: string = '';
            if (policyPlan.value !== '') {
                result = sparsePrice(productPrice(policyPlan.value).price);
            }

            return result;
        });

        const youSave: Ref<string> = computed((): string => {
            let result: string = '';
            if (policyPlan.value !== '') {
                const prices: PropertyPrices = productPrice(policyPlan.value);
                result = sparsePrice(prices.price - prices.priceWithDiscount);
            }

            return result;
        });

        const paymentDescriptionType: Ref<string> = computed(() => {
            let result: string = ' ' + translate('btar_euro');
            if (isMonthlyPayment.value) {
                result += '/' + translate('btar_month').toLowerCase();
            }

            return result;

        });

        const paymentDescriptionTypeVertical: Ref<string> = computed((): string => {
            let result: string = '';
            if (isMonthlyPayment.value) {
                result += translate('btar_month').toLowerCase();
            }

            return result;
        });

        const hasDiscount: Ref<boolean> = computed((): boolean => {
            const prices: PropertyPrices = productPrice(policyPlan.value);

            return prices.price > prices.priceWithDiscount;
        });

        const isMonthlyPayment: Ref<boolean> = computed((): boolean => {
            return paymentType.value === PropertyPaymentType.Monthly;
        });

        const userType: Ref<string> = computed((): string => {
            return btaBase.user.isLogged() ?
                UserState.authenticated : UserState.guest;
        });

        const paymentType: Ref<string> = computed((): string => {
            return form.field('paymentOption').value;
        });

        const policyPlanIsSelected: Ref<boolean> = computed((): boolean => {
            return policyPlan.value !== '';
        });


        watch(() => policyPlan.value, () => {
            if (policyPlan.value !== '') {
                visibleMobilePlan.value = policyPlan.value;
                form.touch();
            }
        });


        function policyPlanButtonClasses(newPolicyPlan: string): string {
            let result = CssClass.Red;
            if (policyPlan.value === newPolicyPlan) {
                result = CssClass.Hidden;
            } else {
                if (policyPlanIsSelected.value) {
                    result = CssClass.Outside;
                }
            }

            return result;
        }

        function policyPlanClasses(newPolicyPlan: string): string {
            const result: string[] = [];
            if (policyPlan.value === newPolicyPlan) {
                result.push(CssClass.Active);
            }
            if (btaBase.isVerticalMode.value) {
                if (visibleMobilePlan.value !== newPolicyPlan) {
                    result.push(CssClass.Hidden);
                }
            }

            return result.join(' ');
        }

        function miniPlanClasses(policyPlan: string): string {
            const result: string[] = [];
            if (visibleMobilePlan.value === policyPlan) {
                result.push(CssClass.Active);
            }

            return result.join(' ');
        }

        function selectPolicyPlan(event: VueEvent): void {
            policyPlan.value = event.params.id;
            visibleMobilePlan.value = event.params.id;
        }

        function selectAndSubmit(event: VueEvent): void {
            policyPlan.value = event.params.id;
            submit();
        }

        function showPolicyPlanCovered(event: VueEvent): void {
            showCoveredPopup(event.params.id);
        }

        function additionalRiskOpenerClick(event: VueEvent, id: string): void {
            event.sender.toggleClass(CssClass.Opened);
            $('.description-opener[data-opener="' + id + '"]').toggleClass(CssClass.Visible);
        }

        function showSelectedPlanCovered(): void {
            if (policyPlan.value !== '') {
                showCoveredPopup(policyPlan.value);
            }
        }

        function selectCustomPolicyPlan(event: VueEvent): void {
            policyPlan.value = event.params.id;
        }

        function formattedPrice(targetPrice: string | number): string {
            return price(targetPrice, false);
        }

        function sparsePrice(targetPrice: number, noCents: boolean = false): string {
            return sparse(targetPrice, noCents);
        }

        function policyPlanBadgeIsVisible(id: string): boolean {
            return productPrice(id).discount > 0;
        }

        function policyBadgeValue(id: string): string {
            return String(productPrice(id).discount);
        }

        function risks(id: string, type: string): PropertyRiskData[] {
            let result: PropertyRiskData[] = [];
            const term: PropertyProductTerms = productTermsById(id);
            if (isSet(term.risks) && isSet(term.risks[type])) {
                result = term.risks[type];
            }

            return result;
        }

        function riskName(risk: PropertyRiskData): string {
            return translateForType('one_property_risk_' + risk.id, PropertyEnum.TranslationType);
        }

        function policyPlanRiskPrice(risk: PropertyRiskData, currency: string): string {
            let targetPrice: number = 0;
            let message: boolean = false;
            if (isSet(risk.prices)) {
                risk.prices.forEach((value: PropertyRiskDataPrice): void => {
                    if (value.priceType === userType.value) {
                        if (isSet(value.price)) {
                            targetPrice = value.price;
                        }
                    }
                });
            } else {
                message = true;
            }
            let result: string = '<span class="zero-price"></span>';
            if (targetPrice > 0) {
                result = '<span>' + sparse(targetPrice, true) + '</span><span>' + currency + '</span>';
            } else if (message) {
                result = translateForType('one_property_risk_' + risk.id + '_IS_SELECTED', PropertyEnum.TranslationType);
            }

            return result;
        }

        function productPriceById(productId: string, isSparse: boolean = false, hideZeroPrice: boolean = false): string {
            const targetPrice: number = productPrice(productId).priceWithDiscount;
            let formattedPrice: string = isSparse ? sparsePrice(targetPrice) : String(targetPrice);
            if (hideZeroPrice && targetPrice === 0) {
                formattedPrice = '';
            }

            return formattedPrice;
        }

        function additionalRiskTooltipsterIsVisible(additionalRisk: PropertyRisk): boolean {
            return additionalRisk.tooltipTitle !== '' && additionalRisk.tooltipDescription !== '';
        }

        function onPropertyDataAction(): void {
            btaBase.navigate(SubmitterUrls.getInstance().previousStep());
        }

        function additionalOptionsButtonClasses(included: boolean): string {
            return included ? CssClass.Outside : CssClass.Red;
        }

        function applyBadges(newBadges: string): void {
            badges.value = JSON.parse(useTransforms().transformedVueSafeString(newBadges));
        }

        function translation(stringId: string): string {
            return translateForType(stringId, PropertyEnum.TranslationType);
        }

        function hasTranslation(stringId: string): boolean {
            return hasLocalization(stringId, PropertyEnum.TranslationType);
        }

        function featuredRiskTooltipText(risk: PropertyRiskData, type: string = 'title'): string {
            return translate('one_property_risk_' + risk.id + '_tip_' + type);
        }

        function productTermsById(id: string): PropertyProductTerms {
            let result: PropertyProductTerms = new class implements PropertyProductTerms {
                public id: string = '';
                public deductible: number = 0;
                public prices: PropertyRiskDataPrice[] = [];
                public risks: Record<string, PropertyRiskData[]> = {};
            }
            if (!btaBase.userStorage.storageIsEmpty) {
                btaBase.userStorage.storageData.forEach((product: DynamicDictionary): void => {
                    if (product.id === id) {
                        const paymentTerms: DynamicDictionary = isSet(selectedProductWithAdditionalRisks.id) &&
                        selectedProductWithAdditionalRisks.id === product.id ?
                            selectedProductWithAdditionalRisks.paymentTerms : product.paymentTerms;
                        paymentTerms.forEach((terms: PropertyProductTerms) => {
                            if (terms.id === paymentType.value) {
                                result = terms;
                            }
                        });
                    }
                });
            }

            return result;
        }

        function submit(): void {
            dataLayer.pushDataLayer(dataLayerParams());
            nextTick((): void => {
                prepareSubmit();
                if (btaBase.settings.metaApiEnabled()) {
                    sendMetaEvent();
                }
                stepsSubmitter.proceedStep('', btaBase.nextStep());
            })
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('policyPlan', policyPlan.value)
            stepsSubmitter.addSubmitCustomParam('payment[type]', paymentType.value)
            stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
            stepsSubmitter.addSubmitCustomParam('dataLayerParams', dataLayerParams());
        }

        function dataLayerParams(): DynamicDictionary {
            const dataLayer: DynamicDictionary =
                useTransforms().deepClonedObjectWithoutVueReactivity(btaBase.userStorage.stepStorageData.dataLayerParams);
            dataLayer.variant = btaBase.userStorage.stepStorageData.dataLayerParams.variant + '-' + policyPlan.value;
            dataLayer.price = selectedProductPrice.value;
            dataLayer.dimension10 = paymentType.value;

            return dataLayer;
        }

        function showCoveredPopup(id: string): void {
            prepareCoveredPopup(id);
            PopupService.getInstance().show(new OnePopup().withType().coveredProperty);
            nextTick(() => {
                btaBase.initPopupTooltips();
            });
        }

        function prepareCoveredPopup(id: string): void {
            const deductible: string = String(productTermsById(id).deductible ?? '');
            const coveredRisks: PropertyRiskData[] = risks(id, PropertyRiskType.Covered);
            coveredPopup.build(id, deductible, coveredRisks);
        }

        function setupForm(): Promise<void> {
            return new Promise(resolve => {
                form.addField(new FormField('paymentOption', 'monthly', 'required'));
                form.setReady();
                resolve();
            });
        }

        function productPrice(id: string): PropertyPrices {
            const result: PropertyPrices = new class implements PropertyPrices {
                public price: number = 0;
                public priceWithDiscount: number = 0;
                public discount: number = 0;
            };
            const terms: PropertyProductTerms = productTermsById(id);
            if (isSet(terms.prices)) {
                terms.prices.forEach((targetPrice: PropertyRiskDataPrice) => {
                    if (targetPrice.priceType === userType.value) {
                        result.price = targetPrice.price;
                    }
                    if (targetPrice.priceType === userType.value + '-discount') {
                        result.priceWithDiscount = targetPrice.price;
                        result.discount = targetPrice.discount;
                    }
                });
            }

            return result;
        }

        function onAfterFormRestored(restoredElementsCount: number): void {
            form.validate().then();
            btaBase.userStorage.storageData.forEach((value: DynamicDictionary, index: number): void => {
                if (index === 1 && policyPlan.value === '') {
                    visibleMobilePlan.value = value.id;
                }
            });
            prepareSelectedPropertyOptions();
        }

        function prepareSelectedPropertyOptions(): void {
            selectedPropertyOptions.value = [];
            const isRoomMode: boolean =
                btaBase.userStorage.stepStorageData['properties[0][type]'] === PropertyEnum.Type.Apartment;
            let propertyType: string = btaBase.userStorage.stepStorageData['properties[0][type]'];
            if (isRoomMode) {
                let roomElement: string = translation('one_property_common_' +
                    PropertyTransformation.getInstance().transform(propertyType).toLowerCase());
                const totalArea: string = btaBase.userStorage.stepStorageData['properties[0][totalArea]'];
                roomElement += ', ' + totalArea + ' m<sup>2</sup>';
                selectedPropertyOptions.value.push(roomElement);
            } else {
                for (let i: number = 0; i < PropertyEnum.AdditionalObjectsLimit; i++) {
                    if (isSet(btaBase.userStorage.stepStorageData['properties[' + i + '][totalArea]'])) {
                        const type: string = i === 0 ? 'type' : 'subType';
                        propertyType = btaBase.userStorage.stepStorageData['properties[' + i + '][' + type + ']'];
                        const totalArea: string = btaBase.userStorage.stepStorageData['properties[' + i + '][totalArea]'];
                        let houseElement: string = translation('one_property_common_' +
                            PropertyTransformation.getInstance().transform(propertyType).toLowerCase());
                        houseElement += ', ' + totalArea + ' m<sup>2</sup>';
                        selectedPropertyOptions.value.push(houseElement);
                    }
                }
            }
        }

        function sendMetaEvent(): void {
            request.post(Url.Ajax.addToCartEvent, {
                productCategory: 'PropertyInsurance',
                productName: 'Property',
                productIds: stepsSubmitter.getAllParams()['customParam[policyPlan]'],
                policyPrice: productPriceById(policyPlan.value),
            }).then();
        }

        const applyStepUrls = (next: string, previous: string): void => {
            SubmitterUrls.getInstance().applyStepUrls(next, previous);
        }


        return {
            ...btaBase, ...{
                form,
                policyPlan,
                coveredPopup,
                submit,
                policyPlanButtonClasses,
                policyPlanClasses,
                miniPlanClasses,
                selectPolicyPlan,
                selectAndSubmit,
                showPolicyPlanCovered,
                additionalRiskOpenerClick,
                showSelectedPlanCovered,
                selectCustomPolicyPlan,
                formattedPrice,
                sparsePrice,
                policyPlanBadgeIsVisible,
                policyBadgeValue,
                risks,
                riskName,
                policyPlanRiskPrice,
                productPriceById,
                additionalRiskTooltipsterIsVisible,
                onPropertyDataAction,
                additionalOptionsButtonClasses,
                applyBadges,
                translation,
                hasTranslation,
                featuredRiskTooltipText,
                productTermsById,
                policyPlanLocalized,
                paymentOptionInputOptions,
                miniPolicyPaymentType,
                selectedPropertyOptions,
                additionalContainerClasses,
                propertyProductIsSelected,
                selectedProductPrice,
                selectedProductOriginalPrice,
                youSave,
                paymentDescriptionType,
                paymentDescriptionTypeVertical,
                hasDiscount,
                isMonthlyPayment,
                userType,
                paymentType,
                policyPlanIsSelected,
                CurrentStep,
                applyStepUrls,
                setupForm,
                stepsSubmitter,
                onWindowResizeSubscription,
                visibleMobilePlan,
                onAfterFormRestored,
            }
        };
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        // this.popup.showPopup(PopupType.LoadingWait);
        this.setStep(this.CurrentStep);
        this.setFacility('one-property');
        this.stepsSubmitter.submitMethod(Method.Get);
        this.setStorageUsage(true);
        this.setupForm().then((): void => {
            this.onWindowResizeSubscription = this.onWindowResize
                .subscribe((): void => {
                    if (this.isVerticalMode) {
                        if (this.policyPlan === '') {
                            this.policyPlan = this.visibleMobilePlan;
                        }
                    }
                });
            const onAfterFormRestoredSubscription = this.userStorage.onFormStorageDataIsReady
                .subscribe((value: number): void => {
                    this.onAfterFormRestored(value);
                    onAfterFormRestoredSubscription.unsubscribe();
                });
        });
    },

    beforeDestroy() {
        if (this.onWindowResizeSubscription) {
            this.onWindowResizeSubscription.unsubscribe();
        }
    },
});
</script>
