import { useNumbers } from '@/Composables/Numbers';

export const usePayByLink = (): PayByLinkParams => {
    const duePayment = (premiums: number[], paymentCountOrIc: string): number => {
        const monthsCount: number = Number(paymentCountOrIc.replace(/\D/, ''));
        const totalPremium: number = useNumbers().arraySum(premiums);
        const onePaymentValue: number = fraction(division(totalPremium, monthsCount));
        const onePaymentValueFraction: number = fraction(onePaymentValue);
        const difference: number = subtraction(onePaymentValue, onePaymentValueFraction);
        const totalDifference: number = multiplication(difference, monthsCount, 100);

        return addition(fraction(totalDifference), onePaymentValueFraction, 100);
    }

    /**
     * @param value float value
     * @param decimals decimals count
     * @return value with given decimals without any rounding
     */
    function fraction(value: number, decimals: number = 2): number {
        const expression: RegExp = new RegExp(String.raw`^-?\d+(?:\.\d{0,${decimals}})?`, 'g');
        const result: string[] = value.toString().match(expression) ?? [];

        return Number(result[0]);
    }

    function division(dividend: number, divisor: number, decimalsMultiplier: number = 100000): number {
        return Math.round((dividend / divisor + Number.EPSILON) * decimalsMultiplier) / decimalsMultiplier;
    }

    function subtraction(minuend: number, subtrahend: number, decimalsMultiplier: number = 100000): number {
        return Math.round((minuend - subtrahend + Number.EPSILON) * decimalsMultiplier) / decimalsMultiplier;
    }

    function addition(augend: number, addend: number, decimalsMultiplier: number = 100000): number {
        return Math.round((augend + addend + Number.EPSILON) * decimalsMultiplier) / decimalsMultiplier;
    }

    function multiplication(multiplier: number, multiplicand: number, decimalsMultiplier: number = 100000): number {
        return Math.round((multiplier * multiplicand + Number.EPSILON) * decimalsMultiplier) / decimalsMultiplier;
    }

    return {
        duePayment,
    }
}

export interface PayByLinkParams {
    duePayment: (premiums: number[], paymentCountOrIc: string) => number;
}
