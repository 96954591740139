import Form from '@/assets/libraries/form/form';

export default class PolicyValidators {
    private form: Form = new Form();

    public init(form: Form): void {
        this.form = form;
    }

    public customMedicalExpensesValidator(): object {
        return {
            mustBeSelected: () => {
                return !this.form.field('customMedicalExpenses').isEmpty();
            }
        };
    }

    public customLuggageValidator(): object {
        return {
            mustBeSelected: () => {
                return !this.form.field('customLuggage').isEmpty();
            }
        };
    }

    public flightInterruptionValidator(): object {
        return {
            mustBeSelected: () => {
                return !this.form.field('flightInterruption').isEmpty();
            }
        };
    }

    public extraCoverageValidator(): object {
        return {
            mustBeSelected: () => {
                return !this.form.field('extraCoverage').isEmpty();
            }
        };
    }
}
