<script lang="ts">
import { computed, defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import { useTranslate } from '@/Composables/Translate';
import { useNavigate } from "@/Composables/Navigate";
import { Subscription } from 'rxjs';
import MovablePropertySteps from '@/pages/ItemsAndBelongings/Classes/MovablePropertySteps';
import UserStorage from '@/services/user.storage.service';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonBorder from '@/Components/ButtonWithCallback/Enums/button.border.enum';
import { MovableProperty } from '@/interfaces/resources/MovableProperties/MovablePropertyInterface';
import CoveredPopupTypes from '@/Components/CoveredPopup/Enums/CoveredPopupTypes';
import { CoveredPopupBuilder } from '@/pages/ItemsAndBelongings/Classes/CoveredPopupBuilder';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import moment from 'moment';
import FormField from '@/assets/libraries/form/form-field';
import OneDate from '@/assets/libraries/Date/OneDate';
import User from '@/services/user.service';
import Validation from '@/services/validation.service';
import SummaryValidators from '@/pages/ItemsAndBelongings/SummaryAndPayment/SummaryValidators';
import Sanitizer from '@/services/sanitizer.service';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import RecurringPaymentBanklink from '@/Enums/RecurringPaymentBanklinkEnum';
import AppCountry from '@/assets/libraries/app/app-country';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import CardDetails from '@/Components/CreditCardDetails/CardDetails';
import Value from '@/assets/libraries/form/value';
import ExtractDataService from '@/services/extract.data.service';
import SettingsService from '@/services/settings.service';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const {translate, translateForType} = useTranslate();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 5;
        const Facility: string = 'items-and-belongings';
        const ConsentsGlue: string = ':';
        const AddressAdditionalHidden: string = 'hide-additional';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
        const validators: SummaryValidators = new SummaryValidators();
        const cardDetailsMovableProperty: Ref<DynamicDictionary | null> = ref(null);

        let bankLinkId: Ref<number> = ref(0);

        const showMarketingConsents: Ref<boolean> = computed(() => {
            return !btaBase.user.current.receiveMarketing;
        });

        const isNonResident: Ref<boolean> = computed(() => {
            return !form.field('policy-holder-resident').value;
        });

        const birthDateRequired: Ref<boolean> = computed(() => {
            return isNonResident.value || policyHolderCodeIsAnonymous.value;
        });

        const policyHolderCodeIsAnonymous: Ref<boolean> = computed(() => {
            const holderCodeField: FormField = form.field('policy-holder-code');
            const holderCode: string = String(holderCodeField.value);

            return holderCodeField.isNotEmpty() && Validation.isValidPersonCodeWithoutDate(holderCode);
        });

        const policyStartDate: Ref<string> = computed(() => {
            const result: Date = moment(form.field('policy-start-date').value.startDate).toDate();

            return OneDate.short(result);
        });

        const policyEndDate: Ref<string> = computed(() => {
            const startDate: Date = form.field('policy-start-date').value.startDate;
            const result: Date = moment(startDate)
                .add(1, 'year')
                .subtract(1, 'day')
                .toDate();

            return OneDate.short(result);
        });

        const applyStepUrls = (next: string, previous: string): void => {
            SubmitterUrls.getInstance().applyStepUrls(next, previous);
        }

        function onBeforeFormRestore(): void {
            patchDefaultValues().then();
        }

        function onAppReady(): void {
            buildCoverageOptions();
            btaBase.dynamicStepper.applyEnabled(MovablePropertySteps.byProductId(selectedProductId()));
            applyFormValidationAndSanitization();
            patchDefaultValues().then((): void => {
                hideAddressAdditional();
            });
        }

        function setupForm(): void {
            form.addField(new FormField('policy-start-date'));
            form.addField(new FormField('policy-holder-resident'));
            form.addField(new FormField('policy-holder-name'));
            form.addField(new FormField('policy-holder-surname'));
            form.addField(new FormField('policy-holder-code'));
            form.addField(new FormField('policy-holder-country'));
            form.addField(new FormField('policy-holder-birth-date'));
            form.addField(new FormField('policy-holder-email'));
            form.addField(new FormField('policy-holder-phone'));
            form.addField(new FormField('policy-holder-address'));
            form.addField(new FormField('agree-terms'));
            form.addField(new FormField('marketing-consents'));
            form.addField(new FormField('card-details'));
            form.addField(new FormField('card-details-agree'));
            form.setReady();
            formIsReady.value = true;
        }

        function resetForm(): void {
            form.fields().forEach((field: FormField): void => {
                field.clearValidators();
                field.markAsUntouched();
            });
            if (!birthDateRequired.value) {
                form.field('policy-holder-birth-date').clear().then();
            }
        }

        function touchAndRequireNecessaryFields(): void {
            if (isAuthenticatedUser()) {
                touchAndRequireUserFields();
            } else {
                touchAndRequireGuestFields();
            }
        }

        function commonFields(): string[] {
            return [
                'policy-holder-name',
                'policy-holder-surname',
                'policy-holder-code',
                'policy-holder-email',
                'policy-holder-phone',
                'policy-holder-address',
                'agree-terms',
                'card-details',
                'card-details-agree',
            ];
        }

        function touchAndRequireUserFields(): void {
            commonFields().forEach((field: string): void => {
                form.field(field)
                    .addValidators('required')
                    .touch();
            })
        }

        function touchAndRequireGuestFields(): void {
            const guestFields: string[] = commonFields();
            if (isNonResident.value) {
                guestFields.push('policy-holder-country');
            }
            if (birthDateRequired.value) {
                guestFields.push('policy-holder-birth-date');
            }
            guestFields.forEach((field: string): void => {
                form.field(field)
                    .addValidators('required')
                    .touch();
            });
        }

        function isAuthenticatedUser(): boolean {
            return User.getInstance().isLogged();
        }

        function applyFormValidationAndSanitization(): void {
            form.field('policy-holder-name')
                .addValidators(validators.captionValidator('policy-holder-name'));
            form.field('policy-holder-surname')
                .addValidators(validators.captionValidator('policy-holder-surname'));
            form.field('policy-holder-code')
                .addValidators(validators.identityNumberValidator());
            form.field('policy-holder-name').addSanitizer(Sanitizer.cleanUppercaseName);
            form.field('policy-holder-surname').addSanitizer(Sanitizer.cleanUppercaseName);
            form.field('policy-holder-code').addSanitizer(Sanitizer.clearPersonCode);
            form.field('policy-holder-phone').addValidators('required');
            form.field('policy-holder-email').addValidators('required');
            form.field('policy-holder-address').addValidators('required');
            form.field('policy-holder-birth-date').addValidators('required');
            form.field('card-details').addValidators('required');
        }

        function patchDefaultValues(): Promise<void> {
            return new Promise(resolve => {
                const user: User = btaBase.user;
                form.field('policy-start-date').patch({startDate: minPolicyStartDate(), endDate: ''});
                form.field('policy-holder-resident').patch(true);
                if (user.isLogged()) {
                    form.field('policy-holder-name').patch(user.current.firstname);
                    form.field('policy-holder-surname').patch(user.current.lastname);
                    form.field('policy-holder-code').patch(user.current.personCode);
                    if (form.field('policy-holder-email').isEmpty()) {
                        form.field('policy-holder-email').patch(user.current.email);
                    }
                    if (form.field('policy-holder-phone').isEmpty()) {
                        form.field('policy-holder-phone').patch({
                            code: user.current.code,
                            iso: user.current.code,
                            country: user.current.phoneCode,
                            phone: user.current.phone,
                        });
                    }
                    if (form.field('policy-holder-address').isEmpty()) {
                        form.field('policy-holder-address').patch({
                            addressCode: user.current.addressCode,
                            label: user.current.address,
                            value: '',
                            additional: user.current.addressExtra,
                            postCode: user.current.postCode
                        });
                    }
                }
                resolve();
            });
        }

        function hideAddressAdditional(): void {
            const user: User = btaBase.user;
            const addressValue: DynamicDictionary = form.field('policy-holder-address').value;
            const hasPostCode: boolean = !!addressValue.postCode;
            if (user.isLogged() && hasPostCode) {
                $('#policy-holder-address').addClass(AddressAdditionalHidden);
            }
        }

        function onBackClick(): void {
            useNavigate().navigate(SubmitterUrls.getInstance().previousStep());
        }

        function onCoveredClick(): void {
            showCoveredPopup()
        }

        function validateCardDetails(): Promise<void> {
            return Object(cardDetailsMovableProperty.value).touchAndValidate().then((): void => {
                bankLinkId.value = RecurringPaymentBanklink.ByCountry[(new AppCountry()).iso()];
            });
        }

        function cardDetails(): string {
            const detailsFromForm: CardDetails = form.field('card-details').value;
            const details: DynamicDictionary = {
                holder: detailsFromForm.holderName,
                number: detailsFromForm.number,
                expiryYear: detailsFromForm.validThru.year,
                expiryMonth: detailsFromForm.validThru.month,
                cvc: detailsFromForm.cvc,
            };

            return JSON.stringify(details);
        }

        function productRecipient(): DynamicDictionary {
            return {
                firstName: form.field('policy-holder-name').value,
                lastName: form.field('policy-holder-surname').value,
                identityNumber: form.field('policy-holder-code').value,
                country: country(),
                birthDate: birthDate(),
                email: form.field('policy-holder-email').value,
                phone: form.field('policy-holder-phone').value,
                isResident: btaBase.user.isLogged() ? true : form.field('policy-holder-resident').value,
                marketingConsents: marketingConsents(),
                address: form.field('policy-holder-address').value,
            };
        }

        function country(): DynamicDictionary {
            const settingsInstance: SettingsService = SettingsService.getInstance();
            let source: DynamicDictionary = {
                ic: '100000000',
                iso: settingsInstance.localeIso(),
                phoneCode: settingsInstance.phoneCode(),
            };
            if (isNonResident.value) {
                source = form.field('policy-holder-country').value;
            }

            return source;
        }

        function policyDate(): string {
            const date: Date = moment(form.field('policy-start-date').value.startDate).toDate();

            return OneDate.iris(date);
        }

        function birthDate(): string {
            const user: User = btaBase.user;
            let result: string | Date = '';
            if (user.isLogged()) {
                result = user.current.birthDate;
            } else {
                if (birthDateRequired.value) {
                    result = moment(form.field('policy-holder-birth-date').value).toDate();
                } else {
                    let countryIso: string = form.field('policy-holder-country').value.iso;
                    if (new Value(countryIso).isEmpty()) {
                        countryIso = new AppCountry().iso();
                    }
                    result = new ExtractDataService()
                        .birthDateFromPersonCode(form.field('policy-holder-code').value, countryIso) ?? '';
                }
            }

            return OneDate.iris(result);
        }

        function marketingConsents(): string[] {
            const selectedConsents: string = form.field('marketing-consents').value.selected;

            return new Value(selectedConsents).isNotEmpty() ?
                selectedConsents.split(ConsentsGlue) : [];
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', stepsSubmitter.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitParam('cardDetails', cardDetails(), false);
            stepsSubmitter.addSubmitParam('productRecipient', productRecipient(), false);
            stepsSubmitter.addSubmitParam('policyStartDate', policyDate(), false);
            stepsSubmitter.addSubmitParam('bankLinkId', bankLinkId.value, false);
            stepsSubmitter.addSubmitParam('paymentFailRedirectUrl',
                SubmitterUrls.getInstance().currentStep(), false);
            stepsSubmitter.addAjaxResponseCallbackAfterStepsStorage(redirectToBank);
        }

        function redirectToBank(): void {
            useNavigate().navigate(Url.PaymentsApi.paymentsPay);
        }

        function onAddressEditClick(): void {
            $('#policy-holder-address').removeClass(AddressAdditionalHidden);
        }

        function onConfirmAndPayClick(): void {
            resetForm();
            touchAndRequireNecessaryFields();
            validateCardDetails().then((): void => {
                form.validate().then((): void => {
                    if (form.isValid()) {
                        if (bankLinkId.value !== 0) {
                            prepareSubmit();
                            stepsSubmitter.submitMethod(Method.Post);
                            stepsSubmitter.submitStep(Url.Ajax.movablePropertiesPurchase);
                        }
                    } else {
                        const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
                        if (invalidElements.length > 0) {
                            invalidElements[0].scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                                inline: 'center'
                            });
                        }
                    }
                });
            });
        }

        function selectedProductId(): string {
            return UserStorage.getInstance().stepStorageData.productId ?? '';
        }

        function buildCoverageOptions(): void {
            const productFromStorage: MovableProperty | undefined = selectedProduct();
            if (productFromStorage) {
                coveredPopup.withCoveredTitle('see_what_covered_single')
                    .withTranslationKey('items_and_belongings')
                    .withCoveredType(CoveredPopupTypes.CoveredPopupSingle)
                    .withCoveragePlanKey(UserStorage.getInstance().stepStorageData.coverageId)
                    .withSelectedRisks(UserStorage.getInstance().stepStorageData.selectedRisks)
                    .withExcludedRisks(btaBase.settings.movablePropertySettings().excludedRisks)
                    .withContent([productFromStorage])
                    .withMode('summary')
                    .build();
            }
        }

        function selectedProduct(): MovableProperty | undefined {
            const storage: UserStorage = UserStorage.getInstance();
            const storedProductId: string = selectedProductId();
            return (storage.storageData as MovableProperty[])
                .find((product: MovableProperty): boolean => product.id === storedProductId);
        }

        function showCoveredPopup(): void {
            PopupService.getInstance().show(new OnePopup().withType().oneCovered);
        }

        function minPolicyStartDate(): Date {
            const offset: number = btaBase.settings.movablePropertySettings().minPolicyStartDateOffset;

            return moment().add(offset, 'day').toDate();
        }

        function maxPolicyStartDate(): Date {
            const offset: number = btaBase.settings.movablePropertySettings().maxPolicyStartDateOffset;

            return moment(minPolicyStartDate()).add(offset, 'days').toDate();
        }

        function coveredButtonParams(): ButtonWithCallbackParams {
            return {
                title: translate('see_what_covered'),
                textColor: ButtonTextColor.Black,
                backgroundColorHover: ButtonBackground.White,
                backgroundColor: ButtonBackground.White,
                icon: ButtonIcon.Covered,
                iconColor: ButtonIconColor.Green,
                borderColor: ButtonBorder.Pale,
            };
        }

        function confirmAndPayButtonParams(): ButtonWithCallbackParams {
            return {
                title: translateForType('summary_confirm_and_pay', 'items_and_belongings'),
                textColor: ButtonTextColor.White,
                backgroundColor: ButtonBackground.Red,
            };
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                validators,
                formIsReady,
                onBackClick,
                applyStepUrls,
                setupForm,
                onBeforeFormRestore,
                onAppReady,
                coveredButtonParams,
                coveredPopup,
                onCoveredClick,
                showMarketingConsents,
                confirmAndPayButtonParams,
                onConfirmAndPayClick,
                minPolicyStartDate,
                maxPolicyStartDate,
                policyStartDate,
                policyEndDate,
                isNonResident,
                birthDateRequired,
                cardDetailsMovableProperty,
                birthDate,
                onAddressEditClick,
            }
        }
    }

    ,

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();
        this.validators.init(this.form);

        const onBeforeFormRestoredSubscription: Subscription =
            this.userStorage.onBeforeFormStorageDataIsRestored.subscribe((): void => {
                this.onBeforeFormRestore();
                onBeforeFormRestoredSubscription.unsubscribe();
            });

        const onAppIsPreparedAndReady: Subscription =
            this.onAppIsPreparedAndReady.subscribe((): void => {
                this.onAppReady();
                onAppIsPreparedAndReady.unsubscribe();
            });
    }
});
</script>
