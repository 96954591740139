import FormStorage from '@/interfaces/form.storage.interface';
import FormStorageElement from '@/interfaces/form.storage.element.interface';
import Url from '@/Enums/UrlEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import AppEnvironment from '@/assets/libraries/app/app-environment';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import UserStorage from '@/services/user.storage.service';
import {Ref, ref} from 'vue';
import {useFormatter} from '@/Composables/Formatter';

export default class SpaFormStorageService {
    private static instance: SpaFormStorageService;
    private dataProviderCallback?: () => DynamicDictionary;
    private sessionTimer: Worker = new Worker('js/timer.js?rev=rand()');
    private savingIntervalSeconds: number = 3;
    private userStorage: UserStorage = UserStorage.getInstance();
    private oneBaseService: OneBase = OneBaseService.getInstance();
    private cachedStorageData: Ref<string> = ref('');
    private storingLocked: boolean = false;

    public static getInstance(): SpaFormStorageService {
        if (!SpaFormStorageService.instance) {
            SpaFormStorageService.instance = new SpaFormStorageService();
            SpaFormStorageService.instance.init();
        }
        return SpaFormStorageService.instance;
    }

    private init(): void {
        if (!(new AppEnvironment()).isAcceptanceTest()) {
            $((): void => {
                window.addEventListener('beforeunload', (): void => {
                    this.saveFormStorage();
                })
                this.sessionTimer.onmessage = (event: MessageEvent) => {
                    if (event.data.seconds === this.savingIntervalSeconds) {
                        this.saveFormStorage();
                        this.sessionTimer.postMessage('reset');
                    }
                }
            });
        }
    }

    public applyDataProviderCallback(callback: () => DynamicDictionary): SpaFormStorageService {
        this.dataProviderCallback = callback;

        return this;
    }

    public formStorageFields<T>(): T {
        const json: string = !['', '""'].includes(this.userStorage.formStorageJson)
            ? this.userStorage.formStorageJson
            : '{"data": {"fields": []}}';

        return JSON.parse(json).data.fields.reduce(
            (transformed: DynamicDictionary, field: FormStorageElement) => {
                transformed[field.fieldName] = field.fieldValue;
                return transformed;
            }, {});
    }

    public isEmpty(): boolean {
        return this.userStorage.formStorageJson.length === 0;
    }

    public lockStorage(): void {
        this.storingLocked = true;
    }

    public unlockStorage(): void {
        this.storingLocked = false;
    }

    private saveFormStorage(): void {
        const cachedData: string = this.cachedStorageData.value;
        const token: string = $("meta[name=csrf-token]").attr("content") ?? '';
        const uid: string = this.userStorage.userStoreUid;
        // TODO fix after vue migration
        const facility: string = (this.oneBaseService.stepFacility as Ref).value || this.oneBaseService.stepFacility;
        const currentStep: string = this.oneBaseService.currentStep().toString();
        const headers: Object = {
            type: 'application/json',
        };
        const transformedStorage: FormStorage = this.transformedStorage();
        const body: string = JSON.stringify({
            '_token': token,
            'uid': uid,
            'facility': facility,
            'step': currentStep,
            'forms': [transformedStorage],
        });

        if (cachedData !== body && !this.storingLocked && transformedStorage.formElements.length > 0) {
            const blob: Blob = new Blob([body], headers);
            const {formattedUrl} = useFormatter();
            navigator.sendBeacon(formattedUrl(Url.Ajax.formStore), blob);
            this.cachedStorageData.value = body;
        }
    }

    private transformedStorage(): FormStorage {
        const data: DynamicDictionary = this.dataProviderCallback
            ? this.dataProviderCallback()
            : {};
        const fields: FormStorageElement[] = Object.keys(data).map((fieldName: string): FormStorageElement => {
            return {
                fieldName: fieldName,
                fieldValue: data[fieldName],
            };
        });

        return {
            formName: this.userStorage.formUid,
            formElements: fields,
        }
    }
}
