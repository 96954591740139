<script setup lang="ts">

import {computed, onMounted, ref, Ref} from 'vue';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Services/TwoWayCommunicationService';
import {useTranslate} from '@/Composables/Translate';
import Translations from '@/services/translations.service';
import OneBaseService from '@/services/OneBaseService';


const twoWayCommunicationService: TwoWayCommunication = TwoWayCommunication.getInstance();

const {translate} = useTranslate();


const communicationTitle: Ref<string> = ref('');


const communicationUrl: Ref<string> = computed(() => {
    return twoWayCommunicationService.communicationsUrl();
});


onMounted((): void => {
    Translations.getInstance().onExternalDataIsReady.subscribe(() => {
        communicationTitle.value = translate('communication');
    });
});
</script>

<template>
    <a class="communication-notificator"
       :href="communicationUrl"
       v-if="OneBaseService.getInstance().user.isLogged()">
        <div class="icon">
            <img class="image" src="images/one/communications/mail.svg" width="20" height="16" alt="mail icon">
            <div class="count-circle"
                 v-if="twoWayCommunicationService.unreadCommunicationsCount()"
            >{{ twoWayCommunicationService.unreadCommunicationsCount() }}
            </div>
        </div>
        <div class="title">{{ communicationTitle }}</div>
    </a>
</template>

<style lang="scss" scoped>
.communication-notificator {
    display: flex;
    align-items: center;
    gap: var(--size-nano);
    padding-right: 22px;
    cursor: pointer;
    border-right: 1px solid var(--component-color-border-default);

    @include respond-below('lg') {
        border-right: none;
    }

    .icon {
        position: relative;
        width: 20px;
        height: 16px;

        .image {
            position: absolute;
        }

        .count-circle {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            right: -8px;
            top: -9px;
            border-radius: 50%;
            font-size: var(--font-size-femto);
            color: var(--text-color-attention);
            background-color: var(--pink-non-transparent);
            border: 1px solid var(--red-100);
        }
    }

    .title {
        font-size: var(--font-size-tiny);
        font-weight: 600;
        @include respond-below('lg') {
            display: none;
        }
    }
}
</style>