<script lang="ts">
import {computed, defineComponent, ref, Ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import AgreementsList from '@/pages/OneDashboard/Insurances/Interfaces/AgreementsListInterface';
import InsurancesContentTab from '@/pages/OneDashboard/Insurances/Enums/InsurancesContentTabEnum';
import {InputOption} from '@/interfaces/InputOptionInterface';
import Invoice from '@/Components/PolicyBlock/Interfaces/InvoiceInterface';
import {Subscription} from 'rxjs';
import {OneDashboardInterface, useOneDashboard} from '@/pages/OneDashboard/Composables/OneDashboard';
import PaymentsContentTab from '@/pages/OneDashboard/Payments/Enums/PaymentsContentTabEnum';
import Agreement from '@/Components/PolicyBlock/Interfaces/AgreementInterface';
import {useTranslate} from '@/Composables/Translate';
import RenewPolicy from '@/pages/OneDashboard/Insurances/Services/RenewPolicyService';
import Url from '@/Enums/UrlEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import PopupBase from '@/assets/libraries/popups/popup.base';
import OnePopup from '@/assets/libraries/popups/one.popup';
import {useDefine} from '@/Composables/Define';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import PopupService from '@/services/custom.popup.service';
import AppCountry from '@/assets/libraries/app/app-country';
import PopupType from '@/Enums/PopupTypeEnum';
import {AxiosResponse} from 'axios';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import FormField from '@/assets/libraries/form/form-field';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import AjaxAgreement from '@/Components/PolicyBlock/Interfaces/AjaxAgreementInterface';
import {AjaxAgreementBuilder} from '@/Components/PolicyBlock/Builders/AjaxAgreementBuilder';
import PolicyDetailsPopup from '@/pages/OneDashboard/Insurances/PolicyDetailsPopup';
import Translations from '@/services/translations.service';
import {HealthCard} from '@/interfaces/resources/health.card.interface';
import InsuredObject from '@/Components/PolicyBlock/Interfaces/InsuredObjectInterface';
import PolicyRiskChunk from '@/interfaces/one_policy/PolicyRiskChunk';
import Adjustment from '@/Components/PolicyBlock/Interfaces/AdjustmentInterface';
import AdjustmentDocumentBlock from '@/interfaces/one_policy/adjustment.document.block.interface';
import RecurringPaymentDetails from '@/interfaces/recurring.payment.details.interface';
import Method from '@/Enums/MethodEnum';
import StringDictionary from '@/interfaces/string.dictionary.interface';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Services/TwoWayCommunicationService';
import TwoWayModal from '@/Apps/TwoWayCommunication/Services/TwoWayModalService';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();


        const {isSet} = useDefine();
        const oneDashboard: OneDashboardInterface = useOneDashboard();
        const request: AxiosParams = useAxios();
        const {translateForType, applyTranslationType, language} = useTranslate();


        const CurrentStep: number = 1;
        const Facility: string = 'dashboard';


        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const insurances: Ref<AgreementsList> = ref({
            [InsurancesContentTab.Active]: [],
            [InsurancesContentTab.Inactive]: [],
            [InsurancesContentTab.Transport]: [],
            [InsurancesContentTab.Property]: [],
            [InsurancesContentTab.Health]: [],
            [InsurancesContentTab.Accident]: [],
            [InsurancesContentTab.Travel]: [],
            [InsurancesContentTab.Other]: [],
            [InsurancesContentTab.SpecialOffers]: [],
        });
        const inputOptionsContentTabs: Ref<InputOption[]> = ref([]);
        const invoices: Ref<Invoice[]> = ref([]);
        const agreementsFetched: Ref<boolean> = ref(false);


        const insurancesContentTab: Ref<typeof InsurancesContentTab> = computed(() => {
            return InsurancesContentTab;
        });

        const showRadioTabs: Ref<boolean> = computed(() => {
            return agreementsFetched.value && inputOptionsTabs.value
                .filter((tab: InputOption): boolean => !tab.hidden).length !== 0;
        });

        const showNoAgreementsPlaceholder: Ref<boolean> = computed(() => {
            return agreementsFetched.value && !hasActiveInsurancesOrOffers.value;
        });

        const inputOptionsTabs: Ref<InputOption[]> = computed(() => {
            return inputOptionsContentTabs.value;
        });

        const hasSpecialOffers: Ref<boolean> = computed(() => {
            return insurances.value[InsurancesContentTab.SpecialOffers].length > 0 &&
                insuranceBlockFilteredOffers.value.length > 0;
        });

        const hasActiveOrUpcomingHealthInsurance: Ref<boolean> = computed(() => {
            return insurances.value[InsurancesContentTab.Health]
                .filter((agreement: Agreement): boolean => agreement.isActive || agreement.isUpcoming)
                .length > 0;
        });

        const insuranceBlockFilteredOffers: Ref<Agreement[]> = computed(() => {
            const result: Agreement[] = [];
            insuranceBlock(InsurancesContentTab.SpecialOffers).forEach((agreement: Agreement) => {
                if (isNotPaymentToBroker(agreement) && isPolicyHolder(agreement)) {
                    result.push(agreement);
                }
            });

            return result;
        });

        const hasActiveInsurances: Ref<boolean> = computed(() => {
            return insurances.value[InsurancesContentTab.Active].length > 0;
        });

        const hasActiveInsurancesOrOffers: Ref<boolean> = computed(() => {
            return insurances.value[InsurancesContentTab.Active].length > 0 ||
                hasSpecialOffers.value;
        });

        const noPoliciesAtAll: Ref<boolean> = computed(() => {
            let result: boolean = true;
            const types: string[] = [
                InsurancesContentTab.Active,
                InsurancesContentTab.Inactive,
                InsurancesContentTab.Transport,
                InsurancesContentTab.Property,
                InsurancesContentTab.Health,
                InsurancesContentTab.Accident,
                InsurancesContentTab.Travel,
                InsurancesContentTab.Other,
            ];
            types.forEach((key: string): void => {
                if (insurances.value[key].length > 0) {
                    result = false;
                }
            });

            return result;
        });

        const currentTab: Ref<PaymentsContentTab> = computed(() => {
            return form.field('input-radio-tabs').value;
        });


        const init = (): void => {
            applyTranslationType('dashboard');
            RenewPolicy.getInstance().applyLanguage(language());
            btaBase.axiosFetch(Url.Ajax.oneDashboardInsurances, {}, Method.Get, true)
                .then((response: DynamicDictionary): void => {
                    if (response.data && response.data.errors) {
                        const simpleErrorPopup: PopupBase = new OnePopup()
                            .withType()
                            .simpleError;
                        PopupService.getInstance().show(simpleErrorPopup);
                    } else {
                        buildInsurancesTabsContent(response).then((): void => {
                            buildTabs();
                            setupForm();
                            agreementsFetched.value = true;
                            if (needToShowHealthConsent()) {
                                showHealthConsent();
                            }
                        });
                    }
                });
        }

        const isVisibleContentTab = (contentTab: string): boolean => {
            return currentTab.value === contentTab;
        }

        const translated = (stringId: string): string => {
            return translateForType(stringId, 'dashboard');
        }

        const nothingToShow = (tab: InsurancesContentTab): string => {
            let stringId: string = String('no_' + tab + '_policies_to_show').toLowerCase();
            if (!useTranslate().hasLocalization(stringId, useTranslate().type())) {
                stringId = 'no_policies_to_show';
            }

            return translated(stringId);
        }

        const onViewPolicyClick = (agreement: Agreement): void => {
            fetchPolicyAdditionalDetails(agreement);
        }

        const onViewOfferClick = (agreement: Agreement): void => {
            fetchPolicyAdditionalDetails(agreement);
        }

        const onMakePaymentClick = (agreement: Agreement): void => {
            fetchPolicyAdditionalDetails(agreement);
        }

        const onRenewPolicyClick = (agreement: Agreement): void => {
            RenewPolicy.getInstance().onRenewPolicyClick(agreement);
        }

        const isPolicyHolder = (agreement: Agreement): boolean => {
            return isSet(agreement.holder) && agreement.holder!.id === btaBase.user.current.personId;
        }

        const isPolicyInsuredPerson = (agreement: Agreement): boolean => {
            return isSet(agreement.insured) && agreement.insured!.id === btaBase.currentUser.value.personId;
        }

        const isNotPaymentToBroker = (agreement: Agreement): boolean => {
            return isSet(agreement.payment.toBroker) && !agreement.payment.toBroker;
        }

        const insuranceBlock = (type: string): Agreement[] => {
            return insurances.value[type];
        }

        const insuranceBlockLength = (type: string): number => {
            let length: number = 0;
            if (insurances.value[type]) {
                if (insurances.value[type] && Array.isArray(insurances.value[type])) {
                    length = insurances.value[type].length;
                } else {
                    length = Object.keys(insurances.value[type]).length;
                }
            }

            return length;
        }

        const insuranceTabIsHidden = (type: string): boolean => {
            let result: boolean;
            if (type === InsurancesContentTab.Active) {
                result = insuranceBlock(InsurancesContentTab.Active).length === 0 &&
                    insuranceBlock(InsurancesContentTab.SpecialOffers).length === 0;
            } else {
                result = insuranceBlock(type).length === 0;
            }

            return result;
        }

        const needToShowHealthConsent = (): boolean => {
            return new AppCountry().isLT() &&
                hasActiveOrUpcomingHealthInsurance.value &&
                !btaBase.user.userAgreedPersonalProcessing();
        }

        const showHealthConsent = (): void => {
            btaBase.popup.applyCallbackModel(healthAgreeCallback)
                .showPopup(PopupType.HealthPolicyAgree);
        }

        const healthAgreeCallback = (): void => {
            const params: DynamicDictionary = {};
            const headers: DynamicDictionary = {};
            btaBase.lockInput(true);
            PopupService.getInstance().show(new OnePopup().withType().loading);
            request.post(Url.Ajax.agreeToHealthTerms, params, {
                    headers: headers
                }
            ).then((value: AxiosResponse): void => {
                const data: DynamicDictionary = value.data.data.consent;
                if (data.error === false) {
                    btaBase.user.current.agreedPersonalProcessing = data.consent.agreedPersonalProcessing;
                    btaBase.user.current.personalProcessingExpirationDate = data.consent.personalProcessingExpirationDate;
                }
                btaBase.lockInput(false);
                PopupService.getInstance().hide();
            }).catch((reason: DynamicDictionary): void => {
                btaBase.lockInput(false);
                PopupService.getInstance().hide();
                btaBase.error.show(ErrorType.Error, 'healthConsent', reason);
            });
        }

        const buildTabs = (): void => {
            const tabs: InsurancesContentTab[] = [
                InsurancesContentTab.Active,
                InsurancesContentTab.Inactive,
                InsurancesContentTab.Transport,
                InsurancesContentTab.Property,
                InsurancesContentTab.Health,
                InsurancesContentTab.Accident,
                InsurancesContentTab.Travel,
                InsurancesContentTab.Other,
            ];
            tabs.forEach((tab: InsurancesContentTab): void => {
                inputOptionsContentTabs.value.push(
                    (new InputOptionBuilder())
                        .setName(translated('one_dashboard_agreement_tabs_' + tab))
                        .setValue(tab as string)
                        .setHidden(insuranceTabIsHidden(tab as string))
                        .build()
                );
            });
        }

        const buildInsurancesTabsContent = (response: DynamicDictionary): Promise<void> => {
            return new Promise(resolve => {
                insurances.value[InsurancesContentTab.SpecialOffers] = response.special;
                insurances.value[InsurancesContentTab.Active] = response.active;
                insurances.value[InsurancesContentTab.Inactive] = response.terminated;
                insurances.value[InsurancesContentTab.Transport] = response.transport;
                insurances.value[InsurancesContentTab.Property] = response.property;
                insurances.value[InsurancesContentTab.Health] = response.health;
                insurances.value[InsurancesContentTab.Accident] = response.accident;
                insurances.value[InsurancesContentTab.Travel] = response.travel;
                insurances.value[InsurancesContentTab.Other] = response.other;
                resolve();
            });
        }

        const setupForm = (): void => {
            form.addField(new FormField('input-radio-tabs', defaultTab()));
            form.setReady();
            formIsReady.value = true;
        }

        const defaultTab = (): LimitedVariant => {
            let result: LimitedVariant = '';
            const defaultValue: InputOption | undefined = inputOptionsContentTabs.value
                .find((tab: InputOption): boolean => !tab.hidden);
            if (defaultValue) {
                result = defaultValue.value;
            } else {
                if (hasSpecialOffers.value) {
                    result = InsurancesContentTab.Active;
                }
            }

            return result;
        }

        const ajaxAgreement = (value: Agreement): AjaxAgreement => {
            return new AjaxAgreementBuilder().fromAgreement(value).build();
        }

        const fetchPolicyAdditionalDetails = (agreement: Agreement): void => {
            btaBase.axiosFetch(Url.Ajax.oneDashboardPolicyDetails,
                ajaxAgreement(agreement),
                Method.Get,
                true
            ).then((response: DynamicDictionary): void => {
                invoices.value = response.invoices as Invoice[];
                new PolicyDetailsPopup(agreement, Translations.getInstance().type, btaBase.user.current)
                    .applyInvoices(invoices.value ?? [])
                    .applyHealthCards(response.healthCards as HealthCard[])
                    .applyPrintouts(response.printouts as DynamicDictionary[])
                    .applyUpcomingPrintouts(response.upcomingPrintouts as DynamicDictionary[])
                    .applyTerms(response.terms as DynamicDictionary[])
                    .applyObjects(response.objects as InsuredObject[] ?? [])
                    .applySubscriptionHistory(agreement.subscriptionHistory as Agreement[] ?? [])
                    .applyUpcomingAgreements(agreement.upcomingAgreements as Agreement[] ?? [])
                    .applyRisks(response.insuredRisks as PolicyRiskChunk[] ?? [])
                    .applyAnnexes(response.annexes as Adjustment[])
                    .applyHistory(response.history as AdjustmentDocumentBlock[])
                    .applyRecurringPayment(
                        response.recurringPayment as RecurringPaymentDetails ?? null
                    )
                    .showDetails();
            });
        }

        const applyRenewNowUrls = (urls: StringDictionary): void => {
            RenewPolicy.getInstance().applyRenewUrls(urls);
        }

        const showCommunicationsModal = (): void => {
            TwoWayModal.getInstance()
                .applyProceedActionCallback(init)
                .show();
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                insurances,
                inputOptionsContentTabs,
                invoices,
                agreementsFetched,
                insurancesContentTab,
                showRadioTabs,
                showNoAgreementsPlaceholder,
                currentTab,
                inputOptionsTabs,
                hasSpecialOffers,
                hasActiveOrUpcomingHealthInsurance,
                insuranceBlockFilteredOffers,
                hasActiveInsurances,
                hasActiveInsurancesOrOffers,
                noPoliciesAtAll,
                oneDashboard,
                init,
                insuranceTabIsHidden,
                insuranceBlockLength,
                setupForm,
                isVisibleContentTab,
                translated,
                nothingToShow,
                onViewPolicyClick,
                onViewOfferClick,
                onMakePaymentClick,
                onRenewPolicyClick,
                insuranceBlock,
                isNotPaymentToBroker,
                isPolicyInsuredPerson,
                isPolicyHolder,
                applyRenewNowUrls,
                defaultTab,
                showCommunicationsModal,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.oneDashboard.buildNavigationBelt();
        if (TwoWayCommunication.getInstance().unreadCommunicationsCount() > 0) {
            this.showCommunicationsModal();
        } else {
            const subscription: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
                this.init();
                subscription.unsubscribe();
            });
        }
    }
});
</script>
