<script setup lang="ts">
import {computed, getCurrentInstance, onMounted, reactive, ref, Ref, UnwrapNestedRefs} from 'vue';
import PayByLinkService from '@/Apps/PayByLink/Pay/Services/PayByLinkService';
import Form from '@/assets/libraries/form/form';
import FormField from '@/assets/libraries/form/form-field';
import { useTranslate } from '@/Composables/Translate';
import { MtplOffer } from '@/interfaces/resources/Offers/MtplOfferInterface';
import InsurancePlan from '@/Components/InsurancePlanWidget/Interfaces/InsurancePlanInterface';
import InsurancePlanItem from '@/Components/InsurancePlanWidget/Interfaces/InsurancePlanItemInterface';
import { InputOption } from '@/interfaces/InputOptionInterface';
import AdditionalOption from '@/Components/AdditionalOptionsList/Interfaces/MtplOfferOptionInterface';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Value from '@/assets/libraries/form/value';
import { CoverageRisk } from '@/interfaces/resources/MovableProperties/CoverageRiskInterface';
import MtplOfferOptionBuilder from '@/Components/AdditionalOptionsList/Builders/MtplOfferOptionBuilder';
import InsurancePlanItemBuilder from '@/Components/InsurancePlanWidget/Builders/InsurancePlanItemBuilder';
import InsurancePlanBuilder from '@/Components/InsurancePlanWidget/Builders/InsurancePlanBuilder';
import { useNumbers } from '@/Composables/Numbers';
import { useDefine } from '@/Composables/Define';
import AgreementPeriodMap from '@/Apps/PayByLink/Classes/AgreementPeriodMap';
import AdditionalOptionsMap from '@/Apps/PayByLink/Classes/AdditionalOptionsMap';
import {Router, useRouter} from 'vue-router';
import OfferRisk from '@/Apps/PayByLink/Pay/Interfaces/OfferRiskInterface';
import RequestService from '@/services/request.service';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import { useScroll } from '@/Composables/Scroll';
import EventBus from '@/services/event.bus.service';
import PlaceholderOption from '@/Components/AdditionalOptionsList/Interfaces/PlaceholderOption';
import PlaceholderOptionBuilder from '@/Components/AdditionalOptionsList/Builders/PlaceholderOptionBuilder';
import AppCountry from '@/assets/libraries/app/app-country';
import AppCustomForm from '@/Components/CustomForm/CustomForm.vue';
import AppInputRadioOverlayed from '@/Components/InputRadioOverlayed/InputRadioOverlayed.vue';
import AppAdditionalOptionsList from '@/Components/AdditionalOptionsList/AdditionalOptionsList.vue';
import AppInsurancePlanWidget from '@/Components/InsurancePlanWidget/InsurancePlanWidget.vue';
import OneBaseService from '@/services/OneBaseService';

const {translate, translateForType} = useTranslate();

const router: Router = useRouter();
const payByLinkService: PayByLinkService = PayByLinkService.getInstance();
const form: Form = new Form();
const TranslationType: string = 'mtpl_renewal';

let offer: Ref<MtplOffer> = ref({} as MtplOffer);
const insurancePlan: UnwrapNestedRefs<InsurancePlan> = reactive({
    title: '',
    priceTitle: '',
    price: 0,
    discount: 0,
    items: [],
    paymentFrequency: '',
});
const insurancePlanPrice: Ref<number> = ref(0);
const insurancePlanItems: Ref<InsurancePlanItem[]> = ref([]);
const offerPeriodOptions: Ref<InputOption[]> = ref([]);
const offerOptions: Ref<AdditionalOption[]> = ref([]);
const additionalOptionsByPeriodIc: Ref<AdditionalOption[]> = computed((): AdditionalOption[] => {
    return offerOptions.value.filter((option: AdditionalOption): boolean =>
        option.periodIc === form.field('offer-period').value);
});
const optionsByPeriodIc: Ref<(PlaceholderOption|AdditionalOption)[]> = computed((): (PlaceholderOption|AdditionalOption)[] => {
    return [
        ...(includePlaceholderOption() ? [placeholderOption()] : []),
        ...additionalOptionsByPeriodIc.value,
    ]
});
const offerPremium: Ref<number> = computed((): number => {
    const currentPeriodIc: string = form.field('offer-period').value;

    return offer.value.prices[currentPeriodIc]?.premium ?? 0;
});
const viewIsReady: Ref<boolean> = ref(false);

onMounted(() => {
    setupForm();
    restoreValues();
    onAppReady();
});

function setupForm(): void {
    form.addField(new FormField('offer-period'));
    form.addField(new FormField('offer-options'));
    form.setReady();
}

function onAppReady(): void {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    PopupService.getInstance().show(new OnePopup().withType().loading);
    fetchOffer().then((): void => {
        patchDefaultOfferPeriod();
        buildOfferPeriodOptions();
        buildOfferOptions();
        buildInsurancePlanItems();
        calculatePrice();
        buildInsurancePlan();
        patchAllOptions();
        useScroll().scrollToTop();
        PopupService.getInstance().hide();
    }).catch((): void => {
        onFetchOfferFailed();
    });
}

function restoreValues(): void {
    const storedValues: DynamicDictionary = payByLinkService.fields;
    const fieldsMap: DynamicDictionary = {
        offerPeriod: 'offer-period',
        offerOptions: 'offer-options',
    };

    Object.keys(fieldsMap).forEach((key: string): void => {
        if (useDefine().isSet(storedValues[key]) && new Value(storedValues[key]).isNotEmpty()) {
            form.field(fieldsMap[key]).setValue(storedValues[key]);
        }
    });
}

function storeValues(): void {
    const existingValues: DynamicDictionary = JSON.parse(JSON.stringify(payByLinkService.fields));
    Object.assign(payByLinkService.fields, {
        ...existingValues,
        offerPeriod: form.field('offer-period').value,
        offerOptions: form.field('offer-options').value,
    });
}

async function fetchOffer(): Promise<void> {
    return RequestService.getInstance().get({
        uri: Url.Ajax.mtplRenewalFetchOffer,
        content: {
            hash: payByLinkService.payByLink.value.id,
        },
    }).then((response: AxiosResponse): void => {
        if (useDefine().validResponse(response)) {
            TransferStateService.getInstance().set('mtplOffer', response.data.data.body.offer);
            payByLinkService.init();
            Object.assign(offer.value, payByLinkService.mtplOffer.value);
            viewIsReady.value = true;
        } else {
            throw response;
        }
    })
}

function patchDefaultOfferPeriod(): void {
    if (form.field('offer-period').isEmpty()) {
        form.field('offer-period').setValue(payByLinkService.payByLink.value.periodIc);
    }
}

function patchAllOptions(): void {
    let tempValue: DynamicDictionary = JSON.parse(JSON.stringify(form.field('offer-options').value));
    offerPeriodOptions.value.forEach((period: InputOption): void => {
        let currentOptions: DynamicDictionary | undefined = tempValue[period.value as string];
        if (!currentOptions) {
            offerOptions.value
                .filter((option: AdditionalOption): boolean => option.periodIc === period.value)
                .forEach((option: AdditionalOption): void => {
                    const currentState: DynamicDictionary = {[option.risk.id]: option.enabled.value};
                    if (new Value(tempValue[option.periodIc]).isNotEmpty()) {
                        tempValue[option.periodIc][option.risk.id] = option.enabled.value;
                    } else {
                        if (new Value(tempValue).isNotEmpty()) {
                            tempValue[option.periodIc] = currentState;
                        } else {
                            tempValue = {[option.periodIc]: currentState}
                        }
                    }
                });
        }
    });
    form.field('offer-options').patch(tempValue);
    storeValues();
}

function buildOfferPeriodOptions(): void {
    priceKeys().forEach((periodIc: string): void => {
        offerPeriodOptions.value.push(
            new InputOptionBuilder()
                .setValue(periodIc)
                .setName(String(new AgreementPeriodMap().monthsByIc(periodIc)))
                .build()
        );
    });
}

function priceKeys(): string[] {
    const priceKeys: string[] = Object.keys(offer.value.prices);
    const oneYearIndex: number = priceKeys.indexOf(AgreementPeriodMap.OneYear);
    if (oneYearIndex >= 0) {
        priceKeys.splice(oneYearIndex, 1);
        priceKeys.sort();
        priceKeys.push(AgreementPeriodMap.OneYear);
    } else {
        priceKeys.sort();
    }

    return priceKeys;
}

function buildOfferOptions(): void {
    Object.keys(offer.value.prices).forEach((periodIc: string): void => {
        Object.keys(offer.value.prices[periodIc]).forEach((priceKey: string): void => {
            if (priceKey !== 'premium') {
                const riskKey: string = priceKey.replace('Premium', '');
                const riskIc: string = new AdditionalOptionsMap().icByKey(riskKey);
                const storedOptions: DynamicDictionary = form.field('offer-options').value;
                const offerRisk: OfferRisk | undefined = payByLinkService.payByLink.value.offerRisks
                    .find((risk: OfferRisk): boolean => risk.ic === riskIc);
                let optionState: boolean = periodIc === payByLinkService.payByLink.value.periodIc
                    ? !!offerRisk?.included : false;
                if (storedOptions[periodIc]) {
                    optionState = new Value(storedOptions[periodIc][riskIc])
                        .isEmpty() ? false
                        : storedOptions[periodIc][riskIc];
                }
                const coveredRisk: CoverageRisk = new class implements CoverageRisk {
                    id: string = riskIc;
                    insuredSum: number = 0;
                    isAdditional: boolean = true;
                    isFeatured: boolean = false;
                    price: number = offer.value.prices[periodIc][priceKey];
                    withoutInsuredSum: boolean = true;
                };
                if (coveredRisk.price > 0 && offerRisk?.canInclude) {
                    offerOptions.value.push(
                        new MtplOfferOptionBuilder()
                            .withName(translateForType(riskIc, TranslationType))
                            .withPeriodIc(periodIc)
                            .withCoverageRisk(coveredRisk)
                            .withState(optionState)
                            .build()
                    );
                }
            }
        });
    });
}

function buildInsurancePlanItems(): void {
    insurancePlanItems.value = [];
    addSelectedProductToItems();
    enabledOptionsForPeriod().forEach((option: AdditionalOption): void => {
        insurancePlanItems.value.push(
            new InsurancePlanItemBuilder()
                .withTitle(option.name)
                .withPrice(option.risk.price)
                .build()
        );
    });
}

function addSelectedProductToItems(): void {
    const offerPeriodLabel: string = [
        new AgreementPeriodMap()
            .monthsByIc(form.field('offer-period').value),
        new AgreementPeriodMap()
            .monthsLabelByIc(form.field('offer-period').value),
    ].join(' ');
    const insurancePlanTitle: string = [
        translateForType('mtpl_insurance', TranslationType),
        offerPeriodLabel,
    ].join(', ');
    insurancePlanItems.value.push(
        new InsurancePlanItemBuilder()
            .withTitle(insurancePlanTitle)
            .withPrice(offerPremium.value)
            .build()
    );
}

function buildInsurancePlan(): void {
    Object.assign(
        insurancePlan,
        new InsurancePlanBuilder()
            .withTitle(translateForType('insurance_widget_title', TranslationType))
            .withPrice(insurancePlanPrice.value)
            .withPriceTitle(translateForType('insurance_widget_price_title', TranslationType))
            .withItems(insurancePlanItems.value)
            .withPaymentFrequency('&nbsp;&euro;')
            .build()
    )
}

function calculatePrice(): void {
    const prices: number[] = [];
    prices.push(offerPremium.value);
    enabledOptionsForPeriod().forEach((option: AdditionalOption): void => {
        prices.push(option.risk.price);
    });
    insurancePlanPrice.value = useNumbers().arraySum(prices);
}

function enabledOptionsForPeriod(): AdditionalOption[] {
    return additionalOptionsByPeriodIc.value
        .filter((option: AdditionalOption): boolean => option.enabled.value);
}

function refreshAdditionalOptions(optionEmit: DynamicDictionary, stashValues: boolean = false): void {
    Object.keys(optionEmit).forEach((key: string): void => {
        const currentPeriodIc: string = form.field('offer-period').value;
        const tempValue: DynamicDictionary = form.field('offer-options').value;
        if (new Value(tempValue[currentPeriodIc]).isNotEmpty()) {
            tempValue[currentPeriodIc][key] = optionEmit[key];
            form.field('offer-options').patch(tempValue);
        } else {
            form.field('offer-options').patch({
                ...tempValue,
                [currentPeriodIc]: optionEmit,
            });
        }
    });
    calculatePrice();
    buildInsurancePlanItems();
    buildInsurancePlan();
    if (stashValues) {
        storeValues();
    }
}

function includePlaceholderOption(): boolean {
    return roadAssistanceIsEnabledForPeriod(AgreementPeriodMap.OneYear)
        && form.field('offer-period').value !== AgreementPeriodMap.OneYear
        && (new AppCountry()).isEE();
}

function roadAssistanceIsEnabledForPeriod(periodIc: string): boolean {
    const roadAssistanceIc: string = (new AdditionalOptionsMap).icByKey('roadAssistance');

    return offerOptions.value
        .filter((option: AdditionalOption): boolean => option.periodIc === periodIc && option.risk.id === roadAssistanceIc)
        .length !== 0;
}

function placeholderOption(): PlaceholderOption {
    return (new PlaceholderOptionBuilder())
        .withText(translateForType('road_assistance_available_at_twelve_months', TranslationType))
        .withButtonText(translateForType('switch_period_to_twelve_months', TranslationType))
        .build();
}

function onAdditionalOptionToggle(optionEmit: DynamicDictionary): void {
    refreshAdditionalOptions(optionEmit, true);
}

function onAdditionalOptionMounted(optionEmit: DynamicDictionary): void {
    refreshAdditionalOptions(optionEmit, false);
}

function onPlaceholderOptionClick(): void {
    form.field('offer-period').setValue(AgreementPeriodMap.OneYear);
}

function onInsuranceWidgetContinue(): void {
    storeValues();
    router.push({name: 'pay-by-link-pay-summary'});
}

function onOfferPeriodChange(): void {
    calculatePrice();
    buildInsurancePlanItems();
    buildInsurancePlan();
    storeValues();
}

function onFetchOfferFailed(): void {
    payByLinkService.hasCalculationError.value = true;
    router.push({name: 'pay-by-link-pay'});
    EventBus.getInstance().emit('offer-failed', {});
}

</script>

<template>
    <div class="step-container" v-show="viewIsReady">
        <app-custom-form v-if="form.isReady()" :form="form" class="form">
            <a class="to-previous-step" @click="router.back()">
                <img src="images/one/arrow-left.svg" alt="back">
                <span>{{ translate('back_button') }}</span>
            </a>
            <section class="header">
                <h2 class="title">{{ translateForType('edit_offer_view_title', TranslationType) }}</h2>
                <div class="description">{{ translateForType('edit_offer_view_description', TranslationType) }}</div>
            </section>
            <section class="offer-options">
                <app-input-radio-overlayed
                    class="period-options"
                    :form-field="form.field('offer-period')"
                    :options="offerPeriodOptions"
                    @change="onOfferPeriodChange"></app-input-radio-overlayed>
                <div class="widgets-container">
                    <div class="left-side" v-if="optionsByPeriodIc.length > 0">
                        <app-additional-options-list
                            :form-field="form.field('offer-options')"
                            :options="optionsByPeriodIc"
                            @option-click="onPlaceholderOptionClick"
                            @option-mounted="onAdditionalOptionMounted($event)"
                            @option-toggle="onAdditionalOptionToggle($event)">
                        </app-additional-options-list>
                    </div>
                    <div class="right-side">
                        <div class="right-side">
                            <app-insurance-plan-widget
                                :insurance-plan="insurancePlan"
                                @insurance-widget-continue="onInsuranceWidgetContinue">
                            </app-insurance-plan-widget>
                        </div>
                    </div>
                </div>
            </section>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.step-container {
    margin-bottom: var(--size-big);
    margin-top: calc(56px + var(--size-big));
    padding-left: var(--size-small);
    padding-right: var(--size-small);

    .header {
        padding: 0 var(--size-small);

        @include respond-above('sm') {
            padding: 0 var(--size-big);
        }

        .title,
        .description {
            text-align: center;
        }

        .title {
            font-size: var(--font-size-big);
        }

        .description {
            margin-top: var(--size-tiny);
            color: var(--text-color-subtlest);
            font-size: var(--font-size-tiny);
        }
    }

    .offer-options {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: var(--size-small);
        gap: var(--size-small);

        @include respond-above('sm') {
            gap: var(--size-big);
        }

        .period-options {
            width: 100%;
            justify-self: center;

            @include respond-above('sm') {
                width: 400px;
            }
        }

        .widgets-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: var(--size-normal);

            .left-side {
                display: flex;
                flex-direction: column;
                gap: var(--size-small);
            }

            .policy-plan {
                padding: var(--size-small);
                border: 2px solid var(--component-color-border-active);
                background: linear-gradient(
                        0deg,
                        var(--system-color-success-light) 0%,
                        var(--system-color-success-light) 100%
                ),
                var(--white);
                border-radius: 8px;

                .plan-title {
                    display: flex;
                    justify-content: space-between;
                    font-weight: bold;
                    font-size: var(--font-size-small);

                    .price-text {
                        font-size: var(--font-size-nano);
                        font-weight: initial;
                    }
                }

                .coverage {
                    font-size: var(--font-size-nano);
                    color: var(--text-color-link);
                    margin-top: var(--size-nano);
                }
            }

            @include respond-above('sm') {
                gap: var(--size-big);
                flex-direction: row;
            }
        }
    }

    @include respond-above('sm') {
        padding: 0 var(--size-big);
        margin-bottom: var(--size-huge);
    }
}
</style>
